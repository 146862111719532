import React, { useEffect, useState, useContext } from 'react'
import Dots from "../../svgIcons/Dots";
import Dotts from "../../svgIcons/Dotss";
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from "react-router-dom";
import InjectIntl from 'react-intl-inject';
import Navigation from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from 'react-bootstrap';
import { userContext } from "../../store/context/user";
import { API_BASE_URL } from "../../utils/globals";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
function Pricing(props) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(userContext);
  const [selectedPlan, setSelectedPlan] = useState('')
  const [premuimPlan, setPremuimPlan] = useState('')
  const [proPlan, setProPlan] = useState('')
  const [showPaypalButton, setPaypalButton] = useState(false);
  const [plans, setPlans] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  useEffect(() => {
    let arr = [], arr1 = [];
    (async () => {
      const { data } = await axios.get(`${API_BASE_URL}/plan/`);
      data.plans.map((p) => {
        if (p.status === 'free')
          arr.push(p)
        if (p.status === 'active')
          arr1.push(p)
        if(p.name == "Pro")
        setProPlan(p)
        if(p.name == "Premium")
        setPremuimPlan(p)
      })
      let newArr = [...arr, ...arr1];
      setPlans(newArr);
    })();
    // setAppLanguage(props.language)
  }, []);
  const purchaseNow = (item) => {
    console.log("state", item)
    if (state.isLoggedIn) {
      setSelectedPlan(plans.find(x => x.planId.includes(item)))
      setPaypalButton(true)
    }
    else {
      // navigate('/register', { state: { plan: plan, isMonthly: isMonthly ? isMonthly : false } });

    }
  }
  console.log(selectedPlan, showPaypalButton)
  const updatePlanId = async (id) => {
    try {
      let isMonthly = state.isMonthly;
      const { data } = await axios.patch(
        `${API_BASE_URL}/user/planId/${state.user._id}?planId=${state.user.planId}`,
        { id, isMonthly },
        { headers: { Authorization: `Bearer ${state.user.token}` } }
      );
      if (data) dispatch({ type: "UPDATE_USER", payload: data });
      else {
        alert("An error Occured");
      }
    } catch (error) {
      alert("An Error Occured");
    }
  };
  return (
    <InjectIntl>
      {({ intl }) => (
        <>
        {
          <>
          <div className="max-w-[1500px] mx-auto mt-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
            <Navigation setLanguage={props.setLanguage} language={props.language} />
            <section className="flex items-center flex-col sm:flex-row">
              <div className="flex flex-1 p-4 flex-col px-3  my-10">
                <div className=" w-full text-center my-4 ">
                  <div className="text-sm text-[#A92D62] pricingtitle"> {intl.formatMessage({ id: 'home.pricingMainHeading' })} </div>
                </div>
                <div className="text-[#363636] text-[27px] w-full text-center lang9">

                  <div className="  text-center leading-tight my-4 pricingHeading">
                    {intl.formatMessage({ id: 'home.pricingHeading' })}
                  </div>
                </div>
              </div>
            </section>

            <Container fluid="md" className='pricingdec mt-2  max-w-[1600px] w-[100%] mx-auto '>
              <section className="flex items-center flex-col  max-w-[1600px] w-[100%]">

                {!isTabletOrMobile &&
                  <div className="absolute -left-20 top-90  mt-60 ">
                    <Dots size={120} />
                  </div>
                }   {isTabletOrMobile &&
                  <div className="absolute -left-20 top-90  mt-40 ">
                    <Dots size={120} />
                  </div>
                }
                <div className=' mt-4'>
                  <p className='texttranslation'>
                    You are charged for Cloud Translation based on <b>monthly</b> usage. This page states usage fees for Cloud </p>

                  <p className='texttranslation'>  Translation - <b>Basic, Pro, and Premium.</b> Usage of text translation is calculated in a <b>million of characters.</b>  All</p>
                  <p className='texttranslation'>plans except for guest users are inclusive of batch translations i.e multiple text translations can be</p>

                  <p className='texttranslation'>delivered in a single call. </p>
                </div>


              </section>
            </Container>

            <div className=" w-full text-left  parameters mt-4">
              {!isTabletOrMobile &&
                <div className="absolute right-2 top-90 mt-4 ">
                  <Dotts size={120} />
                </div>
              }
              {isTabletOrMobile &&
                <div className="absolute right-20 top-90 mt-40 ">
                  <Dotts size={120} />
                </div>
              }

              <p className='parameters px-10 p-4'>
                Parameters are different for each type of translation refer the below detailed plans.
              </p>
            </div>


            <div className=" w-full text-left pricepermonth mt-4">

              <p className='pricepermonth px-20  '>
                Prices Per Month
              </p>
              <p className='pricepermonthdec px-20  mt-4 '>
                The text you include in a detect language or translate text request will be charged for the amount that the Cloud Translation processes with no additional charge to detect language. Note that you must select the specific source language to receive the translation. For example, if you submit 500,001 characters for translation without specifying the source language, you are only charged for the 500,001 characters based on the default language.
              </p>
              <p className='pricepermonthdec px-20   mt-4 '>

                Prices are pro rata (proportional and incremental). Charges are scaled to the number of characters actually provided to Cloud Translation. For Example:  550,000 characters for processing within a month, you are charged $0.75. The first 500,000 characters are free, and then you are charged for the additional 50,000 characters sent for detection, translation, or both.

              </p>

            </div>




            <div className=" w-full mt-10 text-center">
              <button
                onClick={() => navigate('/')}
                className="getquote mb-4 mt-10 contactsales">

                Try Translation for Free

              </button>
            </div>

            {!isTabletOrMobile &&
              <div className="absolute left-10 top-90 -mt-20">
                <img src="/assets/imgs/chaticon.png" className="chaticon " />
              </div>
            }

            {isTabletOrMobile &&
              <div className="absolute left-10 top-90 mt-20">
                <img src="/assets/imgs/chaticon.png" className="chaticon " />
              </div>
            }

            {!isTabletOrMobile &&
              <div className="absolute -left-20 top-90 mt-20">
                <Dots size={120} />
              </div>
            }

            {isTabletOrMobile &&
              <div className="absolute -left-20 top-90 -mt-100">
                <Dots size={120} />
              </div>
            }

            <div className=" w-full text-left  mt-4">

              <p className='newuser'>
                If you're new to <span className='text-[#01BABF]'>PoshTranslate,</span> create an account to evaluate how the tool

                performs for various types of file and document <span className='text-[#A92D62]'> translation.</span> </p>

              <p className='newuser'> New customers can try  <span className='text-[#A92D62]'>for free </span>and get 5000 characters  for free in their

                account to run, test, and deploy workloads.</p>

            </div>


            <div className="flex items-center translationfree flex-col sm:flex-row mt-4 px-4">
              <img src='/assets/imgs/translationfree.png' className='translationfree' />


            </div>

            {!isTabletOrMobile &&
              <div className="absolute right-2 top-90 mt-4 ">
                <Dotts size={120} />
              </div>
            }
            <br></br>


            <Container className="mt-10 px-20">
              <div>

                <div className="col-lg-12 col guest mt-4">
                  <p className=''>
                    Cloud Translation - <span className='text-[#A92D62]'> Guest </span></p> </div>
                <div>
                  <p className='gusetdes col-lg-12 col mt-4'>
                    The following pricing information applies to the <b>Text Translate Method</b> call available in<span className='text-[#01BABF]'> 9 different languages</span>
                  </p>
                </div>
              </div>

              <Row className='flexitemhead  mb-2 mt-10  '>
                <Col className="flexitemhead " >Feature</Col>
                <Col className="flexitemhead" >Usage</Col>
                <Col className="flexitemhead" >Price</Col>
              </Row>


              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" >Total usage for text translation</Col>
                <Col className="flexitemDes" >First 5000 characters per month</Col>
                <Col className="flexitemDes" >Free with langauge detection</Col>
              </Row>
              <Row>
                <Col>
                  {/* {!isTabletOrMobile &&
  <button className="float-right  purchaseNow mt-4"  type="submit">Purchase Now</button> 
} */}
                </Col>
              </Row>

            </Container>


            <Container className="mt-10 px-20">
              <div className="">

                <div className="col-lg-12 guest mt-4">
                  <p className=''>
                    Cloud Translation - <span className='text-[#A92D62]'> Basic </span></p> </div>
                <div>
                  <p className='gusetdes col-lg-12 mt-4'>
                    The following pricing information applies to the <b>Text Translate Method</b>call available in<span className='text-[#01BABF]'> 9 different languages</span>



                  </p>
                </div>
              </div>

              <Row className='flexitemhead  mb-2 mt-10 '>
                <Col className="flexitemhead" >Feature</Col>
                <Col className="flexitemhead" >Usage</Col>
                <Col className="flexitemhead" >Price</Col>
              </Row>


              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" >Total usage for text translation</Col>
                <Col className="flexitemDes" >First 500,000 characters per month</Col>
                <Col className="flexitemDes" >Free (worth $7.5 credit every month)</Col>
              </Row>
              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" ></Col>
                <Col className="flexitemDes" >500,001 - 1,500,000 characters per month</Col>
                <Col className="flexitemDes" >Every 1 million characters thereafter will be charged $15</Col>
              </Row>

              <Row>
                <Col>
                  {!isTabletOrMobile && !state.isLoggedIn &&
                    <button onClick={() =>navigate("/register",{state:"Basic Plan"})} 
                      className="float-right   purchaseNow mt-4" >

                      Purchase Now</button>
                  }
                </Col>
              </Row>
            </Container>



            <Container className="mt-10 px-20">
              <div className="">

                <div className="col-lg-12 guest mt-4">
                  <p className=''>
                    Cloud Translation - <span className='text-[#A92D62]'> Pro </span></p> </div>
                <div>
                  <p className='gusetdes col-lg-12 mt-4'>



                    The following pricing information applies to the <b>Text Translate Method</b> and Document Translation Call available in <span className='text-[#01BABF]'> 9 different languages </span>
                    with <span className='text-[#A92D62]'> free cloud storage </span>and <span className='text-[#A92D62]'> no file size limit </span>
                  </p>
                </div>

              </div>
              <Row className='flexitemhead  mb-2 mt-10 '>
                <Col className="flexitemhead" >Feature</Col>
                <Col className="flexitemhead" >Usage</Col>
                <Col className="flexitemhead" >Price</Col>
              </Row>


              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" >Total usage for text translation</Col>
                <Col className="flexitemDes" >First 500,000 characters per month</Col>
                <Col className="flexitemDes" >Free (worth $7.5 credit every month)</Col>
              </Row>
              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" ></Col>
                <Col className="flexitemDes" >500,001 - 1,500,000 characters per month</Col>
                <Col className="flexitemDes" >$20 - Every 1 million characters thereafter will be charged $15</Col>
              </Row>

              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" ></Col>
                <Col className="flexitemDes" >Upload up to 1 document</Col>
                <Col className="flexitemDes" ></Col>
              </Row>

              <Row>
                <Col>
                  {!isTabletOrMobile && proPlan&&
                    <>
                      {
                        state.isLoggedIn ?
                          <>
                            <div className="float-right  purchaseNow mt-4"  >
                              <PayPalScriptProvider
                                options={{
                                  "client-id":
                                  process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                }}
                              >
                                <PayPalButtons
                                  style={{
                                    color: "silver",
                                    layout: "horizontal",
                                    height: 50,
                                    tagline: false,
                                    shape: "pill",
                                    label: "pay",
                                  }}
                                  createOrder={(data, actions) => {
                                    return actions.order.create({
                                      purchase_units: [
                                        {
                                          amount: {
                                            value: 20,
                                          },
                                        },
                                      ],
                                    });
                                  }}
                                  onApprove={(data, actions) => {
                                    return actions.order.capture().then(async (details) => {
                                      const name = details.payer.name.given_name;
                                      let obj = {
                                        email: state.user.email,
                                        planId: proPlan.planId,
                                        amount: proPlan.monthlyPrice,
                                        plan_name: proPlan.name,
                                        isMonthly:true
                                      }
                                      console.log("obj",obj)
                                      // updatePlanId(proPlan.planId)
                                      axios
                                        .post(`${API_BASE_URL}/paymentHistory`, obj)
                                        .then(() => {
                                          navigate('/paymentHistory');
              
                                        })
                                        .catch((err) => console.log("err", err));
              
              
                                    });
                                  }}

                                />
                              </PayPalScriptProvider></div>
                          </>
                          :
                          <>
                            <button className="float-right  purchaseNow mt-4" onClick={() => navigate("/register",{state:"Pro Plan"})}  >Purchase Now</button>
                          </>

                      }
                    </>
                  }

                </Col>
              </Row>
            </Container>



            <Container className="mt-10 px-20">
              <div className="">

                <div className="col-lg-12 guest mt-4">
                  <p className=''>
                    Cloud Translation - <span className='text-[#A92D62]'> Premium  </span></p> </div>
                <div>
                  <p className='gusetdes col-lg-12 mt-4'>
                    The following pricing information applies to the <b>Text Translate Method</b> call available in<span className='text-[#01BABF]'> 9 different languages</span>

                  </p>
                </div>
              </div>

              <Row className='flexitemhead  mb-2 mt-10 '>
                <Col className="flexitemhead" >Feature</Col>
                <Col className="flexitemhead" >Usage</Col>
                <Col className="flexitemhead" >Price</Col>
              </Row>


              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" >Total usage for text translation</Col>
                <Col className="flexitemDes" >First 500,000 characters per month</Col>
                <Col className="flexitemDes" >Free (worth $7.5 credit every month)</Col>
              </Row>
              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" ></Col>
                <Col className="flexitemDes" >500,001 - 1,500,000 characters per month</Col>
                <Col className="flexitemDes" >$500 - Every 1 million characters thereafter will be charged $15</Col>
              </Row>

              <Row className='flexitemDes  mb-2 mt-10'>
                <Col className="flexitemDes" ></Col>
                <Col className="flexitemDes" >Upload up to 40 document</Col>
                <Col className="flexitemDes" ></Col>
              </Row>

              <Row>
                <Col>
                {!isTabletOrMobile &&
                    <>
                      {
                        state.isLoggedIn ?
                          <>
                            <div className="float-right  purchaseNow mt-4"  >
                              <PayPalScriptProvider
                                options={{
                                  "client-id":
                                  process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                }}
                              >
                                <PayPalButtons
                                  style={{
                                    color: "silver",
                                    layout: "horizontal",
                                    height: 50,
                                    tagline: false,
                                    shape: "pill",
                                    label: "pay",
                                  }}
                                  createOrder={(data, actions) => {
                                    return actions.order.create({
                                      purchase_units: [
                                        {
                                          amount: {
                                            value: 500,
                                          },
                                        },
                                      ],
                                    });
                                  }}

                                />
                              </PayPalScriptProvider></div>
                          </>
                          :
                          <>
                            <button className="float-right  purchaseNow mt-4" onClick={() => navigate("/register",{state:"Premium Plan"})}   >Purchase Now</button>
                          </>

                      }
                    </>
                  }
                </Col>
              </Row>
            </Container>



            <Container fluid="md" className='contactusbg mt-10  max-w-[1600px] w-[100%] mx-auto '>
              <div>  <div className="col-lg-12 my-4 px-3  w-full text-center contactcus">Contact Us</div></div>


              <div className=" col-lg-12 my-2 px-3 w-full text-center getintouch"> Get in Touch</div>
              {!isTabletOrMobile &&
                <div className=" col-lg-12 my-2 px-3 w-full text-center contactcusdec"> If you still have a questions,
                  <br>
                  </br>PoshTranslate team is always happy to help!</div>
              }

              {isTabletOrMobile &&
                <div className=" col-lg-12 my-2 px-3 w-full text-center contactcusdec">
                  If you still have a question,  PoshTranslate team is
                  always happy to help. Connect with us get your
                  questions and inquesries answerd and solved.
                  {/* (Needs paraphrasing Adeline) */}</div>
              }


              <div className="text-center sm:text-left w-full mb-4 mt-4 px-4">
                <button className="contactsales">
                  <a href="mailto:info@poshenterpriseinc.com" style={{ colo: "white" }}>Contact Sales</a>
                </button>

              </div>




            </Container>
          </div>
          <Footer language={props.language} />
          </>
        }
          
        </>
      )}</InjectIntl>
  );
}

export default Pricing
