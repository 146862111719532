import React, { useContext, useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { userContext } from "../../store/context/user";
import { AiTwotoneEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { validatePassword } from "../../utils/validate";
import InjectIntl from 'react-intl-inject';
import { Button, Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import FadeLoader from "react-spinners/FadeLoader";
import showPwdImg from '../../../src/show-eye.svg';
import hidePwdImg from '../../../src/hide-eye.svg';
let payments, card;
const Settings = (props) => {
  const [languageSelected, setAppLanguage] = useState('en');
  const { state, dispatch } = useContext(userContext);
  const [isInput, setIsInput] = useState(false);
  const [name, setName] = useState('');
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [planDetails, setPlanDetails] = useState("");
  const [togglePasswordOld, setTogglePasswordOld] = useState(false);
  const [togglePasswordNew, setTogglePasswordNew] = useState(false);

  const [cardInfo, setCardInfo] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [planExpired, setPlanExpired] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [activeCardID, setActiveCardID] = useState('');
  const [hideLoader, setHideLoader] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdOld, setIsRevealPwdOld] = useState(false);
  const [adminDetails, setAdminDetails] = useState({
    clientID: '',
  });
  const [planId, setPlanId] = useState('')
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  useEffect(async () => {
    //Initialise the square payment 
    payments = window.Square.payments(process.env.REACT_APP_SANDBOX_APPLICATION_ID, process.env.REACT_APP_SANDBOX_LOCATION_ID);
    try {
      card = await payments.card();
      await card.attach('#card-container');
    } catch (e) {
    }
    getAllCards();
    if (state && state.user.name)
      setName(state.user.name)

    {
      const { data } = await axios.get(
        `${API_BASE_URL}/user/userid/${state.user.userId}`,

      );
      setUserPlan(data.userBalance);
    }

    axios
      .get(`${API_BASE_URL}/user/${state.user._id}`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      })
      .then(async (res) => {
      
        dispatch({
          type: "UPDATE_PLAN",
          payload: res.data.user.planId
        });
        dispatch({
          type: "UPDATE_PURCHASE_DATE",
          payload: res.data.user?.plan_purchased_on
        });
        setPlanId(res.data.user.planId)
        const { data } = await axios.get(`${API_BASE_URL}/plan/${res.data.user.planId}`);

        setPlanDetails(data.plan)
        // setUsers(res.data.users);
      })
      .catch(() => { });
  }, []);
  const getAllCards = async () => {
    //Retrieve Card Information
    if(state.user.customerID){
      axios
      .post(`${API_BASE_URL}/auth/retrieveCard`, { customer_id: state.user.customerID },{
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        // console.log("res",res.data)
        setCardInfo(res.data);
        if(res.data){
          axios
          .post(`${API_BASE_URL}/auth/checkActiveCard`, {
            cards:res.data,
            customerID:state.user.customerID
          },{
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          })
          .then(async (res) => {
            setActiveCardID(res.data.message)
          })
          .catch(() => { });
        }
        
    })
    }
    else
    setHideLoader(true)
   
  }
  const updateName = async () => {
    setIsInput(false);
    try {
      const { data } = await axios.patch(
        `${API_BASE_URL}/user/name/${state.user._id}?planId=${state.user.planId}`,
        { name },
        { headers: { Authorization: `Bearer ${state.user.token}` } }
      );
      if (data) dispatch({ type: "UPDATE_USER", payload: data });
      else {
        alert("An error Occured");
      }
    } catch (error) {
      alert("An Error Occured");
    }
  };

  const closeInput = () => {
    setIsInput(false);
    setName(state.user.name);
  };

  const resetPassword = async (intl) => {
    if (!validatePassword(newPassword, intl).isValid) {
      alert(`New Password: ${validatePassword(newPassword, intl).message}`);
      return;
    }
    try {
      axios.patch(
        `${API_BASE_URL}/user/password/${state.user._id}?planId=${state.user.planId}`,
        { oldPassword, newPassword },
        { headers: { Authorization: `Bearer ${state.user.token}` } }
      ).then((data) => {
        if (data) {
          alert("Password Updated");
          setOldPassword("");
          setNewPassword("");
          // dispatch({ type: "UPDATE_USER", payload: data });
        } else {
          alert("An error Occured");
        }
      }).catch((err) => {
        alert(err.response?.data?.message)
      })

    } catch (err) {
      console.log(err)
    }
  };
  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      throw new Error(
        `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
      );
    }
  }


  const verifyBuyer = async (payments, token) => {
    const verificationDetails = {
      billingContact: {
        familyName:state.user.name,
        givenName: state.user.name,
        email: state.user.email,
      },
      intent: 'STORE',
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  }

  async function storeCard(token, customerId, verificationToken) {
    const bodyParameters = {
      locationId: process.env.REACT_APP_SANDBOX_LOCATION_ID,
      sourceId: token,
      customerId,
      verificationToken,
    };

    axios
      .post(`${API_BASE_URL}/auth/addCard`, bodyParameters)
      .then((res) => {
        console.log("res", res)
        if (res && res.data.card)
        setCardInfo((cards) => [...cards,  res.data.card]);
      })



  }


  const handleStoreCardMethodSubmission = async (event,
    paymentMethod,
    customerId
  ) => {
    console.log("customerId",state);
    event.preventDefault();
    setShowModal(false)
    const cardButton = document.getElementById('card-button');
    try {
      // disable the submit button as we await tokenization and make a payment request.

      cardButton.disabled = true;
      const token = await tokenize(paymentMethod);

      let verificationToken = await verifyBuyer(payments, token);
      const storeCardResults = await storeCard(
        token,
        customerId,
        verificationToken
      );

      // displayResults('SUCCESS');
      console.debug('Store Card Success', storeCardResults);
    } catch (e) {
      cardButton.disabled = false;
      // displayResults('FAILURE');
      console.error('Store Card Failure', e);
    }
  }

  const removeMyCard = (card_id,index) => {
    console.log(card_id)
    axios
      .post(`${API_BASE_URL}/auth/removeCard`, { card_id,index })
      .then((res) => {
        console.log("res", res)
        setCardInfo(cardInfo.filter((card,i) => {
          return i !== index;
        }))
        // setCardInfo((cards) => [...cards,  res.data.card]);
      }) 
  }
  const makeActive = (card,index) => {
    console.log(card)
    axios
      .post(`${API_BASE_URL}/auth/makeActive`, { card,user:state.user },{
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
      if(res.data){
        setActiveCardID(index)
      }
       
      })
  }
  const override = {
    display: "block",
    borderColor: "black",
  };


  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  return (
    <InjectIntl>
      {({ intl }) => (
        <>

          <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur') ? 'rtl' : 'ltr' }}>
            <Navbar setLanguage={props.setLanguage} language={props.language} />
            <div className="flex flex-row items-start">
              <Sidebar />

              <div className="flex-1 p-4">
                <div className="font-semibold text-lg mb-2 py-2">{intl.formatMessage({ id: 'home.profile' })}</div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="col-span-2 md:col-span-1">
                    <div className="text-sm text-cyan-700 px-2 py-1">{intl.formatMessage({ id: 'home.contactName' })}</div>
                    {isInput ? (
                      <div className="relative border-cyan-900 border-[2px] p-2">
                        <input
                          autoFocus={true}
                          className="w-full h-full outline-none"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="absolute top-[50%] translate-y-[-50%] flex flex-row gap-2"
                          style={languageSelected == 'ar' ? { left: '0.5rem' } : { right: '0.5rem' }}>
                          <AiOutlineCheck
                            onClick={updateName}
                            size={24}
                            className="text-cyan-700 cursor-pointer"
                          />
                          <AiOutlineClose
                            onClick={closeInput}
                            size={24}
                            className="text-cyan-700 cursor-pointer"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="relative border-cyan-600 border-[2px] p-2">
                        {state && state.user && state.user.name}
                        <div
                          onClick={() => setIsInput(true)}
                          style={languageSelected == 'ar' ? { left: '0.5rem' } : { right: '0.5rem' }}
                          className="absolute top-[50%] translate-y-[-50%]"
                        >
                          <AiTwotoneEdit
                            size={24}
                            className="text-cyan-700 cursor-pointer"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="text-sm text-cyan-700 px-2 py-1">{intl.formatMessage({ id: 'home.Email' })}</div>
                    <div className="border-cyan-600 border-[2px] p-2">
                      {state && state.user && state.user.email}
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="text-sm text-cyan-700 px-2 py-1">{intl.formatMessage({ id: 'home.planID' })}</div>
                    <div className="border-cyan-600 border-[2px] p-2">
                      {state && state.user && state.user.planId}
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-span-2 md:col-span-1">
                  <div className="d-flex justify-content-center">

                    <Button onClick={() => { navigate('/pricing') }}
                      style={{ background: '#048A8F', color: '#FFF', height: '40px', border: 'none', borderRadius: "15px" }} > {intl.formatMessage({ id: 'home.upgrade_plan' })}</Button>
                  </div>

                  <div className="text-sm text-cyan-700 px-2 py-1">{"Card Details"}
                  </div>

                  <div style={{ margin: '1px' }} className="d-flex">
                    <>
                    {cardInfo.length == 0 && !hideLoader &&
                  <FadeLoader color={'#048A8F'} loading={true} cssOverride={override} size={50} />}
               
                      {cardInfo &&
                        cardInfo.map((x, index) => {
                          return <div key={index} >
                            <Card style={{ width: '18rem', marginRight: "10px", border: activeCardID == x.id ? "1px solid" : "" }}>
                              <Card.Body>
                                <Card.Title>
                                  {x.cardBrand}
                                </Card.Title>
                                <Card.Text>
                                  {/* <div>Card Name : {x.cardBrand}</div> */}
                                  <div>Card Type : {x.cardType}</div>
                                  <div>Expire Month & Year  : {x.cardYear} Month  {x.cardYear}</div>
                                  {
                                    activeCardID == index ?
                                      <> <div className="text-success" style={{ marginBottom: "10px" }}><b>Card In Use</b></div>
                                      </>
                                      :
                                      <>
                                      </>}

                                  <div>
                                    {activeCardID != index ?
                                      <><Button
                                        style={{ background: '#048A8F', color: '#FFF', border: 'none', borderRadius: "15px" }} onClick={() => removeMyCard(x.cardID,index)}>Remove Card</Button>&nbsp;
                                        <Button
                                          style={{ background: '#048A8F', color: '#FFF', border: 'none', borderRadius: "15px" }} onClick={() => makeActive(x,index)}>make Active </Button>
                                      </> :
                                      <div></div>} </div>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        })
                      }
                      <div
                        tabIndex="-1"
                        aria-hidden="true"
                        className={`${showModal ? "flex" : "hidden"
                          } transition-all overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-10 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
                      >
                        <div className="relative p-4 w-full max-w-4xl h-full  py-20" >
                          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" style={{ marginTop: "35px" }}>
                            {/* Header */}
                            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                              <div className="font-semibold text-xl">{"Add Card "}</div>
                            </div>

                            {/* Body */}
                            <div className="p-6 space-y-6"><div id="card-container"></div></div>

                            {/* Footer */}
                            <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                              <Button onClick={() => { setShowModal(false) }}
                                style={{ background: '#048A8F', color: '#FFF', border: 'none', borderRadius: "15px" }} > Close</Button>
                              <Button id="card-button" onClick={(evt) => { handleStoreCardMethodSubmission(evt, card, state.user.customerID) }}
                                style={{ background: '#048A8F', color: '#FFF', border: 'none', borderRadius: "15px" }} > Submit</Button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </>

                  </div><br /> <Button onClick={() => { setShowModal(true) }}
                    style={{ background: '#048A8F', color: '#FFF', border: 'none', borderRadius: "15px" }} > Add Card</Button>
                  <div className="text-sm text-cyan-700 px-2 py-1">{intl.formatMessage({ id: 'home.planDetails' })}
                  </div>

                  <div style={{ margin: '1px' }}>{planDetails &&
                    <>
                      {intl.formatMessage({ id: 'home.planName' })}:{planDetails.name}<br />
                      {intl.formatMessage({ id: 'home.allowed_languages' })} : {planDetails.languages.map(lang => {
                        if (lang == 'en') return "English ,";
                        if (lang == 'ar') return "Arabic ,";
                        if (lang == 'fr') return "French ,";
                        if (lang == 'ur') return "Urdu ,";
                        if (lang == 'hi') return "Hindi ,";
                        if (lang == 'es') return "Spanish ,";
                        if (lang == 'ja') return "Japanese ,";
                        if (lang == 'zh') return "Chinese ,";
                        if (lang == 'tr') return "Turkish ";
                      })}<br />
                      {intl.formatMessage({ id: 'home.chrCount' })} :{planDetails.characterCount == -1 ? " Unlimited" : planDetails.characterCount}<br />
                      {intl.formatMessage({ id: 'home.docCount' })} :{planDetails.globalDocCount == -1 ? " Unlimited" : planDetails.globalDocCount}<br />
                      {/* {intl.formatMessage({ id: 'home.pdfCount' })} :{planDetails.pdfCount  == -1 ?" Unlimited":planDetails.pdfCount}<br/> */}
                      {/* {intl.formatMessage({ id: 'home.OCRCount' })} :{planDetails.ocrCount  == -1 ?" Unlimited":planDetails.ocrCount}<br/> */}
                      {
                        
                        (userPlan.freeCharactersAvailable && userPlan.charactersMonthlyUsed - 500000 > 0) ?
                          <>
                            <b>Amount to be Paid : {((userPlan.charactersMonthlyUsed - 5) * 15 / 1000000) + "$"}</b>
                          </> : <></>


                      }
                      {
                        
                        (!userPlan.freeCharactersAvailable ) ?
                          <>
                            <b>Amount to be Paid : {(userPlan.charactersMonthlyUsed  * 15 / 1000000) + "$"}</b>
                          </> : <></>


                      }
                      { }
                      <br /> <br />
                    </>
                  }
                  </div>
                </div>
                <hr className="my-3" />

                <div className="font-semibold text-lg mb-2">{intl.formatMessage({ id: 'home.resetPassword' })}</div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="col-span-2 md:col-span-1">
                    <div className="relative border-cyan-900 border-[2px] p-2" style={{ display: 'inline-grid', width: '96%' }}>
                     
                    <div className="pwd-container">
                      <input
                        placeholder={intl.formatMessage({ id: 'home.oldPass' })}
                        type={isRevealPwdOld ? 'text' : 'password'}
                        className="w-full h-full outline-none"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                              <img 
          title={isRevealPwdOld ? "Hide password" : "Show password"}
          src={isRevealPwdOld ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealPwdOld(prevState => !prevState)}
        />
                      <span style={languageSelected == 'ar' ? {
                        marginRight: '95%',
                        marginTop: '-4%'
                      } : {
                        marginLeft: '95%',
                        marginTop: '-4%'
                      }} >
                        
                      </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="relative border-cyan-900 border-[2px] p-2" style={{ display: 'inline-grid', width: '96%' }}>
                    <div className="pwd-container">
                      <input
                        placeholder={intl.formatMessage({ id: 'home.newPass' })}
                        type={isRevealPwd ? 'text' : 'password'}
                        className="w-full h-full outline-none"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                       <img 
          title={isRevealPwd ? "Hide password" : "Show password"}
          src={isRevealPwd ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealPwd(prevState => !prevState)}
        />
                  <span style={languageSelected == 'ar' ? {
                        marginRight: '95%',
                        marginTop: '-4%'
                      } : {
                        marginLeft: '95%',
                        marginTop: '-4%'
                      }} >
                        
                      </span>
                      </div>
                    </div>
                  </div>
                  <button
                    className="col-span-2 bg-cyan-600 hover:bg-cyan-500 py-2 text-white"
                    onClick={() => resetPassword(intl)}
                    disabled={!newPassword || !oldPassword}
                  >
                    {intl.formatMessage({ id: 'home.reset' })}
                  </button>
                </div>
              </div>
            </div>

          </div>

          <Footer />
        </>
      )}</InjectIntl>
  );
};

export default Settings;
