import React,{useState} from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { UserContextProvider } from "./store/context/user";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import OldRegister from "./pages/Register/OldRegister";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SendReset from "./pages/ResetPassword/SendReset";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import TextTranslation from "./pages/TextTranslation/TextTranslation";
import DocumentTranslation from "./pages/DocumentTranslation/DocumentTranslation";
import DocumentHistory from "./pages/DocumentHistory/DocumentHistory";
import ImageTanslation from "./pages/ImageTanslation/ImageTanslation";
import Payment from "./pages/Payment/Payment";
import PaymentBalance from "./pages/PaymentBalance/PaymentBalance";
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AdminLogin from "./pages/Admin/AdminLogin";
import UsersList from "./pages/Admin/UsersList";
import OldUsersList from "./pages/Admin/OldUsersList";
import UserPlans from "./pages/Admin/UserPlans";
import NotFound from "./pages/NotFound/NotFound";
import "./styles/reset.scss";
import Solutions from "./pages/Solutions/Solutions";
import About from "./pages/About/About";
import Pricing from "./pages/Pricing/Pricing";
import Contact from "./pages/Contact/Contact";
import Translate from "./pages/Translate/Translate";
import Dashboard from "./pages/Admin/Dashboard";
import Projects from "./pages/Admin/Projects";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import Transactions from "./pages/Transactions/Transactions";
import Settings from "./pages/Settings/Settings";
import Logs from "./pages/Admin/Logs";
import Apps from "./pages/Apps/Apps";
import messages from "../src/languages-intl/index";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import FilesHistory from "./pages/FilesHistory/FilesHistory";
import Admin from "./pages/Admin/Admin";
import CheckOut from "./pages/Pricing/CheckOut";
import {IntlProvider} from "react-intl";
import PrivacyPolicy from "./pages/Privacypolicy/PrivacyPolicy";
import TermsCondition from "./pages/Privacypolicy/TermsCondition";
// import Payment1 from './pages/Register/payment';
import UserPlanDetail from "./pages/Admin/UserPlanDetail"
import Faq from "./pages/faq/faq";

import ScrollToTop from './components/ScrollToTop';
import RateTranslation from "./pages/Admin/RateTranslation";
import PaymentConfirmation from "./pages/appShare/payment-confirmation";
import AdminConfig from "./pages/Admin/adminConfig";
import RegisterCard from "./pages/Register/registerCard";


function App() {

    const [appLanguage, setAppLanguage] = useState(window.localStorage.getItem('lang')||'en');
  const setLanguage = (language) => {
    setAppLanguage(language)
    window.localStorage.setItem('lang', language);
    
    
}
  return (
    <UserContextProvider  >
      <IntlProvider locale={appLanguage} messages={messages[appLanguage]} >
      <BrowserRouter>
        {/* <Navbar /> */}
        <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Home  setLanguage={setLanguage} language={appLanguage}/>} />
          {/* <Route exact path="/pay" element={<Payment1  setLanguage={setLanguage} language={appLanguage}/>} /> */}
          <Route exact path="/Plans" element={<UserPlanDetail  setLanguage={setLanguage} language={appLanguage}/>} />
          
          <Route exact path="/solutions" element={<Solutions setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/about" element={<About setLanguage={setLanguage} language={appLanguage}/>} />
          <Route exact path="/pricing" element={<Pricing setLanguage={setLanguage} language={appLanguage}/>} />
          <Route exact path="/contact" element={<Contact setLanguage={setLanguage} language={appLanguage}/>} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/checkout" element={<CheckOut />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/termscondition" element={<TermsCondition />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route
            exact
            path="/translate"
            element={
              <ProtectedRoute>
                <Translate setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
          <Route exact path="/login" element={<Login setLanguage={setLanguage} language={appLanguage}/>} />
          <Route exact path="/register" element={<Register  setLanguage={setLanguage} language={appLanguage}/>} />
          <Route exact path="/send-reset" element={<SendReset />} />
          <Route
            exact
            path="/reset-password/:token"
            element={<ResetPassword />}
          />
          <Route
            exact
            path="/dashboard"
            element={
              <ProtectedRoute>
                <UserDashboard setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/transactions"
            element={
              <ProtectedRoute>
                <Transactions setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
           {/* <Route exact path="/27498sogri12" element={<AppShare setLanguage={setLanguage} language={appLanguage}/>} /> */}
           <Route exact path="/invest-success" element={<PaymentConfirmation setLanguage={setLanguage} language={appLanguage}/>} />
          <Route
            exact
            path="/apps"
            element={
              <ProtectedRoute>
                <Apps setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/text-translation"
            element={
              <ProtectedRoute>
                <TextTranslation />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/document-translation"
            element={
              <ProtectedRoute>
                <DocumentTranslation />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/files"
            element={
              <ProtectedRoute>
                <FilesHistory setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/paymentHistory"
            element={
              <ProtectedRoute>
                <PaymentHistory setLanguage={setLanguage} language={appLanguage}/>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/image-translation"
            element={
              <ProtectedRoute>
                <ImageTanslation />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/payment-balance"
            element={
              <ProtectedRoute>
                <PaymentBalance />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/cardVerification"
            element={
              // <ProtectedRoute>
                <RegisterCard />
              // </ProtectedRoute>
            }
          />

          {/*******************************ADMIN routes*******************************/}
          <Route
            exact
            path="/admin/dashboard"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
            <Route
            exact
            path="/admin/paypalconfig"
            element={
              <AdminRoute>
                <AdminConfig />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/admin/projects"
            element={
              <AdminRoute>
                <Projects />
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/admin/users"
            element={
              <AdminRoute>
                <UsersList  setLanguage={setLanguage} language={appLanguage}/>
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/admin/plans"
            element={
              <AdminRoute>
                <UserPlans setLanguage={setLanguage} language={appLanguage}/>
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/admin/logs"
            element={
              <AdminRoute>
                <Logs setLanguage={setLanguage} language={appLanguage}/>
              </AdminRoute>
            }
          />
          <Route
            exact
            path="/admin/rateTranslation"
            element={
              <AdminRoute>
                <RateTranslation setLanguage={setLanguage} language={appLanguage}/>
              </AdminRoute>
            }
          />

          <Route
            // exact
            path="/admin/login"
            element={
              // <AdminRoute>
              <AdminLogin />
              // </AdminRoute>
            }
          />
          
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
      </IntlProvider>
    </UserContextProvider>
  );
}

export default App;
