import React, { useContext, useEffect, useState } from "react";
import Dots from "../../svgIcons/Dots";
import Dotts from "../../svgIcons/Dotss";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap'
import "./Solutions.css";
import SolutionModal from "../../components/Modal/SolutionModal";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive';
import InjectIntl from 'react-intl-inject';
import Navigation from "../../components/Navbar/Navbar";
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
export default function Solutions(props) {



  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
 
  const [showBullets, setShowBullets] = useState([]);

  const [modall, setModal] = useState(false);
  const Toggle = () => setModal(!modall);
  const location = useLocation();
  useEffect(() => {
    if (location.hash == "#pdf") {
      const section = document.getElementById('pdf');
      section.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else if (location.hash == "#text") {
      const section = document.getElementById('text');
      section.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else if (location.hash == "#word") {
      const section = document.getElementById('word');
      section.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    else {
      window.scroll(0, 0)
    }

  })
  const handleShow = (type) => {

    



    let arrText = [{text:"PoshTranslate considers each line of text, a unique translation string"},
    {text:"If two files have the exact same of text, they will be captured as two separate strings"},
    {text:"White spaces are also counted as part of detect language and translation."},
    {text:"User can copy translated text and paste elsewhere."}]

  
    let arrPdf = [{text:"You can upload a PDF file and download the same in a Word file format or PDF."},
    {text:"You can choose the option to receive the text translation via email. "},
    {text:"Text in the tables will also be translated and the structure will be retained."}]

    

    let arrWord = [{text:"You can upload a Word document and download the same only in Word file format."},
    {text:"You can choose to receive the translated document via email."},
    {text:"ML will continuously report progress percentage of the file translated."},
    {text:"Only available for Pro and Premium customers. "}]


    let arrImage = [{text:"Text within the image will be translated in the selected langauge."},
    {text:"Dashboard view to measure the number of characters remaining"}
 ]
    if(type == 'pdf'){
      setShowType('pdf')
      setShowBullets(arrPdf)
      setShow(true);
    }
    else if(type == 'text'){
      setShowType('text')
      setShowBullets(arrText)
      setShow(true);
    }
    else  {
      setShowType('word')
      setShowBullets(arrWord)
      setShow(true);
    }
    if(type == 'image'){
      setShowType('image')
      setShowBullets(arrImage)
      setShow(true);
    }
   
  }
  const displayHeading = () =>{
   
    if(showType == 'text')
    return "Plain Text Translation"
    else if(showType == 'pdf')
    return "PDF Document Translation"
   /*  else 
    return "Word Document Translation" */
    else if(showType == 'word')
    return "Word Document Translation"

    else if(showType == 'image')
    return "Image Translation"
  }
  const displayContents = () =>{
    if(showBullets && showBullets.length>0)
    return showBullets.map(x=>{
      return <li style={{ listStyleType: "disc" }} className="modaltext"> {x.text}</li>
    })
  }
  const handleClose = () => 
  {
    setShow(false);
    setShowBullets('')
    setShowType('')
  }

  return (
    <InjectIntl>
      {({ intl }) => (
        <>
          <div className="max-w-[1590px] mx-auto mt-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
            <Navigation setLanguage={props.setLanguage} language={props.language} />
            <section className="flex items-center flex-col sm:flex-row">
       
            <div className="flex flex-1 p-4 flex-col px-4 mt-20 solutiontitle">
            {!isTabletOrMobile &&
              <div className="absolute left-10 top-20 langIcon">
                <img src="/assets/imgs/lang.png" />
              </div>
}
            {isTabletOrMobile &&
              <div className="absolute left-5 top-40 langIcon">
                <img src="/assets/imgs/lang.png" />
              </div>
}
              {intl.formatMessage({ id: 'home.solutionsHeadding' })}
            </div>
            </section>
            <div className="solutionHeadding  mx-auto " >
              {intl.formatMessage({ id: 'home.solutionSubheadding' })}
            </div>
            <div className="flex items-center"></div>
            <section className="flex flex-col-reverse sm:flex-row bg-[#ffffff] bg-opacity-[0.02] mb-5" id="text">
              <Container className="px-4">
                <Row className="justify-content-md-center py-10 ">
                   <Col xs="3" lg="3"  >
                  <img
                    src="/assets/imgs/txt.png"
                    alt="RTL"

                  />
                </Col>
                <Col xs="8" lg="4">
                    <div className="">
                    {!isTabletOrMobile &&
                      <div className="absolute -right-0 top-60 ">
                        <Dotts size={120} />
                      </div>
                  }
                      <div className="solutioncardHeadding">
                        {intl.formatMessage({ id: 'home.plaintextHeadding' })}
                      </div>
                      <div className=" my-2 solutiontext ">
                        {intl.formatMessage({ id: 'home.plaintextSubheadding' })}
                      </div>
                      <button id='button' className="knowmore  justify-content-end mt-4" onClick={()=>handleShow("text")}>
                        {intl.formatMessage({ id: 'home.knowMore' })}
                        &nbsp;&nbsp; <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </Col>
                  <Col md="auto"></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col md="auto"></Col>
                  <Col xs lg="1">
                  </Col>
                </Row>
              </Container> </section>
            <br></br>
            <section className="flex flex-col-reverse sm:flex-row bg-[#ffffff] bg-opacity-[0.02] mb-5" id="pdf">
              <Container className="px-4">
                <Row className="d-flex justify-content-center py-10 textrow">
                <Col xs="8" lg="4" className="">
                    <div className=" ">
                      <div className="solutioncardHeadding">  {intl.formatMessage({ id: 'home.pdfHeadding' })}
                      </div>
                      <div className=" my-2 solutiontext ">
                        {intl.formatMessage({ id: 'home.pdfSubheadding' })}
                      </div>
                      <button id='button' className="knowmore  justify-content-end mt-4" onClick={()=>handleShow("pdf")}>
                        {intl.formatMessage({ id: 'home.knowMore' })} &nbsp;&nbsp; <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </button></div>
                  </Col>
                  <Col xs="3" lg="3" >
                    <img
                      src="/assets/imgs/pdf.png"
                      alt="RTL"
                    />
                  </Col>
                  <Col md="auto"></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col md="auto"></Col>
                  <Col xs lg="1">
                  </Col>
                </Row>
         
                {!isTabletOrMobile &&
                <div className="absolute -left-20 top-100 ">
                      <Dotts size={120} />
                    </div>
                    }
                      {isTabletOrMobile &&
                <div className="absolute -left-30 top-80  ">
                      <Dotts size={120} />
                    </div>
                    }
                  
              </Container>
            </section>    <br></br>
            <section className="flex flex-col-reverse sm:flex-row bg-[#ffffff] bg-opacity-[0.02] mb-5" id="word">
              <Container className="px-4">
                <Row className="justify-content-md-center py-10 textrow">
                  <Col xs ="3" lg="3">
                    <img
                      src="/assets/imgs/doc.png"
                      alt="RTL"

                    />
                  </Col>
                  <Col xs="8" lg="4">
                    <div className=" ">
                      <div className="solutioncardHeadding">
                        {intl.formatMessage({ id: 'home.wordHeadding' })}    </div>
                      <div className=" my-2 solutiontext ">
                        {intl.formatMessage({ id: 'home.wordSubheadding' })}
                      </div>
                      <button id='button' className="knowmore  justify-content-end mt-4" onClick={()=>handleShow("word")}>
                        {intl.formatMessage({ id: 'home.knowMore' })} &nbsp;&nbsp; <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </Col>
                  <Col md="auto"></Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col md="auto"></Col>
                  <Col xs lg="1">

                  </Col>
                </Row>
              </Container>
            </section>
            <section className="flex flex-col-reverse sm:flex-row bg-[#ffffff] bg-opacity-[0.02] mb-5">
              <Container className="px-4">
                <Row className="justify-content-md-center py-10 textrow">  
                <Col xs="8" lg="4">
                  <div className=" ">
                  {!isTabletOrMobile &&
                    <div className="absolute -right-0 top-120 ">
                      <Dotts size={120} />
                    </div>
                            }
                           
                    <div className="solutioncardHeadding">
                      {intl.formatMessage({ id: 'home.imageHeadding' })}
                    </div>
                    <div className=" my-2 solutiontext ">
                      {intl.formatMessage({ id: 'home.imageSubheadding' })}
                    </div>
                    <button id='button' className="knowmore  justify-content-end mt-4"  onClick={()=>handleShow("image")}>
                      {intl.formatMessage({ id: 'home.knowMore' })}  &nbsp;&nbsp;<i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </Col>
                  <Col xs="3" lg="3">
                    <img
                      src="/assets/imgs/img.png"
                      alt="RTL"
                    />
                  </Col>
                  <Col md="auto"></Col>

                </Row>
                <Row>
                  <Col></Col>
                  <Col md="auto"></Col>
                  <Col xs lg="1">
                  </Col>
                </Row>
              </Container>
            </section>
            <br></br><section className="flex flex-col-reverse sm:flex-row bg-[#ffffff] bg-opacity-[0.02] mb-5">
              <div className="flex flex-1 justify-center items-center">
                <div className="flex flex-col">
                  <div className="text-center bringingSolutions">
                    <p>
                      {intl.formatMessage({ id: 'home.bringingSolutions' })}
                    </p> </div>
                </div>
              </div>
            </section>
            <br></br>
            <section className=" justify-content-md-center">
              <div className="absolute -left-20 top-120 ">
            <Dotts size={120} />
            </div>


 {/*   <Container>
                <Row className="justify-content-md-center justify-center">
                  <Col xs  lg="5" >
                  <div className="ppaudio justify-center">    {intl.formatMessage({ id: 'home.powerPoint' })}
                
                    <div className="items-center  mt-10 justify-center">
                      <img
                        src="/assets/imgs/pp.png"  
                          alt="RTL"
                       
                        
                      />
                    </div>
                    </div>
                  </Col>
                  <Col xs  lg="5">
                    <div className="ppaudio justify-center"> {intl.formatMessage({ id: 'home.audio' })}
                
                    <div className="items-center  mt-10 justify-center">
                      <img
                        src="/assets/imgs/audio.png" 
                        alt="RTL"
                       
                        
                      />
                    </div>
                    </div>
                  </Col>
                </Row>
              </Container>  */}

              <div className="container">
        <div className="row justify-content-md-center ">
            <div className="col-lg-4 col-lg-3 col  mb-4">
                <div className="cardpp ">
              
  
                    <div className="card-body ">
                       
                        <div className="ppaudio  ">    {intl.formatMessage({ id: 'home.powerPoint' })}
                
                <div className=" mt-10">
                  <img
                    src="/assets/imgs/pp.png"  className="img-fluid pp"
                      alt="RTL"
                   
                    
                  />
                </div>
                </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4  col-lg-3 col mb-4">
                <div className="cardpp">
                
  
                    <div className="card-body">
                      
                        <div className="ppaudio "> {intl.formatMessage({ id: 'home.audio' })}
                
                        <div className="   mt-10">
                  <img
                    src="/assets/imgs/audio.png"  className="img-fluid pp"
                    alt="RTL"
                   
                    
                  />
                       </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  


              


     
            </section>
            <br></br>
            <section className="flex flex-col-reverse sm:flex-row bg-[#ffffff] bg-opacity-[0.02] py-10">
              <div className="flex flex-1 justify-center items-center">
                <div className="choose " >
                  {intl.formatMessage({ id: 'home.whyChoose' })}
                </div>
              </div>
            </section>
            <div className="solutionHeadding text-center mb-3 -mb-4 " >  {intl.formatMessage({ id: 'home.aiPowered' })}
            </div>
            {isTabletOrMobile &&
            <div className="flex flex-row flex-wrap justify-center">
              <Card className="text-center card2 ">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center">

                    {intl.formatMessage({ id: 'home.cardHeadding1' })}
                  </Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding1' })}
                  </Card.Text>
                </Card.Body>
              </Card>
            
            {/*   <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center">   {intl.formatMessage({ id: 'home.cardHeadding2' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding2' })}
                  </Card.Text>
                </Card.Body>
              </Card> */}
            </div>
}     
            <div className="flex flex-row flex-wrap justify-center">
            {!isTabletOrMobile &&
              <Card className="text-center card2 ">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center">

                    {intl.formatMessage({ id: 'home.cardHeadding1' })}
                  </Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding1' })}
                  </Card.Text>
                </Card.Body>
              </Card>
}

{!isTabletOrMobile &&
              <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center">   {intl.formatMessage({ id: 'home.cardHeadding2' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding2' })}
                  </Card.Text>
                </Card.Body>
              </Card>
}
              {isTabletOrMobile &&
              <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center">   {intl.formatMessage({ id: 'home.cardHeadding2' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding2' })}
                  </Card.Text>
                </Card.Body>
              </Card>
}

{isTabletOrMobile &&
              <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center"> {intl.formatMessage({ id: 'home.cardHeadding3' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding3' })}
                  </Card.Text>
                </Card.Body>
              </Card>
}
            </div>
            <div className="flex flex-row  flex-wrap justify-center ">
            {!isTabletOrMobile &&
              <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center"> {intl.formatMessage({ id: 'home.cardHeadding3' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding3' })}
                  </Card.Text>
                </Card.Body>
              </Card>
}
              <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center">  {intl.formatMessage({ id: 'home.cardHeadding4' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">

                    {intl.formatMessage({ id: 'home.cardSheadding4' })}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="text-center card2">
                <Card.Body>
                  <Card.Title className="cardtitle justify-center"> {intl.formatMessage({ id: 'home.cardHeadding5' })}</Card.Title>
                  <Card.Text className="cardtext justify-center">
                    {intl.formatMessage({ id: 'home.cardSheadding5' })}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Modal show={show } onHide={handleClose}  className="">
                
                <Modal.Header closeButton className="py-10 px-20 mt-20 h-full">
             
                  <div className="flex flex-row  ">
                    <img src="/assets/imgs/txtmodal.png" className="txtmodal px-10"
                    />
                  </div>
                  <Modal.Title className="modaltitle " >
                  { displayHeading()}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modaltext px-20 " >
                 
                {showBullets && showBullets.length>0 && displayContents()}
             
                   </Modal.Body>
                <Modal.Footer>
                  <img src="/assets/imgs/logomodal.png" className="logomodal" onClick={handleClose}
                  />
                </Modal.Footer>
              </Modal>
            </div>
            <br></br>
         
          </div>

          <Footer language={props.language} />
        </>

      )}</InjectIntl>
  );
}
