import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import "./UserPlans.scss";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import { MdAddChart } from "react-icons/md";
import ModalInput from "../../components/Modal/ModalInput";
import Modal from "../../components/Modal/Modal";
import ModalButton from "../../components/Modal/ModalButton";
import ModalSelect from "../../components/Modal/ModalSelect";
import getFileSize from "../../utils/getFileSize";
import ReactSelect  from "react-select";
import Pagination from "../../components/Pagination/Pagination";
import TH from "../../components/Table/TH";
import TD from "../../components/Table/TD";
import Table from 'react-bootstrap/Table';
import ModalAlert from "../../components/Modal/ModalAlert";
import { FormattedMessage } from 'react-intl';
import InjectIntl from 'react-intl-inject';

const headerContent = [
  "#",
  <FormattedMessage id="home.contactName"></FormattedMessage>,
  <FormattedMessage id="home.languages"></FormattedMessage>,
  <FormattedMessage id="home.characters"></FormattedMessage>,
  // "PDFs",
  <FormattedMessage id="home.documents"></FormattedMessage>,
  <FormattedMessage id="home.images"></FormattedMessage>,
  <FormattedMessage id="home.status"></FormattedMessage>,
 <FormattedMessage id="home.price"></FormattedMessage>,
 <FormattedMessage id="home.actions"></FormattedMessage>,
];
const optionsYN = [
  { label: 'Yes',value: 'yes'  },
  {  label: 'No' ,value: 'no'},
]

const optionPlanType = [
  { label: 'Direct Plan',value: 'direct'  },
  {  label: 'ML Plan' ,value: 'ml'},
]

const newPlan = {
  name: "",
  languages: [],
  characterCount: 0,
  globalDocCount:0,
  globalmaxDocSize:0,
  globalTotalDocSize:0,
  pdfCount: 0,
  maxPdfSize: 0,
  totalPdfSize: 0,
  docCount: 0,
  maxDocSize: 0,
  totalDocSize: 0,
  ocrCount: 0,
  maxOcrSize: 0,
  totalOcrSize: 0,
  monthlyPrice: 0,
  annualPrice: 0,
  direct_translation:{ label: 'Yes',value: 'yes'  },
  document_translation:{ label: 'Yes',value: 'yes'  },
  api_allowed: 0,
  cloud_storage: 0,
  batch_translate_text: 0,
  batch_translate_doc: 0,
  total_requests: 0,
  language_detection: 0,
  plan_type:{ label: 'Direct Plan',value: 'direct'  },
  price_per_document_page:0,
  max_characters_per_page:0

};

const statusTypes = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Guest", value: "guest" },
  { label: "Free", value: "free" },
];

const sortTypes = [
  { label: "Name (A-Z)", value: { key: "name", dir: -1 } },
  { label: "Name (Z-A)", value: { key: "name", dir: 1 } },
  { label: "Languages (Low to High)", value: { key: "languages", dir: -1 } },
  { label: "Languages (High to Low)", value: { key: "languages", dir: 1 } },
  { label: "Status (A-Z)", value: { key: "status", dir: -1 } },
  { label: "Status (Z-A)", value: { key: "status", dir: 1 } },
  {
    label: "Characters (Low to High)",
    value: { key: "characterCount", dir: -1 },
  },
  {
    label: "Characters (High to Low)",
    value: { key: "characterCount", dir: 1 },
  },
  { label: "PDFs (Low to High)", value: { key: "pdfCount", dir: -1 } },
  { label: "PDFs (High to Low)", value: { key: "pdfCount", dir: 1 } },
  { label: "Documents (Low to High)", value: { key: "docCount", dir: -1 } },
  { label: "Documents (High to Low)", value: { key: "docCount", dir: 1 } },
  { label: "Images (Low to High)", value: { key: "ocrCount", dir: -1 } },
  { label: "Images (High to Low)", value: { key: "ocrCount", dir: 1 } },
  { label: "Price (Low to High)", value: { key: "monthlyPrice", dir: -1 } },
  { label: "Price (High to Low)", value: { key: "monthlyPrice", dir: 1 } },
];

export default function UserPlans(props) {
  const [allPlans, setAllPlans] = useState([]);
  const [nameFeedback, setNameFeedback] = useState("Min. 3 characters");
  const [langFeedback, setLangFeedback] = useState("Min. 3 characters");
  const [formValid, setFormValid] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [plansToDisplay, setPlansToDisplay] = useState([]);
  const [currentDeletePlanId, setCurrentDeletePlanId] = useState("");
  const [currentEditPlanId, setCurrentEditPlanId] = useState("");
  const [currentEditPlan, setCurrentEditPlan] = useState({});
  const [newPlanData, setNewPlanData] = useState({});
  const { state } = useContext(userContext);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const navigate = useNavigate();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [sortBy, setSortBy] = useState(sortTypes[0].value);

  const [statusType, setStatusType] = useState(statusTypes[0].value);
  
const [show, setShow] = useState(false);
const [title,setTitle]=useState('')

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${API_BASE_URL}/languages`);
      setAvailableLanguages(
        data.result.map((a) => ({
          label: a.split(" ")[0],
          value: a.split(" ")[1].slice(1, 3),
        }))
      );
    })();
  }, []);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/plan/admin`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        setAllPlans(res.data.plans);
        setFilteredPlans(res.data.plans);
        setCurrentDeletePlanId("");
        setCurrentEditPlan({});
        setNewPlanData(newPlan);
      })
      .catch((err) =>{
        setTitle(<FormattedMessage id="home.alertGetPlan"></FormattedMessage>)
        setShow(true)
      });
  }, [shouldUpdate]);

  useEffect(() => {
    setNewPlanData(newPlan);
  }, []);


  const handleSelectPlan = (plan) => {
    currentEditPlan.name = plan.name;
    currentEditPlan.status = plan.status;
    currentEditPlan.languages = availableLanguages.filter((l) =>
      plan.languages.includes(l.value)
    );
    currentEditPlan.characterCount = plan.characterCount;
    currentEditPlan.pdfCount = plan.pdfCount;
    currentEditPlan.maxPdfSize = plan.maxPdfSize;
    currentEditPlan.totalPdfSize = plan.totalPdfSize;
    currentEditPlan.docCount = plan.docCount;
    currentEditPlan.maxDocSize = plan.maxDocSize;
    currentEditPlan.totalDocSize = plan.totalDocSize;
    currentEditPlan.ocrCount = plan.ocrCount;
    currentEditPlan.maxOcrSize = plan.maxOcrSize;
    currentEditPlan.totalOcrSize = plan.totalOcrSize;
    currentEditPlan.monthlyPrice = plan.monthlyPrice;
    currentEditPlan.annualPrice = plan.annualPrice;

    currentEditPlan.globalDocCount = plan.globalDocCount;
    currentEditPlan.globalmaxDocSize = plan.globalmaxDocSize;
    currentEditPlan.globalTotalDocSize = plan.globalTotalDocSize;

    currentEditPlan.direct_translation = plan.direct_translation;
    currentEditPlan.image_translation = plan.image_translation;
    currentEditPlan.document_translation = plan.document_translation;
    currentEditPlan.api_allowed = plan.api_allowed;
    currentEditPlan.cloud_storage = plan.cloud_storage;
    currentEditPlan.batch_translate_text = plan.batch_translate_text;
    currentEditPlan.batch_translate_doc = plan.batch_translate_doc;
    currentEditPlan.total_requests = plan.total_requests;
    currentEditPlan.language_detection = plan.language_detection;

    currentEditPlan.plan_type = plan.plan_type;
    currentEditPlan.price_per_document_page = plan.price_per_document_page;
    currentEditPlan.max_characters_per_page = plan.max_characters_per_page;
    
    setCurrentEditPlanId(plan._id);
    navigate("/Plans",{state:{currentEditPlan,plan}})
    setCurrentEditPlan({ ...currentEditPlan });
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentEditPlan({ ...currentEditPlan, [name]: value });
  };

 /*  const handleCreateInputChange = (event) => {
    
  
    const { name, value } = event.target;
    setNewPlanData({ ...newPlanData, [name]: value });
  }; */

  const handleCreateInputChange = (event) => {
    
    const { name, value } = event.target;
   
   /*  if(name == 'password' && !validatePasswordNew(value).isValid) 
    {
      const resultPassword = validatePasswordNew(value);
      setPasswordFeedback(resultPassword.message);
    } 
    if(name == 'password' && validatePasswordNew(value).isValid) 
    setPasswordFeedback('');
    if(name == 'email' && !validateEmail(value).isValid) 
    {
      const resultPassword = validateEmail(value);
      setEmailFeedback(resultPassword.message);
    }
    if(name == 'email' && validateEmail(value).isValid) 
    setEmailFeedback("") */;
    if(name == 'name' && value.length <3) 
    setNameFeedback('Min 3 Characters');
    if(name == 'name' && value.length >3) 
    setNameFeedback('');
    

  
   
    setNewPlanData({ ...newPlanData, [name]: value });
  };


  const handleEditPlan = () => {
    setEditModal(false);

    axios
      .patch(
        `${API_BASE_URL}/plan/${currentEditPlanId}`,
        {
          ...currentEditPlan,
          languages: currentEditPlan.languages.map((l) => l.value),
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(() => setShouldUpdate(!shouldUpdate))
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertUpdatePlan"></FormattedMessage>)
				setShow(true)
			  });

  };

  const handleCreatePlan = () => {
   /* 
    if (newPlanData.name.trim() === "") {
      alert("Name cannot be Empty");
    }
    else{ */
      setCreateModal(false);
      axios
      .post(
        `${API_BASE_URL}/plan/create`,
        {
          ...newPlanData,
          languages: newPlanData.languages.map((l) => l.value),
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(() => setShouldUpdate(!shouldUpdate))
     

      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertCreatePlan"></FormattedMessage>)
				setShow(true)
			  });
  
    
  };

  const handleDeletePlan = () => {
    setDeleteModal(false);
    axios
      .delete(`${API_BASE_URL}/plan/${currentDeletePlanId}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then(() => setShouldUpdate(!shouldUpdate))
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertDeletePlan"></FormattedMessage>)
				setShow(true)
			  });
  };

  useEffect(() => {
    let filtered = allPlans;
    filtered = filtered.filter(
      (f) => f.status === statusType || statusType === "all"
    );
    const { key, dir } = sortBy;
    if (key === "languages")
      filtered = filtered.sort((a, b) =>
        a[key].length < b[key].length ? dir : -dir
      );
    else filtered = filtered.sort((a, b) => (a[key] < b[key] ? dir : -dir));
    setFilteredPlans(filtered);
  }, [allPlans, sortBy, statusType]);


  useEffect(() => {
    console.log("formValid",nameFeedback,langFeedback)
    if(!nameFeedback )
    setFormValid(true)
    else
    setFormValid(false)

  },[nameFeedback,langFeedback])

  return (
    <InjectIntl>
    {({ intl }) => (
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>

      <div className="flex flex-row">
        <AdminSidebar />

        <div className="w-full flex-1 p-4">
          <div className="w-full">
            <div className="font-semibold text-[24px] mb-2">{intl.formatMessage({ id: 'home.plans' })}</div>
            <button
              onClick={() => navigate("/Plans")}
              className="bg-[#A92D62] text-white px-2 py-1 rounded-sm flex flex-row items-center"
            >
              <MdAddChart size={24} className="mr-4" />
              <span>{intl.formatMessage({ id: 'home.addPlan' })}</span>
            </button>
            <CreateModal
            intl={intl}
              show={createModal}
              onHide={() => setCreateModal(false)}
              handleCreateInputChange={handleCreateInputChange}
              handleCreatePlan={handleCreatePlan}
              nameFeedback={nameFeedback}
              langFeedback={langFeedback}
              formValid={formValid}
              newPlanData={newPlanData}
              availableLanguages={availableLanguages}
            />
          </div>

          <div className="px-1">
            <div className="font-semibold">{intl.formatMessage({ id: 'home.filters' })}</div>
            <div className="flex flex-row gap-2 flex-wrap py-2">
              <div>
                <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.access' })}</div>
                <select
                  value={statusType}
                  onChange={(e) => setStatusType(e.target.value)}
                  className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                >
                  {statusTypes.map((a) => (
                    <option value={a.value}>{a.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.sort' })}</div>
                <select
                  value={JSON.stringify(sortBy)}
                  onChange={(e) => setSortBy(JSON.parse(e.target.value))}
                  className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                >
                  {sortTypes.map((a) => (
                    <option value={JSON.stringify(a.value)}>{a.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <Table responsive>
            <thead className="bg-[#048A8F]">
              <tr>
                {headerContent.map((item, index) => (
                  <TH
                    className={`${index === 0 ? "rounded-l-md pl-2" : ""} ${
                      index === headerContent.length - 1
                        ? "rounded-r-md pr-2"
                        : ""
                    }`}
                    key={index}
                  >
                    {item}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {plansToDisplay.map((plan, index) => (
                <tr key={index}>
                  <TD className="pl-2">{index + 1}</TD>
                  {/* <TD>{plan.planId}</TD> */}
                  <TD>{plan.name}</TD>
                  <TD>{plan.languages.length}</TD>
                  <TD>{plan.characterCount == -1?"Unlimited":plan.characterCount}</TD>
                  {/* <TD>
                    {plan.pdfCount == -1?"Unlimited":plan.pdfCount} x {getFileSize(plan.maxPdfSize)} <br /> (
                    {getFileSize(plan.totalPdfSize)} Total)
                  </TD> */}
                  <TD>
                    {plan.globalDocCount == -1?"Unlimited":plan.globalDocCount} x {getFileSize(plan.globalmaxDocSize)} <br />(
                    {getFileSize(plan.globalTotalDocSize)} Total)
                  </TD>
                  <TD>
                    {plan.ocrCount == -1?"Unlimited":plan.ocrCount} x {getFileSize(plan.maxOcrSize)} <br />(
                    {getFileSize(plan.totalOcrSize)} Total)
                  </TD>
                  <TD>
                    Monthly:{plan.monthlyPrice}$ <br /> 
                  </TD>
                  <TD>
                    {plan.status === "active" ? (
                      <div className="text-sm text-green-600">Active</div>
                    ) : plan.status === "inactive" ? (
                      <div className="text-sm text-amber-600">Inactive</div>
                    ) : plan.status === "free" ? (
                      <div className="text-sm text-amber-600">Free</div>
                    ) : (
                      <div className="text-sm text-blue-600">Guest</div>
                    )}
                  </TD>
                  <TD>
                    <i
                      className="fa fa-solid fa-edit userplans__table__action"
                      onClick={() => {
                        handleSelectPlan(plan);
                        // setEditModal(true);
                      }}
                    ></i>
                    <EditModal
                    intl={intl}
                      show={editModal}
                      onHide={() => setEditModal(false)}
                      handleEditInputChange={handleEditInputChange}
                      handleEditPlan={handleEditPlan}
                      currentEditPlan={currentEditPlan}
                      availableLanguages={availableLanguages}
                    />
                    {/* <i
                      className="fa fa-solid fa-trash userplans__table__action"
                      onClick={() => {
                        setCurrentDeletePlanId(plan._id);
                        setDeleteModal(true);
                      }}
                    ></i>
                    <DeleteModal
                      show={deleteModal}
                      onHide={() => setDeleteModal(false)}
                      handleDeletePlan={handleDeletePlan}
                    /> */}
                  </TD>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            data={filteredPlans}
            updateData={(data) => setPlansToDisplay(data)}
          />
        </div>
      </div>
      <CreateModall
              show={show}
              title={title}
              setShow={setShow}
            />
        
    </div>
     )}</InjectIntl>
  );
}

const EditModal = ({
  intl,
  handleEditPlan,
  currentEditPlan,
  handleEditInputChange,
  show,
  onHide,
  availableLanguages,
}) => {
  return (
    <Modal
      show={show}
      title={intl.formatMessage({ id: 'home.editPlan' })} 
      footer={() => <ModalButton text={intl.formatMessage({ id: 'home.close' })} onClick={onHide} />}
      body={() => (
        <div>
          <div className="grid grid-cols-3 gap-1">
            <ModalInput
              label="Name"
              value={currentEditPlan.name}
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: 'home.contactName' })}
              onChange={handleEditInputChange}
             
              required
              />
            {/* <ModalInput
              value={currentEditPlan.languages}
              type="number"
              name="languages"
              placeholder="Supported Languages"
              onChange={handleEditInputChange}
              min={1}
              max={9}
              required
            /> */}
            <div>
              <div className="text-xs text-capitalize">{intl.formatMessage({ id: 'home.languages' })}</div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    handleEditInputChange({
                      target: { name: "languages", value: v },
                    });
                  }}
                  isMulti={true}
                  menuPosition="fixed"
                  value={currentEditPlan.languages}
                  options={availableLanguages}
                  disabled
                />
              </div>
            </div>
            <div>
              <div className="text-xs text-capitalize">Plan Type </div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    handleEditInputChange({
                      target: { name: "plan_type", value:v },
                    });
                  }}
                  menuPosition="fixed"
                  value={currentEditPlan.plan_type}
                  options={optionPlanType}
                />
              </div>
            </div>
            <ModalInput
              value={currentEditPlan.characterCount}
              type="number"
              min={0}
              name="characterCount"
              placeholder={intl.formatMessage({ id: 'home.allowedCharacters' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan?.globalDocCount}
              type="number"
              min={0}
              name="globalDocCount"
              placeholder={"Global Allowed Docs"}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan?.globalmaxDocSize}
              type="number"
              min={0}
              name="globalmaxDocSize"
              placeholder={"Max global Doc size(Bytes)"}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan?.globalTotalDocSize}
              type="number"
              min={0}
              name="globalTotalDocSize"
              placeholder={"Total global Doc Storage(Bytes)"}
              onChange={handleEditInputChange}
            />
              {/* <ModalInput
              value={currentEditPlan.pdfCount}
              type="number"
              min={0}
              name="pdfCount"
              placeholder={intl.formatMessage({ id: 'home.pdfs' })}
              onChange={handleEditInputChange}
            /> */}
            {/* <ModalInput
              value={currentEditPlan.maxPdfSize}
              type="number"
              min={0}
              name="maxPdfSize"
              placeholder={intl.formatMessage({ id: 'home.maxPDF' })}
              onChange={handleEditInputChange}
            /> */}
            {/* <ModalInput
              value={currentEditPlan.totalPdfSize}
              type="number"
              min={0}
              name="totalPdfSize"
              placeholder={intl.formatMessage({ id: 'home.totalPDF' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.docCount}
              type="number"
              min={0}
              name="docCount"
              placeholder={intl.formatMessage({ id: 'home.allowedDoc' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.maxDocSize}
              type="number"
              min={0}
              name="maxDocSize"
              placeholder={intl.formatMessage({ id: 'home.maxDoc' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.totalDocSize}
              type="number"
              min={0}
              name="totalDocSize"
              placeholder={intl.formatMessage({ id: 'home.docStorage' })}
              onChange={handleEditInputChange}
            /> */}
            <ModalInput
              value={currentEditPlan.ocrCount}
              type="number"
              min={0}
              name="ocrCount"
              placeholder={intl.formatMessage({ id: 'home.allowedOcrs' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.maxOcrSize}
              type="number"
              min={0}
              name="maxOcrSize"
              placeholder={intl.formatMessage({ id: 'home.maxOCR' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.totalOcrSize}
              type="number"
              min={0}
              name="totalOcrSize"
              placeholder={intl.formatMessage({ id: 'home.totalOCR' })}
              onChange={handleEditInputChange}
            />
                   <div>
              <div className="text-xs text-capitalize">Direct Translation </div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    let temp =[]
                    handleEditInputChange({
                      target: { name: "direct_translation", value:v },
                    });
                  }}
                  menuPosition="fixed"
                  value={currentEditPlan.direct_translation}
                  options={optionsYN}
                />
              </div>
            </div>
            <div>
              <div className="text-xs text-capitalize">Document Translation</div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    let temp =[]
                    handleEditInputChange({
                      target: { name: "document_translation", value: v  },
                    });
                  }}
                  menuPosition="fixed"
                  value={currentEditPlan.document_translation}
                  options={optionsYN}
                />
              </div>
            </div>
            <ModalInput
              value={currentEditPlan?.api_allowed}
              type="number"
              min={0}
              name="api_allowed"
              placeholder={"API Allowed"}
              
              onChange={handleEditInputChange}
            />
            
             <ModalInput
              value={currentEditPlan?.cloud_storage}
              type="number"
              min={0}
              name="cloud_storage"
              placeholder={"Cloud Storage"}
              
              onChange={handleEditInputChange}
            />
              <ModalInput
              value={currentEditPlan.batch_translate_text}
              type="number"
              min={0}
              name="batch_translate_text"
              placeholder={"Batch Translate Text"}
              onChange={handleEditInputChange}
            />
              <ModalInput
              value={currentEditPlan.batch_translate_doc}
              type="number"
              min={0}
              name="batch_translate_doc"
              placeholder={"Batch Translate Document"}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.total_requests}
              type="number"
              min={0}
              name="total_requests"
              placeholder={"total_requests"}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.price_per_document_page}
              type="number"
              min={0}
              name="price_per_document_page"
              placeholder={"Price Per Document Page"}
              onChange={handleEditInputChange}
            />
              <ModalInput
              value={currentEditPlan.max_characters_per_page}
              type="number"
              min={0}
              name="max_characters_per_page"
              placeholder={"Max Characters Per Page"}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.monthlyPrice}
              type="number"
              min={0}
              name="monthlyPrice"
              placeholder={intl.formatMessage({ id: 'home.monthlyPrice' })}
              onChange={handleEditInputChange}
            />
            <ModalInput
              value={currentEditPlan.annualPrice}
              type="number"
              min={0}
              name="annualPrice"
              placeholder={intl.formatMessage({ id: 'home.annualPrice' })}
              onChange={handleEditInputChange}
            />
            <ModalSelect
              value={currentEditPlan.status}
              name="status"
              label={intl.formatMessage({ id: 'home.status' })}
              onChange={handleEditInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="guest">Guest</option>
              <option value="free">Free</option>
            </ModalSelect>
          </div>
          <ModalButton onClick={handleEditPlan} type="blue" text={intl.formatMessage({ id: 'home.save' })} />
        </div>
      )}
    />
  );
};

const DeleteModal = ({ show, onHide, handleDeletePlan }) => {
  return (
    <Modal
      show={show}
      body={null}
      title="Are you sure you want to delete this plan?"
      footer={() => (
        <>
          <ModalButton text="Close" onClick={onHide} />
          <ModalButton
            text="Delete Plan"
            type="red"
            onClick={handleDeletePlan}
          />
        </>
      )}
    />
  );
};

const CreateModal = ({
  intl,
  newPlanData,
  handleCreatePlan,
  handleCreateInputChange,
  nameFeedback,
  langFeedback,
  show,
  onHide,
  formValid,
  availableLanguages,
}) => {
  return (
    <Modal
      show={show}
      title={intl.formatMessage({ id: 'home.createPlan' })} 
      footer={() => <ModalButton text={intl.formatMessage({ id: 'home.close' })} onClick={onHide} />}
      body={() => (
        <div>
          <div className="grid grid-cols-3 gap-1">
            <ModalInput
              value={newPlanData.name}
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: 'home.contactName' })} 
              onChange={handleCreateInputChange}
              nameFeedback={nameFeedback}
            />
            <div>
              <div className="text-xs text-capitalize">{intl.formatMessage({ id: 'home.languages' })} </div>
              <div>
                <ReactSelect
                    onChange={(v) => {
                      console.log("lang",v)
                      handleCreateInputChange({
                        target: { name: "languages", value: v },
                      });
                    }}
                  isMulti={true}
                  menuPosition="fixed"
                  value={newPlanData.languages}
                  options={availableLanguages}
         
              langFeedback={langFeedback}

          
                />
              </div>
            </div>
            <div>
              <div className="text-xs text-capitalize">Plan Type </div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    handleCreateInputChange({
                      target: { name: "plan_type", value:v },
                    });
                  }}
                  menuPosition="fixed"
                  value={newPlanData.plan_type}
                  options={optionPlanType}
                />
              </div>
            </div>
            <ModalInput
              value={newPlanData.characterCount}
              type="number"
              min={0}
              name="characterCount"
              placeholder={intl.formatMessage({ id: 'home.allowedCharacters' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData?.globalDocCount}
              type="number"
              min={0}
              name="globalDocCount"
              placeholder={"Global Allowed Docs"}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData?.globalmaxDocSize}
              type="number"
              min={0}
              name="globalmaxDocSize"
              placeholder={"Max global Doc size(Bytes)"}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData?.globalTotalDocSize}
              type="number"
              min={0}
              name="globalTotalDocSize"
              placeholder={"Total global Doc Storage(Bytes)"}
              onChange={handleCreateInputChange}
            />
            {/* <ModalInput
              value={newPlanData.pdfCount}
              type="number"
              min={0}
              name="pdfCount"
              placeholder={intl.formatMessage({ id: 'home.pdfs' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.maxPdfSize}
              type="number"
              min={0}
              name="maxPdfSize"
              placeholder={intl.formatMessage({ id: 'home.maxPDF' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.totalPdfSize}
              type="number"
              min={0}
              name="totalPdfSize"
              placeholder={intl.formatMessage({ id: 'home.totalPDF' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.docCount}
              type="number"
              min={0}
              name="docCount"
              placeholder={intl.formatMessage({ id: 'home.allowedDoc' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.maxDocSize}
              type="number"
              min={0}
              name="maxDocSize"
              placeholder={intl.formatMessage({ id: 'home.maxDoc' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.totalDocSize}
              type="number"
              min={0}
              name="totalDocSize"
              placeholder={intl.formatMessage({ id: 'home.docStorage' })}
              onChange={handleCreateInputChange}
            /> */}
            <ModalInput
              value={newPlanData.ocrCount}
              type="number"
              min={0}
              name="ocrCount"
              placeholder={intl.formatMessage({ id: 'home.allowedOcrs' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.maxOcrSize}
              type="number"
              min={0}
              name="maxOcrSize"
              placeholder={intl.formatMessage({ id: 'home.maxOCR' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.totalOcrSize}
              type="number"
              min={0}
              name="totalOcrSize"
              placeholder={intl.formatMessage({ id: 'home.totalOCR' })}
              onChange={handleCreateInputChange}
            />
             <div>
              <div className="text-xs text-capitalize">Direct Translation </div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    console.log("v",v)
                    handleCreateInputChange({
                      target: { name: "direct_translation", value:v },
                    });
                  }}
                  menuPosition="fixed"
                  value={newPlanData.direct_translation}
                  options={optionsYN}
                />
              </div>
            </div>
            <div>
              <div className="text-xs text-capitalize">Document Translation</div>
              <div>
                <ReactSelect
                  onChange={(v) => {
                    let temp =[]
                    handleCreateInputChange({
                      target: { name: "document_translation", value: v  },
                    });
                  }}
                  menuPosition="fixed"
                  value={newPlanData.document_translation}
                  options={optionsYN}
                />
              </div>
            </div>
            <ModalInput
              value={newPlanData?.api_allowed}
              type="number"
              min={0}
              name="api_allowed"
              placeholder={"API Allowed"}
              
              onChange={handleCreateInputChange}
            />
            
             <ModalInput
              value={newPlanData?.cloud_storage}
              type="number"
              min={0}
              name="cloud_storage"
              placeholder={"Cloud Storage"}
              
              onChange={handleCreateInputChange}
            />
              <ModalInput
              value={newPlanData.batch_translate_text}
              type="number"
              min={0}
              name="batch_translate_text"
              placeholder={"Batch Translate Text"}
              onChange={handleCreateInputChange}
            />
              <ModalInput
              value={newPlanData.batch_translate_doc}
              type="number"
              min={0}
              name="batch_translate_doc"
              placeholder={"Batch Translate Document"}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.total_requests}
              type="number"
              min={0}
              name="total_requests"
              placeholder={"total_requests"}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.language_detection}
              type="number"
              min={0}
              name="language_detection"
              placeholder={"language_detection"}
              onChange={handleCreateInputChange}
            />
            
            <ModalInput
              value={newPlanData.monthlyPrice}
              type="number"
              min={0}
              name="monthlyPrice"
              placeholder={intl.formatMessage({ id: 'home.monthlyPrice' })}
              onChange={handleCreateInputChange}
            />
            <ModalInput
              value={newPlanData.annualPrice}
              type="number"
              min={0}
              name="annualPrice"
              placeholder={intl.formatMessage({ id: 'home.annualPrice' })}
              onChange={handleCreateInputChange}
            />
             <ModalInput
              value={newPlanData.price_per_document_page}
              type="number"
              min={0}
              name="price_per_document_page"
              placeholder={"Price Per Document Page"}
              onChange={handleCreateInputChange}
            />
              <ModalInput
              value={newPlanData.max_characters_per_page}
              type="number"
              min={0}
              name="max_characters_per_page"
              placeholder={"Max Characters Per Page"}
              onChange={handleCreateInputChange}
            />
          </div>
          <ModalButton onClick={handleCreatePlan} type="blue" text={intl.formatMessage({ id: 'home.save' })} disabled={!formValid}/>
        </div>
      )}
    />
  );
};



		
		const CreateModall = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};
