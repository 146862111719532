import axios from "axios";
import React, { useContext, useEffect, useRef, useState, CSSProperties } from "react";
import { userContext } from "../../store/context/user";
import { API_BASE_URL } from "../../utils/globals";
import S3 from "react-aws-s3";
import { FiUploadCloud } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import getFileSize from "../../utils/getFileSize";
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FormattedMessage } from 'react-intl';

import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["PDF", "TXT", "DOCX"];

const DocumentTranslation = ({ srcLang, destLang,intl,languageSelected,socket,fileProcessed,setFileProcessed,setErrorInProcessing,errorInProcessing,errorMsg,changePercentage }) => {
  const [bucketCredentials, setBucketCredentials] = useState({});
  const [file, setFile] = useState(null);
  const [targetFileId, setTargetFileId] = useState(null);
  const [targetFileStatus, setTargetFileStatus] = useState(1);
  const [sourceFileLink, setSourceFileLink] = useState(null);
  const [targetFileLink, setTargetFileLink] = useState(null);
  const [sendMailFlag,setSendMailFlag] = useState(false)
  // const [errorMsg, setErrorMsg] = useState(''); 
  const [fileTypeDownload, setfileTypeDownload] = useState(''); 
  const [title,setTitle]=useState('')
  const [show, setShow] = useState(false);
  let [loading, setLoading] = useState(true);
  const [limit ,setLimit ]= useState('')
  const [updateCount,setUpdateCount]=useState(false)
  const [percentage, setPercentage] = useState(0);
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] =useState(false);
  const refreshIntervalId = React.useRef();
  const { state, dispatch } = useContext(userContext);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const fileInputRef = useRef();
 
  const handleFileUpload = (file) => {
    if (file.name.includes('.pdf') || file.name.includes('.txt') || file.name.includes('.docx') || file.name.includes('.doc'))
      setFile(file);
    else{
      setTitle(<FormattedMessage id="home.alertfileExtenstion"></FormattedMessage>)
      setLimit(' .pdf, .docx, .txt, .doc')
      setShow(true)
    }

  };
  const kFormatter = (num) => {
    if (num >= 1) {
      return num.toLocaleString();
    }
    return num;
  }


  useEffect(async () => {
    if (state && state.user) {
      const { data } = await axios.get(
        `${API_BASE_URL}/user/userid/${state.user.userId}`,
      )
      dispatch({
        type: "UPDATE_GLOBAL_DOC_STORAGE_BALANCE",
        payload: data.userBalance.globalDocStorageBalance
      });dispatch({
        type: "UPDATE_GLOBAL_DOC_COUNT_BALANCE",
        payload: data.userBalance.globalDocCountBalance
      });
      axios
      .get(`${API_BASE_URL}/user/${state.user._id}`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      })
      .then(async(res) => {
        dispatch({
          type: "UPDATE_PLAN",
          payload:res.data.user.planId
        });
        if(res.data.user && res.data.user.planId){
          axios
          .get(`${API_BASE_URL}/plan/${res.data.user.planId}`)
          .then((response) => {
            // console.log("response",response)
            if(!response?.data.plan)
            localStorage.removeItem("poshTranslateState");
          })
          .catch((error) => {
            setTitle(<FormattedMessage id="home.alertpdfSize"></FormattedMessage>)
            setShow(true)
            setTargetFileStatus(1);
            console.log(error);
          });
        }
       
      })

     
    }
   
  }, [])
  useEffect(async () => {
    if (state && state.user && updateCount) {
      const { data } = await axios.get(
        `${API_BASE_URL}/user/userid/${state.user.userId}`,
      )
      dispatch({
        type: "UPDATE_GLOBAL_DOC_STORAGE_BALANCE",
        payload: data.userBalance.globalDocStorageBalance
      });dispatch({
        type: "UPDATE_GLOBAL_DOC_COUNT_BALANCE",
        payload: data.userBalance.globalDocCountBalance
      });
    }
  }, [updateCount])

  useEffect(() => {
        setPercentage(changePercentage);
  }, [changePercentage]);
  const joinSocketRoom = (room) => {
    socket.emit('join', room);
  }
  const handleDocumentUpload = (event) => {
    setPercentage(5)
    var fileType = 1;
    event.preventDefault();
    console.log("state.document_translation",state)
    if(state && state.document_translation.value == "no")
    {
      setTitle("You dont have permission for file translate")
      setShow(true)
      return;
    }
    if (!file) {
      setTitle(<FormattedMessage id="home.alertSelectfile"></FormattedMessage>)
      setShow(true)
      return;
    }
    if (srcLang === "" || destLang === "" || srcLang === destLang) {
      setTitle(<FormattedMessage id="home.alertLanguageempty"></FormattedMessage>)
      setShow(true)
      setTargetFileStatus(1);
      return;
    }
    if(file.name.split('.')[1] == 'docx' || file.name.split('.')[1] == 'doc' || file.name.split('.')[1] == 'txt')
     
    if (fileType === 1 && state.globalDocCountBalance == 0) {
      setTitle(<FormattedMessage id="home.alertBlanceempty"></FormattedMessage>)
      setShow(true)
      return;
    }
    if (fileType === 2 && !state.docCountBalance) {
      setTitle(<FormattedMessage id="home.alertBlanceempty"></FormattedMessage>)
      setShow(true)
      setTargetFileStatus(1);
      return;
    }
    if (fileType === 1 && file.size > state.globalmaxDocSize && state.globalmaxDocSize != -1) {
     
      setTitle(<FormattedMessage id="home.alertPdfbig"></FormattedMessage>)
      setLimit(getFileSize(state.maxPdfSize))
      setShow(true)
      setTargetFileStatus(1);
      return;
    }
    if (fileType === 2 && file.size > state.maxDocSize && state.maxDocSize != -1) {
      
      setTitle(<FormattedMessage id="home.alertDocbig"></FormattedMessage>)
      setLimit(getFileSize(state.maxDocSize))
      setShow(true)
      setTargetFileStatus(1);
      return;
    }
    setTargetFileStatus(-1);
    const config = {
      bucketName: bucketCredentials.bucket_name,
      region: bucketCredentials.region_name,
      accessKeyId: bucketCredentials.aws_access_key_id,
      secretAccessKey: bucketCredentials.aws_secret_access_key,
    };

    var fileName = file.name.replace(".", `-${Date.now()}.`);
    const bucket = new S3(config);
    bucket
      .uploadFile(file, fileName)
      .then((fileDetails) => {
        console.log("fileDetails",fileDetails)
        axios
          .post(
            `${API_BASE_URL}/file?planId=${state.user.planId}`,
            {
              userId: state.user.userId,
              sourceFileLink: fileDetails.location,
              fileName: fileName,
              startTime: new Date(),
              fileType,
              fileSize: file.size,
              sourceFileLanguage: srcLang.id,
              targetFileLanguage: destLang.id,
              sendEmail:sendMailFlag,
              fileTypeDownload
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          )
          .then((responseNotify) => {
            console.log("responseNotify", responseNotify);
            let fileId = responseNotify.data.fileId
            joinSocketRoom(responseNotify?.data.fileId)
            setSourceFileLink(fileDetails.location);
            setFileUploadedSuccessfully(true);
            setTargetFileLink(responseNotify.data.targetFileLink);
            if (responseNotify.data.isBlockingMode) {
              // setTargetFileStatus(1);
            }
            if (responseNotify.data.fileId) {
              setTargetFileId(responseNotify.data.fileId);
            }
            dispatch({
              type:
                fileType === 1
                  ? "UPDATE_GLOBAL_DOC_COUNT_BALANCE"
                  : "UPDATE_DOC_COUNT_BALANCE",
              payload:
                fileType === 1
                  ? responseNotify.data.globalDocCountBalance
                  : responseNotify.data.docCountBalance,
            });
            dispatch({
              type:
                fileType === 1
                  ? "UPDATE_GLOBAL_DOC_STORAGE_BALANCE"
                  : "UPDATE_DOC_STORAGE_BALANCE",
              payload:
                fileType === 1
                  ? responseNotify.data.globalDocStorageBalance
                  : responseNotify.data.docStorageBalance,
            });
              
            


          })
          .catch((err) => {
            console.log(err)
            if (err && err.response?.data?.message) {
              alert(err.response?.data?.message)
            }
            setTargetFileStatus(1);
            console.error(err);
          });
      })
      .catch((error) => {
        setTitle(<FormattedMessage id="home.alertnotUploadfile"></FormattedMessage>)
        setShow(true)
        setTargetFileStatus(1);
        console.log(error);
      });
  };



  useEffect(() => {
    if(state && state.user && state.user?.planId && state.token){
      axios
      .get(`${API_BASE_URL}/file/bucket?planId=${state.user?.planId}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((response) => {
        setBucketCredentials(response.data);
      })
      .catch((error) => {
        console.log("error",error)
        setTitle(<FormattedMessage id="home.alertbucketcredentials"></FormattedMessage>)
        setShow(true)
        setTargetFileStatus(1);
      });
  
      return () => {
        clearInterval(refreshIntervalId.current);
      }
    }
    
  }, []);
  const override = {
    display: "block",
    borderColor: "black",
  };
 
  return (
    <div className="flex flex-col" style={{margin:"16px"}}>
      <div className="flex flex-col h-70 p-2 justify-center items-center border-zinc-200 border-t-[1px] w-full">
        <div className="border-zinc-300 flex flex-col items-center border-[1px] border-dashed rounded-md p-4">
          {file  && fileProcessed  ? (
            <>
              <div className="text-sm">  {intl.formatMessage({ id: 'home.fileTranslation' })}  {targetFileStatus == 0 ? "in Progress" : "Completed"}</div>
              <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
                <div className="text-sm font-semibold">{file.name}</div>
                <div
                  className="cursor-pointer ml-5"
                  onClick={() => {
                    setFile(null);
                    setFileProcessed(false)
                    setTargetFileId(null);
                    setTargetFileStatus(1);
                    setTargetFileLink(0);
                    setFileUploadedSuccessfully(false);
                  }}
                >
                  <IoCloseOutline size="20" />
                </div>
              </div>
              <button
                onClick={() => {
                  const link =targetFileLink;
                  const a = document.createElement("a");
                  a.setAttribute('href',link);
                  a.setAttribute('target','_blank');
                  a.click();

                }}
                className="px-3 py-2 border-[#048A8F] text-[#048A8F] uppercase border-2 rounded-md text-xs"
              >
                 {intl.formatMessage({ id: 'home.getFile' })}
              </button>
            </>
          ) :

            <> 
            {errorInProcessing && errorMsg    ?
            <>
              <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
              <div className="text-sm font-semibold">{errorMsg}{errorMsg.includes('This document')?`.Cannot process more than allowed characters. Allowed character is ${state.characterCountBalance} . Please check your Account Balance`:"" }</div>
              <div
                  className="cursor-pointer ml-5"
                  onClick={() => {
                    setErrorInProcessing(false)
                    setFile(null);
                    setTargetFileId(null);
                    setTargetFileStatus(1);
                    setTargetFileLink(0);
                    setFileUploadedSuccessfully(false);
                  }}
                >
                  <IoCloseOutline size="20" />
                </div>
            </div>
               <button
               onClick={() => {
                setErrorInProcessing(false);
                 setFile(null);
                 setTargetFileId(null);
                 setTargetFileStatus(1);
                 setTargetFileLink(0);
                 setFileUploadedSuccessfully(false);


               }}
               className="px-3 py-2 border-[#048A8F] text-[#048A8F] uppercase border-2 rounded-md text-xs"
             >
                {intl.formatMessage({ id: 'home.back' })}
             </button>
             </>
            :
            <>
             {targetFileStatus == -1 ?
              <>
              {/*  <div className="sweet-loading">
                  <BounceLoader color={color} loading={loading} cssOverride={override} size={150} />
                </div>   */}

 <div className="sweet-loading">
               <div style={{ width: 150,  }}>
        <CircularProgressbar value={percentage} text={`${percentage}%`}   cssOverride={override} size={150}loading={loading}  />
      </div> 
                </div>  

                
              </> 
              :
              
              targetFileStatus == 0 ?
              <>
                <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
                  <div className="text-sm font-semibold">{state.isLoggedIn ? 'File is Translating.Please check status in File Tranlation Page' :
                    'Oops Seems like Translation is taking bit time.Try with smaller files'}</div>

                </div>
                <button
                  onClick={() => {
                    setFile(null);
                    setTargetFileId(null);
                    setTargetFileStatus(1);
                    setTargetFileLink(0);
                    setFileUploadedSuccessfully(false);


                  }}
                  className="px-3 py-2 border-[#048A8F] text-[#048A8F] uppercase border-2 rounded-md text-xs"
                >
                  {intl.formatMessage({ id: 'home.back' })}
                </button>
              </>
              :

              file ? (
              <>
                <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
                  <div className="text-sm font-semibold">{file.name}</div>
                  <div
                    className="cursor-pointer ml-5"
                    onClick={() => {
                      setFile(null);
                      setTargetFileId(null);
                      setTargetFileStatus(1);
                      setTargetFileLink(0);
                      setFileUploadedSuccessfully(false);
                    }}
                  >
                    <IoCloseOutline size="20" />
                  </div>
                </div>
                <button
                  onClick={(e)=>handleDocumentUpload(e)}
                  className="px-3 py-2 border-[#048A8F] text-[#048A8F] uppercase border-2 rounded-md text-xs"
                >
               {intl.formatMessage({ id: 'home.uplaodTranslate' })}
                </button>
              </>
              ) : (
              <>
              {<div className="flex flex-row"> 
              <p style={{margin: "7px 34px 0px 8px"}}> {intl.formatMessage({ id: 'home.downloadfile' })}</p>
                <div className="flex-1 flex-row " >  
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"  onChange={()=>setfileTypeDownload('')} checked={fileTypeDownload == ''}/>
                                    <label className="form-check-label" >{intl.formatMessage({ id: 'home.default' })}</label>
                                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"  onChange={()=>setfileTypeDownload('.pdf')} checked={fileTypeDownload == '.pdf'}/>
                                    <label className="form-check-label" >PDF</label>
                                </div>
                                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"  onChange={()=>setfileTypeDownload('.doc')} checked={fileTypeDownload == '.doc'}/>
                                    <label className="form-check-label" >Word</label>
                                </div> </div> </div>

              }
                {state.isLoggedIn && <div className="flex flex-row">  
                  <p style={{margin: "7px 50px 0px -18px"}}>Get translated file</p>
                  <div className="flex-1 flex-row " > 
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"  onChange={()=>setSendMailFlag(true)} checked={sendMailFlag}/>
                                    <label className="form-check-label" >Email me</label>
                                </div>
                                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"  onChange={()=>setSendMailFlag(false)} checked={!sendMailFlag}/>
                                    <label className="form-check-label" >Wait here</label>
                                </div> </div> </div>}
                {/* <FiUploadCloud size={35} /> */}
                {/* <div>{intl.formatMessage({ id: 'home.selectFile' })}</div> */}
                <div className="text-[#0006] text-sm my-2">
                <FileUploader style={{width:"10px"}} label={"Select a file or drag and drop "} handleChange={handleFileUpload} name="file" types={fileTypes} />
                </div>
               
                {/* <input
                  ref={fileInputRef}
                  type="file"
                  hidden
                  accept=".pdf,.docx,.txt,.doc"
                  onChange={handleFileUpload}
                /> */}
                {/* <button
                  onClick={() => fileInputRef?.current.click()}
                  className="px-3 py-2 border-[#048A8F] text-[#048A8F] uppercase border-2 rounded-md text-xs"
                >
                 {intl.formatMessage({ id: 'home.browse' })}
                </button> */}
              </>
              )}
            </>
            
            }
           
              
              </>
          }
        </div>
      </div>
      <div className="text-xs w-full text-center">
      {state?.user?.role == "guest"?
        <>
         Maximum file size allowed is &nbsp;&nbsp;  
        {state.maxDocSize == '-1'?"Unlimted":(getFileSize(state.globalDocStorageBalance))}  
        </>:
        <>
        {intl.formatMessage({ id: 'home.documentBalance' })}:{state.globalDocCountBalance == '-1'?"Unlimted":kFormatter(state.globalDocCountBalance)}, (
        {state.maxDocSize == '-1'?"Unlimted":(getFileSize(state.globalDocStorageBalance))}) 
        </>}
      </div>
      <CreateModal
              show={show}
              title={title}
              limit={limit}
              setShow={setShow}
            />
    </div>
  );
};
const CreateModal = ({
  show,
  title,
  setShow,limit
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      limit={limit}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};
export default DocumentTranslation;
