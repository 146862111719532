import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import { validatePasswordNew, validateEmail } from "../../utils/validate";
import "./Login.scss";
import { useLocation } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { AiFillFacebook } from "react-icons/ai";
import InjectIntl from 'react-intl-inject';
import { useMediaQuery } from 'react-responsive';
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { FormattedMessage } from 'react-intl';
import AppleLogin from 'react-apple-login';
import { Button } from 'react-bootstrap';
import Logindots from "../../svgIcons/Logindots";
import jwt_decode from "jwt-decode";
export default function Login(props) {
  const [userData, setUserData] = useState({ email: "", password: "",isRegistered :false });
  const [emailFeedback, setEmailFeedback] = useState("");
  const [passwordFeedback, setPasswordFeedback] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const { state, dispatch } = useContext(userContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [profile, setProfile] = useState('null');
  const [plans, setPlans] = useState([]);
  const [languageSelected, setAppLanguage] = useState('en');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [title, setTitle] = useState('')
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  var platform = require('platform');
  const clientId = '80926039823-8jmk3fgn65nido0qv3d34b7bosvrakj4.apps.googleusercontent.com';

  const handleClick = () => {
    // 👇️ toggle
    setIsActive(current => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  useEffect(async () => {

    const { data } = await axios.get(`${API_BASE_URL}/plan/`);
    setPlans(data.plans)

  }, [])
  useEffect(async () => {
    var chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
    const randomPassword = [4, 3, 1].map(function (len, i) { return Array(len).fill(chars[i]).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function () { return 0.5 - Math.random() }).join('');


    if (profile?.email) {
      setUserData({
        email: profile.email,
        password: randomPassword,
        name: profile.name,
      })
      
      const emailResponse = await axios.get(`${API_BASE_URL}/user/login/${profile.email}`);

      if (emailResponse.data.user.length > 0 && emailResponse.data?.user[0]?.userLoginType == "1") {
        // userData.email = profile.email;
        // userData.password = emailResponse.data.user.randomPassword;
        axios
          .get(`${API_BASE_URL}/user/userid/${emailResponse.data.user[0].userId}`, {
            headers: {
              Authorization: `Bearer ${emailResponse.data.user[0].token}`,
            },
          })
          .then(async (res) => {
            dispatch({
              type: "USER_LOGIN",
              payload: {
                user: emailResponse.data.user[0],
                token: emailResponse.data.user[0].token,
                characterCountBalance: res.data.userBalance.characterCountBalance,
                pdfCountBalance: res.data.userBalance.pdfCountBalance,
                docCountBalance: res.data.userBalance.docCountBalance,
                ocrCountBalance: res.data.userBalance.ocrCountBalance,
                pdfStorageBalance: res.data.userBalance.pdfStorageBalance,
                docStorageBalance: res.data.userBalance.docStorageBalance,
                ocrStorageBalance: res.data.userBalance.ocrStorageBalance,
                availableLanguages: res.data.userBalance.languages,
                planName: emailResponse.data.user[0].planId,
                googleUser: true,
                globalDocCountBalance: res.data.userBalance.globalDocCount,
                globalDocStorageBalance: res.data.userBalance.globalTotalDocSize,
                direct_translation: res.data.userBalance.direct_translation,
                document_translation: res.data.userBalance.document_translation,
                api_allowed: res.data.userBalance.api_allowed,
                cloud_storage: res.data.userBalance.cloud_storage,
                batch_translate_text: res.data.userBalance.batch_translate_text,
                batch_translate_doc: res.data.userBalance.batch_translate_doc,
                total_requests: res.data.userBalance.total_requests,
                language_detection: res.data.userBalance.language_detection,
              },
            });
            navigate("/dashboard");
          })
          .catch((err) => {
            setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
            setShow(true)
          });
      }
      else {
      
        let newuserData = {
          email: profile.email,
          password: randomPassword,
          name: profile.name,
          planId: plans.find(x => x.name === 'Basic')?.planId,
          userLoginType: "1"
        }
        navigate("/cardVerification", { state: newuserData })
      }

    }
  }, [profile])
  // var accessToken =gapi.auth.getToken().access_token;

  useEffect(() => {
    const resultPassword = validatePasswordNew(userData.password);
    const resultEmail = validateEmail(userData.email);
    let newFormValue = true;
    if (!resultEmail.isValid) {
      setEmailFeedback(resultEmail.message);
      newFormValue &= false;
    } else {
      setEmailFeedback("");
      newFormValue &= true;
    }
    if (!resultPassword.isValid) {
      setPasswordFeedback(resultPassword.message);
      newFormValue &= false;
    } else {
      setPasswordFeedback("");
      newFormValue &= true;
    }
    setFormValid(newFormValue);
  }, [userData]);

  const handleInputChange = (event) => {
    userData[event.target.name] = event.target.value;
    setUserData({ ...userData });
  };

  const _handleKeyDown = (e) => {
    console.log('do validate');
    if (e.key === 'Enter') {
      console.log('do validate');
    }
  }

  const handleLogin = (event) => {
    event.preventDefault();
    axios
      .post(`${API_BASE_URL}/auth/login`, userData)
      .then((response) => {
        console.log("res", response)
        dispatch({
          type: "USER_LOGIN",
          payload: {
            user: response.data.user,
            token: response.data.user.token,
            characterCountBalance: response.data.characterCountBalance,
            pdfCountBalance: response.data.pdfCountBalance,
            docCountBalance: response.data.docCountBalance,
            ocrCountBalance: response.data.ocrCountBalance,
            pdfStorageBalance: response.data.pdfStorageBalance,
            docStorageBalance: response.data.docStorageBalance,
            ocrStorageBalance: response.data.ocrStorageBalance,
            availableLanguages: response.data.availableLanguages,
            planName: response.data.planName,
            globalDocCountBalance: response.data.globalDocCount,
            globalDocStorageBalance: response.data.globalTotalDocSize,
            direct_translation: response.data.direct_translation,
            document_translation: response.data.document_translation,
            api_allowed: response.data.api_allowed,
            cloud_storage: response.data.cloud_storage,
            batch_translate_text: response.data.batch_translate_text,
            batch_translate_doc: response.data.batch_translate_doc,
            total_requests: response.data.total_requests,
            language_detection: response.data.language_detection,
            image_translation:response.data.image_translation,
          },
        });
        console.log(location.state)
        if (location.state && location.state.planSelected && location.state.planSelected.name != "Basic")
          navigate('/pricing', { state: { planSelected: location.state.planSelected } })
        else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        if (err && err.response?.data?.message) {

          if (err.response?.data?.message == 'user not found or wrong input') {
            setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
            setShow(true)
          }
          else if (err.response?.data?.message == 'For Admins please login to https://poshtranslate.com/admin/login') {
            setTitle(<FormattedMessage id="home.adminLogin"></FormattedMessage>)
            setShow(true)
          }
          else {
            alert(err.response?.data?.message)
          }
        }
      });
  };

  const logOut = () => {
    setProfile(null);
  }
  const onSuccess = async (res) => {
    console.log("res", res)
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        ux_mode: 'redirect',
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
    if (res)
      setProfile(res.profileObj);


  };
  const responseFacebook = async (response) => {
    console.log("from responseFacebook")
    let tempEmail;
    var chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
    const randomPassword = [4, 3, 1].map(function (len, i) { return Array(len).fill(chars[i]).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function () { return 0.5 - Math.random() }).join('');
    if (!response?.email)
      tempEmail = `guest-${response.id}@poshtranslate.com`;
    if (response) {
      let registerData = {
        email: response?.email ? response?.email : tempEmail,
        password: randomPassword,
        name: response.name,
        planId: plans.find(x => x.name === 'Basic')?.planId,
        userLoginType: "2"
      }
      const emailResponse = await axios.get(`${API_BASE_URL}/user/login/${response?.email ? response?.email : tempEmail}`);
      if (emailResponse.data.user.length > 0 && emailResponse.data?.user[0]?.userLoginType == "2") {
        axios
          .get(`${API_BASE_URL}/user/userid/${emailResponse.data.user[0].userId}`, {
            headers: {
              Authorization: `Bearer ${emailResponse.data.user[0].token}`,
            },
          })
          .then(async (res) => {
            dispatch({
              type: "USER_LOGIN",
              payload: {
                user: emailResponse.data.user[0],
                token: emailResponse.data.user[0].token,
                characterCountBalance: res.data.userBalance.characterCountBalance,
                pdfCountBalance: res.data.userBalance.pdfCountBalance,
                docCountBalance: res.data.userBalance.docCountBalance,
                ocrCountBalance: res.data.userBalance.ocrCountBalance,
                pdfStorageBalance: res.data.userBalance.pdfStorageBalance,
                docStorageBalance: res.data.userBalance.docStorageBalance,
                ocrStorageBalance: res.data.userBalance.ocrStorageBalance,
                availableLanguages: res.data.userBalance.languages,
                planName: emailResponse.data.user[0].planId,
                googleUser: true,
                globalDocCountBalance: res.data.userBalance.globalDocCount,
                globalDocStorageBalance: res.data.userBalance.globalTotalDocSize,
                direct_translation: res.data.userBalance.direct_translation,
                document_translation: res.data.userBalance.document_translation,
                api_allowed: res.data.userBalance.api_allowed,
                cloud_storage: res.data.userBalance.cloud_storage,
                batch_translate_text: res.data.userBalance.batch_translate_text,
                batch_translate_doc: res.data.userBalance.batch_translate_doc,
                total_requests: res.data.userBalance.total_requests,
                language_detection: res.data.userBalance.language_detection,
              },
            });
            navigate("/dashboard");
          })
          .catch((err) => {
            setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
            setShow(true)
          });
      }
      else {
        // userData.email = response.email;
        axios.post(`${API_BASE_URL}/auth/register`, registerData).then((newresponse) => {
          if (newresponse.data && newresponse.data.message == "Duplicate Email ID Found") {
            setTitle(<FormattedMessage id="home.alertemailDuplicate"></FormattedMessage>)
            setShow(true)
          }
          else {
            console.log("userData", userData, profile)
            let newuserData = {
              email:response?.email ? response?.email : tempEmail,
              password: randomPassword,
              name: response.name,
              planId: plans.find(x => x.name === 'Basic')?.planId,
              userLoginType: "2"
            }
          
            navigate("/cardVerification", { state: newuserData })
          }
        })
      }
    }
  }
  const componentClicked = data => {
    // console.log("data", data);

  };
  const onFailure = (err) => {
    // console.log('failed', err);
  };

  const responseAppleLogin = async (res) => {
    console.log("from responseAppleLogin")
    let tempEmail;
    var chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
    const randomPassword = [4, 3, 1].map(function (len, i) { return Array(len).fill(chars[i]).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function () { return 0.5 - Math.random() }).join('');
    console.log("res", res)
    if (res && res.authorization) {
      var decoded = jwt_decode(res.authorization.id_token);
      console.log(decoded)
      if (decoded.email) {
        let registerData = {
          email: decoded.email,
          password: randomPassword,
          name: decoded.email.split("@")[0],
          planId: plans.find(x => x.name === 'Basic')?.planId,
          userLoginType: "3"
        }
        const emailResponse = await axios.get(`${API_BASE_URL}/user/login/${decoded.email}`);
        console.log("emailResponse", emailResponse)
        if (emailResponse.data.user.length > 0 && emailResponse.data?.user[0]?.userLoginType == "3") {
          axios
            .get(`${API_BASE_URL}/user/userid/${emailResponse.data.user[0].userId}`, {
              headers: {
                Authorization: `Bearer ${emailResponse.data.user[0].token}`,
              },
            })
            .then(async (res) => {
              dispatch({
                type: "USER_LOGIN",
                payload: {
                  user: emailResponse.data.user[0],
                  token: emailResponse.data.user[0].token,
                  characterCountBalance: res.data.userBalance.characterCountBalance,
                  pdfCountBalance: res.data.userBalance.pdfCountBalance,
                  docCountBalance: res.data.userBalance.docCountBalance,
                  ocrCountBalance: res.data.userBalance.ocrCountBalance,
                  pdfStorageBalance: res.data.userBalance.pdfStorageBalance,
                  docStorageBalance: res.data.userBalance.docStorageBalance,
                  ocrStorageBalance: res.data.userBalance.ocrStorageBalance,
                  availableLanguages: res.data.userBalance.languages,
                  planName: emailResponse.data.user[0].planId,
                  googleUser: true,
                  globalDocCountBalance: res.data.userBalance.globalDocCount,
                  globalDocStorageBalance: res.data.userBalance.globalTotalDocSize,
                  direct_translation: res.data.userBalance.direct_translation,
                  document_translation: res.data.userBalance.document_translation,
                  api_allowed: res.data.userBalance.api_allowed,
                  cloud_storage: res.data.userBalance.cloud_storage,
                  batch_translate_text: res.data.userBalance.batch_translate_text,
                  batch_translate_doc: res.data.userBalance.batch_translate_doc,
                  total_requests: res.data.userBalance.total_requests,
                  language_detection: res.data.userBalance.language_detection,
                },
              });
              navigate("/dashboard");
            })
            .catch((err) => {
              setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
              setShow(true)
            });
        }
        else {
          // userData.email = response.email;
          axios.post(`${API_BASE_URL}/auth/register`, registerData).then((newresponse) => {
            if (newresponse.data && newresponse.data.message == "Duplicate Email ID Found") {
              setTitle(<FormattedMessage id="home.alertemailDuplicate"></FormattedMessage>)
              setShow(true)
            }
            else {
              console.log("userData", userData, profile)

              let newuserData = {
                email: decoded.email,
                password: randomPassword,
                name: decoded.email.split("@")[0],
                planId: plans.find(x => x.name === 'Basic')?.planId,
                userLoginType: "3"
              }
            
              navigate("/cardVerification", { state: newuserData })
            }
          })
        }
      }
    }
  };


  return (
    <InjectIntl>
      {({ intl }) => (
        <div style={{ overflow: 'hidden' }}>

{isTabletOrMobile &&
    <div class="grid-container">

<div class="grid-child ">
<div className="text-center h-full w-full text-lg-left screen">
           <img
              src="/assets/imgs/signtriangle.png"
               className="h-full w-full object-contain"
               alt="signup screen"   
             />
           </div>
  
</div>

<div class="grid-child ">

<img
  onClick={() => navigate('/')}
         src="/assets/imgs/signin.png"
          className="h-full w-full ml-0 object-contain" 
          style={{disply:'flex', justifyContent:'left' ,
          height: '150px',
       
          left: '287px',
          top: '345px',
          borderRadius: '0px',
        cursor:'pointer'}}
          alt="home"  
           
        />
       
</div>

</div> 
}
          <div className="flex flex-row">
            {!isTabletOrMobile &&
              <div className="h-full w-[44%] hidden sm:flex">
                <div className="text-center h-full w-full text-lg-left screen">
                  <img
                    src="/assets/imgs/singinn.png"
                    className="h-full w-full object-contain"
                    alt="signup screen"
                  />
                </div>
                <div className="flex flex-row justify-between items-center w-full ml-0">
             
                  <img       onClick={() => navigate('/')}
                    src="/assets/imgs/signin.png"
                    className="h-full w-full ml-0 object-contain"
                    style={{
                      disply: 'flex', justifyContent: 'left',
                      height: '242px',

                      left: '287px',
                      top: '345px',
                      borderRadius: '0px',
                      cursor:'pointer'
                    }}
                    alt="screen"
                  />
                 
                </div>
              </div>
            }
                   <div className="col-md-6 forms">
       {
              !isTabletOrMobile &&
       
              <div className="flex-1 flex-row mt-5  ">
                <div className="flex flex-row justify-end w-full mr-3" style={{ marginLeft: "-41px" }}>
                  <div className="text-sm  text-[#323232]"  >
                    {intl.formatMessage({ id: 'home.newUser' })}
                    <Link
                      to="/register"
                      className="text-[#01BABF]  text-sm newAccount"
                    >
                      {intl.formatMessage({ id: 'home.createUser' })}
                    </Link>
                  </div></div>
              </div>
            } 
       

              <div className="flex flex-1 flex-col justify-center px-4" style={{ marginTop: '60px' }}>
                <div className="text-[34px] text-[#01BABF] mb-4 title">{intl.formatMessage({ id: 'home.signIn' })}</div>
                <div className="row text-center">
                  <div className=" col-md-4 col-sm-12 col mt-1">
                    <GoogleLogin
                      clientId={clientId}
                      buttonText={intl.formatMessage({ id: 'home.signInG' })}
                      onSuccess={(res) => onSuccess(res)}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      isSignedIn={false}
                      render={renderProps => (
                        <button id="fbgoogle" className="py-2 px-8  rounded-[10px] text-center  border-[1px] outline-none   fbbutton "
                          style={{
                            fontWeight: isActive ? '500' : '',
                            font: isActive ? 'bold' : '',
                                     
                          marginLeft:"-5px",
                            fontFamily:'Raleway',
                                  padding:' 0px 10px 0px 10px',
                                      fontSize:'16px',
                                     lineHeight:'19px',
                                     height:'65px',
                                    width:'204px',
                          }}
                          onClick={renderProps.onClick} disabled={renderProps.disabled}
                        >   <i
                          style={{}}
                          className="fa fa-google"
                          aria-hidden="true"

                        ></i> {intl.formatMessage({ id: 'home.signgoogle' })}</button>
                      )}
                    />
                  </div>
                  <div className=" col-md-4 col-sm-12  col mt-1">
                    <FacebookLogin
                      appId="1186973665197148"
                      fields="name,email,picture"
                      cssClass="my-facebook-button-class"
                      icon={<span><i className="fa fa-facebook" style={{ marginLeft: '5px' }}></i>&nbsp;&nbsp;</span>}
                      textButton={intl.formatMessage({ id: 'home.signInF' })}
                      onClick={componentClicked}
                      callback={responseFacebook}
                      render={renderProps => (
                        <button id="fbgoogle" className="py-2 px-8  rounded-[10px] text-center  border-[1px] outline-none -ml-10 mr-2 fbbutton "
                          style={{
                            fontWeight: isActive ? '500' : '',
                            font: isActive ? 'bold' : '',
                            marginLeft:"-24px",
                            fontFamily:'Raleway',
                                  padding:' 0px 10px 0px 10px',
                                      fontSize:'16px',
                                     lineHeight:'19px',
                                     height:'65px',
                                    width:'204px',
                          }}
                          onClick={renderProps.onClick}
                        >
                          <i
                            style={{}}
                            className="fa fa-facebook-square"
                            aria-hidden="true"
                          ></i> {intl.formatMessage({ id: 'home.signfacebook' })}

                        </button>
                      )}
                    />
                  </div>
                  <div className=" col-md-4 col-sm-12 col mt-1">
                    <AppleLogin clientId={'com.poshenterpriseinc.poshtranslatestaging.sid'}
                      redirectURI={'https://staging.poshtranslate.com'}
                      scope="email name"
                      usePopup={true}
                      responseType={"id_token"}
                      responseMode={"form_post"}
                      callback={response => responseAppleLogin(response)}
                      render={({ onClick }) => (
                        <button id="fbgoogle" className="py-2 px-8  rounded-[10px] text-center  border-[1px] outline-none  -ml-20 mr-4 fbbutton "
                          style={{
                            fontWeight: isActive ? '500' : '',
                            font: isActive ? 'bold' : '',
                          
                          marginLeft:"-48px",
                            fontFamily:'Raleway',
                                  padding:' 0px 10px 0px 10px',
                                      fontSize:'16px',
                                     lineHeight:'19px',
                                     height:'65px',
                                    width:'204px',
                          }}
                          onClick={onClick}
                        >   <i
                          style={{}}
                          className="fa fa-apple"
                          aria-hidden="true"

                        ></i> {intl.formatMessage({ id: 'home.signapple' })}</button>
                      )}
                    />
                  </div>
                </div>
                <div style={{
                  width: '100%',
                  textAlign: 'center',
                  borderBottom: ' 1px solid #CFCFCF',
                  lineHeight: '0.1em',
                  margin: ' 38px 0px 31px 10px'
                }}><span style={{
                  background: '#fff',
                  padding: '0 10px'
                }}>{intl.formatMessage({ id: 'home.or' })}</span></div>
                <div className="w-[100%]">
                  <form onSubmit={(e)=>handleLogin(e)}>
                  <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 py-3">
                    <div className="flex flex-col my-1 w-full">
                      <div className="label text-[#01BABF] my-1 emailLabel"> {intl.formatMessage({ id: 'home.Email' })}</div>
                      <input
                        type="email"
                        className="input1 mt-2"
                        value={userData.email}
                        name="email"
                        onChange={handleInputChange}  placeholder="Please type your email"
                      />
                      {userData.email && <span className="input-feedback">{emailFeedback}</span>}
                    </div>
                    <div className="flex flex-col my-1 w-full">
                      <div className="label text-[#01BABF]   my-1 emailLabel"> {intl.formatMessage({ id: 'home.password' })}</div>
                      <input
                        type={togglePassword ? "text" : "password"}
                        className="input1 mt-2"
                        value={userData.password}
                        name="password"
                        onChange={handleInputChange} placeholder="Please enter your password"
                      />
                      <span style={languageSelected == "ar" ? {
                        marginRight: '90%',
                        marginTop: '-34px'
                      } : {
                        marginLeft: '90%',
                        marginTop: '-34px'
                      }
                      } onClick={() => setTogglePassword(!togglePassword)}>
                        <i className="fa fa-eye" id="togglePassword" ></i>
                      </span>
                      {userData.password && <span className="input-feedback" style={{ padding: '12px' }}>{passwordFeedback}</span>}
                    </div>
                  </div>
                  <div className="flex flex-row items-center mt-2">
                    <input type="checkbox" className="checkBoxR" />
                    <div className="  ml-3 rememberMe">
                      {intl.formatMessage({ id: 'home.rememberMe' })}
                    </div>
                    {isTabletOrMobile &&
                    <div className="flex flex-row items-center ">
                        <Link
                          to="/send-reset"
                          className="text-[#01BABF]  forgotPass"
                        >
                          {intl.formatMessage({ id: 'home.forgot' })}  ?
                        </Link>
                      </div>}
                  </div>
                  <button
                  type="submit" 
                    // onClick={handleLogin}
                    className="mt-4 gradient-btn mb-2 sigInBtn"
                    disabled={!formValid}
                  >
                    {intl.formatMessage({ id: 'home.signIn' })}
                  </button>
                  </form>
                  {
                    !isTabletOrMobile ?
                      <div className="flex flex-row items-center ">
                        <Link
                          to="/send-reset"
                          className="text-[#01BABF]   text-sm "
                        >
                          {intl.formatMessage({ id: 'home.forgot' })}  ?
                        </Link>
                      </div> :
                      <div className="flex flex-row items-center mt-4 justify-center newUser ">
                        {intl.formatMessage({ id: 'home.newUser' })}
                      <Link
                         to="/register"
                        className="text-[#01BABF]   text-sm createUser  "
                      >
                       {intl.formatMessage({ id: 'home.createUser' })} 
                      </Link>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {isTabletOrMobile &&
          <div className="flex flex-row " style={{float:"right"}}>
            <div className="absolute right-10  logindots">
              <Logindots size={110} />
            </div>
        
                   
                   </div>}
          {!isTabletOrMobile &&
            <div className="flex flex-row " style={{ float: "right" }}>
              <img src="/assets/imgs/dots.png" className="dots" />
            </div>
          }
          <CreateModal
            show={show}
            title={title}
            setShow={setShow}
          />
        </div>
      )}</InjectIntl>
  );
}
const CreateModal = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />}
      body={() => (
        <div>

          <ModalButton type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />
        </div>
      )}
    />
  );
};