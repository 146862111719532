import React, { useContext } from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { IoCallOutline, IoSettingsOutline } from "react-icons/io5";
import { BsCommand, BsPeople, BsTranslate, BsList, AiFillFileText} from "react-icons/bs";
import { FiBriefcase } from "react-icons/fi";
import { RiDashboardLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { userContext } from "../../store/context/user";
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
const Sidebar = ({ languageSelected }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const pathname = useLocation().pathname;
  const { state } = useContext(userContext);
  return (
    <>
    {!isTabletOrMobile &&
      <div className="p-4  mt-2 max-w-[280px] w-[20%]">
      <div className="text-[20px] font-semibold " >
        <FormattedMessage id="home.goodMorning"></FormattedMessage>  {state?.user?.name.split(" ")[0].length<10?state?.user?.name.split(" ")[0]:state?.user?.name.split(" ")[0].substr(0,10)+"..."}


       
      </div>
   {/*    <div className="text-[#828282] text-xs" > <FormattedMessage id="home.haveGoodDay"></FormattedMessage></div> */}

    
      <div>
        <Link
          to="/dashboard"
          className={`flex flex-row items-center my-3 ${pathname === "/dashboard" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <RiDashboardLine size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}>  <FormattedMessage id="home.dashBoard"></FormattedMessage></div>
        </Link>
        <Link
          to="/translate"
          className={`flex flex-row items-center my-3 ${pathname === "/translate" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <BsTranslate size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}> <FormattedMessage id="home.translate"></FormattedMessage></div>
        </Link>
        <Link
          to="/transactions"
          className={`flex flex-row items-center my-3 ${pathname === "/transactions" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <BsList size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}> <FormattedMessage id="home.textTr"></FormattedMessage></div>
        </Link>
        <Link
          to="/files"
          className={`flex flex-row items-center my-3 ${pathname === "/files" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <BsList size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}> <FormattedMessage id="home.fileTr"></FormattedMessage></div>
        </Link>
        <Link
          to="/paymentHistory"
          className={`flex flex-row items-center my-3 ${pathname === "/paymentHistory" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <AiOutlineAppstore size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}> <FormattedMessage id="home.payment"></FormattedMessage></div>
        </Link>
        <Link
          to="/apps"
          className={`flex flex-row items-center my-3 ${pathname === "/apps" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <AiOutlineAppstore size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}> <FormattedMessage id="home.keys"></FormattedMessage></div>
        </Link>
        <hr />
        <Link
          to="/settings"
          className={`flex flex-row items-center my-3 ${pathname === "/settings" ? "text-[#048A8F]" : "text-zinc-400"
            } `}
        >
          <IoSettingsOutline size={20} className="mr-2" />
          <div className="text-sm" style={languageSelected == "ar" ? { marginRight: "10px" }:{}}> <FormattedMessage id="home.settings"></FormattedMessage></div>
        </Link>
      </div>

      <div className="w-full  bg-opacity-10 px-3 pb-3 flex flex-col items-center pt-24 relative mt-20 rounded-md">
        {/* <img
          src="/assets/imgs/lamp.png"
          alt="lamp"
          className="absolute -top-7"
          height={130}
          width={130}
        />
        <button className="z-30 bg-white text-center text-xs px-4 py-2 rounded-md text-[#6956E5]">
          <FormattedMessage id="home.share"></FormattedMessage>
        </button> */}
      </div>
    </div>
      }
      </>
  );
};

export default Sidebar;
