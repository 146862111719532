import React from "react";

const Dots = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 204 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="3" fill="#81C4C6" />
      <circle cx="21" cy="3" r="3" fill="#81C4C6" />
      <circle cx="39" cy="3" r="3" fill="#81C4C6" />
      <circle cx="57" cy="3" r="3" fill="#81C4C6" />
      <circle cx="75" cy="3" r="3" fill="#81C4C6" />
      <circle cx="93" cy="3" r="3" fill="#81C4C6" />
      <circle cx="111" cy="3" r="3" fill="#81C4C6" />
      <circle cx="129" cy="3" r="3" fill="#81C4C6" />
      <circle cx="147" cy="3" r="3" fill="#81C4C6" />
      <circle cx="165" cy="3" r="3" fill="#81C4C6" />
      <circle cx="183" cy="3" r="3" fill="#81C4C6" />
      <circle cx="201" cy="3" r="3" fill="#81C4C6" />
      <circle cx="3" cy="21" r="3" fill="#81C4C6" />
      <circle cx="21" cy="21" r="3" fill="#81C4C6" />
      <circle cx="39" cy="21" r="3" fill="#81C4C6" />
      <circle cx="57" cy="21" r="3" fill="#81C4C6" />
      <circle cx="75" cy="21" r="3" fill="#81C4C6" />
      <circle cx="93" cy="21" r="3" fill="#81C4C6" />
      <circle cx="111" cy="21" r="3" fill="#81C4C6" />
      <circle cx="129" cy="21" r="3" fill="#81C4C6" />
      <circle cx="147" cy="21" r="3" fill="#81C4C6" />
      <circle cx="165" cy="21" r="3" fill="#81C4C6" />
      <circle cx="183" cy="21" r="3" fill="#81C4C6" />
      <circle cx="201" cy="21" r="3" fill="#81C4C6" />
      <circle cx="3" cy="39" r="3" fill="#81C4C6" />
      <circle cx="21" cy="39" r="3" fill="#81C4C6" />
      <circle cx="39" cy="39" r="3" fill="#81C4C6" />
      <circle cx="57" cy="39" r="3" fill="#81C4C6" />
      <circle cx="75" cy="39" r="3" fill="#81C4C6" />
      <circle cx="93" cy="39" r="3" fill="#81C4C6" />
      <circle cx="111" cy="39" r="3" fill="#81C4C6" />
      <circle cx="129" cy="39" r="3" fill="#81C4C6" />
      <circle cx="147" cy="39" r="3" fill="#81C4C6" />
      <circle cx="165" cy="39" r="3" fill="#81C4C6" />
      <circle cx="183" cy="39" r="3" fill="#81C4C6" />
      <circle cx="201" cy="39" r="3" fill="#81C4C6" />
      <circle cx="3" cy="57" r="3" fill="#81C4C6" />
      <circle cx="21" cy="57" r="3" fill="#81C4C6" />
      <circle cx="39" cy="57" r="3" fill="#81C4C6" />
      <circle cx="57" cy="57" r="3" fill="#81C4C6" />
      <circle cx="75" cy="57" r="3" fill="#81C4C6" />
      <circle cx="93" cy="57" r="3" fill="#81C4C6" />
      <circle cx="111" cy="57" r="3" fill="#81C4C6" />
      <circle cx="129" cy="57" r="3" fill="#81C4C6" />
      <circle cx="147" cy="57" r="3" fill="#81C4C6" />
      <circle cx="165" cy="57" r="3" fill="#81C4C6" />
      <circle cx="183" cy="57" r="3" fill="#81C4C6" />
      <circle cx="201" cy="57" r="3" fill="#81C4C6" />
      <circle cx="3" cy="75" r="3" fill="#81C4C6" />
      <circle cx="21" cy="75" r="3" fill="#81C4C6" />
      <circle cx="39" cy="75" r="3" fill="#81C4C6" />
      <circle cx="57" cy="75" r="3" fill="#81C4C6" />
      <circle cx="75" cy="75" r="3" fill="#81C4C6" />
      <circle cx="93" cy="75" r="3" fill="#81C4C6" />
      <circle cx="111" cy="75" r="3" fill="#81C4C6" />
      <circle cx="129" cy="75" r="3" fill="#81C4C6" />
      <circle cx="147" cy="75" r="3" fill="#81C4C6" />
      <circle cx="165" cy="75" r="3" fill="#81C4C6" />
      <circle cx="183" cy="75" r="3" fill="#81C4C6" />
      <circle cx="201" cy="75" r="3" fill="#81C4C6" />
      <circle cx="3" cy="93" r="3" fill="#81C4C6" />
      <circle cx="21" cy="93" r="3" fill="#81C4C6" />
      <circle cx="39" cy="93" r="3" fill="#81C4C6" />
      <circle cx="57" cy="93" r="3" fill="#81C4C6" />
      <circle cx="75" cy="93" r="3" fill="#81C4C6" />
      <circle cx="93" cy="93" r="3" fill="#81C4C6" />
      <circle cx="111" cy="93" r="3" fill="#81C4C6" />
      <circle cx="129" cy="93" r="3" fill="#81C4C6" />
      <circle cx="147" cy="93" r="3" fill="#81C4C6" />
      <circle cx="165" cy="93" r="3" fill="#81C4C6" />
      <circle cx="183" cy="93" r="3" fill="#81C4C6" />
      <circle cx="201" cy="93" r="3" fill="#81C4C6" />
      <circle cx="3" cy="111" r="3" fill="#81C4C6" />
      <circle cx="21" cy="111" r="3" fill="#81C4C6" />
      <circle cx="39" cy="111" r="3" fill="#81C4C6" />
      <circle cx="57" cy="111" r="3" fill="#81C4C6" />
      <circle cx="75" cy="111" r="3" fill="#81C4C6" />
      <circle cx="93" cy="111" r="3" fill="#81C4C6" />
      <circle cx="111" cy="111" r="3" fill="#81C4C6" />
      <circle cx="129" cy="111" r="3" fill="#81C4C6" />
      <circle cx="147" cy="111" r="3" fill="#81C4C6" />
      <circle cx="165" cy="111" r="3" fill="#81C4C6" />
      <circle cx="183" cy="111" r="3" fill="#81C4C6" />
      <circle cx="201" cy="111" r="3" fill="#81C4C6" />
      <circle cx="3" cy="129" r="3" fill="#81C4C6" />
      <circle cx="21" cy="129" r="3" fill="#81C4C6" />
      <circle cx="39" cy="129" r="3" fill="#81C4C6" />
      <circle cx="57" cy="129" r="3" fill="#81C4C6" />
      <circle cx="75" cy="129" r="3" fill="#81C4C6" />
      <circle cx="93" cy="129" r="3" fill="#81C4C6" />
      <circle cx="111" cy="129" r="3" fill="#81C4C6" />
      <circle cx="129" cy="129" r="3" fill="#81C4C6" />
      <circle cx="147" cy="129" r="3" fill="#81C4C6" />
      <circle cx="165" cy="129" r="3" fill="#81C4C6" />
      <circle cx="183" cy="129" r="3" fill="#81C4C6" />
      <circle cx="201" cy="129" r="3" fill="#81C4C6" />
      <circle cx="3" cy="147" r="3" fill="#81C4C6" />
      <circle cx="21" cy="147" r="3" fill="#81C4C6" />
      <circle cx="39" cy="147" r="3" fill="#81C4C6" />
      <circle cx="57" cy="147" r="3" fill="#81C4C6" />
      <circle cx="75" cy="147" r="3" fill="#81C4C6" />
      <circle cx="93" cy="147" r="3" fill="#81C4C6" />
      <circle cx="111" cy="147" r="3" fill="#81C4C6" />
      <circle cx="129" cy="147" r="3" fill="#81C4C6" />
      <circle cx="147" cy="147" r="3" fill="#81C4C6" />
      <circle cx="165" cy="147" r="3" fill="#81C4C6" />
      <circle cx="183" cy="147" r="3" fill="#81C4C6" />
      <circle cx="201" cy="147" r="3" fill="#81C4C6" />
      <circle cx="3" cy="165" r="3" fill="#81C4C6" />
      <circle cx="21" cy="165" r="3" fill="#81C4C6" />
      <circle cx="39" cy="165" r="3" fill="#81C4C6" />
      <circle cx="57" cy="165" r="3" fill="#81C4C6" />
      <circle cx="75" cy="165" r="3" fill="#81C4C6" />
      <circle cx="93" cy="165" r="3" fill="#81C4C6" />
      <circle cx="111" cy="165" r="3" fill="#81C4C6" />
      <circle cx="129" cy="165" r="3" fill="#81C4C6" />
      <circle cx="147" cy="165" r="3" fill="#81C4C6" />
      <circle cx="165" cy="165" r="3" fill="#81C4C6" />
      <circle cx="183" cy="165" r="3" fill="#81C4C6" />
      <circle cx="201" cy="165" r="3" fill="#81C4C6" />
      <circle cx="3" cy="183" r="3" fill="#81C4C6" />
      <circle cx="21" cy="183" r="3" fill="#81C4C6" />
      <circle cx="39" cy="183" r="3" fill="#81C4C6" />
      <circle cx="57" cy="183" r="3" fill="#81C4C6" />
      <circle cx="75" cy="183" r="3" fill="#81C4C6" />
      <circle cx="93" cy="183" r="3" fill="#81C4C6" />
      <circle cx="111" cy="183" r="3" fill="#81C4C6" />
      <circle cx="129" cy="183" r="3" fill="#81C4C6" />
      
    </svg>
  );
};

export default Dots;
