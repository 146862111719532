import React from 'react';

export default function NotFound() {
	return (
		<div className='container d-flex flex-column align-items-center justify-content-center w-100'>
			<img src='/assets/imgs/homelogo.png' alt='not found' className='w-25 m-2' />
			<h2 className='m-5'>Sorry, the page you are looking for doesn't exist</h2>
		</div>
	);
}
