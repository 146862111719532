import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../../utils/globals';
import '../Login/Login.scss';
import {
	useParams
} from "react-router-dom";
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
export default function ResetPassword() {
	const parseJwt = (token) => {
		if (!token) { return; }
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	}
	const params = useParams();
	let email = parseJwt(params.token).data.email;
	const [userData, setUserData] = useState({ oldPassword: '', newPassword: '', email: email });
	const [passwordFeedback, setPasswordFeedback] = useState('');
	const [formValid, setFormValid] = useState(false);
	const [togglePasswordNew, setTogglePasswordNew] = useState(false);
	const [togglePasswordConfirm, setTogglePasswordConfirm] = useState(false);
	const [title,setTitle]=useState('')
    const [show, setShow] = useState(false);
	const [resetFlag, setResetFlag] = useState(false);
	const validatePassword = (value) => {
		const regex = new RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/);
		return {
			isValid: regex.test(value),
			message: 'password must contain Min. 8 characters with letters and digits',
		};
	};

	useEffect(() => {
		const resultOldPassword = validatePassword(userData.oldPassword);
		const resultNewldPassword = validatePassword(userData.newPassword);
		let newFormValue = true;
		if (!resultOldPassword.isValid) {
			setPasswordFeedback(resultOldPassword.message);
			newFormValue &= false;
		} else {
			setPasswordFeedback('');
			newFormValue &= true;
		}
		if (!resultNewldPassword.isValid) {
			setPasswordFeedback(resultNewldPassword.message);
			newFormValue &= false;
		} else {
			setPasswordFeedback('');
			newFormValue &= true;
		}
		if (userData.newPassword !== userData.oldPassword) {
			setPasswordFeedback('Two passwords does not match');
			newFormValue &= false;
		}
		setFormValid(newFormValue);
	}, [userData]);

	const handleInputChange = (event) => {
		userData[event.target.name] = event.target.value;

		setUserData({ ...userData });
	};

	const handleResetPassword = (event) => {
		event.preventDefault();
		
		axios
			.post(`${API_BASE_URL}/auth/reset-password/${params.token}`, userData)
			.then((res) => 
			{
				if(res.data.message == "Token expired")
			    setTitle('Token expired')
				else if(res.data.message == "User Not found")
				setTitle('User Not found')
				else{
					setResetFlag(true)
					setTitle('Password reset successfully')
				}
            setShow(true)
			})
			.catch((err) => console.log("err",err));
	};

	return (
		<div className="h-screen w-screen flex flex-row">
			<div className="h-full w-[48%] p-10 hidden sm:flex">
				<img
					src="/assets/imgs/ResetPageImage.jpg"
					className="h-full w-full object-contain"
					alt="signup screen"
				/>
			</div>

			<div className="flex flex-1 flex-col p-3 h-full">
				<div className="flex flex-row justify-end w-full">
					<div className="text-sm font-semibold text-[#7A86A1]">
						New User?{" "}
						<Link
							to="/register"
							className="text-[#6149CD] hover:text-[#492fbd] font-semibold text-sm"
						>
							Create New Account
						</Link>
					</div>
				</div>

				<div className="flex flex-1 flex-col justify-center px-20">
					<div className="font-semibold text-[24px] mb-4">Reset Password?</div>

					<div className="text-sm font text-[#7A86A1]">
						Enter the email address you used when you joined and we'll send you
						instructions to reset your password.
					</div>

					<div className="w-[100%] sm:w-[80%]">
						<div className="grid gap-2 grid-cols-1 py-3">
							<div className="flex flex-col my-1 w-full">
								<input
									type={togglePasswordNew ? 'text' : 'password'}
									className='form-control login__form__input'
									placeholder='New Password'
									name='oldPassword'
									onChange={handleInputChange}
									autoComplete='off'
									minLength='8'
									required
								/>
								<span style={{
									marginLeft: '90%',
									marginTop: '-10%'
								}} onClick={() => setTogglePasswordNew(!togglePasswordNew)}>
									<i className="fa fa-eye" id="togglePassword" ></i>
								</span>
								<input
									style={{ marginTop: '30px' }}
									type={togglePasswordConfirm ? 'text' : 'password'}
									className='form-control login__form__input'
									placeholder='Confirm Password'
									name='newPassword'
									onChange={handleInputChange}
									autoComplete='off'
									minLength='8'
									required
								/>
								<span style={{
									marginLeft: '90%',
									marginTop: '-10%'
								}} onClick={() => setTogglePasswordConfirm(!togglePasswordConfirm)}>
									<i className="fa fa-eye" id="togglePassword" ></i>
								</span>
							</div>
							<span className='login__form__feedback' style={{marginTop: "20px",fontSize: "15px"}}>{passwordFeedback}</span>
						</div>

						<button className="gradient-btn" disabled={formValid ==0?true:false}  onClick={handleResetPassword}>Submit</button>

						<div className="mt-6">
							<Link to="/login" className="text-sm font-semibold text-[#6149CD]">
								{"<"} Back to sign in
							</Link>
						</div>
					</div>
				</div>
			</div>
			<CreateModal
              show={show}
              title={title}
              setShow={setShow}
			  resetFlag={resetFlag}
            />
		</div>
	);
}
const CreateModal = ({
	show,
	title,
	setShow,resetFlag
  }) => {
	const navigate = useNavigate();
	return (
	  <ModalAlert
		show={show}
		title={title}
		footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>{setShow(false);
			if(resetFlag)
			navigate('/login')
		}} />}
		body={() => (
		  <div>
		  
			<ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
		  </div>
		)}
	  />
	);
  };
