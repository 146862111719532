import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../store/context/user";

export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const { state } = useContext(userContext);

  useEffect(() => {
    if (!state.isLoggedIn) {
      navigate("/login");
    }
  }, [navigate, state.isLoggedIn]);
  return children;
}
