import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { userContext } from "../../store/context/user";
import getFileSize from "../../utils/getFileSize";
import { API_BASE_URL } from "../../utils/globals";
import { useNavigate } from 'react-router-dom';
import InjectIntl from 'react-intl-inject';
import { FormattedMessage } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import DateRangePicker from "rsuite/DateRangePicker";
import Pagination from "../../components/NewPagination/Pagination";
const accessTypes = [
  
  { label: "All", value: 2 },
  { label: "Done", value: 1 },
  { label: "Error", value: -1 },
  { label: "In Process", value: 0 },
];

const sortTypes = [
  { label: "Latest", value: { key: "createdAt", dir: -1 } },
  { label: "Oldest", value: { key: "createdAt", dir: 1 } },
  { label: "Source Language (A-Z)", value: { key: "sourceFileLanguage", dir: 1 } },
  { label: "Source Language (Z-A)", value: { key: "sourceFileLanguage", dir: -1 } },
  { label: "Target Language (A-Z)", value: { key: "targetFileLanguage", dir: 1 } },
  { label: "Target Language (Z-A)", value: { key: "targetFileLanguage", dir: 1 } },
];
const headerContent = [
  // "#",
  <FormattedMessage id="home.fileName"></FormattedMessage>,
  <FormattedMessage id="home.createdDate"></FormattedMessage>,
  <FormattedMessage id="home.sourceLanguage"></FormattedMessage>,
  'Translated File',
  <FormattedMessage id="home.targetLanguage"></FormattedMessage>,
  <FormattedMessage id="home.fileSize"></FormattedMessage>,
  <FormattedMessage id="home.status"></FormattedMessage>,
];

const FilesHistory = (props) => {
  const navigate = useNavigate();
  const [fileHistory, setFileHistory] = useState([]);
  const [updateStatus,setUpdateStatus] = useState('');
  const { state } = useContext(userContext);
  const [languageSelected, setAppLanguage] = useState('en');
  const [show, setShow] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [allTranslations, setAllTranslations] = useState([]);
  const { afterToday } = DateRangePicker;
  const [dateRange, setDateRange] = useState(null);
  const [filteredTranslations, setFilteredTranslations] = useState([]);
  const [accessType, setAccessType] = useState(2);
  const [sortBy, setSortBy] = useState(sortTypes[0].value);
  const [currentpage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSort, setSelectedSort] = useState({ "createdAt": -1 });
  const [totalTranslations, setTotalTranslations] = useState(0);
  const [title,setTitle]=useState('');
  useEffect(() => {
    axios
      .post(`${API_BASE_URL}/file/history/${state.user.userId}`, 
      { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        setTotalTranslations(res.data.results.count);
        setAllTranslations(res.data.results.data);
      })
     .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertFileHistory"></FormattedMessage>)
				setShow(true)
			  });
  }, []);
  useEffect(() => {
    if(updateStatus){
      axios
      .post(`${API_BASE_URL}/file/history/${state.user.userId}`, 
      { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        setTotalTranslations(res.data.results.count);
        setAllTranslations(res.data.results.data);
      })
     .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertFileHistory"></FormattedMessage>)
				setShow(true)
			  });
    }
   
  }, [updateStatus]);
  const downloadFile = async (file) => {
    axios
      .get(
        `${API_BASE_URL}/file/status/${file.fileId}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((res) => {
        if(res.data.status == 1){
          setUpdateStatus(!updateStatus)
          const link =file.targetFileLink;
          const a = document.createElement("a");
          a.setAttribute('href',link);
          a.setAttribute('target','_blank');
          a.click();
        }
       
        else{
          setUpdateStatus(!updateStatus)
          setTitle(res.data.message.includes('This document contains')?"Cannot process more than allowed characters. Allowed character is " + state.characterCountBalance+ ". Please check your Account Balance":res.data.message)
          setShow(true)
        }
        
      })
    // const response = await fetch(link);
    // console.log("response", response);
    // if (response.status != "200") {
    //   alert("Processing your file.Please wait")
    // }
    // else{
    //   window.open(link)
    // }
  }

  const getFileStatus = (status) =>{
    if(status == 1)
    return 'Done'
    else if(status == -1)
    return 'Error'
    else return 'In Process'
  }
  const displayName = (name) =>{
    return name.split('.')[0].substring(0, 16) + '...'+name.split('.')[1];
  }
  useEffect(async () => {
    axios
      .post(`${API_BASE_URL}/file/history/${state.user.userId}`, 
      { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        console.log("res",res)
        setTotalTranslations(res.data.results.count);
        setAllTranslations(res.data.results.data);
      })
     .catch((err) =>{
       console.log("err",err)
				setTitle(<FormattedMessage id="home.alertFileHistory"></FormattedMessage>)
				setShow(true)
			  });
  }, [selectedFilters, selectedSort, currentpage, rowsPerPage])
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])

  const loadNewPage = async () => {
    setCurrentPage(currentpage + 1)

  }
  const loadPreviousPage = async () => {
    setCurrentPage(currentpage - 1)

  }
  const loadNewPaginationSet = (value) => {
    setRowsPerPage(value)
  }
  const filterByDate = async (value) => {
    console.log("value", value);
    if (value) {
      setSelectedFilters({
        ...selectedFilters, createdAt: {
          $gte: value[0],
          $lte: value[1]
        }
      })
    }
    else {
      const newItems = { ...selectedFilters };
      delete newItems.createdAt;
      setSelectedFilters(newItems);
    }
  }
  const filterByAccessType = async (value) => {
    if (value && value != "2") {
      setSelectedFilters({ ...selectedFilters, status: value })
    }
    else {
      const newItems = { ...selectedFilters };
      delete newItems.status;
      setSelectedFilters(newItems);
    }
  }
  const sortByFn = async (value) => {
    let obj = JSON.parse(value);
    console.log("obj",obj)
    if (obj) {
      let key = obj.key;
      let dir = obj.dir
      if (key == "sourceFileLanguage") setSelectedSort({ "sourceFileLanguage": dir })
      else if (key == "targetFileLanguage") setSelectedSort({ "targetFileLanguage": dir })
      else if (key == "status") setSelectedSort({ "status": dir })
      else if (key == "createdAt") setSelectedSort({ "createdAt": dir })
    }
    else {
      const newItems = { ...selectedSort };
      delete newItems.sort;
      setSelectedSort(newItems);
    }


  }

  return (
    <InjectIntl>
    {({ intl }) => (
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>
      <div className="flex flex-row items-start">
        <Sidebar languageSelected={languageSelected}/>

        <div className="w-full flex-1 p-4">
          <div className="w-full">
            <div className="font-semibold text-[24px] text-[#048A8F] text-center  py-2 mb-2"> {intl.formatMessage({ id: 'home.lFileTranslation' })}</div>
            <div className="flex flex-row  gap-2 flex-wrap py-2">
              <div>
                <div className="text-xs text-zinc-500"> {intl.formatMessage({ id: 'home.dataRange' })}</div>
                <DateRangePicker
                  format="yyyy-MM-dd HH:mm:ss"
                  disabledDate={afterToday()}
                  value={dateRange}
                  className="mt-1"
                  onChange={(e) =>{ setDateRange(e); filterByDate(e) }}
                />
              </div>

              <div>
                <div className="text-xs text-zinc-500">Status Type</div>
                <select
                  value={accessType}
                  onChange={(e) => {filterByAccessType(e.target.value); setAccessType(+e.target.value);}}
                  className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                >
                  {accessTypes.map((a,index) => (
                    <option key={index} value={a.value}>{a.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.sort' })}</div>
                <select
                  value={JSON.stringify(sortBy)}
                  onChange={(e) => {sortByFn(e.target.value); setSortBy(JSON.parse(e.target.value))}}
                  className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                >
                  {sortTypes.map((a,index) => (
                    <option key={index}  value={JSON.stringify(a.value)}>{a.label}</option>
                  ))}
                </select>
              </div>
            </div>          
          </div>
          <Table responsive>
            <thead className="bg-[#048A8F] text-[#fff]">
              <tr>
                {headerContent.map((item, index) => (
                  <TH
                    className={` ${index === 0 ? "rounded-l-md pl-2" : ""
                      } ${index === headerContent.length - 1
                        ? "rounded-r-md pr-2"
                        : ""
                      }`}
                    key={index}
                  >
                    {item}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {allTranslations.map((file, index) => (
            
                <tr key={index}>
                  {/* <TH className="pl-2">{index + 1}</TH> */}
                  <TD><a
                  
                   onClick={() => {
                    const link =file.sourceFileLink;
                    const a = document.createElement("a");
                    a.setAttribute('href',link);
                    a.setAttribute('target','_blank');
                    a.click();
                   }} style={{ cursor: "pointer" }}
                      className="text-decoration-underline"
                    >{displayName(file.sourceFileLink.split(".com/")[1])} </a></TD>
                  <TD>{ new Date(file.createdAt).toLocaleString()}</TD>
                 
                  <TD>{file.sourceFileLanguage}</TD>
                  <TD>

                    <a
                    style={{ cursor: "pointer" }}
                       onClick={() => downloadFile(file)}
                      className="text-decoration-underline"
                    >
                      {displayName(file.targetFileLink.split(".com/")[1])}
                    </a>
                  </TD>
                  <TD>{file.targetFileLanguage}</TD>
                  {/* <TD>

                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => downloadFile(file)}
                      className="text-decoration-underline"
                    >
                     {intl.formatMessage({ id: 'home.targetFile' })}
                    </a>

                  </TD> */}
                
                  <TD>{getFileSize(file.fileSize)}</TD>
                  <TD>{getFileStatus(file.status)}</TD>
                </tr>
              ))}

              {
                allTranslations.length == 0 &&
                <div >Records not found</div>
              }
            </tbody>
          </Table>
          <Pagination
                  totalTranslations={totalTranslations}
                  rowsPerPage={rowsPerPage}
                  currentpage={currentpage}
                  data={allTranslations}
                  setRowsPerPage={setRowsPerPage}
                  loadNewPage={loadNewPage}
                  loadPreviousPage={loadPreviousPage}
                  loadNewPaginationSet={loadNewPaginationSet}
                  setCurrentPage={setCurrentPage}
                />
        </div>
      </div>
      <CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
        
    </div>
    )}</InjectIntl>
  );
};

const TH = ({ children, className }) => {
  return (
    <th className={`font-semibold text-sm py-1 px-1 ${className}`}>{children}</th>
  );
};

const TD = ({ children, className }) => {
  return (
    <td className={`text-zinc-600 text-sm px-1 text-center ${className}`}>{children}</td>
  );
};

const CreateModal = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};

export default FilesHistory;
