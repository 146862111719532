import React from "react";

const LinkedIn = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3162 11.2574V11.257H11.3191V7.12363C11.3191 5.10157 10.8816 3.54395 8.50591 3.54395C7.36385 3.54395 6.59744 4.16756 6.28456 4.75877H6.25152V3.73272H3.99902V11.257H6.34449V7.53123C6.34449 6.55026 6.53137 5.60169 7.75224 5.60169C8.95518 5.60169 8.97311 6.72119 8.97311 7.59416V11.2574H11.3162Z"
        fill="white"
      />
      <path
        d="M0.179688 3.74609H2.52799V11.2703H0.179688V3.74609Z"
        fill="white"
      />
      <path
        d="M1.36009 0C0.609255 0 0 0.60624 0 1.35336C0 2.10048 0.609255 2.71939 1.36009 2.71939C2.11092 2.71939 2.72017 2.10048 2.72017 1.35336C2.7197 0.60624 2.11045 0 1.36009 0V0Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedIn;
