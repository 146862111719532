import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
const ModalSendReset = ({ show, limit, title, footer }) => {
  console.log(title)
  let hideEmail = function(email) {
    return email.replace(/(.{2})(.*)(?=@)/,
      function(gp1, gp2, gp3) { 
        for(let i = 0; i < gp3.length; i++) { 
          gp2+= "*"; 
        } return gp2; 
      });
  };
  return (
    <div
      tabIndex="-1"
      aria-hidden="true"
     
      className={`${show ? "flex" : "hidden"
        } 
      
      transition-all overflow-y-auto overflow-x-hidden fixed top-0  right-0 left-0 z-50  h-modal  justify-center items-center`}
    >
      <div className="relative p-4 w-70 py-20"  style={{width:"40% !important"}}>
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Header */}
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <div className="font-semibold text-xl " style={{marginLeft:"44%"}}>
              
            
              <AiOutlineCheckCircle size={50}style={{color:"green"}}/></div>
          </div>

          {/* Body */}
          <div className="p-6 space-y-6 text-center">
            <div style={{fontSize:"25px",fontWeight:"600"}}>Password Reset Email Sent</div>
            An email has being sent to your email address <span><b>{hideEmail(title)}</b></span>. Please follow <br/>
            the directions in the email to reset your password.
            </div>

          {/* Footer */}
          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            {footer()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSendReset;
