import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/admin/login");
  }, [navigate]);
  return <div></div>;
};

export default Admin;
