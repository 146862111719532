import React, { useContext } from "react";
import { AiOutlineAppstore, AiFillSchedule } from "react-icons/ai";
import { IoCallOutline, IoSettingsOutline } from "react-icons/io5";
import { BsCommand, BsPeople, BsList } from "react-icons/bs";
import { FiBriefcase } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { userContext } from "../../store/context/user";
import { useMediaQuery } from 'react-responsive'
import InjectIntl from 'react-intl-inject';
import { FormattedMessage } from 'react-intl';

const AdminSidebar = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const pathname = useLocation().pathname;
  const { state } = useContext(userContext);
  return (
    <>
    {!isTabletOrMobile &&
    <div className="p-4 max-w-[280px]  w-[20%]">
      <div className="text-[20px] font-semibold ">
      <FormattedMessage id="home.goodMorning"></FormattedMessage> {state?.user?.name.split(" ")[0]}
      </div>
     {/*  <div className="text-[#828282] text-xs">Hope you have a good day</div> */}

      <div className="mt-10">
        <Link
          to="/admin/users"
          className={`flex flex-row items-center my-3 ${
            pathname === "/admin/users" ? "text-[#048A8F]" : "text-zinc-400"
          } `}
        >
          <HiUsers size={20} className="mr-2" />
          <div className="text-sm"> <FormattedMessage id="home.usersList"></FormattedMessage> </div>
        </Link>
        <Link
          to="/admin/plans"
          className={`flex flex-row items-center my-3 ${
            pathname === "/admin/plans" ? "text-[#048A8F]" : "text-zinc-400"
          } `}
        >
          <AiFillSchedule size={20} className="mr-2" />
          <div className="text-sm"> <FormattedMessage id="home.userPlan"></FormattedMessage> </div>
        </Link>
        <Link
          to="/admin/logs"
          className={`flex flex-row items-center my-3 ${
            pathname === "/admin/logs" ? "text-[#048A8F]" : "text-zinc-400"
          } `}
        >
          <BsList size={20} className="mr-2" />
          <div className="text-sm"><FormattedMessage id="home.logs"></FormattedMessage> </div>
        </Link>
        <Link
          to="/admin/rateTranslation"
          className={`flex flex-row items-center my-3 ${
            pathname === "/admin/rateTranslation" ? "text-[#048A8F]" : "text-zinc-400"
          } `}
        >
          <BsList size={20} className="mr-2" />
          <div className="text-sm">Rate Translation </div>
        </Link>
        <Link
          to="/admin/paypalconfig"
          className={`flex flex-row items-center my-3 ${
            pathname === "/admin/paypalconfig" ? "text-[#048A8F]" : "text-zinc-400"
          } `}
        >
          <BsList size={20} className="mr-2" />
          <div className="text-sm">Paypal Configuration </div>
        </Link>
        <hr />
      </div>

     {/*  <div className="w-full bg-[#6956E5] bg-opacity-10 px-3 pb-3 flex flex-col items-center pt-24 relative mt-20 rounded-md">
        <img
          src="/assets/imgs/lamp.png"
          alt="lamp"
          className="absolute -top-7"
          height={130}
          width={130}
        />
        <button className="z-30 bg-white text-center text-xs px-4 py-2 rounded-md text-[#6956E5]">
          Share Your Thoughts
        </button>
      </div> */}
    </div>
     }
     </>
    
  );
};

export default AdminSidebar;
