import React, { useEffect } from "react";
import {
    Card,
    Col,
    Container,
    NavLink,
    Row
} from "react-bootstrap";
import "./pricing.styles.scss";
import { FormattedMessage } from "react-intl";
import Navigation from "../../components/Navbar/Navbar";
function PaymentConfirmation(props) {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <React.Fragment>
            <Navigation setLanguage={props.setLanguage} language={props.language} />
            <div
                className={
                    "site-blocks-cover-flipped inner-page-cover"
                }

            >
                <Container>
                    <Row className="align-items-center justify-content-start">
                        <Col lg="12">
                            <h1 className="font-weight-bold mt-5 mb-3 space-letters">
                                Invest
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Row className="justify-content-center">
                    <Col className="text-start">
                        <p className="pricing-para">Thank you for investing in us. </p>
                        <p className="pricing-summary">
                            You should be recieving an email from us.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Row xs="1" md="8">
                    <Col>
                        <Card className="mb-4 rounded-3 shadow-sm">
                            <Card.Body>
                                <div className="profile-card js-profile-card">
                                    <div className="profile-card__img" style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        <img src={'/assets/imgs/modal.png'} alt="profile card" />
                                    </div>

                                    <div className="profile-card__cnt js-profile-cnt">
                                        <div className="profile-card__name">Successful Payment</div>
                                        <div className="profile-card__txt">
                                            You have now successfully invested in Posh Translate. We
                                            will send you a confirmation email. In case you have any
                                            inquiries, you can always contact us.
                                        </div>

                                        <div className="profile-card-ctr">
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PaymentConfirmation;
