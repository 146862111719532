import React, { useContext, useEffect, useState, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { userContext } from "../../store/context/user";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import Pagination from "../../components/NewPagination/Pagination";
import TH from "../../components/Table/TH";
import TD from "../../components/Table/TD";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import InjectIntl from 'react-intl-inject';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import { CSVLink, CSVDownload } from "react-csv";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
const accessTypes = [
  { label: "All", value: -1 },
  { label: "Web", value: 0 },
  { label: "API Key", value: 1 },

];

const sortTypes = [
  { label: "Latest", value: { key: "createdAt", dir: -1 } },
  { label: "Oldest", value: { key: "createdAt", dir: 1 } },
  { label: "Source Language (A-Z)", value: { key: "sourceLanguage", dir: 1 } },
  { label: "Source Language (Z-A)", value: { key: "sourceLanguage", dir: -1 } },
  { label: "Target Language (A-Z)", value: { key: "targetLanguage", dir: 1 } },
  { label: "Target Language (Z-A)", value: { key: "targetLanguage", dir: -1 } },
  { label: "Access Type (A-Z)", value: { key: "mode", dir: 1 } },
  { label: "Access Type (Z-A)", value: { key: "mode", dir: -1 } },
];

const Transactions = (props) => {
  const { state } = useContext(userContext);
  const [languageSelected, setAppLanguage] = useState('en');
  const [allTranslations, setAllTranslations] = useState([]);
  const [filteredTranslations, setFilteredTranslations] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const data1 = dataToDisplay.slice(10, 100);

  const [currentpage, setCurrentPage] = useState(1);
  const [totalTranslations, setTotalTranslations] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { afterToday } = DateRangePicker;
  const [selectedText, setselectedText] = useState("");
  const [selectedtargetText, setselectedtargetText] = useState("");
  // Filters
  const [dateRange, setDateRange] = useState(null);
  const [show, setShow] = useState(false);
  const [showtarget, setShowtarget] = useState(false);
  const [accessType, setAccessType] = useState(-1);
  const [sortBy, setSortBy] = useState(sortTypes[0].value);
  const [downloadReady, setDownloadReady] = useState([]);
  const [toggleHide, setToggleHide] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSort, setSelectedSort] = useState({ "createdAt": -1 });
  const csvInstance = useRef();
  const [loading, setLoading] = useState(true);
  const [buttonText, setButtonText] = useState("Read More");

  function handleClick() {
    setShow(true)
  }

  function handleClicktarget() {
    setShowtarget(true)

  }
  const loadNewPage = async () => {
    setCurrentPage(currentpage + 1)

  }
  const loadPreviousPage = async () => {
    setCurrentPage(currentpage - 1)

  }
  const loadNewPaginationSet = (value) => {
    setRowsPerPage(value)
  }

  const filterByAccessType = async (value) => {
    if (value && value != "-1") {
      setSelectedFilters({ ...selectedFilters, mode: value })
    }
    else {
      const newItems = { ...selectedFilters };
      delete newItems.mode;
      setSelectedFilters(newItems);
    }
  }


  const sortByFn = async (value) => {
    let obj = JSON.parse(value);
    if (obj) {
      let key = obj.key;
      let dir = obj.dir
      if (key == "sourceLanguage") setSelectedSort({ "sourceLanguage": dir })
      else if (key == "targetLanguage") setSelectedSort({ "targetLanguage": dir })
      else if (key == "mode") setSelectedSort({ "mode": dir })
      else if (key == "createdAt") setSelectedSort({ "createdAt": dir })
    }
    else {
      const newItems = { ...selectedSort };
      delete newItems.sort;
      setSelectedSort(newItems);
    }


  }

  const filterByDate = async (value) => {
    console.log("value", value);
    if (value) {
      setSelectedFilters({
        ...selectedFilters, createdAt: {
          $gte: value[0],
          $lte: value[1]
        }
      })
    }
    else {
      const newItems = { ...selectedFilters };
      delete newItems.createdAt;
      setSelectedFilters(newItems);
    }
  }

  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])

  const handleClose = () => {
    setShow(false);
  }
  const handleClosetarget = () => {
    setShowtarget(false);
  }
  useEffect(async () => {
    const { data } = await axios.post(
      `${API_BASE_URL}/all/${state.user.userId}`,
      { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    );
    setTotalTranslations(data.results.count);
    setAllTranslations(data.results.data);
  }, [selectedFilters, selectedSort, currentpage, rowsPerPage])

  const downloadCSV = () => {
    axios.post(
      `${API_BASE_URL}/all/${state.user.userId}`,
      { page: currentpage, limit: 1000000, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort, csv: true },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    ).then((data) => {
      console.log("data", data.data.results.data)
      setFilteredTranslations(data.data.results.data);

    }).catch(() => {


    });
  }
  useEffect(async () => {
    if (filteredTranslations.length > 0) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setFilteredTranslations([]);
      });
    }
  }, [filteredTranslations])


  return (
    <InjectIntl>
      {({ intl }) => (
        <>


          <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur') ? 'rtl' : 'ltr' }}>
            <Navbar setLanguage={props.setLanguage} language={props.language} />
            <div className="flex flex-row items-start">
              <Sidebar languageSelected={languageSelected} />
              <div className="w-full p-4">

                <div className="w-full  font-semibold text-center p-2  text-[#048A8F] text-[24px]">
                  {intl.formatMessage({ id: 'home.textTranslation' })}
                </div>

                <div className="px-1">
                  <div className="font-semibold"> {intl.formatMessage({ id: 'home.filters' })}</div>
                  <div className="flex flex-row  gap-2 flex-wrap py-2">
                    <div>

                      <div className="text-xs text-zinc-500"> {intl.formatMessage({ id: 'home.dataRange' })}</div>
                      <DateRangePicker
                        format="yyyy-MM-dd HH:mm:ss"
                        disabledDate={afterToday()}
                        value={dateRange}
                        className="mt-1"
                        onChange={(e) => { setDateRange(e); filterByDate(e) }}
                      />
                    </div>

                    <div>
                      <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.access' })}</div>
                      <select
                        value={accessType}
                        onChange={(e) => { filterByAccessType(e.target.value); setAccessType(+e.target.value) }}
                        className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                      >
                        {accessTypes.map((a, index) => (
                          <option key={index} value={a.value}>{a.label}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.sort' })}</div>
                      <select
                        value={JSON.stringify(sortBy)}
                        onChange={(e) => { sortByFn(e.target.value); setSortBy(JSON.parse(e.target.value)) }}
                        className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                      >
                        {sortTypes.map((a, index) => (
                          <option key={index} value={JSON.stringify(a.value)}>{a.label}</option>
                        ))}
                      </select>
                    </div>

                    {
                      <CSVLink data={filteredTranslations} filename={"Text-Translation.csv"}
                        ref={csvInstance}>

                      </CSVLink>
                    }
                    {
                      <div onClick={() => downloadCSV()}
                        className="bg-[#A92D62] text-white px-2 py-1 rounded-sm flex flex-row items-center csvexport" target="_blank"
                        style={{
                          color: '#FFF',
                          cursor: "pointer",
                          height: '30px',
                          marginTop: '20px'

                        }} >
                        <AiOutlineAppstoreAdd size={24} className="mr-4" />
                        Export CSV</div>
                    }
                  </div>

                </div>

                <Table responsive>
                  <thead className="bg-[#048A8F] text-[#fff]" >
                    <tr>
                      <TH className={`rounded-l-md pl-2`}>{intl.formatMessage({ id: 'home.dateTIme' })}</TH>
                      <TH>{intl.formatMessage({ id: 'home.orginal' })}</TH>
                      <TH>{intl.formatMessage({ id: 'home.translated' })}</TH>
                      <TH className={`rounded-r-md pr-2`}>{intl.formatMessage({ id: 'home.access' })}</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {allTranslations.map((tr, index) => (
                      <tr key={index}>
                        <TD className="pl-2">
                          {new Date(tr.createdAt).toLocaleString()}
                        </TD>
                        <TD>
                          <span className="font-semibold  ellipsis">
                            [{tr.sourceLanguage ?tr.sourceLanguage.toUpperCase():"Unknown"}]{" "}
                            {tr.sourceText}
                          </span>
                          {
                            tr.sourceText.length > 15 &&
                            <span className="text-black capitalize ellipsis" style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "pointer" }} onClick={() => { handleClick(); setselectedText(tr.sourceText); }}   >{buttonText}</span>

                          }
                        </TD>
                        <TD>
                          <span className="font-semibold ellipsis">
                            [{tr.targetLanguage.toUpperCase()}]{" "}
                            {tr.targetText}
                          </span>{" "}

                          {
                            tr.targetText.length > 15 &&
                            <span className="text-black capitalize ellipsis" style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "pointer" }} onClick={() => { handleClicktarget(); setselectedtargetText(tr.targetText); }}   >{buttonText}</span>

                          }
                        </TD>
                        <TD>{tr.mode === 0 ? "Web" : "API Key"}</TD>
                      </tr>
                    ))}
                    {
                      allTranslations.length == 0 &&
                      <div >Records not found</div>
                    }
                  </tbody>
                </Table>

                <Pagination
                  totalTranslations={totalTranslations}
                  rowsPerPage={rowsPerPage}
                  currentpage={currentpage}
                  data={allTranslations}
                  setRowsPerPage={setRowsPerPage}
                  loadNewPage={loadNewPage}
                  loadPreviousPage={loadPreviousPage}
                  loadNewPaginationSet={loadNewPaginationSet}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>


          </div>

          <Modal show={show} onHide={handleClose} className="closebtn ">

            <Modal.Header className="h-full">

              <button type="button" onClick={() => handleClose()} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>


              </button>
            </Modal.Header>
            <Modal.Body className="readmore px-20 w-full  "   >
              {selectedText}
            </Modal.Body>
            <Modal.Footer>
              <img src="/assets/imgs/logomodal.png" className="logomodal" onClick={handleClose}
              />
            </Modal.Footer>
          </Modal>
          <Modal show={showtarget} onHide={handleClosetarget}  >

            <Modal.Header className="h-full">

              <button type="button" onClick={() => handleClosetarget()} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>
            </Modal.Header>
            <Modal.Body className="readmore px-20 w-full " >
              {selectedtargetText}
            </Modal.Body>
            <Modal.Footer>
              <img src="/assets/imgs/logomodal.png" className="logomodal" onClick={handleClosetarget}
              />
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}</InjectIntl>
  );
};

export default Transactions;
