import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Translation from "../../components/Translation/Translation";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';
const ChartsToDisplay = ({ type, translation,characters,pdfs,intl }) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
  const months1 = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
 
  const monthCountDisplay = () => {
    if (characters && characters.length > 0) {
      return characters.map((chr) => { return chr.count })
    }
  }
  const monthCountDisplayPdf = () => {
   
    if (pdfs && pdfs.length > 0) {
      return pdfs.map((pdf) => { return pdf.count })
    }
  }
 
  const datacharacter = {
    labels:months.splice(0,new Date().getMonth()+1),
    datasets: [
      {
        label:intl?.formatMessage({ id: 'home.characterUsed' }),
        data:monthCountDisplay() ,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
     
    ],
  };
 
  const dataPdf = {
    labels:months1.splice(0,new Date().getMonth()+1),
    datasets: [
      {
        label:"Number of Documents & Image Used",
        data: monthCountDisplayPdf(),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
     
    ],
  };
  const countDisplay = () => {
    if (translation && translation.length > 0) {
      return translation.map((translation) => { return translation.count })
    }
  }
  const languageSelcted = () => {
    if (translation && translation.length > 0) {
      return translation.map((translation) => { return translation.languageFull })
    }
  }
  const data = {
    labels: languageSelcted(),
    datasets: [
      {
        label: '# of Votes',
        data: countDisplay(),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(252, 94, 176, 0.8)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 99, 71, 0.5)',
          'rgba(182, 113, 149, 0.8)',
          'rgba(213, 196, 205, 0.8)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(252, 94, 176, 0.8)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 99, 71, 0.5)',
          'rgba(182, 113, 149, 0.8)',
          'rgba(213, 196, 205, 0.8)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderWidth: 1,
      },
    ],
  }
  return (
    type == 'pie' ?
      <><Chart type='pie' data={data} /><br/>
      <span  className="flex flex-row items-center text-center" style={{color:'black'}}><FormattedMessage id="home.mostUsedTranslation"></FormattedMessage> </span></> :
       <div ><Chart type='line' data={datacharacter}    width={600} height={250}/><br/>
       <br/>
        <Chart type='line' data={dataPdf}    width={600} height={250}/><br/>
        </div>
  );
};

export default ChartsToDisplay;