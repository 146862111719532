import React, { createContext, useEffect, useReducer } from "react";
import { userReducer } from "../reducers/user";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";

export const userContext = createContext();

let initialState;

if (localStorage.getItem("poshTranslateState")) {
  initialState = JSON.parse(localStorage.getItem("poshTranslateState"));
} else {
  initialState = {
    isLoggedIn: false,
    isAdmin: false,
    user: null,
    planName: null,
    token: null,
    characterCountBalance: 0,
    pdfCountBalance: 0,
    docCountBalance: 0,
    ocrCountBalance: 0,
    pdfStorageBalance: 0,
    docStorageBalance: 0,
    ocrStorageBalance: 0,
    maxPdfSize: 0,
    maxDocSize: 0,
    maxOcrSize: 0,
    availableLanguages: [],
    firstLoading: true,
    isMonthly:true,
  };
}

export const UserContextProvider = (props) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
    <userContext.Provider value={{ state, dispatch }}>
      {props.children}
    </userContext.Provider>
  );
};
