import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../utils/globals';
import { userContext } from '../../store/context/user';
import './PaymentBalance.scss';
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { FormattedMessage } from 'react-intl';
export default function PaymentBalance() {
	const { state, dispatch } = useContext(userContext);
	const [show, setShow] = useState(false);
	const [title,setTitle]=useState('')


	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/plan/${state.user.planId}`)
			.then((response) => {
				dispatch({
					type: 'SET_PLAN_NAME',
					payload: response.data.plan.name,
				});
			})
			.catch((err) =>{
				setTitle(<FormattedMessage id="home.alertGetPlanName"></FormattedMessage>)
				setShow(true)
			  });
	}, []);

	return (
		<div className='paymentbalance'>
			<h1 className='paymentbalance__title'>Your Balance Details</h1>
			<h2 className='paymentbalance__subtitle'>
				Your current plan: <i>{state.planName}</i>
			</h2>
			<div className='paymentbalance__details'>
				<p className='paymentbalance__balance'>character Balance: {state.characterCountBalance} characters</p>
				<p className='paymentbalance__balance'>PDF Balance: {state.pdfCountBalance} PDFs</p>
				<p className='paymentbalance__balance'>Document Balance: {state.docCountBalance} documents</p>
				<p className='paymentbalance__balance'>Image Balance: {state.ocrCountBalance} images</p>
			</div>
			<Link to='/payment' className='paymentbalance__link'>
				Upgrade your plan
			</Link>

			<CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
		</div>
	);
}
		
const CreateModal = ({
	show,
	title,
	setShow
  }) => {
	return (
	  <ModalAlert
		show={show}
		title={title}
		footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
		body={() => (
		  <div>
		  
			<ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
		  </div>
		)}
	  />
	);
  };