import en from './en.json';
import ar from './ar.json';
import fr from './fr.json';
import es from './es.json';
import hi from './hi.json';
import ur from './ur.json';
import ju from './ju.json';
import zh from './zh.json';
import tr from './tr.json';
export default {
    en,ar,fr,es,hi,ur,ju,zh,tr
}   