import React from 'react';
import Translation from '../../components/Translation/Translation';
import './TextTranslation.scss';

export default function TextTranslation() {
	return (
		<main className='text-translation'>
			<Translation apiEndpoint='text' />
		</main>
	);
}
