export const userReducer = (state, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      var newState = {
        ...state,
        isLoggedIn: true,
        isAdmin: false,
        user: action.payload.user,
        token: action.payload.token,
        characterCountBalance: action.payload.characterCountBalance,
        pdfCountBalance: action.payload.pdfCountBalance,
        docCountBalance: action.payload.docCountBalance,
        ocrCountBalance: action.payload.ocrCountBalance,
        pdfStorageBalance: action.payload.pdfStorageBalance,
        docStorageBalance: action.payload.docStorageBalance,
        ocrStorageBalance: action.payload.ocrStorageBalance,
        availableLanguages: action.payload.availableLanguages,
        planName: action.payload.planName,
        googleUser:action.payload.googleUser,
        globalDocCountBalance:action.payload.globalDocCount,
        globalDocStorageBalance:action.payload.globalTotalDocSize,
        direct_translation: action.payload.direct_translation,
        document_translation: action.payload.document_translation,
        api_allowed: action.payload.api_allowed,
        cloud_storage: action.payload.cloud_storage,
        batch_translate_text: action.payload.batch_translate_text,
        batch_translate_doc: action.payload.batch_translate_doc,
        total_requests: action.payload.total_requests,
        language_detection: action.payload.language_detection,
        image_translation:action.payload.image_translation,
        cardStored:action.payload.cardStored,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "ADMIN_LOGIN":
      newState = {
        ...state,
        isLoggedIn: true,
        isAdmin: true,
        user: action.payload.user,
        token: action.payload.token,
        characterCountBalance: action.payload.characterCountBalance,
        pdfCountBalance: action.payload.pdfCountBalance,
        docCountBalance: action.payload.docCountBalance,
        ocrCountBalance: action.payload.ocrCountBalance,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "LOGOUT":
      localStorage.removeItem("poshTranslateState");
      return {
        ...state,
        isLoggedIn: false,
        isAdmin: false,
        user: null,
        token: null,
        characterCountBalance: 0,
        pdfCountBalance: 0,
        docCountBalance: 0,
        ocrCountBalance: 0,
        pdfStorageBalance: 0,
        docStorageBalance: 0,
        ocrStorageBalance: 0,
        availableLanguages: [
          "en",
          "ar",
          "fr",
          "es",
          "hi",
          "ur",
          "ja",
          "zh",
          "tr"
        ],
      };

    case "SET_AVAILABLE_LANGUAGES":
      newState = {
        ...state,
        availableLanguages: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;

    case "SET_PLAN_NAME":
      newState = {
        ...state,
        planName: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;

    case "UPDATE_CHARACTER_COUNT_BALANCE":
      newState = {
        ...state,
        characterCountBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
      case "UPDATE_PLANS":
        var newState = {
          ...state,
          direct_translation: action.payload.direct_translation,
          characterCountBalance: action.payload.characterCountBalance,
          availableLanguages: action.payload.availableLanguages,
          document_translation: action.payload.document_translation,
          globalDocStorageBalance: action.payload.globalDocStorageBalance,
          globalDocCountBalance: action.payload.globalDocCountBalance
        
         };
        localStorage.setItem("poshTranslateState", JSON.stringify(newState));
        return newState;
    case "UPDATE_DOC_COUNT_BALANCE":
      newState = {
        ...state,
        docCountBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_PDF_COUNT_BALANCE":
      newState = {
        ...state,
        pdfCountBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_OCR_COUNT_BALANCE":
      newState = {
        ...state,
        ocrCountBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;

    case "UPDATE_DOC_STORAGE_BALANCE":
      newState = {
        ...state,
        docStorageBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_GLOBAL_DOC_STORAGE_BALANCE":
      newState = {
        ...state,
        globalDocStorageBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_GLOBAL_DOC_COUNT_BALANCE":
      newState = {
        ...state,
        globalDocCountBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_ISMONTHLY_PLAN":
      newState = {
        ...state,
        isMonthly: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_PDF_STORAGE_BALANCE":
      newState = {
        ...state,
        pdfStorageBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "UPDATE_OCR_STORAGE_BALANCE":
      newState = {
        ...state,
        ocrStorageBalance: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "SET_MAX_PDF_SIZE":
      newState = {
        ...state,
        maxPdfSize: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "SET_MAX_DOC_SIZE":
      newState = {
        ...state,
        maxDocSize: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "SET_MAX_OCR_SIZE":
      newState = {
        ...state,
        maxOcrSize: action.payload,
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "ADD_NEW_APP":
      newState = {
        ...state,
        user: { ...state.user, apps: [action.payload, ...state.user.apps] },
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;

    case "UPDATE_PLAN":
      newState = {
        ...state,
        user: { ...state.user, planId: action.payload },
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
      case "UPDATE_PURCHASE_DATE":
        newState = {
          ...state,
          user: { ...state.user, plan_purchased_on: action.payload },
        };
        localStorage.setItem("poshTranslateState", JSON.stringify(newState));
        return newState;
    case "DELETE_APP":
      let updatedUserApps = [...state.user.apps].filter(
        (app) => app.appId !== action.payload
      );
      newState = {
        ...state,
        user: { ...state.user, apps: updatedUserApps },
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;
    case "GUEST_STATE":
      newState = {
        ...state,
        characterCountBalance: action.payload.characterCountBalance,
        charactersUsed:0,
        charactersMonthlyUsed:0,
        fileUsed:0,
        ImageUsed:0,
        fileStorageUsed:0,
        charactersMonthlyUsed:0,
        freeCharactersAvailable:true,
        pdfCountBalance: action.payload.pdfCountBalance,
        globalDocCountBalance: action.payload.globalDocCountBalance,
        globalDocStorageBalance: action.payload.globalmaxDocSize,
        docCountBalance: action.payload.docCountBalance,
        ocrCountBalance: action.payload.ocrCountBalance,
        pdfStorageBalance: action.payload.pdfStorageBalance,
        docStorageBalance: action.payload.docStorageBalance,
        ocrStorageBalance: action.payload.ocrStorageBalance,
        availableLanguages: action.payload.availableLanguages,
        user: action.payload.user,
        isAdmin: false,
        isLoggedIn: false,
        firstLoading: false,
        planName: action.payload.planName,
        token: action.payload.user.token,
        direct_translation: action.payload.direct_translation,
        image_translation: action.payload.image_translation,
        document_translation: action.payload.document_translation,
        language_detection: action.payload.language_detection,
        api_allowed: action.payload.api_allowed,
        cloud_storage: action.payload.cloud_storage,
        batch_translate_text: action.payload.batch_translate_text,
        batch_translate_doc: action.payload.batch_translate_doc,
        total_requests: action.payload.total_requests,
        plan_type: action.payload.plan_type,
        price_per_document_page: action.payload.price_per_document_page,
        max_characters_per_page: action.payload.max_characters_per_page,
        cardStored:false
      };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;

    case "UPDATE_USER":
      newState = { ...state, user: action.payload.user };
      localStorage.setItem("poshTranslateState", JSON.stringify(newState));
      return newState;

      case "UPDATE_PAYMENT_ID":
        newState = {
          ...state,
          paymentID: action.payload.paymentID,
        };
        localStorage.setItem("poshTranslateState", JSON.stringify(newState));
        return newState;
      case "UPDATE_CUSTOMERID":
        newState = {
          ...state,
          user: { ...state.user, customerID: action.payload.customerID },
        };
        localStorage.setItem("poshTranslateState", JSON.stringify(newState));
        return newState;
        case "UPDATE_CARD_ID":
          newState = {
            ...state,
            user: { ...state.user, cardID: action.payload.cardID },
          };
          localStorage.setItem("poshTranslateState", JSON.stringify(newState));
          return newState;
    default:
      break;
  }
};
