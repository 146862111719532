import React, { useEffect, useState } from "react";
import TranslateIcon from "../../svgIcons/TranslateIcon";
import Check from "../../svgIcons/Check";
import Footer from "../../components/Footer/Footer";
import { BiChevronRight } from "react-icons/bi";
import Navbar from "../../components/Navbar/Navbar";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const CheckOut = () => {
    const [plans, setPlans] = useState([]);
    const [isMonthly, setIsMonthly] = useState(true);
    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`${API_BASE_URL}/plan/`);
            console.log("data", data);
            setPlans(data.plans.filter((p) => p.status === "active"));
        })();
    }, []);
    const [faqIndex, setFaqIndex] = useState(0);
    return (
        <div className="max-w-[1280px] mx-auto py-20">
            <PayPalScriptProvider options={{ "client-id": "test" }}>
                <PayPalButtons
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: "1.99",
                                    },
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            const name = details.payer.name.given_name;
                            alert(`Transaction completed by ${name}`);
                        });
                    }}
                />
            </PayPalScriptProvider>
        </div>
    );
};

export default CheckOut;
