import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Navbar from "../../components/Navbar/Navbar";

const getTableData = (rows) => {
  var data = [];
  var characters = "0123456789";

  for (var i = 0; i < rows; i++) {
    const rN = characters
      .split("")
      .sort(() => 0.5 - Math.random())
      .slice(0, 6)
      .join("");
    data.push({
      id: rN,
      title: `Title ${i + 1}`,
      service: rN,
      status: rN,
      jobCost: rN,
      orderNumber: rN,
      delivery: rN,
      language: rN,
    });
  }

  return data;
};

const Dashboard2 = () => {
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setData(getTableData(50));
  }, []);

  useEffect(() => {
    setDataToDisplay(
      data.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      )
    );
  }, [data, page, rowsPerPage]);
  return (
    <div className="max-w-[1280px] mx-auto">
      {/* <div className="flex flex-row justify-between items-center w-full px-4">
        <div>
          <img
            src="/assets/imgs/homelogo.png"
            alt="logo"
            className="h-[60px]"
          />
        </div>
        <div className="flex flex-row items-center">
          <AiOutlineSearch size={24} className="mx-1" />
          <IoIosNotificationsOutline size={24} className="mx-1" />
          <div className="rounded-full h-8 w-8 mx-1 overflow-hidden">
            <img src="/assets/imgs/profile.png" alt="profile" />
          </div>
          <BiChevronDown size={24} className="mx-1" />
        </div>
      </div> */}

      <Navbar />

      <div className="flex flex-row">
        <Sidebar />

        <div className="p-4 flex flex-col w-full">
          <div className="flex flex-row">
            <input
              type="search"
              placeholder="Search by Name, Brand, Variant etc…"
              className="flex-1 border-zinc-300 border-[1px] rounded-md px-2 py-1 text-sm outline-none"
            />
            <select className="border-zinc-300 border-[1px] rounded-md px-2 py-1 text-sm outline-none ml-2">
              <option>Action</option>
            </select>
          </div>

          <div className="w-full">
            <table className="w-full border-separate table-row-space">
              <thead>
                <tr className="font-semibold bg-zinc-200">
                  <TH className="pl-2 rounded-l-md">
                    <input type="checkbox" />
                  </TH>
                  <TH>#ID</TH>
                  <TH>Project Title</TH>
                  <TH>Service</TH>
                  <TH>Status</TH>
                  <TH>Job Cost</TH>
                  <TH>Order Number</TH>
                  <TH>Est. Delivery</TH>
                  <TH className="pr-2 rounded-r-md">Language</TH>
                </tr>
              </thead>
              <tbody>
                {dataToDisplay.map((item) => (
                  <tr key={item.id} className="hover:border-2 border-zinc-400">
                    <TD className="pl-2">
                      <input type="checkbox" />
                    </TD>
                    <TD>
                      <div className="flex flex-row items-center">
                        <div className="h-8 w-8 rounded-sm bg-red-500 mr-2"></div>
                        <div>{item.id}</div>
                      </div>
                    </TD>
                    <TD>{item.title}</TD>
                    <TD>{item.service}</TD>
                    <TD>{item.status}</TD>
                    <TD>{item.jobCost}</TD>
                    <TD>{item.orderNumber}</TD>
                    <TD>{item.delivery}</TD>
                    <TD>{item.language}</TD>
                  </tr>
                ))}
              </tbody>
            </table>

            <hr />

            <div className="flex flex-row justify-between items-center mt-3">
              <div className="text-sm">
                {(page - 1) * rowsPerPage + 1}-
                {(page - 1) * rowsPerPage + rowsPerPage > data.length + 1
                  ? data.length
                  : (page - 1) * rowsPerPage + rowsPerPage}{" "}
                of {data.length} items
              </div>
              <div className="flex flex-row items-center">
                <button
                  onClick={() => setPage((p) => p - 1)}
                  disabled={page === 1}
                  className="p-1 border-[1px] border-zinc-400 rounded-md mx-1"
                >
                  <FiChevronLeft size={16} />
                </button>

                {/* <button className="px-1 mx-1">1</button>
                <button className="px-1 mx-1">2</button>
                <button className="px-1 mx-1">3</button>
                <button className="px-1 mx-1">4</button>
                <button className="px-1 mx-1">5</button>
                <div>...</div>
                <button className="px-1 mx-1">10</button> */}

                <div className="mx-2 text-sm">
                  Page {page} of {Math.ceil(data.length / rowsPerPage)}
                </div>
                <button
                  onClick={() => setPage((p) => p + 1)}
                  disabled={page === Math.ceil(data.length / rowsPerPage)}
                  className="p-1 border-[1px] border-zinc-400 rounded-md mx-1"
                >
                  <FiChevronRight size={16} />
                </button>

                <input
                  value={rowsPerPage}
                  min={1}
                  max={data.length}
                  onChange={(e) => setRowsPerPage(+e.target.value)}
                  type="number"
                  className="border-[1px] border-zinc-400 rounded-md mx-1 w-[50px] outline-none text-center pageNum-input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const TH = ({ children, className }) => {
  return <th className={`font-semibold py-1 ${className}`}>{children}</th>;
};

const TD = ({ children, className }) => {
  return <td className={`text-zinc-600 text-sm ${className}`}>{children}</td>;
};

export default Dashboard2;
