import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from '../../store/context/user';

export default function AdminRoute({ children }) {
	const navigate = useNavigate();
	const { state } = useContext(userContext);

	useEffect(() => {
		if (!state.isLoggedIn || !state.isAdmin) {
			navigate('/admin/login');
		}
	}, []);
	return children;
}
