import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../utils/globals";
import "../Login/Login.scss";
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive'
import ModalSendReset from "../../components/Modal/ModalSendReset";
import ModalButton from "../../components/Modal/ModalButton";

import InjectIntl from 'react-intl-inject';
import Logindots from "../../svgIcons/Logindots";
export default function SendReset() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [userData, setUserData] = useState({ email: "" });
  const [emailFeedback, setEmailFeedback] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [noAccount, setNoAccount] = useState(false);
  const navigate = useNavigate();

  
const [show, setShow] = useState(false);
const [title,setTitle]=useState('')


  const validateEmail = (value) => {
    const regex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return { isValid: regex.test(value), message: "This is not a valid email format!" };
  };

  useEffect(() => {
    const resultEmail = validateEmail(userData.email);
    let newFormValue = true;
    if (!resultEmail.isValid) {
      setEmailFeedback(resultEmail.message);
      newFormValue &= false;
    } else {
      setEmailFeedback("");
      newFormValue &= true;
    }
    setFormValid(newFormValue);
  }, [userData]);

  const handleInputChange = (event) => {
    userData[event.target.name] = event.target.value;
    if(!event.target.value)
    setNoAccount(false)
    setUserData({ ...userData });
  };

  const handleSendReset = (event) => {
    event.preventDefault();

    axios
      .post(`${API_BASE_URL}/auth/send-reset`, userData)
      .then((res) => {
       
        if(res.status == 201)
        setNoAccount(true)
        else{
         
          setTitle(userData.email)
          setShow(true)
          // navigate('/login')
        }
       
			

        // navigate('/login');
      })
     
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertFailtoReset"></FormattedMessage>)
				setShow(true)
			  });
  };

  return (
<InjectIntl>
      {({ intl }) => (
    <div style={{ overflow: 'hidden' }}>
{isTabletOrMobile &&
    <div class="grid-container">

<div class="grid-child ">
<div className="text-center h-full w-full text-lg-left screen">
           <img
              src="/assets/imgs/signtriangle.png"
               className="h-full w-full object-contain"
               alt="signup screen"   
             />
           </div>
  
</div>

<div class="grid-child ">
<img   onClick={() => navigate('/')}

         src="/assets/imgs/signin.png"
          className="h-full w-full ml-0 object-contain" 
          style={{disply:'flex', justifyContent:'left' ,
          height: '150px',
       
          left: '287px',
          top: '345px',
          borderRadius: '0px',
          cursor:'pointer'
        }}
          alt="screen"   
        />
</div>

</div> 
}
    <div className="flex flex-row">
                {!isTabletOrMobile &&
                  
     <div className="h-full w-[44%] hidden sm:flex">
          <div className="text-center h-full w-full text-lg-left screen">
          <img
             src="/assets/imgs/singinn.png"
              className="h-full w-full object-contain"
              alt="signup screen"   
            />
          </div>
          <div className="flex flex-row justify-between items-center w-full ml-0">
    
     
          <img
onClick={() => navigate('/')}
src="/assets/imgs/signin.png"
 className="h-full w-full ml-0 object-contain" 
 style={{disply:'flex', justifyContent:'left' ,
 height: '242px',

 left: '287px',
 top: '345px',
 borderRadius: '0px',
cursor:'pointer'}}
 alt="screen"   
/>
            
          </div>
          </div>
               
                }

      <div className="flex flex-1 flex-col p-3 h-full py-20">
      {/*   <div className="flex flex-row justify-end w-full">
          <div className="text-sm font-semibold text-[#7A86A1]">
            New User?{" "}
            <Link
              to="/register"
              className="text-[#01BABF] font-semibold text-sm"
            >
              Create New Account
            </Link>
          </div>
        </div> */}

<div className="flex flex-1 flex-col justify-center py-20 forgetforms" >
          <div className="title mb-4">
          {intl.formatMessage({ id: 'home.forgotPassword'})}
          </div>

          <div className="col-md-7 text-[#323232] subtitile">
          {intl.formatMessage({ id: 'home.forgotPasswordheadding'})} 
          </div>

          <div className=" ">
        
          <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 py-3">
              <div className="flex flex-col my-1 w-full">
                <div className="text-sm my-1 text-[#01BABF] font-semibold">  {intl.formatMessage({ id: 'home.Email'})} </div>
                <input
                  className="input1 mt-2"
                  value={userData.email}
                  name="email"
                  onChange={handleInputChange} placeholder="Please type your email"
                />
              </div>
              <br/>
             {noAccount && <span style={{color:"red"}} >There is no account associated with this email address</span>}
            </div>

            <button className="gradient-btn" disabled={formValid ==0?true:false} onClick={handleSendReset} >
            {intl.formatMessage({ id: 'home.submit'})}   
     </button>

            <div className="mt-6">
              <Link to="/login" className="backSignin justify-center">
              <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;
              
              {intl.formatMessage({ id: 'home.backSignin'})}   
         
              </Link>
            </div>
          </div>
        </div>
        </div>
    
    </div>
    {isTabletOrMobile &&
          <div className="flex flex-row " style={{float:"right"}}>
            <div className="absolute right-20 top-90  forgotdots">
              <Logindots size={120} />
            </div>
        
                   
                   </div>}
    {!isTabletOrMobile &&
          <div className="flex flex-row " style={{float:"right"}}>
          
          <img src="/assets/imgs/dots.png" className="dots2"  />
                   
                   </div>}
 <CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
    </div>
      )}</InjectIntl>
  );
}

const CreateModal = ({
  show,
  title,
  setShow
}) => {
  const navigate = useNavigate();
  return (
    <ModalSendReset
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>{setShow(false);navigate('/login')}} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={"Done"} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};
