import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdContentCopy, MdDownload, MdShare, MdThumbsUpDown, MdSettingsVoice, MdVolumeUp, MdOutlineThumbDown, MdOutlineThumbUp } from "react-icons/md";
import { HiOutlineShare } from "react-icons/hi";
import { userContext } from "../../store/context/user";
import { API_BASE_URL } from "../../utils/globals";
import { FormattedMessage } from 'react-intl';
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from "use-debounce";
import Tippy from '@tippyjs/react';
import { useSpeechSynthesis } from 'react-speech-kit';
import FadeLoader from "react-spinners/FadeLoader";
import 'tippy.js/dist/tippy.css';


import {
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  TwitterIcon,
} from "react-share";

const TextTranslation = ({ srcLang, destLang, balance, intl, languageSelected, switchLang, copyText, updateLang }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [textInput, setTextInput] = useState("");
  const [textOutput, setTextOutput] = useState("");
  const [switchLanguage, setSwitchLang] = useState(switchLang);

  const { state, dispatch } = useContext(userContext);
  const [title, setTitle] = useState('')
  const [show, setShow] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);
  const [translationID, setTranslationID] = useState('');
  const navigate = useNavigate();

  const { speak } = useSpeechSynthesis();

  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const style = {

    boxSizing: 'border-box',
    fontSize: '14px'
  };
  const override = {
    display: "block",
    borderColor: "black",
  };
  const copyTextToClipboard = async (textOutput) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(textOutput);
    } else {
      return document.execCommand('copy', true, textOutput);
    }
  }


  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(textOutput)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const kFormatter = (num) => {
    if (num >= 1) {
      return num.toLocaleString();
    }
    return num;
  }

  useEffect(async () => {


    if (state && state.user) {
      const { data } = await axios.get(
        `${API_BASE_URL}/user/userid/${state.user.userId}`,
      )
      console.log("data", data)
      dispatch({
        type: "UPDATE_PLANS",
        payload: {
          direct_translation: data.userBalance?.direct_translation,
          characterCountBalance: data.userBalance?.characterCountBalance,
          document_translation: data.userBalance?.document_translation,
          globalDocStorageBalance: data.userBalance?.globalDocStorageBalance,
          globalDocCountBalance: data.userBalance?.globalDocCountBalance,
          availableLanguages: data.userBalance?.languages,
        }
      });

    }
  }, [])

  const handleTranslateText = () => {
    if (srcLang?.id == destLang?.id) {
      setTitle(<FormattedMessage id="home.alertSamelanguage"></FormattedMessage>)
      setShow(true)

    }
    else if (textInput.replaceAll(" ", "").length > 0) {

      if (state?.characterCountBalance == 0) {
        setTitle("your balance is low please upgrade your plan ")
        setShow(true)
        setShowPurchase(true)
        return
      }

      else {
        if (state?.direct_translation?.value == "yes") {
          setIsLoading(true)
          let data = {
            from: srcLang == "Auto Detect" ? "Auto Detect" : srcLang.id,
            to: destLang.id,
            sentence: textInput,
          };
          if (state.user) {
            data.planId = state.user.planId;
            data.userId = state.user.userId;
          }
          axios
            .post(
              `${API_BASE_URL}/${state.isLoggedIn ? "text" : "demo"}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${state.token}`,
                },
              }
            )
            .then((response) => {
              setIsLoading(false)
              console.log("response", response)
              setTranslationID(response.data.translationId)
              setTextOutput(response.data.result);
              if (state.characterCountBalance != -1) {

                dispatch({
                  type: "UPDATE_CHARACTER_COUNT_BALANCE",
                  payload: state.isLoggedIn
                    ? response.data.characterCountBalance
                    : state?.characterCountBalance - textInput.length,
                });
              }
            })
            .catch((err) => {
              setIsLoading(false)
              setTitle("Server Timeout")
              setShow(true)
              console.log("error",err)
            });
        }
        else {
          alert("You do not have permission to translate")
        }

      }
    }

  };
  useEffect(() => {
    let temp = textInput;
    let temp1 = textOutput;
    setTextInput(temp1)
    setTextOutput(temp)

  }, [switchLanguage])

  useEffect(() => {
    if (switchLang != switchLanguage) {
      setSwitchLang(switchLang)
    }
  });
  useEffect(() => {
    if (updateLang)
      handleTranslateText()
  }, [updateLang, destLang])
  const [debouncedText] = useDebounce(textInput, 1000);

  useEffect(() => {
    if (debouncedText)
      handleTranslateText()
    else
      setTextOutput("")
  }, [debouncedText])

  const RateTranslation = (flag) => {
    console.log(flag)
    let data = {
      from: srcLang == "Auto Detect" ? "Auto Detect" : srcLang.id,
      to: destLang.id,
      sentence: textInput,
      rate: flag,
      targetText: textOutput
    };
    data.planId = state.user.planId;
    data.userId = state.user.userId;
    data.translationID = translationID;
    axios
      .post(
        `${API_BASE_URL}/rateTranslation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((response) => {
        console.log("response", response)
      })
      .catch((err) => {
        alert(err.message)
      });
  }

  return (

    <>


      <div className="w-full flex-1">
        <div className="">
          {isTabletOrMobile &&
            <div className="wrapper">
              <textarea
                style={{
                  resize: "none",
                  backgroundColor: "#FCFCFC",
                  border: "1px solid #EFEFEF",
                  borderBlock: "5px 5px"
                }}
                value={textInput}
                maxLength={state.characterCountBalance || 20}
                onChange={(e) => setTextInput(e.target.value)}
                className="resize-none  w-[100%] flex-1  h-60  outline-none  placeholder:text-[#989898]"
                placeholder={intl.formatMessage({ id: 'home.typeToTranslate' })}
              />
              <div className="controls1 mb-2">
                <button className="clear" onClick={() => { setTextInput(''); setTextOutput('') }} style={{ color: '#A92D62', marginLeft: '6px' }}>Clear</button>
              </div>
              <div className="controls2 mb-2 right-0 top-10">
                <button onClick={() => { setTextInput(''); setTextOutput('') }}> <AiOutlineCloseCircle style={{ color: '#A92D62' }}
                  className="cursor-pointer"
                  size={19} /></button>
              </div>
              <div className="controls3 col mb-2">
                <span style={{ marginLeft: "5px" }}
                  className={`items-center text-center `} >{textInput.length}/{state.characterCountBalance == -1 ? "Unlimited" : kFormatter(state.characterCountBalance)}
                </span>
                &nbsp;
                <button
                  style={{
                    borderRadius: '10px',
                    height: '30px',
                    fontSize: '14px',
                    width: '105px'
                  }}
                  onClick={handleTranslateText} className={`
          bg-[#048A8F] text-white items-center text-center border-radius-[20px] translate col `}>{intl.formatMessage({ id: 'home.translate' })} <i className="fa fa-arrow-down" />
                </button>
              </div>
            </div>
          }
          {isTabletOrMobile &&
            <div className="wrapper">
              <textarea
                style={(destLang?.id == 'ar' || destLang?.id == 'ur') ? {
                  paddingLeft: "30px", paddingRight: "30px", paddingTop: "12px", paddingBottom: "12px", resize: "none", backgroundColor: "#FAFAFA",
                  border: "1px solid #EFEFEF", borderBlock: "5px 5px", direction: 'rtl', fontSize: "20px"
                } : { paddingLeft: "30px", paddingRight: "30px", paddingTop: "12px", paddingBottom: "12px", fontSize: "20px", resize: "none", backgroundColor: "#FAFAFA", border: "1px solid #EFEFEF", borderBlock: "5px 5px" }}

                value={textOutput}
                onChange={() => { }}
                className="resize-none  w-[100%] flex-1 h-60  outline-none placeholder:text-[20px] placeholder:font-bold"

              />
              <div className="controlscopy mb-2">
                <button><MdContentCopy style={{ color: ' #01BABF' }}
                  onClick={handleCopyClick}
                  size={30}
                  className="mx-1 text-zinc-400 cursor-pointer"
                />
                  <span className="tooltiptext">{isCopied ? 'Copied!' : ''}</span></button>
              </div>
            </div>
          }
          {!isTabletOrMobile &&
            <section className="flex px-3 items-center flex-col sm:flex-row">
              <div className="flex-1 p-4 h-100 relative" style={{
                paddingRight: "30px", paddingLeft: "2px"
              }}>
                <div className="h-full w-full overflow-y-auto  text-justify">
                  <textarea
                    style={{
                      resize: "none", backgroundColor: "#FAFAFA",
                      border: "1px solid #EFEFEF", borderBottom: "1px solid #fafafa", borderBlock: "5px 5px", fontSize: "20px", paddingLeft: "30px", paddingRight: "30px", paddingTop: "12px", paddingBottom: "12px"

                    }}
                    value={textInput}
                    maxLength={state.characterCountBalance || 20}
                    onChange={(e) => setTextInput(e.target.value)}
                    className="resize-none  w-[100%] flex-1  h-60  outline-none placeholder:text-[20px] placeholder:text-[#989898]"
                    placeholder={intl.formatMessage({ id: 'home.typeToTranslate' })}
                  />
                  <div className="flex justify-start w-full  flex flex-wrap  controlsmic -mb-1  " style={{ backgroundColor: "#FAFAFA", border: "1px solid #EFEFEF", borderTop: "1px solid #fafafa", borderBottom: "1px solid #EFEFEF" }}>
                    <div>
                      <Tippy style={{ paddingTop: "20px" }} content={<span>Translate by voice</span>}>
                        <button disabled={!textInput?.trim()} style={!textInput?.trim() ? {
                          fontFamily: 'Raleway', color: '#c7c7c7'
                        } :
                          {
                            fontFamily: 'Raleway', color: '#c7c7c7'
                          }}  >

                          <MdSettingsVoice

                            size={30}
                            className=""
                           /*  onClick={handleTranslateText} */
                          />

                        </button>
                      </Tippy>
                    </div>
                    <div>
                      <Tippy style={{ paddingTop: "20px" }} content={<span>Listen</span>}>
                        <button disabled={!textInput?.trim()} style={!textInput?.trim() ?
                          {
                            fontFamily: 'Raleway', color: '#c7c7c7'
                          } :
                          {
                            fontFamily: 'Raleway', color: '#c7c7c7'
                          }}>
                          <MdVolumeUp
                            onClick={() => speak({ text: textInput })}
                            size={30}
                            className=""
                          /> </button>
                      </Tippy>
                    </div>
                    <div>
                      <div className=" textcount">
                        <button ><span style={{
                          fontFamily: 'Raleway, "Open Sans", sans-serif', fontSize: "16px", marginRight: "14px"
                        }}
                          className={` `} >{textInput.length}/{state.characterCountBalance == -1 ? "Unlimited" : kFormatter(state.characterCountBalance)}
                        </span> </button>
                      </div>
                    </div>
                  </div>
                  <div className="controlstext px-2"  >
                    <button onClick={() => { setTextInput(''); setTextOutput('') }}> <AiOutlineCloseCircle style={!textInput?.trim() ? { color: '#c7c7c7' } : { color: '#A92D62' }}
                      className="cursor-pointer"
                      size={19} /></button>
                  </div>
                </div>
              </div>

              <div className="flex-1  p-4 h-100 relative" style={{
                paddingRight: "30px", paddingLeft: "2px"
              }}>
                <div className="h-full w-full overflow-y-auto  text-justify">
                  {isLoading ?
                    <div className="sweet-loading  d-flex justify-center">
                      <FadeLoader color={"#048A8F"} loading={isLoading} cssOverride={override} size={50} />
                    </div> :
                    <textarea style={(destLang?.id == 'ar' || destLang?.id == 'ur') ? {
                      paddingLeft: "30px", paddingRight: "30px", paddingTop: "12px", paddingBottom: "12px", fontSize: "20px", resize: "none", backgroundColor: "#FAFAFA",

                      borderBottom: "1px solid #fafafa", border: "1px solid #EFEFEF", borderTop: "5px 5px", direction: 'rtl'
                    } : { paddingLeft: "30px", paddingRight: "30px", paddingTop: "12px", paddingBottom: "12px", fontSize: "20px", resize: "none", backgroundColor: "#FAFAFA", border: "1px solid #EFEFEF", }}
                      value={textOutput}
                      onChange={() => { }}
                      className="resize-none  w-[100%] flex-1 h-60  outline-none placeholder:text-[20px] placeholder:font-bold "

                    />
                  }


                  {

                    <div className="flex justify-end w-full  flex flex-wrap mb-0 controlsmic 		" style={{ backgroundColor: "#FAFAFA", border: "1px solid #EFEFEF", borderTop: "1px solid #fafafa", borderBottom: "1px solid #EFEFEF" }}>
                      {textOutput?.trim()?.length > 0 && <div>  
                  
                      <Tippy style={{ paddingTop: "20px" }} content={<span>Listen</span>}>
                        <button  className="inline-flex items-end w-8 h-8 mr-2 justify-end text-gray-700  rounded-full" disabled={!textInput?.trim()} style={!textInput?.trim() ?
                          {
                            fontFamily: 'Raleway', color: '#c7c7c7'
                          } :
                          {
                            fontFamily: 'Raleway', color: '#c7c7c7'
                          }}>
                          <MdVolumeUp style={{ color: '#01BABF' }}
                            onClick={() => speak({ text: textOutput })}
                            size={30}
                            className=""
                          /> </button>
                      </Tippy>
                
                        
                        <Tippy style={{ paddingTop: "20px" }} content={<span>Copy translation</span>}>
                        <button className="inline-flex items-end w-8 h-8 mr-2 justify-end text-gray-700  rounded-full">
                          <MdContentCopy style={{ color: '#01BABF' }}
                            onClick={handleCopyClick}
                            size={28}
                            className=""
                          />
                          <span className="tooltiptext">{isCopied ? 'Copied!' : ''}</span>
                        </button>
                      </Tippy></div>}
                      <div> <Tippy content={<span>Rate this translation</span>}>

                        <button className="inline-flex items-end w-8 h-8 mr-2 text-gray-700 rounded-full " data-dropdown-toggle="ratethis">
                          {textOutput?.trim()?.length > 0 && <MdThumbsUpDown

                            style={{ color: '#01BABF' }}
                            size={28}
                            className=""
                          />}
                        </button>
                      </Tippy>
                        <div className="hidden bg-white text-base z-50 list-none  rounded-lg border-slate-400 shadow " id="ratethis">
                          {/*   <div className="px-4 py-4"> */}
                          <div className="pt-4">
                            <span className="block text-sm text-center ">Rate this translation</span>

                          </div>
                          <ul className="py-1" aria-labelledby="ratethis">
                            <div className=" flex  place-content-center  mt-2 space-x-4 ">
                              <div className=" rounded-lg">
                                <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800"
                                >
                                  <MdOutlineThumbDown style={{ color: '#01BABF' }}
                                    onClick={() => RateTranslation(false)}
                                    size={28}
                                    className=""
                                  />
                                </button>

                              </div>
                              <div className=" rounded-lg">  <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800"
                              >
                                <MdOutlineThumbUp style={{ color: '#01BABF' }}
                                  onClick={() => RateTranslation(true)}
                                  size={28}
                                  className=""
                                />
                              </button>
                              </div>
                            </div>
                            <div className="flex justify-center ...">
                              <div className="">
                                <h6 className="px-4 my-4 text-xs font-normal text-gray-500 dark:text-gray-300" >Your feedback will be used to help
                                  <br></br> improve the product</h6></div>
                            </div>
                            <div className="flex justify-center ...">
                              <div className="py-1  divide-y divide-gray-100 ">
                                <h6 className="px-4 my-4 text-xs font-normal text-gray-500 dark:text-gray-300" >


                                  <i className="fa fa-pencil" aria-hidden="true" style={{ color: "blue" }}></i> &nbsp;   <a href="" style={{ color: "blue" }}>Suggest an edit</a> </h6></div>

                            </div>
                          </ul>
                        </div>
                      </div>
                      <div >
                        <Tippy content={<span>Share translation</span>}>
                          <button className="inline-flex  justify-right w-8 h-8 mr-2 text-gray-700   rounded-full" type="button" data-dropdown-toggle="shareit">
                            {textOutput?.trim()?.length > 0 && <MdShare style={{ color: '#01BABF' }}

                              size={28}
                              className=""
                            />}
                          </button>
                        </Tippy>
                        <div className=" hidden bg-white text-base  z-50 list-none rounded-lg border-slate-400 shadow " id="shareit" >
                          <div className="pt-4 px-2">
                            <span className="text-sm">Share this translation</span>
                          </div>
                          <ul className="py-1" aria-labelledby="shareit">
                            <div className="flex  place-content-center space-x-4">
                              <div className="rounded-lg">
                                <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800">
                                  <EmailShareButton url={' '} body={textOutput}>
                                    <EmailIcon size="32" round />
                                  </EmailShareButton>

                                </button>
                                <   h3 className="text-sm">Email</h3>
                              </div>
                              <div className=" rounded-lg">  <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800">
                                <TwitterShareButton url={' '} title={textOutput}>
                                  <TwitterIcon size="32" round />
                                </TwitterShareButton>
                              </button>
                                <h3 className="text-sm">Twitter</h3>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  }

                </div>
                <div className="controlstext1 px-2"  >
                  <button onClick={() => { setTextInput(''); setTextOutput('') }}> <AiOutlineCloseCircle style={!textInput?.trim() ? { color: '#c7c7c7' } : { color: '#A92D62' }}
                    className="cursor-pointer"
                    size={19} /></button>
                </div>
              </div>

              <div>

              </div>
            </section>
          }
        </div>
        <CreateModal
          show={show}
          title={title}
          setShow={setShow}
          showPurchase={showPurchase}
          navigate={navigate}
        />
      </div>

    </>


  );
};

export default TextTranslation;
const CreateModal = ({
  show,
  title,
  setShow,
  navigate,
  showPurchase
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => (<div>{showPurchase && <ModalButton type="blue" text={"Purchase"} onClick={() => navigate('/pricing')} />}
        &nbsp; &nbsp; &nbsp;
        <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />
      </div>)}
      body={() => (
        <div>

          <ModalButton type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />
        </div>
      )}
    />
  );
};