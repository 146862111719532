import React from "react";

const ModalSelect = (props) => {
  return (
    <div>
      <div className="text-xs text-capitalize">{props.label}</div>
      <div>
        <select
          {...props}
          className="px-2 mb-1 py-1 w-full outline-none border-2 border-transparent bg-zinc-300 hover:border-zinc-400 focus:border-blue-700 rounded-sm"
        >
          {props.children}
        </select>
      </div>
    </div>
  );
};

export default ModalSelect;
