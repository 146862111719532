import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../utils/globals';
import { userContext } from '../../store/context/user';
import './Payment.scss';

export default function Payment() {
	const { state, dispatch } = useContext(userContext);

	useEffect(() => {
		//
	}, []);

	return (
		<div className='payment'>
			<div>payment page</div>
			<div></div>
			<div></div>
		</div>
	);
}
