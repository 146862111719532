// const API_BASE_URL = 'https://api.poshtranslate.com';
// const API_BASE_URL = "http://localhost:5001";
const API_BASE_URL = 'https://stagingback.poshtranslate.com';
// const API_BASE_URL = 'https://api.poshtranslate.com/v1
const availableFeatures = [
  ["text", "characterCountBalance"],
  ["document", "pdfCountBalance", "docCountBalance"],
  ["image", "ocrCountBalance"],
];
module.exports = { API_BASE_URL, availableFeatures };
