import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../store/context/user";
import { API_BASE_URL } from "../../utils/globals";
import S3 from "react-aws-s3";
import { FiUploadCloud } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import getFileSize from "../../utils/getFileSize";
import { CircularProgressbar } from 'react-circular-progressbar';
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["PNG", "JPEG", "JPG"];
const ImageTranslation = ({ srcLang, destLang, intl, languageSelected, socket, fileProcessed, setFileProcessed, setErrorInProcessing, errorInProcessing, errorMsg, extractedText }) => {
  const [bucketCredentials, setBucketCredentials] = useState({});
  const [file, setFile] = useState(null);
  const [targetFileId, setTargetFileId] = useState(null);
  const [targetFileStatus, setTargetFileStatus] = useState(1);
  const [translatedText, setTranslatedText] = useState('');
  const [sourceFileLink, setSourceFileLink] = useState(null);
  const [targetFileLink, setTargetFileLink] = useState(null);
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] =
    useState(false);
  const refreshIntervalId = React.useRef();
  const { state, dispatch } = useContext(userContext);
  const fileInputRef = useRef();
  const [percentage, setPercentage] = useState(0);
  let [loading, setLoading] = useState(true);
  const override = {
    display: "block",
    borderColor: "black",
  };
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      }
    }, 4000);
  }, [percentage]);
  useEffect(async () => {
    if (state && state.user) {
      const { data } = await axios.get(
        `${API_BASE_URL}/user/userid/${state.user.userId}`,
      )
      dispatch({
        type: "UPDATE_OCR_COUNT_BALANCE",
        payload: data.userBalance.ocrCountBalance
      });

      dispatch({
        type: "UPDATE_OCR_STORAGE_BALANCE",
        payload: data.userBalance.ocrStorageBalance
      });
    }
  }, [])
  const handleFileUpload = (file) => {
    setFile(file);
  };
  const joinSocketRoom = (room) => {
    socket.emit('join', room);
  }
  const handleDocumentUpload = (event) => {
    event.preventDefault();
    if (!file) {
      alert("please select a file to translate");
      return;
    }
    if (srcLang === "" || destLang === "" || srcLang === destLang) {
      alert("source and destination language cannot be same or empty");
      return;
    }

    if (!state.ocrCountBalance) {
      alert("Zero Balane");
      return;
    }

    if (file.size > state.maxOcrSize && state.maxOcrSize != -1) {
      alert(
        `Image file should not be bigger than ${getFileSize(state.maxOcrSize)}`
      );
      return;
    }
    setTargetFileStatus(-1);
    const config = {
      bucketName: bucketCredentials.bucket_name,
      region: bucketCredentials.region_name,
      accessKeyId: bucketCredentials.aws_access_key_id,
      secretAccessKey: bucketCredentials.aws_secret_access_key,
    };
    var fileName = file.name.replace(".", `-${Date.now()}.`);
    const bucket = new S3(config);
    bucket
      .uploadFile(file, file.name.replace(".", `-${Date.now()}.`))
      .then((fileDetails) => {
        console.log("fileDetails", fileDetails)
        axios
          .post(
            `${API_BASE_URL}/file?planId=${state.user.planId}`,
            {
              userId: state.user.userId,
              sourceFileLink: fileDetails.location,
              startTime: new Date(),
              fileType: 3,
              fileName: fileName,
              fileSize: file.size,
              sourceFileLanguage: srcLang.id,
              targetFileLanguage: destLang.id,
              fileTypeDownload: "",
              sendEmail: ""
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          )
          .then((responseNotify) => {
            joinSocketRoom(responseNotify?.data.fileId)
            setSourceFileLink(fileDetails.location);
            setTargetFileLink(responseNotify.data.targetFileLink);
            if (responseNotify.data.fileId) {
              setTargetFileId(responseNotify.data.fileId);
            }
            dispatch({
              type: "UPDATE_OCR_COUNT_BALANCE",
              payload: responseNotify.data.ocrCountBalance,
            });
            dispatch({
              type: "UPDATE_OCR_STORAGE_BALANCE",
              payload: responseNotify.data.ocrStorageBalance,
            });

          })
          .catch((error) => {
            alert("could not process file, please try again later");
            console.error(error);
          });
      })
      .catch((error) => {
        alert("could not upload file, please try again later");
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/file/bucket?planId=${state.user?.planId}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((response) => {
        setBucketCredentials(response.data);
      })
      .catch((error) => {
        alert("could not get bucket credentials, please try again later");
        console.log(error);
      });
    axios
      .get(`${API_BASE_URL}/plan/${state.user?.planId}`)
      .then((response) => {
        dispatch({
          type: "SET_MAX_OCR_SIZE",
          payload: response.data.plan.maxOcrSize,
        });
      })
      .catch((error) => {
        alert("could not get pdf size limit, please try again later");
        console.log(error);
      });
  }, []);
  return (
    <div className="flex flex-col">
      <div className="flex flex-col h-60 p-2 justify-center items-center border-zinc-200 border-t-[1px] w-full">
        <div className="border-zinc-300 flex flex-col items-center border-[1px] border-dashed rounded-md p-4">

          {file && fileProcessed ? (
            <>
             <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
                <div className="text-sm font-semibold">{file.name}</div>
                <div
                  className="cursor-pointer ml-5"
                  onClick={() => {
                    setFile(null);
                    setFileProcessed(false)
                    setTargetFileId(null);
                    setTargetFileStatus(1);
                    setTargetFileLink(0);
                    setFileUploadedSuccessfully(false);
                  }}
                >
                  <IoCloseOutline size="20" />
                </div>
              </div>
              <button
                onClick={() => {
                  const link =targetFileLink;
                  const a = document.createElement("a");
                  a.setAttribute('href',link);
                  a.setAttribute('target','_blank');
                  a.click();

                }}
                className="px-3 py-2 border-[#048A8F] text-[#048A8F] uppercase border-2 rounded-md text-xs"
              >
                 {intl.formatMessage({ id: 'home.getFile' })}
              </button>
            </>
          ) :
            <>
              {errorInProcessing && errorMsg ?
                <>
                  <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
                    <div className="text-sm font-semibold">{errorMsg}</div>
                    <div
                      className="cursor-pointer ml-5"
                      onClick={() => {
                        setErrorInProcessing(false)
                        setFile(null);
                        setTargetFileId(null);
                        setTargetFileStatus(1);
                        setTargetFileLink(0);
                        setFileUploadedSuccessfully(false);
                      }}
                    >
                      <IoCloseOutline size="20" />
                    </div>
                  </div>
                </> :
                targetFileStatus == -1 ?
                  <>
                    <div className="sweet-loading">
                      <div style={{ width: 150, }}>
                        <CircularProgressbar value={percentage} text={`${percentage}%`} cssOverride={override} size={150} loading={loading} />
                      </div>
                    </div>
                  </>
                  :
                  targetFileStatus == 0 ?
                    <>
                      <div className="flex flex-row items-center w-full justify-between my-2 bg-[#A92D62] text-white px-2 py-1 rounded-md">
                        <div className="text-sm font-semibold">{state.isLoggedIn ? 'File is Translating.Please check status in File Tranlation Page' :
                          'Oops Seems like Translation is taking bit time.Try with smaller files'}</div>

                      </div>
                    </>

                    :

                    file ? (
                      <>
                        <div className="flex flex-row items-center w-full justify-between my-2 bg-green-300 px-2 py-1 rounded-md">
                          <div className="text-sm font-semibold">{file.name}</div>
                          <div
                            className="cursor-pointer ml-5"
                            onClick={() => setFile(null)}
                          >
                            <IoCloseOutline size="20" />
                          </div>
                        </div>
                        <button
                          onClick={handleDocumentUpload}
                          className="px-3 py-2 border-[#0F91D2] text-[#0F91D2] uppercase border-2 rounded-md text-xs"
                        >
                          Upload Image
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="text-[#0006] text-sm my-2">
                          <FileUploader style={{ width: "10px" }} label={"Select a file or drag and drop "} handleChange={handleFileUpload} name="file" types={fileTypes} />
                        </div>
                      </>
                    )}
            </>
          }
        </div>
      </div>
      <div className="text-xs w-full text-center">

        {state?.user?.role == "guest" ?
          <>
            Maximum Image size allowed is &nbsp;&nbsp;
            {state.maxDocSize == '-1' ? "Unlimted" : (getFileSize(state.ocrStorageBalance))}
          </> :
          <>
            OCR Balance:{state.ocrCountBalance == '-1' ? "Unlimted" : (state.ocrCountBalance)}, (
            {state.maxDocSize == '-1' ? "Unlimted" : (getFileSize(state.ocrStorageBalance))})
          </>}
      </div>
    </div>
  );
};

export default ImageTranslation;
