import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import "./UserPlans.scss";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import { MdAddChart } from "react-icons/md";
import ModalInput from "../../components/Modal/ModalInput";
import Modal from "../../components/Modal/Modal";
import ModalButton from "../../components/Modal/ModalButton";
import ModalSelect from "../../components/Modal/ModalSelect";
import getFileSize from "../../utils/getFileSize";
import ReactSelect from "react-select";
import Pagination from "../../components/Pagination/Pagination";
import TH from "../../components/Table/TH";
import TD from "../../components/Table/TD";
import Table from 'react-bootstrap/Table';
import ModalAlert from "../../components/Modal/ModalAlert";
import { FormattedMessage } from 'react-intl';
import InjectIntl from 'react-intl-inject';
import Collapsible from 'react-collapsible';
import { useLocation } from 'react-router-dom';
const headerContent = [
  "#",
  <FormattedMessage id="home.contactName"></FormattedMessage>,
  <FormattedMessage id="home.languages"></FormattedMessage>,
  <FormattedMessage id="home.characters"></FormattedMessage>,
  // "PDFs",
  <FormattedMessage id="home.documents"></FormattedMessage>,
  <FormattedMessage id="home.images"></FormattedMessage>,
  <FormattedMessage id="home.status"></FormattedMessage>,
  <FormattedMessage id="home.price"></FormattedMessage>,
  <FormattedMessage id="home.actions"></FormattedMessage>,
];
const optionsYN = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

const optionPlanType = [
  { label: 'Direct Plan', value: 'direct' },
  { label: 'ML Plan', value: 'ml' },
]

const newPlan = {
  name: "",
  languages: [],
  characterCount: 0,
  globalDocCount: 0,
  globalmaxDocSize: 0,
  globalTotalDocSize: 0,
  pdfCount: 0,
  maxPdfSize: 0,
  totalPdfSize: 0,
  docCount: 0,
  maxDocSize: 0,
  totalDocSize: 0,
  ocrCount: 0,
  maxOcrSize: 0,
  totalOcrSize: 0,
  monthlyPrice: 0,
  annualPrice: 0,
  direct_translation: { label: 'Yes', value: 'yes' },
  document_translation: { label: 'Yes', value: 'yes' },
  image_translation: { label: 'Yes', value: 'yes' },
  api_allowed: 0,
  cloud_storage: 0,
  batch_translate_text: 0,
  batch_translate_doc: 0,
  total_requests: 0,
  language_detection: 0,
  plan_type: { label: 'Direct Plan', value: 'direct' },
  price_per_document_page: 0,
  max_characters_per_page: 0

};

const statusTypes = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Guest", value: "guest" },
  { label: "Free", value: "free" },
];

const sortTypes = [
  { label: "Name (A-Z)", value: { key: "name", dir: -1 } },
  { label: "Name (Z-A)", value: { key: "name", dir: 1 } },
  { label: "Languages (Low to High)", value: { key: "languages", dir: -1 } },
  { label: "Languages (High to Low)", value: { key: "languages", dir: 1 } },
  { label: "Status (A-Z)", value: { key: "status", dir: -1 } },
  { label: "Status (Z-A)", value: { key: "status", dir: 1 } },
  {
    label: "Characters (Low to High)",
    value: { key: "characterCount", dir: -1 },
  },
  {
    label: "Characters (High to Low)",
    value: { key: "characterCount", dir: 1 },
  },
  { label: "PDFs (Low to High)", value: { key: "pdfCount", dir: -1 } },
  { label: "PDFs (High to Low)", value: { key: "pdfCount", dir: 1 } },
  { label: "Documents (Low to High)", value: { key: "docCount", dir: -1 } },
  { label: "Documents (High to Low)", value: { key: "docCount", dir: 1 } },
  { label: "Images (Low to High)", value: { key: "ocrCount", dir: -1 } },
  { label: "Images (High to Low)", value: { key: "ocrCount", dir: 1 } },
  { label: "Price (Low to High)", value: { key: "monthlyPrice", dir: -1 } },
  { label: "Price (High to Low)", value: { key: "monthlyPrice", dir: 1 } },
];

export default function UserPlanDetail(props) {
  const [allPlans, setAllPlans] = useState([]);
  const [nameFeedback, setNameFeedback] = useState("Min. 3 characters");
  const [langFeedback, setLangFeedback] = useState("Min. 3 characters");
  const [formValid, setFormValid] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [plansToDisplay, setPlansToDisplay] = useState([]);
  const [currentDeletePlanId, setCurrentDeletePlanId] = useState("");
  const [currentEditPlanId, setCurrentEditPlanId] = useState("");
  const [currentEditPlan, setCurrentEditPlan] = useState({});
  const [newPlanData, setNewPlanData] = useState({});
  const { state } = useContext(userContext);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const navigate = useNavigate();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [sortBy, setSortBy] = useState(sortTypes[0].value);

  const [statusType, setStatusType] = useState(statusTypes[0].value);

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('')
  const location = useLocation();
  useEffect(() => {
   
    (async () => {
      const { data } = await axios.get(`${API_BASE_URL}/languages`);
      setAvailableLanguages(
        data.result.map((a) => ({
          label: a.split(" ")[0],
          value: a.split(" ")[1].slice(1, 3),
        }))
      );
    })();
    console.log("location",location)
    if(location.state)
    setNewPlanData(location.state.currentEditPlan)
  }, []);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/plan/`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        setAllPlans(res.data.plans);
        setFilteredPlans(res.data.plans);
        setCurrentDeletePlanId("");
        setCurrentEditPlan({});
        if(!location.state)
        setNewPlanData(newPlan);
      })
      .catch((err) => {
        setTitle(<FormattedMessage id="home.alertGetPlan"></FormattedMessage>)
        setShow(true)
      });
  }, [shouldUpdate]);

  useEffect(() => {
    if(!location.state)
    setNewPlanData(newPlan);
  }, []);


  const handleSelectPlan = (plan) => {
    currentEditPlan.name = plan.name;
    currentEditPlan.status = plan.status;
    currentEditPlan.languages = availableLanguages.filter((l) =>
      plan.languages.includes(l.value)
    );
    currentEditPlan.characterCount = plan.characterCount;
    currentEditPlan.pdfCount = plan.pdfCount;
    currentEditPlan.maxPdfSize = plan.maxPdfSize;
    currentEditPlan.totalPdfSize = plan.totalPdfSize;
    currentEditPlan.docCount = plan.docCount;
    currentEditPlan.maxDocSize = plan.maxDocSize;
    currentEditPlan.totalDocSize = plan.totalDocSize;
    currentEditPlan.ocrCount = plan.ocrCount;
    currentEditPlan.maxOcrSize = plan.maxOcrSize;
    currentEditPlan.totalOcrSize = plan.totalOcrSize;
    currentEditPlan.monthlyPrice = plan.monthlyPrice;
    currentEditPlan.annualPrice = plan.annualPrice;

    currentEditPlan.globalDocCount = plan.globalDocCount;
    currentEditPlan.globalmaxDocSize = plan.globalmaxDocSize;
    currentEditPlan.globalTotalDocSize = plan.globalTotalDocSize;

    currentEditPlan.direct_translation = plan.direct_translation;
    currentEditPlan.document_translation = plan.document_translation;
    currentEditPlan.image_translation = plan.image_translation;
    currentEditPlan.api_allowed = plan.api_allowed;
    currentEditPlan.cloud_storage = plan.cloud_storage;
    currentEditPlan.batch_translate_text = plan.batch_translate_text;
    currentEditPlan.batch_translate_doc = plan.batch_translate_doc;
    currentEditPlan.total_requests = plan.total_requests;
    currentEditPlan.language_detection = plan.language_detection;

    currentEditPlan.plan_type = plan.plan_type;
    currentEditPlan.price_per_document_page = plan.price_per_document_page;
    currentEditPlan.max_characters_per_page = plan.max_characters_per_page;

    setCurrentEditPlanId(plan._id);
    setCurrentEditPlan({ ...currentEditPlan });
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentEditPlan({ ...newPlanData, [name]: value });
  };

  /*  const handleCreateInputChange = (event) => {
     
   
     const { name, value } = event.target;
     setNewPlanData({ ...newPlanData, [name]: value });
   }; */

  const handleCreateInputChange = (event) => {

    const { name, value } = event.target;
    if (name == 'name' && value.length < 3)
      setNameFeedback('Min 3 Characters');
    if (name == 'name' && value.length > 3)
      setNameFeedback('');

    setNewPlanData({ ...newPlanData, [name]: value });
  };


  const handleEditPlan = () => {
    setEditModal(false);
    console.log("currentEditPlan",newPlanData,location.state)
    axios
      .patch(
        `${API_BASE_URL}/plan/${(location.state.plan._id)}`,
        {
          ...newPlanData,
          languages: newPlanData.languages.map((l) => l.value),
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(() =>  navigate("/admin/plans"))
      .catch((err) => {
        setTitle(<FormattedMessage id="home.alertUpdatePlan"></FormattedMessage>)
        setShow(true)
      });

  };

  const handleCreatePlan = () => {
    /* 
     if (newPlanData.name.trim() === "") {
       alert("Name cannot be Empty");
     }
     else{ */
    setCreateModal(false);
    axios
      .post(
        `${API_BASE_URL}/plan/create`,
        {
          ...newPlanData,
          languages: newPlanData.languages.map((l) => l.value),
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(() => navigate("/admin/plans"))

      .catch((err) => {
        setTitle(<FormattedMessage id="home.alertCreatePlan"></FormattedMessage>)
        setShow(true)
      });


  };

  const handleDeletePlan = () => {
    setDeleteModal(false);
    axios
      .delete(`${API_BASE_URL}/plan/${currentDeletePlanId}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then(() => setShouldUpdate(!shouldUpdate))
      .catch((err) => {
        setTitle(<FormattedMessage id="home.alertDeletePlan"></FormattedMessage>)
        setShow(true)
      });
  };

  useEffect(() => {
    let filtered = allPlans;
    filtered = filtered.filter(
      (f) => f.status === statusType || statusType === "all"
    );
    const { key, dir } = sortBy;
    if (key === "languages")
      filtered = filtered.sort((a, b) =>
        a[key].length < b[key].length ? dir : -dir
      );
    else filtered = filtered.sort((a, b) => (a[key] < b[key] ? dir : -dir));
    setFilteredPlans(filtered);
  }, [allPlans, sortBy, statusType]);


  useEffect(() => {
    if (!nameFeedback)
      setFormValid(true)
    else
      setFormValid(false)

  }, [nameFeedback, langFeedback])

  return (
    <InjectIntl>
      {({ intl }) => (
        <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
          <Navbar setLanguage={props.setLanguage} language={props.language} />

          <div className="flex flex-row">
            <AdminSidebar />

            <div className="w-full flex-1 p-4">
              <div className="w-full">
                <div className="font-semibold text-[24px] mb-2">{location.state ? intl.formatMessage({ id: 'home.editPlan' }) :intl.formatMessage({ id: 'home.createPlan' })} </div>

              </div>
              <Collapsible trigger="Basic Info." >
                <div className="row">

                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.name}
                      type="text"
                      name="name"
                      placeholder={intl.formatMessage({ id: 'home.contactName' })}
                      onChange={handleCreateInputChange}
                      // nameFeedback={nameFeedback}
                    />
                  </div>
                  <div className="col-md-4">
                    <div>
                      <div className="text-xs text-capitalize">Plan Type </div>
                      <div>
                        <ReactSelect
                          onChange={(v) => {
                            handleCreateInputChange({
                              target: { name: "plan_type", value: v },
                            });
                          }}
                          menuPosition="fixed"
                          value={newPlanData.plan_type}
                          options={optionPlanType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <div className="text-xs text-capitalize">{intl.formatMessage({ id: 'home.languages' })} </div>
                      <div>
                        <ReactSelect
                          onChange={(v) => {
                            console.log("lang", v)
                            handleCreateInputChange({
                              target: { name: "languages", value: v },
                            });
                          }}
                          isMulti={true}
                          menuPosition="fixed"
                          value={newPlanData.languages}
                          options={availableLanguages}

                          langFeedback={langFeedback}


                        />
                      </div>
                    </div>
                  </div>

                </div>
              </Collapsible>
              <Collapsible trigger="Text Translation" >
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <div className="text-xs text-capitalize">Direct Translation </div>
                      <div>
                        <ReactSelect
                          onChange={(v) => {
                            console.log("v", v)
                            handleCreateInputChange({
                              target: { name: "direct_translation", value: v },
                            });
                          }}
                          menuPosition="fixed"
                          value={newPlanData.direct_translation}
                          options={optionsYN}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.characterCount}
                      type="number"
                      min={0}
                      name="characterCount"
                      placeholder={intl.formatMessage({ id: 'home.allowedCharacters' })}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>

              </Collapsible>
              <Collapsible trigger="Document Translation" >
                <div className="row">
                  <div className="col-md-4">
                    <div>
                      <div className="text-xs text-capitalize">Document Translation</div>
                      <div>
                        <ReactSelect
                          onChange={(v) => {
                            let temp = []
                            handleCreateInputChange({
                              target: { name: "document_translation", value: v },
                            });
                          }}
                          menuPosition="fixed"
                          value={newPlanData.document_translation}
                          options={optionsYN}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData?.globalDocCount}
                      type="number"
                      min={0}
                      name="globalDocCount"
                      placeholder={"Global Allowed Docs"}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData?.globalmaxDocSize}
                      type="number"
                      min={0}
                      name="globalmaxDocSize"
                      placeholder={"Max global Doc size(Bytes)"}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData?.globalTotalDocSize}
                      type="number"
                      min={0}
                      name="globalTotalDocSize"
                      placeholder={"Total global Doc Storage(Bytes)"}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                </div>
              </Collapsible>
              <Collapsible trigger="Image Translation" >
                <div className="row">
                <div className="col-md-4">
                    <div>
                      <div className="text-xs text-capitalize">Image Translation</div>
                      <div>
                        <ReactSelect
                          onChange={(v) => {
                            let temp = []
                            handleCreateInputChange({
                              target: { name: "image_translation", value: v },
                            });
                          }}
                          menuPosition="fixed"
                          value={newPlanData.image_translation}
                          options={optionsYN}
                        />
                      </div>
                    </div>
                  </div> <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.ocrCount}
                      type="number"
                      min={0}
                      name="ocrCount"
                      placeholder={intl.formatMessage({ id: 'home.allowedOcrs' })}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.maxOcrSize}
                      type="number"
                      min={0}
                      name="maxOcrSize"
                      placeholder={intl.formatMessage({ id: 'home.maxOCR' })}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.totalOcrSize}
                      type="number"
                      min={0}
                      name="totalOcrSize"
                      placeholder={intl.formatMessage({ id: 'home.totalOCR' })}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                </div>
              </Collapsible>
              <Collapsible trigger="Others" >
                <div className="row">
                  <div className="col-md-4">
                    {
                      location.state &&
                      <ModalSelect
                      value={newPlanData?.status}
                      name="status"
                      label={intl.formatMessage({ id: 'home.status' })}
                      onChange={handleCreateInputChange}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                      <option value="guest">Guest</option>
                      <option value="free">Free</option>
                    </ModalSelect>
                    }
                
                    <ModalInput
                      value={newPlanData?.api_allowed}
                      type="number"
                      min={0}
                      name="api_allowed"
                      placeholder={"API Allowed"}

                      onChange={handleCreateInputChange}
                    />
                    <ModalInput
                      value={newPlanData?.cloud_storage}
                      type="number"
                      min={0}
                      name="cloud_storage"
                      placeholder={"Cloud Storage"}

                      onChange={handleCreateInputChange}
                    />
                    <ModalInput
                      value={newPlanData.batch_translate_text}
                      type="number"
                      min={0}
                      name="batch_translate_text"
                      placeholder={"Batch Translate Text"}
                      onChange={handleCreateInputChange}
                    />

                    <ModalInput
                      value={newPlanData.monthlyPrice}
                      type="number"
                      min={0}
                      name="monthlyPrice"
                      placeholder={intl.formatMessage({ id: 'home.monthlyPrice' })}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.batch_translate_doc}
                      type="number"
                      min={0}
                      name="batch_translate_doc"
                      placeholder={"Batch Translate Document"}
                      onChange={handleCreateInputChange}
                    />
                    <ModalInput
                      value={newPlanData.total_requests}
                      type="number"
                      min={0}
                      name="total_requests"
                      placeholder={"total_requests"}
                      onChange={handleCreateInputChange}
                    />


                    <ModalInput
                      value={newPlanData.annualPrice}
                      type="number"
                      min={0}
                      name="annualPrice"
                      placeholder={intl.formatMessage({ id: 'home.annualPrice' })}
                      onChange={handleCreateInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <ModalInput
                      value={newPlanData.language_detection}
                      type="number"
                      min={0}
                      name="language_detection"
                      placeholder={"language_detection"}
                      onChange={handleCreateInputChange}
                    />

                    <ModalInput
                      value={newPlanData.max_characters_per_page}
                      type="number"
                      min={0}
                      name="max_characters_per_page"
                      placeholder={"Max Characters Per Page"}
                      onChange={handleCreateInputChange}
                    />
                    <ModalInput
                      value={newPlanData.price_per_document_page}
                      type="number"
                      min={0}
                      name="price_per_document_page"
                      placeholder={"Price Per Document Page"}
                      onChange={handleCreateInputChange}
                    />

                  </div>
                </div>
              </Collapsible>
              <ModalButton onClick={(!location.state)?handleCreatePlan:handleEditPlan} type="blue" text={intl.formatMessage({ id: 'home.save' })} />



            </div>
          </div>
          <CreateModall
            show={show}
            title={title}
            setShow={setShow}
          />

        </div>
      )}</InjectIntl>
  );
}



const CreateModall = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />}
      body={() => (
        <div>

          <ModalButton type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />
        </div>
      )}
    />
  );
};
