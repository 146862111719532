import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import S3 from 'react-aws-s3';
import { Buffer } from 'buffer';
import { userContext } from '../../store/context/user';
import { API_BASE_URL } from '../../utils/globals';
import './DocumentTranslation.scss';
window.Buffer = window.Buffer || Buffer;

export default function DocumentTranslation() {
	const [bucketCredentials, setBucketCredentials] = useState({});
	const [file, setFile] = useState(null);
	const [targetFileId, setTargetFileId] = useState(null);
	const [targetFileStatus, setTargetFileStatus] = useState(0);
	const [targetFileLink, setTargetFileLink] = useState(null);
	const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
	const [srcLang, setSrcLang] = useState('');
	const [destLang, setDestLang] = useState('');
	const { state, dispatch } = useContext(userContext);

	useEffect(() => {
		axios
			.get(`${API_BASE_URL}/file/bucket?planId=${state.user.planId}`, {
				headers: {
					Authorization: `Bearer ${state.token}`,
				},
			})
			.then((response) => {
				setBucketCredentials(response.data);
			})
			.catch((error) => {
				alert('could not get bucket credentials, please try again later');
				console.log(error);
			});
		axios
			.get(`${API_BASE_URL}/plan/${state.user.planId}`)
			.then((response) => {
				dispatch({
					type: 'SET_MAX_PDF_SIZE',
					payload: response.data.plan.maxPdfSize,
				});
			})
			.catch((error) => {
				alert('could not get pdf size limit, please try again later');
				console.log(error);
			});
	}, []);

	const handleChangeSrcLang = (event) => {
		setSrcLang(event.target.value);
	};

	const handleChangeDestLang = (event) => {
		setDestLang(event.target.value);
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		setFile(file);
	};

	const handleDocumentTranslate = (event) => {
		event.preventDefault();
		if (!file) {
			alert('please select a file to translate');
			return;
		}
		if (srcLang === '' || destLang === '' || srcLang === destLang) {
			alert('source and destination language cannot be same or empty');
			return;
		}
		if (file.size > state.maxPdfSize) {
			alert('file size is too large');
			return;
		}

		
		

		const config = {
			bucketName: bucketCredentials.bucket_name,
			region: bucketCredentials.region_name,
			accessKeyId: bucketCredentials.aws_access_key_id,
			secretAccessKey: bucketCredentials.aws_secret_access_key,
		};
		const bucket = new S3(config);
		bucket
			.uploadFile(file, file.name)
			.then((fileDetails) => {
				console.log(fileDetails);
				setFileUploadedSuccessfully(true);

				axios
					.post(
						`${API_BASE_URL}/file/file-upload?planId=${state.user.planId}`,
						{
							userId: state.user.userId,
							sourceFileLink: fileDetails.location,
							startTime: new Date(),
							fileType: 1,
							fileSize: file.size,
							sourceFileLanguage: srcLang,
							targetFileLanguage: destLang,
						},
						{
							headers: {
								Authorization: `Bearer ${state.token}`,
							},
						}
					)
					.then((responseNotify) => {
						console.log('isBlockingMode: ', responseNotify.data.isBlockingMode);
						setTargetFileLink(responseNotify.data.targetFileLink);
						if (responseNotify.data.isBlockingMode) {
							setTargetFileStatus(1);
						}
						if (responseNotify.data.fileId) {
							setTargetFileId(responseNotify.data.fileId);
						}
						dispatch({
							type: 'UPDATE_PDF_COUNT_BALANCE',
							payload: responseNotify.data.pdfCountBalance,
						});
					})
					.catch((error) => {
						alert('could not process file, please try again later');
						console.error(error);
					});
			})
			.catch((error) => {
				alert('could not upload file, please try again later');
				console.log(error);
			});
	};

	const checkFileStatus = (event) => {
		axios
			.get(`${API_BASE_URL}/file/status/${targetFileId}?planId=${state.user.planId}`, {
				headers: {
					Authorization: `Bearer ${state.token}`,
				},
			})
			.then((responseStatus) => {
				if (responseStatus.data.status) {
					alert('your file is ready to download');
					setTargetFileStatus(1);
				} else {
					alert('your file is being processed');
				}
			})
			.catch((error) => console.log(error));
	};

	return (
		<main className='document-tanslation'>
			<div className='container d-flex flex-column align-items-center justify-content-center'>
				<Link to='/document-history' className='document-tanslation__'>
					See your file translation history <i className='fa fa-solid fa-history'></i>
				</Link>
				<h1 className='document-tanslation__title'>Choose a document</h1>
				<p className='document-tanslation__description'>Upload a .pdf, docx, .txt</p>
				{state.docCountBalance && state.pdfCountBalance ? (
					<form className='document-tanslation__form' onSubmit={handleDocumentTranslate}>
						<div className='document-tanslation__lang'>
							<div>
								<select
									className='border-0 document-tanslation__select'
									value={srcLang}
									id='srcLang'
									onChange={handleChangeSrcLang}>
									<option value=''>source language</option>
									{Object.keys(state.availableLanguages).map((lang, index) => (
										<option value={lang} key={index}>
											{lang}
										</option>
									))}
								</select>
							</div>
							<div>
								<select
									className='border-0 document-tanslation__select'
									value={destLang}
									id='destLang'
									onChange={handleChangeDestLang}>
									<option value=''>target language</option>
									{Object.keys(state.availableLanguages).map((lang, index) => (
										<option value={lang} key={index}>
											{lang}
										</option>
									))}
								</select>
							</div>
						</div>

						<input
							id='inputFile'
							type='file'
							accept='.pdf,.docx,.txt'
							onChange={handleFileUpload}
							className='document-tanslation__file'
							hidden
						/>
						<label htmlFor='inputFile' className='document-tanslation__browse'>
							Browse your computer
						</label>
						<p>{file ? file.name : 'no file chosen'}</p>

						{fileUploadedSuccessfully ? (
							targetFileLink ? (
								targetFileStatus ? (
									<a className='document-tanslation__download' href={targetFileLink} download>
										Download
									</a>
								) : (
									<button
										type='button'
										className='document-tanslation__download'
										onClick={checkFileStatus}>
										Check Translation Status
									</button>
								)
							) : (
								<div className='document-tanslation__waiting'>
									<div className='spinner-border text-danger mb-4' role='status'></div>
									<span>Your file is being translated...</span>
								</div>
							)
						) : (
							<input type='submit' className='document-tanslation__submit' value='Translate File' />
						)}
					</form>
				) : (
					<h2>Sorry, you don't have document translation balance</h2>
				)}
			</div>
			
		</main>
	);
}
