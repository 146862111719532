import React from "react";

const OrdersAnimated = () => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 215 215"
    >
      <defs>
        <linearGradient
          id="b"
          x1={110.23}
          y1={94.5}
          x2={109.3}
          y2={120.81}
          gradientTransform="matrix(1 0 0 -1 0 216)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#e6864e" />
          <stop offset={1} stopColor="#e67240" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={111.15}
          y1={120}
          x2={111.15}
          y2={108.7}
          gradientTransform="matrix(1 0 0 -1 0 216)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1d0024" />
          <stop offset={1} stopColor="#100014" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={106.05}
          y1={114.27}
          x2={106.05}
          y2={90.46}
          gradientTransform="matrix(1 0 0 -1 0 216)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ffe0cc" />
          <stop offset={1} stopColor="#ffeadb" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={107.08}
          y1={95.57}
          x2={107.08}
          y2={80.61}
          gradientTransform="matrix(1 0 0 -1 0 216)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#130027" />
          <stop offset={1} stopColor="#130017" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={106.97}
          y1={111.05}
          x2={97.66}
          y2={155.82}
          gradientTransform="matrix(1 0 0 -1 0 216)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#6956e5" stopOpacity={0} />
          <stop offset={0.98} stopColor="#6956e5" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={214.79}
          y1={537.63}
          x2={206.98}
          y2={638.65}
          gradientTransform="matrix(.08 1 1 -.08 -478.35 -58)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fb896b" stopOpacity={0} />
          <stop offset={1} stopColor="#fb896b" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={101.66}
          y1={167.31}
          x2={115.37}
          y2={29.24}
          gradientTransform="matrix(1 0 0 -1 0 216)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f8c07f" stopOpacity={0} />
          <stop offset={1} stopColor="#f8c07f" />
        </linearGradient>
        <clipPath id="a">
          <path
            d="M107.5 81.44a26.06 26.06 0 0 0-26.06 26.06 26.06 26.06 0 0 0 26.06 26.06 26.06 26.06 0 0 0 26.06-26.06 26.06 26.06 0 0 0-26.06-26.06Z"
            style={{
              fill: "none",
            }}
          />
        </clipPath>
      </defs>
      <circle
        cx={107.5}
        cy={107.5}
        r={26.06}
        style={{
          fill: "#fabe7a",
        }}
      />
      <g
        style={{
          clipPath: "url(#a)",
        }}
      >
        <path
          d="M107.5 81.44a26.06 26.06 0 0 0-26.06 26.06 26.06 26.06 0 0 0 26.06 26.06 26.06 26.06 0 0 0 26.06-26.06 26.06 26.06 0 0 0-26.06-26.06Z"
          style={{
            fill: "#fabe7a",
          }}
        />
        <path
          d="M111.07 121.16c-1.29-2.15-4.27-6.28-11.35-12.09-10.72-8.76-.6-16.77 10.28-12.5 1.16.46 4.37-1.37 7.35.55 1.91 1.25 1.17 2.22 1.91 3.19s3.63.92 4.27 3.45c.37 1.49-.66 3.06-.53 4.19s1.23 2.14 1.29 3.63a4.81 4.81 0 0 1-.3 1.84 4.75 4.75 0 0 1-1 1.59 4.82 4.82 0 0 1-1.52 1.09 4.76 4.76 0 0 1-1.82.43 4.81 4.81 0 0 1-6.93 4.3c-.72.65-1.33.85-1.65.33Z"
          style={{
            fill: "url(#b)",
          }}
        />
        <path
          d="M108.42 96c1.85 1.18 4.13 5.06 3.13 10.26-.22 1.14.46 1.48 1 .08 1.76-4.23 2-8-1-9.86-.53-.32-.83-.06-1.2-.09-.71-.02-1.67-.53-1.93-.39Z"
          style={{
            fill: "url(#c)",
          }}
        />
        <path
          d="M110.82 118.94v-8.11c3.7.55 3.62-6.48-.12-4.76-.82.52-3.14 0-2.92-4.34-4 4.34-8.73-.72-9.13 3.4-.55 5.63 0 11.52 3.5 11.52a6.12 6.12 0 0 0 1.14-.11v2.4c0 1.49-2.69 2.24-2.69 2.24s4.65 4.38 6.59 4.36 6.28-4.36 6.28-4.36-2.65-.75-2.65-2.24Z"
          style={{
            fill: "url(#d)",
          }}
        />
        <path
          d="M103.33 116.54a14.64 14.64 0 0 0 5.18-2.26 6.77 6.77 0 0 1-5.17 3.76Z"
          style={{
            fill: "#ffdbc9",
          }}
        />
        <path
          d="M93.31 125.67c-1.09 2.41-1.65 9.72-1.65 9.72h30.83s-.56-7.31-1.65-9.72c-1-2.11-7.22-4.54-9-5.24a6.68 6.68 0 0 1-9.43 0c-1.88.7-8.15 3.13-9.1 5.24Z"
          style={{
            fill: "url(#e)",
          }}
        />
      </g>
      <g className="animate-spin-slow origin-center">
        <circle
          cx={107.5}
          cy={107.5}
          r={47.7}
          style={{
            fill: "none",
            strokeWidth: 3,
            stroke: "url(#f)",
          }}
        />
        <circle
          cx={153}
          cy={92.04}
          r={5.3}
          style={{
            fill: "#6956e5",
          }}
        />
      </g>
      <g className="animate-spin-slower origin-center">
        <circle
          cx={107.33}
          cy={107.75}
          r={70.67}
          style={{
            fill: "none",
            strokeWidth: "2.999999971316007px",
            stroke: "url(#g)",
          }}
        />
        <circle
          cx={94.69}
          cy={176.85}
          r={5.3}
          style={{
            fill: "#fb896b",
          }}
        />
      </g>
      <g className="animate-spin-slowest origin-center">
        <circle
          cx={107.5}
          cy={107.5}
          r={93.64}
          style={{
            fill: "none",
            strokeWidth: 3,
            stroke: "url(#h)",
          }}
        />
        <circle
          cx={20.48}
          cy={72.6}
          r={5.3}
          style={{
            fill: "#f8c07f",
          }}
        />
      </g>

      <path
        style={{
          fill: "none",
        }}
        d="M0 0h215v215H0z"
      />
    </svg>
  );
};

export default OrdersAnimated;
