import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Pagination = ({ data, updateData,totalTranslations,currentpage,rowsPerPage,setRowsPerPage,loadNewPage,loadPreviousPage,loadNewPaginationSet,setCurrentPage}) => {
  // const [page, setPage] = useState(1);

  return (
    <>
      <hr />

      <div className="flex flex-row justify-between items-center mt-3">
        <div className="text-sm">
        {(currentpage - 1) * rowsPerPage + 1}-
          {rowsPerPage*currentpage}{" "}
          of {totalTranslations} items
        </div>
        <div className="flex flex-row items-center">
          <button
            onClick={() => loadPreviousPage()}
            disabled={currentpage === 1}
            className="p-1 border-[1px] border-zinc-400 rounded-md mx-1"
          >
            <FiChevronLeft size={16} />
          </button>

          <div className="mx-2 text-sm">
            Page{" "}
            <input
              onKeyPress={(event) => {
              if (!/[0-9]/s.test(event.key)) {
                event.preventDefault();
              }}}
              value={currentpage}
              type="number"
              className="w-12 text-center outline-none border-[1px] mx-1"
              onChange={(e) => setCurrentPage(e.target.value)}
              min={1}
              max={Math.ceil(data.length / rowsPerPage)}
            />
            of {Math.ceil(totalTranslations / rowsPerPage)}
          </div>
          <button
            onClick={() => loadNewPage()}
            disabled={currentpage === Math.ceil(totalTranslations/ rowsPerPage)}
            className="p-1 border-[1px] border-zinc-400 rounded-md mx-1"
          >
            <FiChevronRight size={16} />
          </button>

          <input
           onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }}}
            value={rowsPerPage}
            min={1}
            max={data.length}
            onChange={(e) => {loadNewPaginationSet(e.target.value)}}
            type="number"
            className="border-[1px] border-zinc-400 rounded-md mx-1 w-[50px] outline-none text-center pageNum-input"
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
