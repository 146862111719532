import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/globals";
import { validatePasswordNew, validateEmail } from "../../utils/validate";
import { userContext } from "../../store/context/user";
import "../Login/Login.scss";
import "../Login/Login.css"
import { useLocation } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import InjectIntl from 'react-intl-inject';
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { useMediaQuery } from 'react-responsive'
import Logindots from "../../svgIcons/Logindots";
import { FormattedMessage } from 'react-intl';
import jwt_decode from "jwt-decode";
import AppleLogin from 'react-apple-login';
export default function Register(props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [languageSelected, setAppLanguage] = useState('en');
  const [availablePlans, setAvailablePlans] = useState([]);
  const [togglePassword, setTogglePassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    name: "",
    planId: "",
  });
  const [emailFeedback, setEmailFeedback] = useState("");
  const [nameFeedback, setNameFeedback] = useState("");
  const [passwordFeedback, setPasswordFeedback] = useState("");
  const [formValid, setFormValid] = useState(false);
  const { state, dispatch } = useContext(userContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const [isMonthly, setIsMonthly] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedFreePlan, setSelectedFreePlan] = useState('');
  const [profile, setProfile] = useState('null');
  const [title, setTitle] = useState('')
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  var platform = require('platform');
  const clientId = '80926039823-8jmk3fgn65nido0qv3d34b7bosvrakj4.apps.googleusercontent.com';
  const actions = [
    { label: "Add", value: 1 },
    { label: "Edit", value: 2 },
    { label: "Delete", value: 3 }
  ];

  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  useEffect(async () => {
    var chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
    const randomPassword = [4, 3, 1].map(function (len, i) { return Array(len).fill(chars[i]).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function () { return 0.5 - Math.random() }).join('');
    if (profile?.email) {
      setUserData({
        email: profile.email,
        password: randomPassword,
        name: profile.name,
      })

      const emailResponse = await axios.get(`${API_BASE_URL}/user/login/${profile.email}`);
      console.log("emailResponse", emailResponse)
      if (emailResponse.data.user.length > 0 && emailResponse.data?.user[0]?.userLoginType == "1") {
        axios
          .get(`${API_BASE_URL}/user/userid/${emailResponse.data.user[0].userId}`, {
            headers: {
              Authorization: `Bearer ${emailResponse.data.user[0].token}`,
            },
          })
          .then(async (res) => {
            dispatch({
              type: "USER_LOGIN",
              payload: {
                user: emailResponse.data.user[0],
                token: emailResponse.data.user[0].token,
                characterCountBalance: res.data.userBalance.characterCountBalance,
                pdfCountBalance: res.data.userBalance.pdfCountBalance,
                docCountBalance: res.data.userBalance.docCountBalance,
                ocrCountBalance: res.data.userBalance.ocrCountBalance,
                pdfStorageBalance: res.data.userBalance.pdfStorageBalance,
                docStorageBalance: res.data.userBalance.docStorageBalance,
                ocrStorageBalance: res.data.userBalance.ocrStorageBalance,
                availableLanguages: res.data.userBalance.languages,
                planName: emailResponse.data.user[0].planId,
                googleUser: true,
              },
            });
            navigate("/dashboard");
          })
          .catch((err) => {
            setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
            setShow(true)
          });
      }
      else {
      
        let newuserData = {
          email: profile.email,
          password: randomPassword,
          name: profile.name,
          planId: userData.planId,
          userLoginType: "1"
        }
        navigate("/cardVerification", { state: newuserData })
      }

    }
  }, [profile])
  useEffect(async () => {
    let arr = [];
    const { data } = await axios.get(`${API_BASE_URL}/plan/`);

    // setPlans(data.plans.filter((p) => p.status === "active"));
    data.plans.map((p) => {
      if (p.status === 'free' || p.status === 'active')
        arr.push(p)
      if (p.status == 'free')
        setSelectedFreePlan(p)
    })
    console.log(data)
    setPlans(arr);
    axios
      .get(`${API_BASE_URL}/plan/`)
      .then((response) => {
        setAvailablePlans(
          response.data.plans.filter((p) => p.status === "active")
        );
        if (location.state)
          setUserData({
            ...userData,
            planId: response.data.plans.find((p) => location.state.includes(p.name)).planId
          })
        else
        setUserData((userData) => ({
          ...userData,
          planId: response.data.plans.find((p) => p.name === "Basic").planId
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   if (state.isLoggedIn) {
  //     navigate("/");
  //   }
  // }, [navigate, state.isLoggedIn]);

  useEffect(() => {
    const resultPassword = validatePasswordNew(userData.password);
    const resultEmail = validateEmail(userData.email);
    const regex = new RegExp(/^[a-zA-Z]*$/);
    let newFormValue = true;
    if (!resultEmail.isValid) {
      setEmailFeedback(resultEmail.message);
      newFormValue &= false;
    } else {
      setEmailFeedback("");
      newFormValue &= true;
    }
    if (!resultPassword.isValid) {
      setPasswordFeedback(resultPassword.message);
      newFormValue &= false;
    } else {
      setPasswordFeedback("");
      newFormValue &= true;
    }
    if (userData.name.length < 3) {
      setNameFeedback(<FormattedMessage id="home.min3Character"></FormattedMessage>);
      newFormValue &= false;
    }
    else if (userData.name.length > 20) {
      setNameFeedback(<FormattedMessage id="home.min8chr"></FormattedMessage>);

      newFormValue &= false;
    }
    else if (!regex.test(userData.name)) {
      setNameFeedback(<FormattedMessage id="home.onlyAlphabet"></FormattedMessage>);
      newFormValue &= false;
    }
    else {
      setNameFeedback("");
      newFormValue &= true;
    }
    setFormValid(newFormValue);
  }, [availablePlans, userData]);

  const handleInputChange = (event) => {
    userData[event.target.name] = event.target.value;
    setUserData({ ...userData });




  };
  const handleInputChangePlan = (event) => {
    setSelectedPlan(event.target.value.split('@')[0])
    dispatch({
      type: "UPDATE_ISMONTHLY_PLAN",
      payload: event.target.value.split('@')[1] == 'Monthly' ? true : false
    });
    userData[event.target.name] = event.target.value.split('@')[0];
    setUserData({ ...userData });
  };




  const canBeSubmitted = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };
  const onCheckboxClick = () => {
    setChecked(!checked);
    return canBeSubmitted();
  };
  const onSuccess = async (res) => {
    console.log(res)
    if (res)
      setProfile(res.profileObj);


  };
  const responseFacebook = async (response) => {
    console.log("response", response);
    let tempEmail;
    var chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
    const randomPassword = [4, 3, 1].map(function (len, i) { return Array(len).fill(chars[i]).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function () { return 0.5 - Math.random() }).join('');
    if (!response?.email)
      tempEmail = `guest-${response.id}@poshtranslate.com`;
    if (response) {
      console.log("here");
      let registerData = {
        email: response?.email ? response?.email : tempEmail,
        password: randomPassword,
        name: response.name,
        planId: plans.find(x => x.name === 'Basic')?.planId,
        userLoginType: "2"
      }
      const emailResponse = await axios.get(`${API_BASE_URL}/user/login/${response?.email ? response?.email : tempEmail}`);
      console.log('user', emailResponse.data.user)
      if (emailResponse.data.user.length > 0 && emailResponse.data?.user[0]?.userLoginType == "2") {
        axios
          .get(`${API_BASE_URL}/user/userid/${emailResponse.data.user[0].userId}`, {
            headers: {
              Authorization: `Bearer ${emailResponse.data.user[0].token}`,
            },
          })
          .then(async (res) => {
            dispatch({
              type: "USER_LOGIN",
              payload: {
                user: emailResponse.data.user[0],
                token: emailResponse.data.user[0].token,
                characterCountBalance: res.data.userBalance.characterCountBalance,
                pdfCountBalance: res.data.userBalance.pdfCountBalance,
                docCountBalance: res.data.userBalance.docCountBalance,
                ocrCountBalance: res.data.userBalance.ocrCountBalance,
                pdfStorageBalance: res.data.userBalance.pdfStorageBalance,
                docStorageBalance: res.data.userBalance.docStorageBalance,
                ocrStorageBalance: res.data.userBalance.ocrStorageBalance,
                availableLanguages: res.data.userBalance.languages,
                planName: emailResponse.data.user[0].planId,
                googleUser: true,
              },
            });
            navigate("/dashboard");
          })
          .catch((err) => {
            setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
            setShow(true)
          });
      }
      else {
        // userData.email = response.email;
        axios.post(`${API_BASE_URL}/auth/register`, registerData).then((newresponse) => {
          console.log("Main res", newresponse)
          if (newresponse.data && newresponse.data.message == "Duplicate Email ID Found") {
            setTitle(<FormattedMessage id="home.alertemailDuplicate"></FormattedMessage>)
            setShow(true)
          }
          else {
            console.log("userData", userData, profile)
            let newuserData = {
              email:response?.email ? response?.email : tempEmail,
              password: randomPassword,
              name: response.name,
              planId: plans.find(x => x.name === 'Basic')?.planId,
              userLoginType: "2"
            }
          
            navigate("/cardVerification", { state: newuserData })
          }
        })
      }
    }
  }
  const componentClicked = data => {
    console.log("data", data);

  };
  const onFailure = (err) => {
    console.log('failed', err);
  };
  const responseAppleLogin = async (res) => {
    let tempEmail;
    var chars = ["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", "0123456789", "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"];
    const randomPassword = [4, 3, 1].map(function (len, i) { return Array(len).fill(chars[i]).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function () { return 0.5 - Math.random() }).join('');
    console.log("res", res)
    if (res && res.authorization) {
      var decoded = jwt_decode(res.authorization.id_token);
      console.log(decoded)
      if (decoded.email) {
        let registerData = {
          email: decoded.email,
          password: randomPassword,
          name: decoded.email.split("@")[0],
          planId: plans.find(x => x.name === 'Basic')?.planId,
          userLoginType: "3"
        }
        const emailResponse = await axios.get(`${API_BASE_URL}/user/login/${decoded.email}`);
        console.log("emailResponse", emailResponse)
        if (emailResponse.data.user.length > 0 && emailResponse.data?.user[0]?.userLoginType == "3") {
          axios
            .get(`${API_BASE_URL}/user/userid/${emailResponse.data.user[0].userId}`, {
              headers: {
                Authorization: `Bearer ${emailResponse.data.user[0].token}`,
              },
            })
            .then(async (res) => {
              dispatch({
                type: "USER_LOGIN",
                payload: {
                  user: emailResponse.data.user[0],
                  token: emailResponse.data.user[0].token,
                  characterCountBalance: res.data.userBalance.characterCountBalance,
                  pdfCountBalance: res.data.userBalance.pdfCountBalance,
                  docCountBalance: res.data.userBalance.docCountBalance,
                  ocrCountBalance: res.data.userBalance.ocrCountBalance,
                  pdfStorageBalance: res.data.userBalance.pdfStorageBalance,
                  docStorageBalance: res.data.userBalance.docStorageBalance,
                  ocrStorageBalance: res.data.userBalance.ocrStorageBalance,
                  availableLanguages: res.data.userBalance.languages,
                  planName: emailResponse.data.user[0].planId,
                  googleUser: true,
                },
              });
              navigate("/dashboard");
            })
            .catch((err) => {
              setTitle(<FormattedMessage id="home.alertnamePassword"></FormattedMessage>)
              setShow(true)
            });
        }
        else {
          // userData.email = response.email;
          axios.post(`${API_BASE_URL}/auth/register`, registerData).then((newresponse) => {
            if (newresponse.data && newresponse.data.message == "Duplicate Email ID Found") {
              setTitle(<FormattedMessage id="home.alertemailDuplicate"></FormattedMessage>)
              setShow(true)
            }
            else {
              console.log("userData", userData, profile)

              let newuserData = {
                email: decoded.email,
                password: randomPassword,
                name: decoded.email.split("@")[0],
                planId: plans.find(x => x.name === 'Basic')?.planId,
                userLoginType: "3"
              }
            
              navigate("/cardVerification", { state: newuserData })
            }
          })
        }
      }
    }
  };
  return (
    <InjectIntl>
      {({ intl }) => (
        <div style={{ overflowX: 'hidden' }}>
          {isTabletOrMobile &&
            <div className="grid-container">

              <div className="grid-child ">
                <div className="text-center h-full w-full text-lg-left screen">
                  <img
                    src="/assets/imgs/signtriangle.png"
                    className="h-full w-full object-contain"
                    alt="signup screen"
                  />
                </div>

              </div>

              <div className="grid-child ">
                <img
                      onClick={() => navigate('/')}
                  src="/assets/imgs/signin.png"
                  className="h-full w-full ml-0 object-contain"
                  style={{
                    disply: 'flex', justifyContent: 'left',
                    height: '150px',

                    left: '287px',
                    top: '345px',
                    borderRadius: '0px',
                    cursor:'pointer'
                    
                  }}
                  alt="screen"
                />
              </div>

            </div>
          }
          <div className="flex flex-row">

            <div className="h-full w-[44%] hidden sm:flex">
              <div className="text-center h-full w-full text-lg-left screen">
                <img
                  src="/assets/imgs/singinn.png"
                  className="h-full object-contain"
                  alt="signup screen"
                />
              </div>
              <div className="flex flex-row justify-between items-center w-full ml-0">

                <img onClick={() => navigate('/')}

                  src="/assets/imgs/signin.png"
                  className="h-full w-full ml-0 object-contain"
                  style={{
                    disply: 'flex', justifyContent: 'left',
                    height: '242px',

                    left: '287px',
                    top: '345px',
                    borderRadius: '0px',
                    cursor:'pointer'

                    
                  }}
                  alt="screen"
                />
              </div>
            </div>
            <div className="col-md-6 forms">
              <div className="flex-1 flex-row mt-5  ">
                {!isTabletOrMobile &&
                  <div className="flex flex-row justify-end w-full mr-3" style={{ marginLeft: "-41px" }}>
                    <div className="text-sm  text-[#323232] " style={{ marginRight: '6%' }}>
                      {intl.formatMessage({ id: 'home.alreadyMember' })}{" "}
                      <Link
                        to="/login"
                        className="text-[#01BABF]  text-sm  "
                      >
                        {intl.formatMessage({ id: 'home.signIn' })}
                      </Link>
                    </div>
                  </div>
                }
              </div>
              <div className="flex flex-1 flex-col justify-center px-4" style={{ marginTop: '60px' }}>
                {!isTabletOrMobile &&
                  <div className="text-[#01BABF] text-[34px] mb-4 title">{intl.formatMessage({ id: 'home.createyourAccount' })}</div>}
                {isTabletOrMobile &&
                  <div className="text-[#01BABF] text-[34px] mb-4 title">{intl.formatMessage({ id: 'home.createAccount' })}</div>}
                <div className="row text-center">
                  <div className=" col-md-4 col col-sm-12 mt-1">
                    <GoogleLogin
                      clientId={clientId}
                      buttonText={intl.formatMessage({ id: 'home.signInG' })}
                      onSuccess={(res) => onSuccess(res)}
                      onFailure={onFailure}
                      cookiePolicy={'single_host_origin'}
                      isSignedIn={false}
                      render={renderProps => (
                        <button id="fbgoogle" className="py-2 px-8  rounded-[10px]  border-[1px] outline-none ml-1 fbbutton  "

                          style={{

                            fontWeight: isActive ? '500' : '',
                            font: isActive ? 'bold' : '',
                            marginLeft: '-5px',
                            padding: ' 0px 10px 0px 10px',
                            fontSize: '16px',
                            fontFamily: 'Raleway',
                            lineHeight: '19px',
                            height: '65px',
                            width: '204px',

                          }}
                          onClick={renderProps.onClick} disabled={renderProps.disabled}

                        >   <i
                          style={{}}
                          className="fa fa-google"
                          aria-hidden="true"

                        ></i> {intl.formatMessage({ id: 'home.signgoogle' })}</button>
                      )}
                    />
                  </div>
                  <div className=" col-md-4 col-sm-12 col mt-1">
                    <FacebookLogin
                      appId="1186973665197148"
                      fields="name,email,picture"
                      cssClass="my-facebook-button-class"
                      icon={<span><i className="fa fa-facebook" style={{ marginLeft: '5px' }}></i>&nbsp;&nbsp;</span>}
                      textButton={intl.formatMessage({ id: 'home.signInF' })}
                      onClick={componentClicked}
                      callback={responseFacebook}
                      render={renderProps => (
                        <button id="fbgoogle" className="py-2 px-8 rounded-[10px] text-center 
                            border-[1px] outline-none -ml-10 mr-2 fbbutton"

                          style={{

                            fontWeight: isActive ? '500' : '',
                            font: isActive ? 'bold' : '',
                            marginLeft: "-24px",
                            fontFamily: 'Raleway',
                            padding: ' 0px 10px 0px 10px',
                            fontSize: '16px',
                            lineHeight: '19px',
                            height: '65px',
                            width: '204px',

                          }}
                          onClick={renderProps.onClick}
                        >
                          <i
                            style={{}}
                            className="fa fa-facebook-square"
                            aria-hidden="true"
                          ></i> {intl.formatMessage({ id: 'home.signfacebook' })}

                        </button>
                      )}
                    />
                  </div>
                  <div className=" col-md-4 col-sm-12 col mt-1">

                    <AppleLogin clientId={'com.poshenterpriseinc.poshtranslatestaging.sid'}
                      redirectURI={'https://staging.poshtranslate.com'}
                      scope="email name"
                      usePopup={true}
                      responseType={"id_token"}
                      responseMode={"form_post"}
                      callback={response => responseAppleLogin(response)}
                      render={({ onClick }) => (
                        <button id="fbgoogle" className=" py-2 px-8  rounded-[10px] text-center  border-[1px] outline-none -ml-20 mr-4   fbbutton "

                          style={{
                            fontWeight: isActive ? '500' : '',
                            font: isActive ? 'bold' : '',
                            marginLeft: "-48px",
                            fontFamily: 'Raleway',
                            padding: ' 0px 10px 0px 10px',
                            fontSize: '16px',
                            lineHeight: '19px',
                            height: '65px',
                            width: '204px',


                          }}
                          onClick={onClick}

                        >   <i
                          style={{}}
                          className="fa fa-apple"
                          aria-hidden="true"

                        ></i> {intl.formatMessage({ id: 'home.signapple' })}</button>
                      )}
                    />
                  </div>


                </div>
                {
                  platform.manufacturer != 'Apple' &&
                  <div style={{
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: ' 1px solid #CFCFCF',
                    lineHeight: '0.1em',
                    margin: ' 38px 0px 31px 10px'


                  }}>
                    <span style={{
                      background: '#fff',
                      padding: '0 10px'
                    }}>Or</span>

                  </div>
                }
                <div className="flex flex-1 flex-col justify-center ">
                  <div className="w-[100%] sm:w-[100%] ">

                    <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 py-3">
                      <div className="flex flex-col my-1 w-full">
                        <div className="text-sm my-1 text-[#01BABF]  ">{intl.formatMessage({ id: 'home.contactName' })}</div>
                        <input
                          className="input1 mt-2"
                          value={userData.name}
                          name="name"
                          onChange={handleInputChange} placeholder="Please type your name"
                        />
                        {userData.name && <span className="input-feedback">{nameFeedback}</span>}
                      </div>

                      <div className="flex flex-col my-1 w-full">
                        <div className="text-sm text-[#01BABF]   my-1">{intl.formatMessage({ id: 'home.Email' })}</div>
                        <input
                          type="email"
                          className="input1 mt-2"
                          value={userData.email}
                          name="email"
                          onChange={handleInputChange} placeholder="Please type your email"
                        />
                        {userData.email && <span className="input-feedback">{emailFeedback}</span>}
                      </div>

                      <div className="flex flex-col my-1 w-full">
                        <div className="text-sm   text-[#01BABF] my-1">{intl.formatMessage({ id: 'home.password' })}</div>
                        <input
                          type={togglePassword ? 'text' : 'password'}
                          className="input1 mt-2"
                          value={userData.password}
                          name="password"
                          onChange={handleInputChange} placeholder="Please enter your password"

                        />
                        <span style={{
                          marginLeft: '90%',
                          marginTop: '-10%'
                        }} onClick={() => setTogglePassword(!togglePassword)}>
                          <i className="fa fa-eye" id="togglePassword" ></i>
                        </span>
                        {userData.password && <span className="input-feedback" style={{ padding: '19px', marginLeft: "-18px" }}>{passwordFeedback}</span>}
                      </div>

                      <div className="flex flex-col my-1 w-full">
                        <div className="text-sm text-[#01BABF]   my-1">
                          <Link to="/pricing" style={{ color: '#01BABF', fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: 'home.choosePlan' })}
                          </Link>
                        </div>
                        {

                          location.state ?
                            <input
                              type="text"
                              className="input1 mt-2"
                              disabled={true}
                              value={location.state}
                              name="email"
                            /> :
                            <select
                              className="input1 mt-2"
                              name="planId"
                              onChange={handleInputChangePlan}

                            >
                              {plans.map((plan, index) => (

                                <option key={index} value={plan.planId + '@Monthly'} onClick={() => { console.log("here") }}>
                                  {plan.name + '  (Monthly' + '  $' + (plan.monthlyPrice) + ')'}
                                </option>

                              ))}
                            </select>
                        }
                      </div>
                    </div>

                    <div className="flex flex-row items-center mt-2">
                      <input type="checkbox" onClick={onCheckboxClick} />
                      <div className="text-sm iaccept  ml-3">
                        {intl.formatMessage({ id: 'home.accept' })}{" "}
                        <Link to="/termscondition">
                          <span className="text-[#01BABF]   text-sm signIn">
                            {intl.formatMessage({ id: 'home.termscondition' })}
                          </span></Link>
                      </div>
                    </div>
                    <button
                      className="mt-4 gradient-btn"
                      onClick={() => navigate("/cardVerification", { state: userData })}
                      disabled={isDisabled || formValid == 0}
                    >
                      Continue
                    </button>

                  </div>

                </div>
                {isTabletOrMobile &&
                  <div className="flex flex-row justify-center w-full mt-4  " style={{ marginLeft: "" }}>
                    <div className="text-sm   alreadyMember" style={{ marginRight: '6%' }}>
                      {intl.formatMessage({ id: 'home.alreadyMember' })}{" "}
                      <Link
                        to="/login"
                        className="text-[#01BABF]  text-sm signIn "
                      >
                        {intl.formatMessage({ id: 'home.signIn' })}
                      </Link>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {isTabletOrMobile &&
            <div className="flex flex-row " style={{ float: "right" }}>
              <div className="absolute right-20   dots2">
                <Logindots size={110} />
              </div>
            </div>}
          {!isTabletOrMobile &&
            <div className="flex flex-row " style={{ float: "right" }}>

              <img src="/assets/imgs/dots.png" className="dots1" />

            </div>
          }
          <CreateModal
            show={show}
            title={title}
            setShow={setShow}
          />
        </div>
      )}</InjectIntl>
  );
}
const CreateModal = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />}
      body={() => (
        <div>

          <ModalButton type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={() => setShow(false)} />
        </div>
      )}
    />
  );
};