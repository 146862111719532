import React from 'react';
import './ImageTanslation.scss';

export default function ImageTanslation() {
	return (
		<main className='image-tanslation'>
			<div className='container d-flex flex-column align-items-center justify-content-center'>
				<h1 className='image-tanslation__title'>Now you can translate your images</h1>
				<p className='image-tanslation__description'>
					Upload an image and get it translated into your chosen language.
				</p>
				<input accept='image/*' type='file' className='image-tanslation__file'/>
			</div>
		</main>
	);
}
