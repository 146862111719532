import React,{useEffect,useState} from "react";
const ModalInput = (props) => {
 return (
    <div>
      <div className="text-xs text-capitalize">{props.placeholder}</div>
      <div>
        <input
          {...props}

          className="px-2 mb-1 py-1 w-full outline-none border-2 border-transparent bg-zinc-300 hover:border-zinc-400 focus:border-blue-700 rounded-sm"
           />
           {props.type == 'password' &&
            <span className="input-feedback" style={{ padding: '8px' }}>
              {props?.passwordFeedback}</span>
           }
           {props.type == 'email' &&
            <span className="input-feedback" style={{ padding: '8px' }}>{props?.emailFeedback}</span>
           }
           {props.name == 'name' &&
            <span className="input-feedback" style={{ padding: '8px' }}>{props?.nameFeedback}</span>
           }


         
      </div>
    </div>
  );
};

export default ModalInput;
