import React, { useContext, useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { userContext } from "../../store/context/user";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import getFileSize from "../../utils/getFileSize";
import { useNavigate } from 'react-router-dom';
import ChartsToDisplay from './chartsToDisplay';
import InjectIntl from 'react-intl-inject';
const UserDashboard = (props) => {
  const [languageSelected, setAppLanguage] = useState('en');
  const { state, dispatch } = useContext(userContext);
  const [userPlan, setUserPlan] = useState(null);
  const [actualPlan, setActualPlan] = useState(null);
  const [payedUser, setPayedUser] = useState(null);
  const [translation, setTranslation] = useState([])
  const [characters, setCharacters] = useState([]);
  const [allCharacterCount, setAllCharacterCount] = useState(0)
  const [allDocCount, setAllDocCount] = useState(0)
  const [docFileSize, setAllDocFileSize] = useState(0)
  const [allImageCount, setAllImageCount] = useState(0)
  const [imageFileSize, setAllImageFileSize] = useState(0)
  const [pdfs, setPdf] = useState([])
  const [characterPresent, setCharacterPresent] = useState([])
  const navigate = useNavigate();
  let translations;
  let characterCounts;
  let pdfCounts;
 

  useEffect(async () => {
    // let languages = await axios.get(`${API_BASE_URL}/languages`);
    {
    
    const response =  await axios.get(
      `${API_BASE_URL}/user/totalCharacterCount`,{
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }); 
    if(state.user.role == "guest")
    navigate("/")
    setAllCharacterCount(response.data.characterCount)
    setAllDocCount(response.data.docCount)
    setAllDocFileSize(response.data.totalFileSize)
    setAllImageCount(response.data.imageCount)
    setAllImageFileSize(response.data.imageStorage)
    }
    translations = await axios.get(`${API_BASE_URL}/user/mostUsedSrcLanguage`,{
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    }); 
   
     setTranslation(translations?.data.Obj)
     characterCounts = await axios.get(`${API_BASE_URL}/user/translationCountsMonth`,{
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    }); 
     setCharacters(characterCounts.data.dataCollection) 
     pdfCounts = await axios.get(`${API_BASE_URL}/user/pdfTranslationCountsMonth`,{
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    }); 
     setPdf(pdfCounts.data.dataCollectionPdf)
    //  const { data } = await axios.post(
    //   `${API_BASE_URL}/all/${state.user.userId}?planId=${state.user.planId}`,
    //   { headers: { Authorization: `Bearer ${state.token}` } }
    // );
    // console.log("data",data)
    // setCharacterPresent(data.result)
        // if(state.user.cardID)
        // dispatch({ type: "UPDATE_CARD_ID", payload: { cardID: state.user.cardID } });
        if(!state.user.cardID && state.user.customerID){
            let paymentObj = {
              customer_id:state.user.customerID,
              source_id:state.paymentID,
            }
            const cardResponse = await axios.post(`${API_BASE_URL}/auth/createCard`, paymentObj,{
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }); 
            const finalResponse = cardResponse.data.result.card
            dispatch({ type: "UPDATE_CARD_ID", payload: { cardID: finalResponse } });
            if (finalResponse) {
              // alert("Card Added ")
            }
            else {
              alert("Payment Failed")
            }
        }


  }, []);
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  const kFormatter = (num) => {
    // console.log("num",num)
    if (num >= 1) {
      //return (num /1).toString.replace(/(?<!\..*)(\d)(?=(?:\d{2})+(?:\.|$))/g, '$1,')
      return num.toLocaleString();
    }
    return num;
  }
  return (
    <InjectIntl>
      {({ intl }) => (
             <>
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>

      <div className="flex flex-row items-start">
        <Sidebar languageSelected={languageSelected}/>
          {
            state &&
            <div className="px-1 py-4 grid gap-3 grid-cols-3 w-full">
          <div className="bg-emerald-500 p-3 text-white rounded-md col-span-3 md:col-span-1 flex flex-col justify-between">
            <div className="mb-2 font-semibold text-lg"> {"Characters Used"}</div>
            <CustomPieChart
              className="py-2"
              count={[userPlan?.characterCount, state.characterCountBalance]}
              storage={[0, 100]}
            />
            <div className="text-[25px] my-2">
              {allCharacterCount == -1 ? "Unlimited":kFormatter(allCharacterCount)}
            </div>
          </div>
     
          <div className="bg-blue-400 p-3 text-white rounded-md col-span-3 md:col-span-1 flex flex-col justify-between">
            <div className="mb-2 font-semibold text-lg"> {"Documents Used"}</div>
            <CustomPieChart
              className="py-2"
              count={[userPlan?.globalDocCountBalance, userPlan?.globalDocCountBalance]}
              storage={[userPlan?.globalDocStorageBalance, state.globalDocStorageBalance]}
            />
            <div className="text-[25px] my-2 flex flex-row items-center justify-between">
              <div>{allDocCount == -1 ? "Unlimited":kFormatter(allDocCount) + " "+ intl.formatMessage({ id: 'home.files' })} </div>
              <div>{docFileSize == -1 ? "Unlimited":(getFileSize(docFileSize))}</div>
            </div>
          </div>
          <div className="bg-orange-400 p-3 text-white rounded-md col-span-3 md:col-span-1 flex flex-col justify-between">
            <div className="mb-2 font-semibold text-lg"> {"Images Used"}</div>
            <CustomPieChart
              className="py-2"
              count={[userPlan?.pdfCountBalance, userPlan?.pdfCountBalance]}
              storage={[userPlan?.totalPdfSize, state.pdfStorageBalance]}
            />
            <div className="text-[25px] my-2 flex flex-row items-center justify-between">
              <div>{allImageCount == -1 ? "Unlimited":kFormatter(allImageCount)+" " + intl.formatMessage({ id: 'home.files' })} </div>
              <div>{imageFileSize== -1 ? "Unlimited":getFileSize(imageFileSize)}</div>
            </div>
          </div>
          {/* <div className="p-3 text-white rounded-md col-span-3 md:col-span-1 flex flex-col justify-between"> */}
            {/* <div className="mb-2 font-semibold text-lg"> {intl.formatMessage({ id: 'home.pdfBalance' })}</div>
            <CustomPieChart
              className="py-2"
              count={[userPlan?.pdfCountBalance, userPlan?.pdfCountBalance]}
              storage={[userPlan?.totalPdfSize, state.pdfStorageBalance]}
            />
            <div className="text-[25px] my-2 flex flex-row items-center justify-between">
              <div>{userPlan?.pdfCountBalance == -1 ? "Unlimited":kFormatter(userPlan?.pdfCountBalance)+" " + intl.formatMessage({ id: 'home.files' })} </div>
              <div>{userPlan?.pdfStorageBalance == -1 ? "Unlimited":getFileSize(userPlan?.pdfStorageBalance)}</div>
            </div> */}
          {/* </div> */}
          {
          <div className=" p-3 text-white rounded-md col-span-3 md:col-span-1 flex flex-col justify-between">
          
              <ChartsToDisplay type="pie" translation={translation?translation:[]} />
          </div>}
           <div className=" p-3 text-white rounded-md col-span-3 md:col-span-1 flex flex-col justify-between" >
              <ChartsToDisplay intl={intl} type="line" characters={characters} pdfs={pdfs?pdfs:[]}/>
          </div>
        </div> 
          }
        
      </div>


    </div>
    
    <Footer />
          </>
     )}</InjectIntl>
  );
};

export default UserDashboard;

const CustomPieChart = ({ count, storage, className }) => {
  const COLORS = ["#FFF", "#0003"];
  const data2 = [
    { name: "Remaining", value: count[1] },
    { name: "Used", value: count[0] - count[1] },
  ];
  const data1 = [
    { name: "Remaining", value: storage[1] },
    { name: "Used", value: storage[0] - storage[1] },
  ];
  return (
    <ResponsiveContainer width="100%" height={140} className={className}>
      <PieChart>
        {/* <Pie
          data={data1}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius={0}
          outerRadius={40}
          fill="#fff"
          stroke="#0000"
          name="Files"
        >
          {data1.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie> */}
        <Pie
          data={data2}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          innerRadius={45}
          outerRadius={65}
          fill="#0003"
          stroke="#0000"
        >
          {data2.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
