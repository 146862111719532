import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { userContext } from "../../store/context/user";
import TranslateIcon from "../../svgIcons/TranslateIcon";
import { API_BASE_URL } from "../../utils/globals";
import { HiUsers } from "react-icons/hi";
import axios from "axios";
import { gapi } from 'gapi-script';
import { loadGapiInsideDOM } from 'gapi-script';
import { GoogleLogout } from 'react-google-login';
import { FormattedMessage } from 'react-intl';
import InjectIntl from 'react-intl-inject';
import { NavLink } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { AiOutlineAppstore, AiFillSchedule } from "react-icons/ai";
import { IoCallOutline, IoSettingsOutline } from "react-icons/io5";
import { BsCommand, BsPeople, BsList } from "react-icons/bs";
import { Navbar, Container, Button, Nav, NavDropdown } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
let deferredPrompt;
const Navigation = (props) => {



  const { state, dispatch } = useContext(userContext);
  const pathname = useLocation().pathname;
  const [installable, setInstallable] = useState(false);
  const navigate = useNavigate();
  const [userPlan, setUserPlan] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  useEffect(async () => {
    window.addEventListener("beforeinstallprompt", (e) => {
      localStorage.setItem("installPrompt", true)
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
    });

  }, []);
  const setLanguage = (lang) => {
    // delegated listener on change
    document.body.addEventListener("change", (e) => {
      if (e.target.matches("select")) {
        resizeSelect(e.target)
      }
    })
    props.setLanguage(lang)
  }
  const resizeSelect = (sel) => {
    let tempOption = document.createElement('option');
    tempOption.textContent = sel.selectedOptions[0].textContent;
    let tempSelect = document.createElement('select');
    tempSelect.style.visibility = "hidden";
    tempSelect.style.position = "fixed"
    tempSelect.appendChild(tempOption);
    sel.after(tempSelect);
    if (tempOption.textContent.length > 10)
      sel.style.width = `${+tempSelect.clientWidth + 25}px`;
    if (tempOption.textContent.length < 10)
      sel.style.width = `${+tempSelect.clientWidth + 10}px`;
    tempSelect.remove();
  }


  const handleInstallClick = () => {
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {

      if (choiceResult.outcome === 'accepted') {
        setInstallable(true)
        localStorage.setItem("installPrompt", false)
      } else {
      }
    });

  }
  return (


    <InjectIntl>
      {({ intl }) => (
        <Navbar collapseOnSelect expand="lg" fixed='top' className=" max-auto text-[#042552] bg-[#fff]  mx-auto pl-4  " style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>




          {!isTabletOrMobile &&
            <Navbar.Brand className="">
              {
               
                      <>
                        {
                          state.isLoggedIn && (state.user.role == 'admin') &&

                            <img
                              src="/assets/imgs/homelogo.png"
                              alt="logo"
                              className="h-[100px] px-20 homelogo img-fluid"
                            />
                           
                        }
                        {
                          state.isLoggedIn && (state.user.role != 'admin') &&
                           <Link to="/dashboard">
                             <img
                               src="/assets/imgs/homelogo.png"
                               alt="logo"
                               style={{paddingLeft:"20px",paddingRight:"20px"}}
                               className="h-[100px] px-20 homelogo img-fluid"
                             />
                           </Link>
                        }
                        {
                          !state.isLoggedIn &&
                          <Link to="/">
                          <img
                            src="/assets/imgs/homelogo.png"
                            alt="logo"
                            style={{paddingLeft:"20px",paddingRight:"20px"}}
                            className="h-[100px] px-20 homelogo img-fluid"
                          />
                        </Link>
                        }
                      </>
                    
                
              }
            </Navbar.Brand>
          }

          {isTabletOrMobile &&
            <Navbar.Brand className="">
              {
                state.isLoggedIn && ( state.user.planId.includes('PLAN-Free')) ?
                  <Link to="/dashboard">
                    <img
                      src="/assets/imgs/homelogo.png"
                      alt="logo"
                      className="h-[100px] px-20 homelogo img-fluid"
                    /></Link> :
                  <>
                    {state.isLoggedIn && (!state.user.planId.includes('PLAN-Free')) ?
                      <Link to="/dashboard">
                        <img
                          src="/assets/imgs/homelogo.png"
                          alt="logo"
                          className="h-[100px] px-20 homelogo img-fluid"
                        />
                      </Link> :
                      <>
                        {
                          state.isLoggedIn && (state.user.role == 'admin') ?

                            <img
                              src="/assets/imgs/homelogo.png"
                              alt="logo"
                              className="h-[100px] px-20 homelogo img-fluid"
                            />
                            :
                            <Link to="/dashboard">
                              <img
                                src="/assets/imgs/homelogo.png"
                                alt="logo"
                                className="h-[100px] px-20 homelogo img-fluid"
                              />
                            </Link>
                        }
                      </>
                    }
                  </>

              }
            </Navbar.Brand>
          }

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="justify-content-start" style={{ width: "100%" }}>
              {state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&


                <NavLink
                  to="/dashboard"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.dashBoard"></FormattedMessage>
                </NavLink>


              }
              {state.isAdmin && isTabletOrMobile &&

                <NavLink
                  to="/admin/users"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  Users List
                </NavLink>

              }

              {state.isAdmin && isTabletOrMobile &&
                <NavLink
                  to="/admin/plans"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  User Plans
                </NavLink>
              }
              {state.isAdmin && isTabletOrMobile &&

                <NavLink
                  to="/admin/logs"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >

                  Logs
                </NavLink>


              }

              <NavLink
                to="/"
                className={
                  ({ isActive }) => (isActive ? "link-active" : "link")}  >
                <FormattedMessage id="home.home"></FormattedMessage>
              </NavLink>

              {state.isLoggedIn &&
                <NavLink
                  to="/pricing"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.pricing"></FormattedMessage>

                </NavLink>}

              <NavLink
                to="/solutions"
                className={
                  ({ isActive }) => (isActive ? "link-active" : "link")}
              >
                <FormattedMessage id="home.solutions"></FormattedMessage>
              </NavLink>
              {!state.isLoggedIn &&
                <NavLink
                  to="/pricing"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.pricing"></FormattedMessage>

                </NavLink>
              }
              <NavLink
                to="/contact"
                className={
                  ({ isActive }) => (isActive ? "link-active" : "link")}  >
                <FormattedMessage id="home.contactUs"></FormattedMessage>
              </NavLink>


              {
                state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&


                <NavLink
                  to="/translate"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.translate"></FormattedMessage>
                </NavLink>


              }

              {
                state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&


                <NavLink
                  to="/transactions"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.textTr"></FormattedMessage>
                </NavLink>


              }

              {
                state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&


                <NavLink
                  to="/files"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.fileTr"></FormattedMessage>
                </NavLink>


              }

              {
                state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&


                <NavLink
                  to="/paymentHistory"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >

                  <FormattedMessage id="home.payment"></FormattedMessage>
                </NavLink>


              }

              {
                state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&

                <NavLink
                  to="/apps"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.keys"></FormattedMessage>
                </NavLink>


              }

              {
                state.isLoggedIn && isTabletOrMobile && !state.isAdmin &&


                <NavLink
                  to="/settings"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "link")}  >
                  <FormattedMessage id="home.settings"></FormattedMessage>
                </NavLink>


              }
              <div style={{ flexDirection: 'row', justifyContent: 'space-between' }} >

              </ div >
              <select style={{ width: "81px", fontWeight: 600, fontFamily: 'Raleway', }} value={props.language}


                className="hover:text-[#048A8F]  en" onChange={(evt) => setLanguage(evt.target.value)}>

                <option value="en"> English</option>
                <option value="ar">Arabic (عربى)</option>
                <option value="fr">French (Français)</option>
                <option value="ur">Urdu (اردو)</option>
                <option value="hi">Hindi (हिन्दी)</option>
                <option value="es">Spanish (Español)</option>
                <option value="ju">Japanese (日本)</option>
                <option value="zh">Chinese (中国人)</option>
                <option value="tr">Turkish (土耳其)</option>
              </select>


              {
                !state.isLoggedIn &&


                <NavLink
                  to="/login"
                  className={
                    ({ isActive }) => (isActive ? "link-active" : "linkk")}   >
                  <FormattedMessage id="home.login"></FormattedMessage>

                </NavLink>


              }



              {!state.isLoggedIn ? (
                <Button className="downloadapp font-bold	"
                  style={{


                    width: '130px',
                    height: '47px',
                    border: 'solid 1px #A92D62',
                    borderRadius: "10px",
                    marginLeft: "8px",
                    marginRight: "9px",

                  }}
                >
                  <NavLink
                    to="/register" className="downloadapp font-bold	" style={{




                      fontSize: '17px',
                      lineHeight: '21px',
                      textAlign: 'center',

                      color: '#A92D62',

                    }} >
                    <FormattedMessage id="home.register"></FormattedMessage>



                  </NavLink>
                </Button>





              ) : (

                <DropDownLink state={state} dispatch={dispatch} navigate={navigate} props={props} />

              )



              }


              {
                localStorage.getItem("installPrompt") == 'true' &&
                <Button onClick={() => handleInstallClick()} className="downloadapp font-bold	"
                  style={{

                    background: '#A92D62',
                    color: '#FFF',
                    width: '160px',
                    height: '47px',
                    border: 'none',
                    borderRadius: "10px",
                    marginLeft: "8px",
                    marginRight: "-2px",




                  }}>
                  <FormattedMessage id="home.downloadApp"></FormattedMessage>

                </Button>
              }

              <NavLink
                to="/faq"

                className={
                  ({ isActive }) => (isActive ? "link-active" : "link")}   >
                <FormattedMessage id="home.helpcenter"></FormattedMessage>

              </NavLink>


            </Nav>

          </Navbar.Collapse>
        </Navbar>
      )}</InjectIntl>
  );
};

const DropDownLink = ({ state, navigate, dispatch, props }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const clientId = '80926039823-8jmk3fgn65nido0qv3d34b7bosvrakj4.apps.googleusercontent.com';

  const goTo = (link) => {
    setShowDropDown(false);
    navigate(link);
  };

  const handleLogout = async () => {
    // if (window.FB)
    //   window.FB.logout()
    dispatch({
      type: "LOGOUT",
    });
  };

  const NavLink = ({ children, to, onClick }) => (
    <div
      onClick={to ? () => goTo(to) : onClick}
      className="text-gray-700 block py-2 text-sm px-2 hover:bg-slate-200"
    >
      {children}
    </div>
  );
  return (
    <div className="relative inline-block text-left cursor-pointer" >
      <div style={{ width: "100%" }}
        onClick={() => setShowDropDown((p) => !p)}
        className=" text-[#0057D4] loggeduser ml-20 mr-20 hover:text-[#0057D4]"
      >{state.user.name.length<10?state.user.name:state.user.name.substr(0,10)+ "..."} 
    

      &nbsp;&nbsp;<span> <i className="fa fa-caret-down" style={{ color: "#048A8F" }}></i></span>


      </div>
      {showDropDown ? (
        <div style={props.language == "ar" ? { marginRight: "-120px" } : {}} className="origin-top-right absolute z-[500] right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="none">
            {state.isAdmin ? (
              <NavLink to="/admin/users"><FormattedMessage id="home.admin"></FormattedMessage></NavLink>
            ) : (
              <>{
              }
                {
                 
                  <>
                    <NavLink to="/settings"><FormattedMessage id="home.accountSettings"></FormattedMessage></NavLink>
                    <NavLink to="/dashboard"><FormattedMessage id="home.dashboard"></FormattedMessage></NavLink>
                    <NavLink to="/translate"><FormattedMessage id="home.translation"></FormattedMessage></NavLink>
                  </>
                }

              </>
            )}
            <hr className="mx-2" />
            {
              state.googleUser ?
                <GoogleLogout
                  clientId="80926039823-8jmk3fgn65nido0qv3d34b7bosvrakj4.apps.googleusercontent.com"
                  render={renderProps => (
                    <NavLink onClick={renderProps.onClick} disabled={renderProps.disabled}>
                      <FormattedMessage id="home.logout"></FormattedMessage></NavLink>
                  )}
                  clientId={clientId}
                  onLogoutSuccess={handleLogout}
                >
                </GoogleLogout> :
                <NavLink onClick={() => handleLogout()}><FormattedMessage id="home.logout"></FormattedMessage></NavLink>
            }

          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Navigation;
