import React from "react";

const Instagram = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3934 0H3.81518C1.70844 0 0 1.69998 0 3.7963V8.35185C0 10.4482 1.70844 12.1482 3.81518 12.1482H8.3934C10.5001 12.1482 12.2086 10.4482 12.2086 8.35185V3.7963C12.2086 1.69998 10.5001 0 8.3934 0ZM11.064 8.35185C11.064 9.81723 9.86606 11.0093 8.3934 11.0093H3.81518C2.34252 11.0093 1.14455 9.81723 1.14455 8.35185V3.7963C1.14455 2.33093 2.34252 1.13889 3.81518 1.13889H8.3934C9.86606 1.13889 11.064 2.33093 11.064 3.7963V8.35185Z"
        fill="white"
      />
      <path
        d="M6.11074 3.04395C4.42519 3.04395 3.05859 4.40378 3.05859 6.08098C3.05859 7.75819 4.42519 9.11802 6.11074 9.11802C7.79629 9.11802 9.16289 7.75819 9.16289 6.08098C9.16289 4.40378 7.79629 3.04395 6.11074 3.04395ZM6.11074 7.97913C5.05928 7.97913 4.20315 7.12724 4.20315 6.08098C4.20315 5.03396 5.05928 4.18283 6.11074 4.18283C7.1622 4.18283 8.01833 5.03396 8.01833 6.08098C8.01833 7.12724 7.1622 7.97913 6.11074 7.97913Z"
        fill="white"
      />
      <path
        d="M9.37697 3.22354C9.60216 3.22354 9.7847 3.04189 9.7847 2.81782C9.7847 2.59375 9.60216 2.41211 9.37697 2.41211C9.15179 2.41211 8.96924 2.59375 8.96924 2.81782C8.96924 3.04189 9.15179 3.22354 9.37697 3.22354Z"
        fill="white"
      />
    </svg>
  );
};

export default Instagram;
