import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import "../Login/Login.scss";
import "../Login/Login.css"
import { useLocation } from 'react-router-dom';
import InjectIntl from 'react-intl-inject';
import { useMediaQuery } from 'react-responsive'
import Logindots from "../../svgIcons/Logindots";
import { FormattedMessage } from 'react-intl';
import FadeLoader from "react-spinners/FadeLoader";

let payments, card;
export default function RegisterCard(props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    name: "",
    planId: "",
    customerID: "",
    paymentID: "",
    cardID: ""
  });
  const { state, dispatch } = useContext(userContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [customerID, setCustomerID] = useState('');
  const [paymentID, setPaymentID] = useState('');
  const [showFooterText, setShowFooterText] = useState('');
  const [cardID, setCardID] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    console.log(userData)
    if (userData.planId.includes("Pro"))
      setShowFooterText(" We will deduct 20 $ form this card for Purchasing this plan")
    else if (userData.planId.includes("Premium"))
      setShowFooterText(" We will deduct 500 $ form this card for Purchasing this plan")
    else
      setShowFooterText("Please enter your card information")
  }, [userData])
  useEffect(async () => {
    let arr = [];
    setUserData(location.state)

    let customerbody = { email_address: location.state.email }



    //Initialise the square payment 
    payments = window.Square.payments(process.env.REACT_APP_SANDBOX_APPLICATION_ID, process.env.REACT_APP_SANDBOX_LOCATION_ID);
    const createCustomer = await axios.post(`${API_BASE_URL}/auth/createCustomer`, customerbody);
    //Initialise the card 
    card = await payments.card();

    await card.attach('#card-container');

    setCustomerID(createCustomer?.data?.result?.id)

  }, []);
  useEffect(() => {
    if (cardID)
      handleRegister()
  }, [cardID])

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }
  const createPayment = async (token, verificationToken) => {
    const body = {
      locationId:process.env.REACT_APP_SANDBOX_LOCATION_ID,
      sourceId: token,
      planId: userData.planId,
      email: userData.email
    }
    
    const paymentResponse = await axios.post(`${API_BASE_URL}/auth/squarePay`, body);

    const finalResponse = paymentResponse.data.payment
    

    if (finalResponse.status == "COMPLETED" && finalResponse.id ) {
      dispatch({ type: "UPDATE_PAYMENT_ID", payload: { paymentID: finalResponse.id } });
      setPaymentID(finalResponse.id)
      handleRegister();
    }
    else {
      alert("Payment Failed")
    }
    // if (paymentResponse.ok) {
    //   return paymentResponse.json();
    // }

    // const errorBody = await paymentResponse.text();
    // throw new Error(errorBody);
  }
  const verifyBuyer = async (payments, token) => {
    const verificationDetails = {
      billingContact: {
        familyName: "state",
        givenName: 'stat',
        email: "sam@gm.com",
      },
      intent: 'STORE',
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  }
  async function storeCard(token, customerId, verificationToken) {
    const bodyParameters = {
      locationId: process.env.REACT_APP_SANDBOX_LOCATION_ID,
      sourceId: token,
      customerId,
      verificationToken,
    };

    axios
      .post(`${API_BASE_URL}/auth/storeCard`, bodyParameters)
      .then((res) => {
        if (res && res.data.card)
          setCardID(res.data.card);
        dispatch({ type: "UPDATE_CUSTOMERID", payload: { customerID: customerID } });
        dispatch({ type: "UPDATE_CARD_ID", payload: { cardID: res.data.card } });
        // handleRegister()
      })



  }
  const verifyCard = async (event, paymentMethod) => {
    event.preventDefault();
    setIsLoading(false);
    const cardButton = document.getElementById('card-button');
    try {

      // disable the submit button as we await tokenization and make a payment request.
      cardButton.disabled = true;
      setIsLoading(true);
      
      const token = await tokenize(paymentMethod);
      let verificationToken = await verifyBuyer(payments, token);
      if (!userData.planId.includes("Basic"))
        await createPayment(token, verificationToken);
      else
        await storeCard(
          token,
          customerID,
          verificationToken
        );
      // displayPaymentResults('SUCCESS');

      // console.debug('Payment Success', paymentResults);
    } catch (e) {
      cardButton.disabled = false;
      // displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  }
  const handleRegister = async () => {
    try {

      userData.customerID = customerID
      userData.cardID = cardID;

      let register = await axios.post(`${API_BASE_URL}/auth/register`, userData);
      console.log("register", register)
      if (register.data.message == "Duplicate Email ID Found") {
        // setTitle(<FormattedMessage id="home.alertemailDuplicate"></FormattedMessage>)
        // setShow(true)
        alert("Duplicate Mail ID found")
      }
      else {
        let data = {
          email: userData.email,
          password: userData.password,
        }
        axios
          .post(`${API_BASE_URL}/auth/login`, data)
          .then((response) => {
            dispatch({
              type: "USER_LOGIN",
              payload: {
                user: response.data.user,
                token: response.data.user.token,
                characterCountBalance: response.data.characterCountBalance,
                pdfCountBalance: response.data.pdfCountBalance,
                docCountBalance: response.data.docCountBalance,
                ocrCountBalance: response.data.ocrCountBalance,
                pdfStorageBalance: response.data.pdfStorageBalance,
                docStorageBalance: response.data.docStorageBalance,
                ocrStorageBalance: response.data.ocrStorageBalance,
                availableLanguages: response.data.availableLanguages,
                planName: response.data.planName,
                googleUser: true,
                globalDocCountBalance: response.data.globalDocCount,
                globalDocStorageBalance: response.data.globalTotalDocSize,
                direct_translation: response.data.direct_translation,
                document_translation: response.data.document_translation,
                image_translation: response.data.image_translation,
                api_allowed: response.data.api_allowed,
                cloud_storage: response.data.cloud_storage,
                batch_translate_text: response.data.batch_translate_text,
                batch_translate_doc: response.data.batch_translate_doc,
                total_requests: response.data.total_requests,
                language_detection: response.data.language_detection,
                cardID: cardID
              },
            });



            navigate("/dashboard");
          })
          .catch((err) => {
            console.log("err", err)
            alert("Error")
          });
      }

    }
    catch (err) {
      console.log('error', err)
    }


  };

  return (
    <InjectIntl>
      {({ intl }) => (
        <div style={{ overflowX: 'hidden' }}>
          {isTabletOrMobile &&
            <div className="grid-container">

              <div className="grid-child ">
                <div className="text-center h-full w-full text-lg-left screen">
                  <img
                    src="/assets/imgs/signtriangle.png"
                    className="h-full w-full object-contain"
                    alt="signup screen"
                  />
                </div>

              </div>

              <div className="grid-child ">
                <img
                      onClick={() => navigate('/')}
                  src="/assets/imgs/signin.png"
                  className="h-full w-full ml-0 object-contain"
                  style={{
                    disply: 'flex', justifyContent: 'left',
                    height: '150px',

                    left: '287px',
                    top: '345px',
                    borderRadius: '0px',
                    cursor:'pointer'
                  }}
                  alt="screen"
                />
              </div>

            </div>
          }
          <div className="flex flex-row">

            <div className="h-full w-[44%] hidden sm:flex">
              <div className="text-center h-full w-full text-lg-left screen">
                <img
                  src="/assets/imgs/singinn.png"
                  className="h-full object-contain"
                  alt="signup screen"
                />
              </div>
              <div className="flex flex-row justify-between items-center w-full ml-0">

                <img
                   onClick={() => navigate('/')}
                  src="/assets/imgs/signin.png"
                  className="h-full w-full ml-0 object-contain"
                  style={{
                    disply: 'flex', justifyContent: 'left',
                    height: '242px',

                    left: '287px',
                    top: '345px',
                    borderRadius: '0px',
                    cursor:'pointer'
                  }}
                  alt="screen"
                />
              </div>
            </div>
            <div className="col-md-6 forms">
              <div className="flex-1 flex-row mt-5  ">
                {!isTabletOrMobile &&
                  <div className="flex flex-row justify-end w-full mr-3" style={{ marginLeft: "-41px" }}>
                    <div className="text-sm  text-[#323232] " style={{ marginRight: '6%' }}>
                      {intl.formatMessage({ id: 'home.alreadyMember' })}{" "}
                      <Link
                        to="/login"
                        className="text-[#01BABF]  text-sm  "
                      >
                        {intl.formatMessage({ id: 'home.signIn' })}
                      </Link>
                    </div>
                  </div>
                }
              </div>
              <div className="flex flex-1 flex-col justify-center px-4" style={{ marginTop: '60px' }}>
                {!isTabletOrMobile &&
                  <div className="text-[#01BABF] text-[34px] mb-4 title">Validate Card</div>}
                {isTabletOrMobile &&
                  <div className="text-[#01BABF] text-[34px] mb-4 title">Validate Card</div>}
                <div className="row text-center">



                </div>

                <div className="flex flex-1 flex-col justify-center ">
                  <div className="w-[100%] sm:w-[100%] ">

                    <div className="d-flex justify-center">
                      <FadeLoader color={"#048A8F"} loading={!customerID} size={50} />
                    </div>
                    <div >
                      <div id="card-container"></div>
                      {/* <button id="card-button" type="button">Pay $1.00</button> */}

                    </div>

                    <div className="flex flex-row items-center mt-2">
                      {/* <input type="checkbox" onClick={onCheckboxClick} /> */}
                      <div className="text-sm iaccept  ml-3">

                        <Link to="/termscondition">
                          <span className="text-[#01BABF]   text-sm signIn">
                            {
                              showFooterText
                            }

                          </span></Link>
                      </div>
                    </div>
                    <button id="card-button"
                      className="mt-4 gradient-btn"
                      onClick={(evt) => verifyCard(evt, card)}
                    // disabled={isDisabled || formValid == 0}
                    >
                        {isLoading ? <span className="spinner-grow spinner-grow-sm">
                          </span> : <span className="">
                            </span>}
                  
             
                      Validate
                    </button>

                  </div>

                </div>
                {isTabletOrMobile &&
                  <div className="flex flex-row justify-center w-full mt-4  " style={{ marginLeft: "" }}>
                    <div className="text-sm   alreadyMember" style={{ marginRight: '6%' }}>
                      {intl.formatMessage({ id: 'home.alreadyMember' })}{" "}
                      <Link
                        to="/login"
                        className="text-[#01BABF]  text-sm signIn "
                      >
                        {intl.formatMessage({ id: 'home.signIn' })}
                      </Link>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {isTabletOrMobile &&
            <div className="flex flex-row " style={{ float: "right" }}>
              <div className="absolute right-20   dots2">
                <Logindots size={110} />a
              </div>
            </div>}
          {!isTabletOrMobile &&
            <div className="flex flex-row " style={{ float: "right" }}>

              <img src="/assets/imgs/dots.png" className="dots1" />

            </div>
          }

        </div>
      )}</InjectIntl>
  );
}

