import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { userContext } from "../../store/context/user";
import getFileSize from "../../utils/getFileSize";
import { API_BASE_URL } from "../../utils/globals";
import { useNavigate } from 'react-router-dom';
import InjectIntl from 'react-intl-inject';
import { FormattedMessage } from 'react-intl';
import Table from 'react-bootstrap/Table';
const headerContent = [
  "#",
  <FormattedMessage id="home.Email"></FormattedMessage>,
  "Order Id",
  "Status",
  <FormattedMessage id="home.amount"></FormattedMessage>
];

const PaymentHistory = (props) => {
  const [languageSelected, setAppLanguage] = useState('en');
  const navigate = useNavigate();
  const [fileHistory, setFileHistory] = useState([]);
  const { state } = useContext(userContext);
 let tempArr = [];
  useEffect(() => {
    // setFileHistory([])
    axios
      .get(`${API_BASE_URL}/getAllPayments/${state.user.email}`)
      .then((res) => {
        res.data.result.map((x,i)=>{
           
            if(x.email == state.user.email){
              tempArr.push(x)
              
            }
        })
       
        setFileHistory(tempArr)
      })
      .catch((err) => console.log('errr',err));
      
  }, []);

  const downloadFile = async (link)=>{
    const response = await fetch(link);
    console.log("response", response);
    if (response.status != "200") {
      alert("Processing your file.Please wait")
    }
    else{
      window.open(link)
    }
  }
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  return (
    <InjectIntl>
    {({ intl }) => (
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction:(languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>

      <div className="flex flex-row">
        <Sidebar  languageSelected={languageSelected}/>

        <div className="w-full flex-1 p-4">
          <div className="w-full">
            <div className="font-semibold text-[24px] text-[#048A8F] text-center py-2 mb-2">{intl.formatMessage({ id: 'home.paymentHistory' })} </div>
          </div>
          <Table responsive>
            <thead className="bg-[#048A8F] text-[#fff]" >
              <tr>
                {headerContent.map((item, index) => (
                  <TH
                    className={` ${
                      index === 0 ? "rounded-l-md pl-2" : ""
                    } ${
                      index === headerContent.length - 1
                        ? "rounded-r-md pr-2"
                        : ""
                    }`}
                    key={index}
                  >
                    {item}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {fileHistory.map((file, index) => 
                {return <tr key={index}>
                  <TH className="pl-2">{index+1}</TH>
                  <TD>{file.email}</TD>
                  <TD>{file.orderId}</TD> 
                  <TD>{file.status}</TD> 
                  <TD><span style={{color:file.receiptUrl == ""?"green":"red",fontSize:"20px"}}><span >{file.receiptUrl == ""?"+":"-"}</span>{file.amount/100 + " $" }</span></TD> 
                </tr>}
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
    )}</InjectIntl>
  );
};

const TH = ({ children, className }) => {
  return (
    <th className={`font-semibold text-sm py-1 px-1 ${className}`}>{children}</th>
  );
};

const TD = ({ children, className }) => {
  return (
    <td className={`text-zinc-600 text-sm px-1 ${className}`}>{children}</td>
  );
};

export default PaymentHistory;
