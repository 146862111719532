import React from "react";

const Dotts = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 204 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     
 
     
     
      <circle cx="165" cy="3" r="3" fill="#81C4C6" />
      <circle cx="183" cy="3" r="3" fill="#81C4C6" />
      <circle cx="201" cy="3" r="3" fill="#81C4C6" />
 
    
      
      <circle cx="165" cy="21" r="3" fill="#81C4C6" />
      <circle cx="183" cy="21" r="3" fill="#81C4C6" />
      <circle cx="201" cy="21" r="3" fill="#81C4C6" />
     
      <circle cx="165" cy="39" r="3" fill="#81C4C6" />
      <circle cx="183" cy="39" r="3" fill="#81C4C6" />
      <circle cx="201" cy="39" r="3" fill="#81C4C6" />


      <circle cx="165" cy="57" r="3" fill="#81C4C6" />
      <circle cx="183" cy="57" r="3" fill="#81C4C6" />
      <circle cx="201" cy="57" r="3" fill="#81C4C6" />
    


      <circle cx="165" cy="75" r="3" fill="#81C4C6" />
      <circle cx="183" cy="75" r="3" fill="#81C4C6" />
      <circle cx="201" cy="75" r="3" fill="#81C4C6" />
     


      <circle cx="165" cy="93" r="3" fill="#81C4C6" />
      <circle cx="183" cy="93" r="3" fill="#81C4C6" />
      <circle cx="201" cy="93" r="3" fill="#81C4C6" />
    

 
      <circle cx="165" cy="111" r="3" fill="#81C4C6" />
      <circle cx="183" cy="111" r="3" fill="#81C4C6" />
      <circle cx="201" cy="111" r="3" fill="#81C4C6" />
   


      <circle cx="165" cy="129" r="3" fill="#81C4C6" />
      <circle cx="183" cy="129" r="3" fill="#81C4C6" />
      <circle cx="201" cy="129" r="3" fill="#81C4C6" />
  
   

   
      <circle cx="165" cy="147" r="3" fill="#81C4C6" />
      <circle cx="183" cy="147" r="3" fill="#81C4C6" />
      <circle cx="201" cy="147" r="3" fill="#81C4C6" />

   
      <circle cx="165" cy="165" r="3" fill="#81C4C6" />
      <circle cx="183" cy="165" r="3" fill="#81C4C6" />
      <circle cx="201" cy="165" r="3" fill="#81C4C6" />



    </svg>
  );
};

export default Dotts;
