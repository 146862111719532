import React,{useState,useEffect} from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Translation from "../../components/Translation/Translation";

const Translate = (props) => {
  const [languageSelected, setAppLanguage] = useState('en');
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  return (
    <>
    <div className="max-w-[1280px] mx-auto py-20">
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>
      <div className="flex flex-row items-start" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
        <Sidebar languageSelected={languageSelected}/>
        <div className=" w-full ">
          <Translation props={props}/>
        </div>
      </div>
  
    </div>
       <Footer />
       </>
  );
};

export default Translate;
