import React from "react";

const Pdf= ({ size }) => {
  return (
   



<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={size}
 height={size} viewBox="0 0 81.000000 74.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,74.000000) scale(0.100000,-0.100000)"
fill="#A92D62" stroke="none">
<path d="M65 731 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -36 -20 -331 l0 -309
28 -27 27 -28 345 0 345 0 27 28 28 27 0 310 0 310 -28 27 -27 28 -333 2
c-182 1 -341 -2 -352 -6z m345 -176 c9 -11 11 -35 7 -83 -6 -66 -6 -68 29
-104 28 -31 41 -38 72 -38 56 0 87 -20 87 -55 0 -27 -4 -30 -34 -33 -26 -3
-43 4 -73 28 -39 32 -41 32 -96 21 -33 -6 -61 -18 -67 -27 -56 -93 -103 -122
-129 -81 -17 26 11 67 67 98 38 21 53 37 72 77 22 48 23 54 10 87 -18 40 -19
79 -5 106 13 23 43 25 60 4z"/>
<path d="M370 513 c0 -16 5 -35 10 -43 7 -11 10 -4 10 28 0 23 -4 42 -10 42
-5 0 -10 -12 -10 -27z"/>
<path d="M376 351 l-17 -31 30 0 c39 0 48 17 23 42 l-20 21 -16 -32z"/>
<path d="M524 285 c22 -17 56 -20 56 -6 0 11 -25 21 -53 21 -22 -1 -22 -1 -3
-15z"/>
<path d="M258 236 c-28 -22 -38 -51 -16 -44 14 5 53 56 47 62 -2 2 -16 -6 -31
-18z"/>
</g>
</svg>
    
  );
};

export default Pdf;
