import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Pagination = ({ data, updateData }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    updateData(
      data.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page, rowsPerPage]);

  return (
    <>
      <hr />

      <div className="flex flex-row justify-between items-center mt-3">
        <div className="text-sm">
          {(page - 1) * rowsPerPage + 1}-
          {(page - 1) * rowsPerPage + rowsPerPage > data.length + 1
            ? data.length
            : (page - 1) * rowsPerPage + rowsPerPage}{" "}
          of {data.length} items
        </div>
        <div className="flex flex-row items-center">
          <button
            onClick={() => setPage((p) => p - 1)}
            disabled={page === 1}
            className="p-1 border-[1px] border-zinc-400 rounded-md mx-1"
          >
            <FiChevronLeft size={16} />
          </button>

          <div className="mx-2 text-sm">
            Page{" "}
            <input
              value={page}
              type="number"
              className="w-12 text-center outline-none border-[1px] mx-1"
              onChange={(e) => setPage(+e.target.value)}
              min={1}
              max={Math.ceil(data.length / rowsPerPage)}
            />
            of {Math.ceil(data.length / rowsPerPage)}
          </div>
          <button
            onClick={() => setPage((p) => p + 1)}
            disabled={page === Math.ceil(data.length / rowsPerPage)}
            className="p-1 border-[1px] border-zinc-400 rounded-md mx-1"
          >
            <FiChevronRight size={16} />
          </button>

          <input
            value={rowsPerPage}
            min={1}
            max={data.length}
            onChange={(e) => setRowsPerPage(+e.target.value)}
            type="number"
            className="border-[1px] border-zinc-400 rounded-md mx-1 w-[50px] outline-none text-center pageNum-input"
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
