import React from "react";

const Twitter = ({ size }) => {
  return (
    <svg
      width={(size * 14) / 12}
      height={size}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9414 1.33435C13.423 1.56064 12.8705 1.71064 12.2946 1.78347C12.8871 1.43146 13.3393 0.878296 13.5519 0.211554C12.9995 0.539289 12.3896 0.770785 11.7395 0.899972C11.215 0.344209 10.4674 0 9.65181 0C8.06946 0 6.79556 1.27799 6.79556 2.84471C6.79556 3.07013 6.81473 3.28689 6.86178 3.49324C4.48565 3.37793 2.38311 2.24473 0.97067 0.518481C0.724082 0.94419 0.57944 1.43146 0.57944 1.95601C0.57944 2.94095 1.08917 3.81404 1.84898 4.31952C1.38978 4.31085 0.939302 4.17819 0.557656 3.96924C0.557656 3.97791 0.557656 3.98918 0.557656 4.00045C0.557656 5.38249 1.54837 6.53043 2.84753 6.79487C2.61488 6.85816 2.36133 6.88851 2.09818 6.88851C1.9152 6.88851 1.73048 6.87811 1.55708 6.83996C1.9274 7.96622 2.97823 8.79423 4.22773 8.82111C3.25532 9.57802 2.02063 10.0341 0.684 10.0341C0.44961 10.0341 0.224805 10.0237 0 9.99506C1.26605 10.8075 2.7665 11.2713 4.38457 11.2713C9.64397 11.2713 12.5194 6.9362 12.5194 3.17851C12.5194 3.05279 12.515 2.93141 12.5089 2.81089C13.0762 2.41033 13.5528 1.91006 13.9414 1.33435Z"
        fill="white"
      />
    </svg>
  );
};

export default Twitter;
