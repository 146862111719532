import React from "react";

const FaceBook = ({ size }) => {
  return (
    <svg
      width={(size * 7) / 13}
      height={size}
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.73547 0.00268021L5.05672 0C3.1707 0 1.95187 1.24429 1.95187 3.17016V4.63181H0.263967C0.118112 4.63181 0 4.74947 0 4.89461V7.01238C0 7.15751 0.118247 7.27504 0.263967 7.27504H1.95187V12.6189C1.95187 12.764 2.06999 12.8815 2.21584 12.8815H4.41808C4.56394 12.8815 4.68205 12.7639 4.68205 12.6189V7.27504H6.6556C6.80146 7.27504 6.91957 7.15751 6.91957 7.01238L6.92038 4.89461C6.92038 4.82492 6.8925 4.75818 6.84308 4.70887C6.79365 4.65955 6.72631 4.63181 6.65628 4.63181H4.68205V3.39275C4.68205 2.79721 4.82467 2.49488 5.60432 2.49488L6.7352 2.49448C6.88092 2.49448 6.99903 2.37681 6.99903 2.23181V0.265341C6.99903 0.120476 6.88105 0.00294824 6.73547 0.00268021Z"
        fill="white"
      />
    </svg>
  );
};

export default FaceBook;
