import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import InjectIntl from 'react-intl-inject';
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { userContext } from "../../store/context/user";
import axios from 'axios';
import { API_BASE_URL } from '../../utils/globals';
import { validatePassword, validateEmail } from "../../utils/validate";
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive'
  
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
const Contact = (props) => {
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  /* 
  return (
    <div className="max-w-[1280px] mx-auto">
      <Navbar />

      <div className="my-10 px-3 font-semibold text-[24px] text-[#042552] w-full text-center">
        Leave us a message, we'd love to know how you think we're doing.
      </div>

      <section className="flex px-3 items-stretch sm:items-center flex-col sm:flex-row">
        <div className="flex flex-1 flex-row justify-center">
          <div className=" border-[1px] border-[#048A8F] w-full max-w-[450px]">
            <div className="p-8 flex flex-col">
              <label className="text-[#048A8F] mt-3">Name *</label>
              <input className="border-b-[1px] border-[#048A8F] outline-none py-2" />

              <label className="text-[#048A8F] mt-3">Email Address *</label>
              <input className="border-b-[1px] border-[#048A8F] outline-none py-2" />

              <label className="text-[#048A8F] mt-3">Message</label>
              <textarea
                rows={4}
                className="border-b-[1px] border-[#048A8F] outline-none py-2"
              />
              <label className="text-[#048A8F] mt-3">Attach File</label>
              <button className="bg-[#F9B035] text-white rounded-md px-4 py-2 w-fit mt-1">
                Browse Files
              </button>
            </div>
            <button
              onClick={() => {
                window.location = "mailto:manish@ogoul.com";
              }}
              className="bg-[#048A8F] text-white py-3 w-full"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="flex flex-1 p-4 flex-row justify-center">
          <div className="w-[400px] h-[400px]">
            <img src="/assets/svg/messages.svg" alt="heroImage" />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
  */
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const { state, dispatch } = useContext(userContext);
  const navigate = useNavigate();

  const [emailFeedback, setEmailFeedback] = useState("");
  const [nameFeedback, setNameFeedback] = useState("");
  const [messageFeedback, setMessageFeedback] = useState("");
  const [attachfileFeedback, setAttachfileFeedback] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [formValues, setFormValues] = useState({ name: "", email: "", message: "", attachfile: "" });
  const [formErrors, setFormErrors] = useState({});
  const [languageSelected, setAppLanguage] = useState('en');
  const [uploadedName, setUploadedName] = useState('');
  
const [show, setShow] = useState(false);
const [title,setTitle]=useState('')

  const handleChange = (e) => {
    
    if(e.target.name == 'attachfile')
    setUploadedName(e.target.files[0].name)
    // e.preventDefault();
    formValues[e.target.name] = e.target.value;

    setFormValues({ ...formValues });
    // const { name, value } = e.target;
    // setFormValues({ ...formValues, [name]: value });
  };




  useEffect(() => {
    // const resultPassword = validatePassword(formValues.password);
    const resultEmail = validateEmail(formValues.email);
    const regex = new RegExp(/^[a-zA-Z]*$/);
    let newFormValue = true;
    if (!resultEmail.isValid) {
      setEmailFeedback(resultEmail.message);
      newFormValue &= false;
    } else {
      setEmailFeedback("");
      newFormValue &= true;
    }
    // if (!resultPassword.isValid) {
    //   setPasswordFeedback(resultPassword.message);
    //   newFormValue &= false;
    // } else {
    //   setPasswordFeedback("");
    //   newFormValue &= true;
    // }
    if (formValues.name.length < 3) {

      setNameFeedback(<FormattedMessage id="home.min3Character"></FormattedMessage>);
      newFormValue &= false;
    }
    else if (formValues.name.length > 20) {
      setNameFeedback(<FormattedMessage id="home.min8chr"></FormattedMessage>); 
   
      newFormValue &= false;
    }
    else if(!regex.test(formValues.name)){
      setNameFeedback(<FormattedMessage id="home.onlyAlphabet"></FormattedMessage>);
      newFormValue &= false;
    }
    else {
      setNameFeedback("");
      newFormValue &= true;
    }
    if (formValues.message.length === 0) {
      setMessageFeedback( <FormattedMessage id="home.required"></FormattedMessage>);
      newFormValue &= false;
    } else {
      setMessageFeedback("");
      newFormValue &= true;
    }






    setFormValid(newFormValue);
  }, [formValues]);
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  const sendMail = () => {

    
    
    axios
      .post(`${API_BASE_URL}/auth/send-contact`, formValues)
      .then(() =>
      setTitle(<FormattedMessage id="home.alertSendEmail"></FormattedMessage>),
      setShow(true)

      )
    
      
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertFailEmail"></FormattedMessage>)
				setShow(true)
			  });
    if (state.isLoggedIn)
      navigate('/dashboard')
    else
      navigate('/')
  

}


return (
  <InjectIntl>
  {({ intl }) => (
  <>
  <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
    <Navbar setLanguage={props.setLanguage}  language={props.language}/>

    <section className="flex items-center flex-col sm:flex-row  ">
    <div className="flex flex-1 p-4 flex-col px-3  my-10">
    <div className=" w-full text-center my-4 contacttitle">
       {intl.formatMessage({ id: 'home.contacttitle' })}
       </div>
    <div className="my-4 w-full text-center contactHeading">
      
       {intl.formatMessage({ id: 'home.contactHeading' })}
    </div>
    </div>
</section>
    

    { ! isTabletOrMobile &&

    <section className="flex px-2 items-stretch sm:items-center flex-col sm:flex-row">
   
      <div className="flex flex-1 flex-row justify-center">
    
        <div className=" outline-dark dark border-[5px] border-[#7EC0C2]  w-full max-w-[500px]">
        <div className="mask2">
       <img src="/assets/imgs/mask2.png"   alt="heroImage"  style={{height:"620px" , width:"540px"}}/>
       </div>
        <div className="formmask">
       <img src="/assets/imgs/Mask Group.png"   alt="heroImage"  style={{height:"620px", width:"589px" }}/>
       </div>
          <form  >
    
       
            <div className="p-10 flex flex-col" >

         
              <label className="text-[#A92D62] mt-4 contactlabel"> {intl.formatMessage({ id: 'home.contactName' })} *</label>
              {/* <input
                  className="input1"
                  name="name"

                  value={formValues.name}
                  onChange={handleChange}
                /> */}
              <input
                className="border-[2px] border-[#81C4C6] input py-2"
                value={formValues.name}
                name="name"
                onChange={handleChange}
                
                placeholder="please type your name"
              />
               {formValues.name&&<span className="input-feedback">{nameFeedback}</span>}
              {/* <p style={{ color: "red" }} >{formErrors.name}</p> */}

              
              <label className="text-[#A92D62]  mt-4 contactlabel"> {intl.formatMessage({ id: 'home.email' })} *</label>
              <input className="border-[2px] border-[#81C4C6]  input py-2"
                type="text"
                name="email"

                placeholder="please type your email"

                value={formValues.email}
                onChange={handleChange}
              />

               {formValues.email&&<span className="input-feedback">{emailFeedback}</span>}


              <label className="text-[#A92D62] mt-4 contactlabel">{intl.formatMessage({ id: 'home.message' })} *</label>
              <textarea type="text" name="message" 
                rows={4}  placeholder="please type your message"
                className="border-[2px] border-[#81C4C6] input  py-2"
                value={formValues.message}
                onChange={handleChange}
              />


              {formValues.message&&<span className="input-feedback">{messageFeedback}</span>}

              {/* <label className="text-[#048A8F] mt-3">{intl.formatMessage({ id: 'home.browse' })}</label> */}

           {/*    <input
                type="file" name="attachfile" value={formValues.attachfile}
                onChange={handleChange}
                className="bg-[#F9B035] text-white rounded-md px-4 py-2 w-fit mt-1"

              /> */}
           {/*  <div className="image-upload">
              <label htmlFor="file-input">
              <div  className="bg-[#A92D62] text-white rounded-[40px] px-4 py-2 w-fit mt-1" style={{width: "130px",height: "40px",cursor:"pointer"}} >
                {intl.formatMessage({ id: 'home.browse' })}
              </div>
              </label>
            

              <input id="file-input" type="file" name="attachfile"  onChange={handleChange} value={formValues.attachfile} /><br/>
              {formValues.attachfile&&<span className="input-feedback">Uploaded File{uploadedName}</span>}
            </div> */}
            {/* <input type="file" name="attachfile" onChange={handleChange} value={formValues.attachfile} 
                style={{ display: "none" }}/>
                <button  className="bg-[#A92D62] text-white rounded-[40px] px-4 py-2 w-fit mt-1" 
                style={{width: "130px",height: "40px"}} >{intl.formatMessage({ id: 'home.browse' })}</button> */}



            </div>
            <button
              /*  onClick={() => {
                 window.location = "mailto:manish@ogoul.com";
               }} */
              onClick={() => sendMail()}
              disabled={!formValid}
              className="bg-[#048A8F]  text-white py-3 w-full contactbtn"
            >
              {intl.formatMessage({ id: 'home.submit' })}   <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          
          </form>
        
        </div>
       
       
      </div>

 
     
      <div className="flex flex-1 p-4 flex-row justify-center">
        <div className="">
          <img src="/assets/imgs/contactus.png" alt="heroImage" />
        </div>
      </div>

      
           
        <CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
    
    </section>

}
{ ! isTabletOrMobile &&
 <div className="flex flex-row contactdots mb-4" >
          
          <img src="/assets/imgs/contactdots.png"   />
                   
                   </div>
}
 <br></br>
    

  </div>
              

  
  {  isTabletOrMobile &&
            
            <div class="grid-container -mt-20 py-10 px-2 mx-2 contactusform">
           
<div class="grid-child ">


    
    <div className="dark border-[5px] border-[#7EC0C2]  w-full max-w-[400px]">
    
    
    <div className="mask2">
   <img src="/assets/imgs/mask2.png"   alt="heroImage"  style={{height:"370px",  }}/>
   </div>
    <div className="formmask">
   <img src="/assets/imgs/Mask Group.png"   alt="heroImage"  style={{height:"370px",  }}/>
   </div>
      <form  >

   
        <div className="p-4 flex flex-col" >

     
          <label className="text-[#A92D62] mt-4 contactlabel"> {intl.formatMessage({ id: 'home.contactName' })} *</label>
          {/* <input
              className="input1"
              name="name"

              value={formValues.name}
              onChange={handleChange}
            /> */}
          <input
            className="border-[2px] border-[#81C4C6] input py-2"
            value={formValues.name}
            name="name"
            onChange={handleChange}
            
            placeholder="please type your name"
          />
           {formValues.name&&<span className="input-feedback">{nameFeedback}</span>}
          {/* <p style={{ color: "red" }} >{formErrors.name}</p> */}

          
          <label className="text-[#A92D62]  mt-4 contactlabel"> {intl.formatMessage({ id: 'home.email' })} *</label>
          <input className="border-[2px] border-[#81C4C6]  input py-2"
            type="text"
            name="email"

            placeholder="please type your email"

            value={formValues.email}
            onChange={handleChange}
          />

           {formValues.email&&<span className="input-feedback">{emailFeedback}</span>}


          <label className="text-[#A92D62] mt-4 contactlabel">{intl.formatMessage({ id: 'home.message' })} *</label>
          <textarea type="text" name="message" 
            rows={5}  placeholder="please type your message"
            className="border-[2px] border-[#81C4C6]  "
            value={formValues.message}
            onChange={handleChange}
          />


          {formValues.message&&<span className="input-feedback">{messageFeedback}</span>}

          {/* <label className="text-[#048A8F] mt-3">{intl.formatMessage({ id: 'home.browse' })}</label> */}

       {/*    <input
            type="file" name="attachfile" value={formValues.attachfile}
            onChange={handleChange}
            className="bg-[#F9B035] text-white rounded-md px-4 py-2 w-fit mt-1"

          /> */}
       {/*  <div className="image-upload">
          <label htmlFor="file-input">
          <div  className="bg-[#A92D62] text-white rounded-[40px] px-4 py-2 w-fit mt-1" style={{width: "130px",height: "40px",cursor:"pointer"}} >
            {intl.formatMessage({ id: 'home.browse' })}
          </div>
          </label>
        

          <input id="file-input" type="file" name="attachfile"  onChange={handleChange} value={formValues.attachfile} /><br/>
          {formValues.attachfile&&<span className="input-feedback">Uploaded File{uploadedName}</span>}
        </div> */}
        {/* <input type="file" name="attachfile" onChange={handleChange} value={formValues.attachfile} 
            style={{ display: "none" }}/>
            <button  className="bg-[#A92D62] text-white rounded-[40px] px-4 py-2 w-fit mt-1" 
            style={{width: "130px",height: "40px"}} >{intl.formatMessage({ id: 'home.browse' })}</button> */}



        </div>
       
      
      </form>
  
    </div>
    <button
          /*  onClick={() => {
             window.location = "mailto:manish@ogoul.com";
           }} */
          onClick={() => sendMail()}
          disabled={!formValid}
          className="bg-[#048A8F]  text-white py-3 w-full contactbtn"
        >
          {intl.formatMessage({ id: 'home.submit' })}   <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
    <div className="flex flex-row contactdots mb-4" >
          
          <img src="/assets/imgs/contactdots.png"   />
                   
                   </div>
  
               
</div>

<div class="grid-child ">
   
<div className="mx-auto">
        <div className="heroimg">
          <img src="/assets/imgs/contactus.png" alt="heroImage" />
        </div>
      </div>


      

</div>
     
<CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
</div>

}
    <Footer />
  </>
  )}</InjectIntl>
);

};


const CreateModal = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};





export default Contact;
