import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import "./UserPlans.scss";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import ModalInput from "../../components/Modal/ModalInput";
import Modal from "../../components/Modal/Modal";
import ModalButton from "../../components/Modal/ModalButton";
import ModalSelect from "../../components/Modal/ModalSelect";
import ReactSelect from "react-select";
import ModalAlert from "../../components/Modal/ModalAlert";
import { FormattedMessage } from 'react-intl';
import InjectIntl from 'react-intl-inject';
import {
    Col,
    Container,
    Nav,
    NavDropdown,
    NavLink,
    Row,
    Card,
    Form,
    Button,
} from "react-bootstrap";


export default function AdminConfig(props) {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(userContext);
    const [adminDetails, setAdminDetails] = useState({
        clientID: "",
    });
    useEffect(() => {
        axios
            .get(`${API_BASE_URL}/auth/getAdminConfig`,{
                headers: {
                  Authorization: `Bearer ${state.token}`,
                },
              })
            .then((res) => {
                console.log(res)
                if (res) setAdminDetails(res?.data?.admin);
            })
            .catch((err) => console.log("err", err));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // get our new errors
        axios
            .patch(`${API_BASE_URL}/auth/editAdminConig`, adminDetails,{
                headers: {
                  Authorization: `Bearer ${state.token}`,
                },
              })
            .then((res) => {
                if (res.status === 200) {
                    alert("Admin Config Updated ");
                } else {
                    alert("Admin Config Update Failed ");
                }
            })
            .catch((err) => console.log("err", err));
    };
    return (
        <InjectIntl>
            {({ intl }) => (
                <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
                    <Navbar setLanguage={props.setLanguage} language={props.language} />

                    <div className="flex flex-row">
                        <AdminSidebar />

                        <div className="w-full flex-1 p-4">

                            <Container>
                                <h2 variant="h4" sx={{ mb: 5 }}>
                                    <strong> Paypal Configuration</strong>
                                </h2>
                                <Row xs="12" md="12">
                                    <Col>
                                        <Card className="mb-12 rounded-3 shadow-sm">
                                            <Card.Body>
                                                <Form>
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>PayPal Client ID</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={(ev) =>
                                                                        setAdminDetails({
                                                                            clientID: ev.target.value,
                                                                        })
                                                                    }
                                                                    value={adminDetails && adminDetails.clientID}
                                                                />

                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>

                            <Container>
                                <Row className="justify-content-center">
                                    <Col className="text-start">
                                        <p className="pricing-footertext"></p>

                                        <Col xs={12} md={2}>

                                            <button
                                                onClick={(e) => handleSubmit(e)}
                                                className="bg-[#A92D62] text-white px-2 py-1 rounded-sm flex flex-row items-center"
                                            >
                                                {/* <MdAddChart size={24} className="mr-4" /> */}
                                                <span>Update</span>
                                            </button>
                                        </Col>
                                        <br />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>


                </div>
            )}</InjectIntl>
    );
}




