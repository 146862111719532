import React from "react";

const TH = ({ className, children }) => {
  return (
    <th
      className={`font-semibold py-1 px-1 bg-[#048A8F] text-white ${className}`}
    >
      {children}
    </th>
  );
};

export default TH;
