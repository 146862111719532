import React from "react";

const Arrow = ({ size }) => {
  return (
    <svg
      width={size}
      height={(size * 12) / 14}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.77271 0.0902898 5.55473 0.251006 5.39402C0.411723 5.2333 0.629701 5.14301 0.856988 5.14301H10.7861L7.10615 1.46482C6.94523 1.3039 6.85483 1.08565 6.85483 0.858071C6.85483 0.630497 6.94523 0.412243 7.10615 0.251323C7.26707 0.0904038 7.48532 0 7.7129 0C7.94047 0 8.15872 0.0904038 8.31964 0.251323L13.4616 5.39325C13.5414 5.47286 13.6047 5.56743 13.6479 5.67154C13.6911 5.77566 13.7133 5.88728 13.7133 6C13.7133 6.11272 13.6911 6.22434 13.6479 6.32845C13.6047 6.43257 13.5414 6.52714 13.4616 6.60675L8.31964 11.7487C8.15872 11.9096 7.94047 12 7.7129 12C7.48532 12 7.26707 11.9096 7.10615 11.7487C6.94523 11.5878 6.85483 11.3695 6.85483 11.1419C6.85483 10.9144 6.94523 10.6961 7.10615 10.5352L10.7861 6.85699H0.856988C0.629701 6.85699 0.411723 6.7667 0.251006 6.60598C0.0902898 6.44527 0 6.22729 0 6Z"
        fill="#0D59EF"
      />
    </svg>
  );
};

export default Arrow;
