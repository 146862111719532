



const getFileSize =(bytes, decimals = 2) =>{
  if (bytes === 0) return '0 MB';
  if (bytes === -1) return 'Unlimited';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const fileSize = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if(i == 0)
  return ((bytes / (1024)).toFixed(3)) + ' ' + fileSize[2];
  else if( i == 1)
  return ((bytes / (1024*1024)).toFixed(3)) + ' ' + fileSize[2];
  else
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + fileSize[i];
}

export default getFileSize;
