import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import "./UsersList.scss";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import { BiUserPlus } from "react-icons/bi";
import Modal from "../../components/Modal/Modal";
import ModalButton from "../../components/Modal/ModalButton";
import ModalInput from "../../components/Modal/ModalInput";
import ModalSelect from "../../components/Modal/ModalSelect";
import TH from "../../components/Table/TH";
import TD from "../../components/Table/TD";
import { DateRangePicker } from "rsuite";
/* import Pagination from "../../components/Pagination/Pagination"; */
import Pagination from "../../components/NewPagination/Pagination";
import { validatePasswordNew, validateEmail } from "../../utils/validate";
import Table from 'react-bootstrap/Table';
import { FormattedMessage } from 'react-intl';
import ModalAlert from "../../components/Modal/ModalAlert";
import InjectIntl from 'react-intl-inject';
import Form from 'react-bootstrap/Form';

const roleTypes = [
  { label: "All", value: "null" },
  { label: "User", value: "admin" },
  { label: "Admin", value: "user" },
];






const sortTypes = [
  { label: "Latest", value: { key: "createdAt", dir: 1 } },
  { label: "Oldest", value: { key: "createdAt", dir: -1 } },
  { label: "Source Language (A-Z)", value: { key: "sourceLanguage", dir: -1 } },
  { label: "Source Language (Z-A)", value: { key: "sourceLanguage", dir: 1 } },
  { label: "Target Language (A-Z)", value: { key: "targetLanguage", dir: 1 } },
  { label: "Target Language (Z-A)", value: { key: "targetLanguage", dir: -1 } },
  { label: "Access Type (A-Z)", value: { key: "mode", dir: 1 } },
  { label: "Access Type (Z-A)", value: { key: "mode", dir: -1 } },
];
const headerContent = [
  "#",
  <FormattedMessage id="home.registeredOn"></FormattedMessage>,
  <FormattedMessage id="home.contactName"></FormattedMessage>,
  <FormattedMessage id="home.Email"></FormattedMessage>,
  <FormattedMessage id="home.Email"></FormattedMessage>,
  <FormattedMessage id="home.planID"></FormattedMessage>,
  <FormattedMessage id="home.actions"></FormattedMessage>,
];


export default function UsersList(props) {

/*   const [allUsers, setAllUsers] = useState(0); */
  const [totalallUsers, setTotalallUsers] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState("Required");
  const [nameFeedback, setNameFeedback] = useState("Min. 3 characters");
  const [emailFeedback, setEmailFeedback] = useState("Min. 8 characters");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const data1 = usersToDisplay.slice(10, 100);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [currentDeleteUserId, setCurrentDeleteUserId] = useState("");
  const [currentEditUserId, setCurrentEditUserId] = useState("");
  const [currentEditUser, setCurrentEditUser] = useState({});
  const [newUserData, setNewUserData] = useState({});
  const [formValid, setFormValid] = useState(false);
  const { state } = useContext(userContext);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const { afterToday } = DateRangePicker;
  const [dateRange, setDateRange] = useState(null);

  const [roleType, setRoleType] = useState(-1);
  const [sortBy, setSortBy] = useState(sortTypes[0].value);
  const [SearchTerm, setSearchTerm] = useState("");
const [show, setShow] = useState(false);
const [title,setTitle]=useState('')

const [currentpage, setCurrentPage] = useState(1);
const [selectedFilters, setSelectedFilters] = useState({});
const [selectedSort, setSelectedSort] = useState({ "createdAt": -1 });
const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    
    axios
      .get(`${API_BASE_URL}/plan/`)
      .then((res) => {
        setAvailablePlans(res.data.plans);
        setNewUserData({ ...newUserData, planId: res.data.plans[0].planId ,role:'user'});
        setCurrentEditUser({
          ...currentEditUser,
          planId: res.data.plans[0].planId,
          role:'user'
        });
      })
      .catch((err) =>{
        setTitle(<FormattedMessage id="home.alertGetPlan"></FormattedMessage>)
        setShow(true)
      });
  }, []);

  useEffect(() => {
    if(!nameFeedback && !emailFeedback && !passwordFeedback)
    setFormValid(true)
    else
    setFormValid(false)

  },[nameFeedback,emailFeedback,passwordFeedback])

  
 /*  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        setTotalTranslations(res.data.users);
        setFilteredUsers(res.data.users);
      })
    
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertGetUser"></FormattedMessage>)
				setShow(true)
			  });
  }, [shouldUpdate]); */



  const handleSelectUser = (user) => {
    currentEditUser.name = user.name;
    currentEditUser.role = user.role;
    currentEditUser.planId = user.planId;
    setCurrentEditUserId(user._id);
    setCurrentEditUser({ ...currentEditUser });
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentEditUser({ ...currentEditUser, [name]: value });
  };


  const handleCreateInputChange = (event) => {
    
    const { name, value } = event.target;
   
    if(name == 'password' && !validatePasswordNew(value).isValid) 
    {
      const resultPassword = validatePasswordNew(value);
      setPasswordFeedback(resultPassword.message);
    }
    if(name == 'password' && validatePasswordNew(value).isValid) 
    setPasswordFeedback('');
    if(name == 'email' && !validateEmail(value).isValid) 
    {
      const resultPassword = validateEmail(value);
      setEmailFeedback(resultPassword.message);
    }
    if(name == 'email' && validateEmail(value).isValid) 
    setEmailFeedback("");
    if(name == 'name' && value.length <3) 
    setNameFeedback('Min 3 Characters');
    if(name == 'name' && value.length >3) 
    setNameFeedback('');
    
    setNewUserData({ ...newUserData, [name]: value });
  };

 



  const handleEditUser = () => {
    setEditModal(false);
    axios
      .patch(`${API_BASE_URL}/user/${currentEditUserId}`, currentEditUser, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then(() => setShouldUpdate(!shouldUpdate))
     
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertUpdateUser"></FormattedMessage>)
				setShow(true)
			  });

      
  };

  const handleCreateUser = () => {
    setCreateModal(false);
    axios
      .post(`${API_BASE_URL}/user/create`, newUserData, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((res) => {
        if(res.data.message == "Duplicate Email ID Found")
        alert("Duplicate Email ID Found")
        else
        setShouldUpdate(!shouldUpdate)
      })
   

      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertCreateUser"></FormattedMessage>)
				setShow(true)
			  });
  };

  const handleDeleteUser = () => {
    setDeleteModal(false);
    axios
      .delete(`${API_BASE_URL}/user/${currentDeleteUserId}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then(() => setShouldUpdate(!shouldUpdate))
     
      .catch((err) =>{
				setTitle(<FormattedMessage id="home.alertDeleteUser"></FormattedMessage>)
				setShow(true)
			  });

      
  };

  /* useEffect(() => {
    let filtered = allUsers;
    filtered = filtered.filter((f) => f.role !== roleType);
    const { key, dir } = sortBy;
    filtered = filtered.sort((a, b) => (a[key] < b[key] ? dir : -dir));
    if (dateRange) {
      let startDate = new Date(dateRange[0]);
      let endDate = new Date(dateRange[1]);

      filtered = filtered.filter(
        (f) =>
          new Date(f.createdAt) >= startDate && new Date(f.createdAt) < endDate
      );
    }
    setFilteredUsers(filtered);
  }, [roleType, allUsers, dateRange, sortBy]); */
  const loadNewPage = async () => {
    setCurrentPage(currentpage + 1)

  }
  const loadPreviousPage = async () => {
    setCurrentPage(currentpage - 1)

  }
  const loadNewPaginationSet = (value) => {
    setRowsPerPage(value)
  }
  const filterByAccessType = async (value) => {
    if (value && value != "-1") {
      setSelectedFilters({ ...selectedFilters, mode: value })
    }
    else {
      const newItems = { ...selectedFilters };
      delete newItems.mode;
      setSelectedFilters(newItems);
    }
  }


  const sortByFn = async (value) => {
    let obj = JSON.parse(value);
    if (obj) {
      let key = obj.key;
      let dir = obj.dir
      if (key == "sourceLanguage") setSelectedSort({ "sourceLanguage": dir })
      else if (key == "targetLanguage") setSelectedSort({ "targetLanguage": dir })
      else if (key == "mode") setSelectedSort({ "mode": dir })
      else if (key == "createdAt") setSelectedSort({ "createdAt": dir })
    }
    else {
      const newItems = { ...selectedSort };
      delete newItems.sort;
      setSelectedSort(newItems);
    }


  }

  const filterByDate = async (value) => {
    console.log("value", value);
    if (value) {
      setSelectedFilters({
        ...selectedFilters, createdAt: {
          $gte: value[0],
          $lte: value[1]
        }
      })
    }
    else {
      const newItems = { ...selectedFilters };
      delete newItems.createdAt;
      setSelectedFilters(newItems);
    }
  }

  useEffect(async () => {
    const { data } = await axios.post(
      `${API_BASE_URL}/user/`,
      { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    );
    
    console.log("dataaaaaaaa",data)
  setTotalallUsers(data.results.count);  

 
 setAllUsers(data.results.data);
   
  }, [selectedFilters, selectedSort, currentpage, rowsPerPage])

 
  
  return (
    <InjectIntl>
    {({ intl }) => (
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>

      <div className="flex flex-row">
        <AdminSidebar />

        <div className="w-full flex-1 p-4">
          <div className="w-full">
            <div className="font-semibold text-[24px] mb-2">{intl.formatMessage({ id: 'home.users' })}</div>
            <button
              onClick={() => setCreateModal(true)}
              className="bg-[#A92D62] text-white px-2 py-1 rounded-sm flex flex-row items-center"
            >
              <BiUserPlus size={24} className="mr-4" />
              <span>{intl.formatMessage({ id: 'home.addUsers' })}</span>
            </button>
            <CreateModal
              intl={intl}
              handleCreateInputChange={handleCreateInputChange}
              handleCreateUser={handleCreateUser}
              availablePlans={availablePlans}
              show={createModal}
              passwordFeedback={passwordFeedback}
              emailFeedback={emailFeedback}
              newUserData={newUserData}
              nameFeedback={nameFeedback}
              formValid={formValid}
              onHide={() => setCreateModal(false)}
            />
          </div>
          <div className="px-1">
            <div className="font-semibold">{intl.formatMessage({ id: 'home.filters' })}</div>
            <div className="flex flex-row gap-2 flex-wrap py-2">
              <div>
                <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.dataRange' })}</div>
                <DateRangePicker
                  format="yyyy-MM-dd HH:mm:ss"
                  disabledDate={afterToday()}
                  value={dateRange}
                  className="mt-1"
                  onChange={(e) => { setDateRange(e); filterByDate(e) }}
                />
              </div>

              <div>
                <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.access' })}</div>
                <select
                  value={roleType}
                  onChange={(e) =>{ filterByAccessType(e.target.value); setRoleType(e.target.value)}}
                  className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                >
                  {roleTypes.map((a ,index) => (
                    <option key={index} value={a.value}>{a.label}</option>
                  ))}
                </select>

             
              </div>
             
            
              <div>
                <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.sort' })}</div>
                <select
                  value={JSON.stringify(sortBy)}
                  onChange={(e) => { sortByFn(e.target.value); setSortBy(JSON.parse(e.target.value)) }}
                  className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
                >
                  {sortTypes.map((a,index) => (
                    <option  key={index} value={JSON.stringify(a.value)}>{a.label}</option>
                  ))}
                </select>
              </div>
           
 {/*   <div className="text-xs text-zinc-500"></div>  */}
              <input   className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"  type="text" 
              placeholder="Search for User Name"  required onChange={(event) =>{
                                      setSearchTerm(event.target.value)}}/>
            </div>
          
          </div>
          <Table responsive>
            <thead className="bg-[#048A8F]">
              <tr>
                {headerContent.map((item, index) => (
                  <TH
                    className={` ${index === 0 ? "rounded-l-md pl-2" : ""} ${
                      index === headerContent.length - 1
                        ? "rounded-r-md pr-2"
                        : ""
                    }`}
                    key={index}
                  >
                    {item}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {allUsers.filter((user, index) =>{
                if(SearchTerm == ""){
                  return user
                }

                else if (user.name.toLowerCase().includes(SearchTerm.toLowerCase())){
                  return user
                }
              })
               .map((user, index) => (
                <tr key={index}>
                  <TD className="pl-2">{index + 1}</TD>
                  <TD>{new Date(user.createdAt).toLocaleString()}</TD>
                  <TD>{user.name}</TD>
                  <TD>{user.email}</TD>
                  <TD>{user.role}</TD>
                  <TD>{user.role != 'admin'&& user.planId}</TD>
                  <TD>
                    <i
                      className="fa fa-solid fa-edit userslist__table__action"
                      onClick={() => {
                        handleSelectUser(user);
                        setEditModal(true);
                      }}
                    ></i>
                    <EditModal
                    intl={intl}
                      handleEditInputChange={handleEditInputChange}
                      handleEditUser={handleEditUser}
                      availablePlans={availablePlans}
                      currentEditUser={currentEditUser}
                      show={editModal}
                      onHide={() => setEditModal(false)}
                    />
                    <i
                      className="fa fa-solid fa-trash userslist__table__action"
                      onClick={() => {
                        setCurrentDeleteUserId(user._id);
                        setDeleteModal(true);
                      }}
                    ></i>
                    <DeleteModal
                      handleDeleteUser={handleDeleteUser}
                      show={deleteModal}
                      onHide={() => setDeleteModal(false)}
                    />
                  </TD>
                </tr>
              ))}
            </tbody>
            {
                      allUsers.length == 0 &&
                      <div >Records not found</div>
                    }
          </Table>
{/* 
      <Pagination
            data={filteredUsers}
            updateData={(data) => setUsersToDisplay(data)}
          />
 */}

 <Pagination
                  totalTranslations={totalallUsers}
                  rowsPerPage={rowsPerPage}
                  currentpage={currentpage}
                  data={allUsers}
                  setRowsPerPage={setRowsPerPage}
                  loadNewPage={loadNewPage}
                  loadPreviousPage={loadPreviousPage}
                  loadNewPaginationSet={loadNewPaginationSet}
                  setCurrentPage={setCurrentPage}
                /> 
        </div>
      </div>
      <CreateModall
              show={show}
              title={title}
              setShow={setShow}
            />
    </div>
    )}</InjectIntl>
  );
}

const CreateModal = ({
  intl,
  handleCreateInputChange,
  handleCreateUser,
  availablePlans,
  show,
  onHide,
  passwordFeedback,
  emailFeedback,
  nameFeedback,
  newUserData,
  formValid
}) => {
  return (
    <Modal
      show={show}
      title={intl.formatMessage({ id: 'home.createNewUser' })}
      footer={() => <ModalButton text={intl.formatMessage({ id: 'home.close' })} onClick={onHide} />}
      body={() => (
        <div>
          <div className="grid grid-cols-3 gap-1">
            <ModalInput
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: 'home.contactName' })}
              onChange={handleCreateInputChange}
              nameFeedback={nameFeedback}
            />
            <ModalInput
              type="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'home.Email' })}
              onChange={handleCreateInputChange}
              emailFeedback={emailFeedback}
            />
            <ModalInput
              type="password"
              name="password"
              placeholder={intl.formatMessage({ id: 'home.password' })}
              minLength={8}
              onChange={handleCreateInputChange}
              passwordFeedback={passwordFeedback}
            />
            <ModalSelect
              label={intl.formatMessage({ id: 'home.role' })}
              name="role"
              onChange={handleCreateInputChange}
            >
              {/* <option value="">Choose Role...</option> */}
              <option value="user">user</option>
              <option value="admin">admin</option>
            </ModalSelect>
            {
              newUserData.role != 'admin' &&
              <ModalSelect
              label={intl.formatMessage({ id: 'home.planID' })}
              name="planId"
              onChange={handleCreateInputChange}
            >
              {/* <option value="">Choose plan...</option> */}
              {availablePlans.map((plan, i) => (
                <option key={i} value={plan.planId}>
                  {plan.name}
                </option>
              ))}
            </ModalSelect>
            }
            
          </div>
          <ModalButton onClick={handleCreateUser} type="blue" text={intl.formatMessage({ id: 'home.save' })} disabled={!formValid}/>
        </div>
      )}
    />
  );
};

const EditModal = ({
  intl,
  handleEditInputChange,
  handleEditUser,
  availablePlans,
  currentEditUser,
  show,
  onHide,
}) => {
  return (
    <Modal
      show={show}
      title="Edit User"
      footer={() => <ModalButton text={intl.formatMessage({ id: 'home.close' })} onClick={onHide} />}
      body={() => (
        <div>
          <div className="grid grid-cols-3 gap-1">
            <ModalInput
              defaultValue={currentEditUser.name}
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: 'home.contactName' })}
              onChange={handleEditInputChange}
            />
            <ModalSelect
              value={currentEditUser.role}
              label={intl.formatMessage({ id: 'home.role' })}
              name="role"
              onChange={handleEditInputChange}
            >
              <option value="user">user</option>
              <option value="admin">admin</option>
            </ModalSelect>
            {
              currentEditUser.role != "admin" &&
              <ModalSelect
              value={currentEditUser.planId}
              name="planId"
              label={intl.formatMessage({ id: 'home.planID' })}
              onChange={handleEditInputChange}
            >
              {availablePlans.map((plan, i) => (
                <option
                  className="appearance-none p-2"
                  key={i}
                  value={plan.planId}
                >
                  {plan.name}
                </option>
              ))}
            </ModalSelect>
            }
            
          </div>
          <ModalButton onClick={handleEditUser} type="blue" text={intl.formatMessage({ id: 'home.save' })}/>
        </div>
      )}
    />
  );
};

const DeleteModal = ({ show, onHide, handleDeleteUser }) => {
  return (
    <Modal
      show={show}
      body={null}
      title="Are you sure you want to delete this user?"
      footer={() => (
        <>
          <ModalButton text="Close" onClick={onHide} />
          <ModalButton
            text="Delete User"
            type="red"
            onClick={handleDeleteUser}
          />
        </>
      )}
    />
  );
};


	
const CreateModall = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};
