



import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import AdminSidebar from "../../components/Sidebar/AdminSidebar";
import { userContext } from "../../store/context/user";
import { API_BASE_URL } from "../../utils/globals";
/* import Pagination from "../../components/Pagination/Pagination"; */
import TH from "../../components/Table/TH";
import TD from "../../components/Table/TD";
import { DateRangePicker } from "rsuite";
import Table from 'react-bootstrap/Table';
import FadeLoader from "react-spinners/FadeLoader";
import InjectIntl from 'react-intl-inject';
import Pagination from "../../components/NewPagination/Pagination";
const users = [
  { label: "All", value: -1 },
  

];
const sortTypes = [
{ label: "Latest First", value: { key: "createdAt", dir: -1 } },
{ label: "Oldest First", value: { key: "createdAt", dir: 1 } },
{ label: "User Id (A-Z)", value: { key: "userId", dir: -1 } },
{ label: "User Id (Z-A)", value: { key: "userId", dir: 1 } },
{ label: "Details (A-Z)", value: { key: "mode", dir: 1 } },
{ label: "Details (Z-A)", value: { key: "mode", dir: -1 } },
];



const Logs = (props) => {
const [allLogs, setAllLogs] = useState(0);
const [filteredLogs, setFilteredLogs] = useState([]);
const [dataToDisplay, setDataToDisplay] = useState([]);

const [users, setUsers] = useState([]);
const [user, setUser] = useState("All");
const [sortBy, setSortBy] = useState(sortTypes[0].value);

const { afterToday } = DateRangePicker;
const [dateRange, setDateRange] = useState(null);

const { state } = useContext(userContext);


const [currentpage, setCurrentPage] = useState(1);
const [selectedFilters, setSelectedFilters] = useState({});
const [selectedSort, setSelectedSort] = useState({ "createdAt": -1 });
const [rowsPerPage, setRowsPerPage] = useState(10);

let [loading, setLoading] = useState(true);
let [color, setColor] = useState("#048A8F");
const override = {
  display: "block",
  borderColor: "black",
};


const loadNewPage = async () => {
  setCurrentPage(currentpage + 1)

}
const loadPreviousPage = async () => {
  setCurrentPage(currentpage - 1)

}
const loadNewPaginationSet = (value) => {
  setRowsPerPage(value)
}
const filterByAccessType = async (value) => {
  if (value && value != "-1") {
    setSelectedFilters({ ...selectedFilters, mode: value })
  }
  else {
    const newItems = { ...selectedFilters };
    delete newItems.mode;
    setSelectedFilters(newItems);
  }
}


const sortByFn = async (value) => {
  let obj = JSON.parse(value);
  if (obj) {
    let key = obj.key;
    let dir = obj.dir
    if (key == "userId") setSelectedSort({ "userId": dir })
    else if (key == "userId") setSelectedSort({ "userId": dir })
    else if (key == "mode") setSelectedSort({ "mode": dir })
    else if (key == "createdAt") setSelectedSort({ "createdAt": dir })
  }
  else {
    const newItems = { ...selectedSort };
    delete newItems.sort;
    setSelectedSort(newItems);
  }


}

const filterByDate = async (value) => {
  console.log("value", value);
  if (value) {
    setSelectedFilters({
      ...selectedFilters, createdAt: {
        $gte: value[0],
        $lte: value[1]
      }
    })
  }
  else {
    const newItems = { ...selectedFilters };
    delete newItems.createdAt;
    setSelectedFilters(newItems);
  }
}

useEffect(async () => {
    const { data } = await axios.post(`${API_BASE_URL}/log/`, 
    { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
    
    {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    console.log("dataaaaaaaa",data)
    setAllLogs(data.results.count);
    setFilteredLogs(data.results.data);
    
    setUsers(
      allLogs.map((l) => l.userId).filter((a, b, c) => c.indexOf(a) === b)
    );
},[selectedFilters, selectedSort, currentpage, rowsPerPage])






/*  useEffect(() => {
  let filtered = allLogs;
  filtered = filtered.filter((f) => f.userId === user || user === "All");
  const { key, dir } = sortBy;
  filtered = filtered.sort((a, b) => (a[key] < b[key] ? dir : -dir));
  if (dateRange) {
    let startDate = new Date(dateRange[0]);
    let endDate = new Date(dateRange[1]);

    filtered = filtered.filter(
      (f) => new Date(f.logTime) >= startDate && new Date(f.logTime) < endDate
    );
  }
  setFilteredLogs(filtered);
}, [user, allLogs, dateRange, sortBy]); */
return (
  <InjectIntl>
  {({ intl }) => (
  <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (props.language == 'ar' || props.language == 'ur') ? 'rtl' : 'ltr' }}>
    <Navbar setLanguage={props.setLanguage}  language={props.language}/>

    <div className="flex flex-row">
      <AdminSidebar />

      <div className="w-full flex-1 p-4">
        <div className="w-full">
          <div className="font-semibold text-[24px] mb-2">{intl.formatMessage({ id: 'home.logs' })}</div>
        </div>

        <div className="px-1">
          <div className="font-semibold">{intl.formatMessage({ id: 'home.filters' })}</div>
          <div className="flex flex-row gap-2 flex-wrap py-2">
            <div>
              <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.dataRange' })}</div>
              <DateRangePicker
                format="yyyy-MM-dd HH:mm:ss"
                disabledDate={afterToday()}
                value={dateRange}
                className="mt-1"
                onChange={(e) => { setDateRange(e); filterByDate(e) }}
              />
            </div>

            <div>
              <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.access' })}</div>
              <select
                value={user}
              
                onChange={(e) =>{ filterByAccessType(e.target.value); setUser(e.target.value)}}
                className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
              >
                {["All", ...users].map((a, i) => (
                  <option key={i} value={a}>
                    {a}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="text-xs text-zinc-500">{intl.formatMessage({ id: 'home.sort' })}</div>
              <select
                value={JSON.stringify(sortBy)}
                onChange={(e) => { sortByFn(e.target.value); setSortBy(JSON.parse(e.target.value)) }}
                className="mt-1 py-[7px] px-2 outline-none border-zinc-200 border-[1px] rounded-md"
              >
                {sortTypes.map((a,index) => (
                  <option key={index} value={JSON.stringify(a.value)}>{a.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <Table responsive>
          <thead className="bg-[#048A8F]">
            <tr>
              {/* <TH className={`bg-zinc-100 rounded-l-md pl-2`}>#</TH> */}
              <TH className={`rounded-l-md pl-2`}>{intl.formatMessage({ id: 'home.time' })}</TH>
              <TH>{intl.formatMessage({ id: 'home.userId' })}</TH>
              <TH className={`rounded-r-md pr-2`}>{intl.formatMessage({ id: 'home.details' })}</TH>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log, index) => (
              <tr key={index}>
                {/* <TH className="pl-2">
                  {(page - 1) * rowsPerPage + (index + 1)}
                </TH> */}
                <TD>{new Date(log.logTime).toLocaleString()}</TD>
                <TD>{log.userId}</TD>
                <TD>{log.transactionOccured}</TD>
              </tr>
            ))}
          </tbody>
        </Table>
        {filteredLogs.length == 0 &&  <div className="sweet-loading">
                <FadeLoader color={color} loading={loading} cssOverride={override} size={50} />
              </div> }

       
{/* 
        <Pagination
          data={filteredLogs}
          updateData={(data) => setDataToDisplay(data)}
        />  */}

<Pagination
                totalTranslations={allLogs}
                rowsPerPage={rowsPerPage}
                currentpage={currentpage}
                data={filteredLogs}
                setRowsPerPage={setRowsPerPage}
                loadNewPage={loadNewPage}
                loadPreviousPage={loadPreviousPage}
                loadNewPaginationSet={loadNewPaginationSet}
                setCurrentPage={setCurrentPage}
              /> 
        
      </div>
    </div>
  </div>
   )}</InjectIntl>
);
};

export default Logs;
