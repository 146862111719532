import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import Footer from "../../components/Footer/Footer";
import OrdersAnimated from "../../svgIcons/OrdersAnimated";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";

const data = [
  {
    name: "Oct 2021",
    achieved: 4,
    target: 3,
  },
  {
    name: "Nov 2021",
    achieved: 5,
    target: 4,
  },
  {
    name: "Dec 2021",
    achieved: 4,
    target: 3,
  },
  {
    name: "Jan 2022",
    achieved: 6,
    target: 4,
  },
  {
    name: "Feb 2022",
    achieved: 7,
    target: 5,
  },
  {
    name: "Mar 2022",
    achieved: 2,
    target: 3,
  },
];

const Dashboard = () => {
  return (
    <div className="max-w-[1280px] mx-auto">
      {/* <div className="flex flex-row justify-between items-center w-full px-4">
        <div>
          <img
            src="/assets/imgs/homelogo.png"
            alt="logo"
            className="h-[60px]"
          />
        </div>
        <div className="flex flex-row items-center">
          <AiOutlineSearch size={24} className="mx-1" />
          <IoIosNotificationsOutline size={24} className="mx-1" />
          <div className="rounded-full h-8 w-8 mx-1 overflow-hidden">
            <img src="/assets/imgs/profile.png" alt="profile" />
          </div>
          <BiChevronDown size={24} className="mx-1" />
        </div>
      </div> */}

      <Navbar />

      <div className="flex flex-row">
        <Sidebar />

        <div className="px-6 py-2 grid grid-cols-3">
          <div className="flex flex-col col-span-3 md:col-span-2 lg:col-span-1  justify-between p-3 border-zinc-300 rounded-md border-[1px]  items-center m-2">
            <div className="w-full text-left font-semibold">Strength</div>
            <div>
              <div className="flex flex-row items-end">
                <div className="flex flex-col mx-2 items-center">
                  <div>1</div>
                  <div className="h-4 bg-[#FABE7A] w-12 rounded-t-md"></div>
                  <div>a</div>
                </div>
                <div className="flex flex-col mx-2 items-center">
                  <div>5</div>
                  <div className="h-16 bg-[#F6866A] w-12 rounded-t-md"></div>
                  <div>b</div>
                </div>
                <div className="flex flex-col mx-2 items-center">
                  <div>3</div>
                  <div className="h-12 bg-[#59E6F6] w-12 rounded-t-md"></div>
                  <div>c</div>
                </div>
                <div className="flex flex-col mx-2 items-center">
                  <div>10</div>
                  <div className="h-24 bg-[#7661E2] w-12 rounded-t-md"></div>
                  <div>d</div>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="flex flex-row flex-1 items-center">
                  <div className="bg-[#FABE7A] text-white text-xs w-5 h-5 text-center leading-5 rounded-sm m-1">
                    a
                  </div>
                  <div className="mx-2 text-sm whitespace-nowrap">
                    Text Here
                  </div>
                </div>
                <div className="flex flex-row flex-1 items-center">
                  <div className="bg-[#F6866A] text-white text-xs w-5 h-5 text-center leading-5 rounded-sm m-1">
                    b
                  </div>
                  <div className="mx-2 text-sm whitespace-nowrap">
                    Text Here
                  </div>
                </div>
                <div className="flex flex-row flex-1 items-center">
                  <div className="bg-[#59E6F6] text-white text-xs w-5 h-5 text-center leading-5 rounded-sm m-1">
                    c
                  </div>
                  <div className="mx-2 text-sm whitespace-nowrap">
                    Text Here
                  </div>
                </div>
                <div className="flex flex-row flex-1 items-center">
                  <div className="bg-[#7661E2] text-white text-xs w-5 h-5 text-center leading-5 rounded-sm m-1">
                    d
                  </div>
                  <div className="mx-2 text-sm whitespace-nowrap">
                    Text Here
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-3 md:col-span-2 lg:col-span-1 justify-between p-3 border-zinc-300 rounded-md border-[1px] items-stretch m-2">
            <div className="w-full flex flex-row justify-between items-center mb-10">
              <div className="font-semibold">Orders</div>
              <div className="text-zinc-500 text-xs">Aug 25-Sept 25</div>
            </div>
            <div className="flex flex-1 flex-row items-center justify-between">
              <div className="h-full flex flex-col justify-between">
                <div>
                  <div className="flex flex-row items-center">
                    <div className="h-3 w-3 rounded-full bg-[#6956E5] mr-1 text-sm"></div>
                    <div className="text-zinc-400">Pending</div>
                  </div>
                  <div className="font-semibold text-xl">254</div>
                </div>

                <div>
                  <div className="flex flex-row items-center">
                    <div className="h-3 w-3 rounded-full bg-[#FB896B] mr-1 text-sm"></div>
                    <div className="text-zinc-400">Active</div>
                  </div>
                  <div className="font-semibold text-xl">3000</div>
                </div>
                <div>
                  <div className="flex flex-row items-center">
                    <div className="h-3 w-3 rounded-full bg-[#F8C07F] mr-1 text-sm"></div>
                    <div className="text-zinc-400">Total</div>
                  </div>
                  <div className="font-semibold text-xl">3254</div>
                </div>
              </div>

              <div className="h-[200px] w-[200px] p-3">
                <OrdersAnimated />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-span-3 md:col-span-2 lg:col-span-1 p-2 items-stretch justify-between">
            <div className="bg-[#FFF0E6] h-[90px] flex flex-col justify-center items-center rounded-md">
              <div className="font-semibold text-xl">This Month</div>
              <div className="text-sm">Transaction</div>
            </div>
            <div className="bg-[#ECEAFE] h-[90px] flex flex-col justify-center items-center rounded-md">
              <div className="font-semibold text-xl">26</div>
              <div className="text-sm">Draft</div>
            </div>
            <div className="bg-[#E5F7FF] h-[90px] flex flex-col justify-center items-center rounded-md">
              <div className="font-semibold text-xl">20</div>
              <div className="text-sm">Pending</div>
            </div>
          </div>

          <div className="flex flex-col col-span-3 md:col-span-3 lg:col-span-2 shadow-md m-2 p-4 rounded-md">
            {/* Graph */}
            <div className="w-full flex flex-row justify-between items-center mb-2">
              <div className="font-semibold">Orders</div>
              <div className="text-zinc-500 text-xs">Aug 25-Sept 25</div>
            </div>
            <ResponsiveContainer width="90%" height="90%">
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                <YAxis axisLine={false} tickLine={false} />
                <Tooltip />
                {/* <Legend /> */}
                <Line
                  type="monotone"
                  dataKey="achieved"
                  stroke="#FB896B"
                  activeDot={{ r: 5 }}
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="target"
                  stroke="#6956E5"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="flex flex-col col-span-3 md:col-span-2 lg:col-span-1 p-2 items-stretch justify-between">
            <div className="w-full flex flex-row justify-between items-center mb-3">
              <div className="font-semibold">Orders</div>
              <div className="font-semibold text-[#6956E5]">View All</div>
            </div>

            <div>
              <div className="flex flex-row items-center bg-[#F9F9F9] rounded-md p-2 my-2">
                <div className="h-10 w-10 rounded-sm bg-[#6956E5]"></div>
                <div className="ml-2">
                  <div className="font-semibold">Order Name here</div>
                  <div className="text-[#708099] text-sm">
                    04 April, 2021 | 04:00 PM
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center bg-[#F9F9F9] rounded-md p-2 my-2">
                <div className="h-10 w-10 rounded-sm bg-[#6956E5]"></div>
                <div className="ml-2">
                  <div className="font-semibold">Order Name here</div>
                  <div className="text-[#708099] text-sm">
                    04 April, 2021 | 04:00 PM
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center bg-[#F9F9F9] rounded-md p-2 my-2">
                <div className="h-10 w-10 rounded-sm bg-[#6956E5]"></div>
                <div className="ml-2">
                  <div className="font-semibold">Order Name here</div>
                  <div className="text-[#708099] text-sm">
                    04 April, 2021 | 04:00 PM
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center bg-[#F9F9F9] rounded-md p-2 my-2">
                <div className="h-10 w-10 rounded-sm bg-[#6956E5]"></div>
                <div className="ml-2">
                  <div className="font-semibold">Order Name here</div>
                  <div className="text-[#708099] text-sm">
                    04 April, 2021 | 04:00 PM
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
