import { FormattedMessage } from 'react-intl';
export const validatePassword = (value,intl) => {
  if (value.length < 8) {
    return {
      isValid: false,
      message:intl.formatMessage({ id: 'home.min8chr' }),
    };
  } else {
    const regex = new RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/);
    return {
      isValid: regex.test(value),
      message: intl.formatMessage({ id: 'home.mustContain' }),
    };
  }
};
export const validatePasswordNew = (value,intl) => {
 
  if (value.length < 8) {
    return {
      isValid: false,
      message:<FormattedMessage id="home.min8chr"></FormattedMessage>,
    };
  } else {
    const regex = new RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/);
    return {
      isValid: regex.test(value),
      message: <FormattedMessage id="home.mustContain"></FormattedMessage>,
 
    };
  }
};

export const validateEmail = (value) => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if (value.trim() === "") {
    return { isValid: false, message:<FormattedMessage id="home.required"></FormattedMessage>};
  }
 
  return { isValid: regex.test(value), message:  <FormattedMessage id="home.validEmail"></FormattedMessage>};
};


