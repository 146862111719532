import React, { useContext, useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { userContext } from "../../store/context/user";
import axios from "axios";
import { API_BASE_URL } from "../../utils/globals";
import { MdAddChart, MdDelete,MdContentCopy } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import Modal from "../../components/Modal/Modal";
import ModalButton from "../../components/Modal/ModalButton";
import InjectIntl from 'react-intl-inject';
import { FormattedMessage } from 'react-intl';
import ModalAlert from "../../components/Modal/ModalAlert";
import Table from 'react-bootstrap/Table';
import "./App.css";
import Pagination from "../../components/NewPagination/Pagination";
const headerContent = [
  "#",
  <FormattedMessage id="home.contactName"></FormattedMessage>,
  <FormattedMessage id="home.key"></FormattedMessage>,
  <FormattedMessage id="home.copyKey"></FormattedMessage>,
  <FormattedMessage id="home.createdDate"></FormattedMessage>,
  <FormattedMessage id="home.actions"></FormattedMessage>,
];
const accessTypes = [
  { label: "All", value: -1 },
  { label: "Web", value: 0 },
  { label: "API Key", value: 1 },

];

const sortTypes = [
  { label: "Latest", value: { key: "createdAt", dir: -1 } },
  { label: "Oldest", value: { key: "createdAt", dir: 1 } },
  { label: "Source Language (A-Z)", value: { key: "sourceLanguage", dir: 1 } },
  { label: "Source Language (Z-A)", value: { key: "sourceLanguage", dir: -1 } },
  { label: "Target Language (A-Z)", value: { key: "targetLanguage", dir: 1 } },
  { label: "Target Language (Z-A)", value: { key: "targetLanguage", dir: -1 } },
  { label: "Access Type (A-Z)", value: { key: "mode", dir: 1 } },
  { label: "Access Type (Z-A)", value: { key: "mode", dir: -1 } },
];
const Apps = (props) => {
  const { state, dispatch } = useContext(userContext);
  const [languageSelected, setAppLanguage] = useState('en');
  const [newAppName, setNewAppName] = useState("");
  const [currentDeleteAppId, setCurrentDeleteAppId] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [appKeys,setAppKeys]= useState([]);
  const [showFilteredUser,setFilteredUsers]= useState([]);
  const [allTranslations, setAllTranslations] = useState([]);
  const [accessType, setAccessType] = useState(-1);
  const [sortBy, setSortBy] = useState(sortTypes[0].value);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSort, setSelectedSort] = useState({ "createdAt": -1 });
  const [dateRange, setDateRange] = useState(null);
  const [title,setTitle]=useState('')
  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalAppKeys, setTotalKeys] = useState(0);
  const [newUser, setNewUser] = useState(false);
  useEffect(() =>{
    // getAllApps();
  },[])
  const loadNewPage = async () => {
    setCurrentPage(currentpage + 1)

  }
  const loadPreviousPage = async () => {
    setCurrentPage(currentpage - 1)

  }
  const loadNewPaginationSet = (value) => {
    setRowsPerPage(value)
  }
  // const getAllApps = () =>{
  //   axios
  //   .post(
  //     `${API_BASE_URL}/app/${state.user.userId}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${state.token}`,
  //       },
  //     }
  //   )
  //   .then((response) => { 
  //     setTotalKeys(response?.data.results.count);
  //     setAppKeys(response?.data.results.data)
  //   })
  //   .catch((res) =>console.log(res));
  // }

  const handleCreateApp = async (props) => {
    console.log(newAppName.trim() === "")
    if (newAppName.trim() === "") {
      setTitle(<FormattedMessage id="home.alertAppnamezero"></FormattedMessage>)
      setShow(true)
      return;
    }
    const { data } = await axios.post(`${API_BASE_URL}/register`, {
      name: newAppName,
      planId: state.user.planId,
      userId: state.user.userId,
    });
    if (data)
      dispatch({
        type: "ADD_NEW_APP",
        payload: { name: data.name, appId: data.appId },
      });
    setShowInput(false);
    setNewUser(true);
  };

  const handleSelectItemToDelete = (appId) => {
    setDeleteModal(true);
    setCurrentDeleteAppId(appId);
  };

  const handleDeleteApp = () => {
    setDeleteModal(false);
    axios
      .delete(
        `${API_BASE_URL}/${currentDeleteAppId}?userId=${state.user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(()=>{
        dispatch({
          type: "DELETE_APP",
          payload: currentDeleteAppId,
        });
        setNewUser(true);
        
      }
      )
      .catch(() => {
        setTitle(<FormattedMessage id="home.alertAppDelete"></FormattedMessage>)
        setShow(true)
      });
  };
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])


  useEffect(async () => {
    axios
    .post(
      `${API_BASE_URL}/app/${state.user.userId}`,
      { page: currentpage, limit: rowsPerPage, planId: state.user.planId, filters: selectedFilters, sortBy: selectedSort },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    )
    .then((response) =>{
      console.log("res",response)
      setTotalKeys(response?.data.results.count);
      setAppKeys(response?.data.results.data)
    })
    .catch((res) =>console.log(res));
  }, [selectedFilters, selectedSort, currentpage, rowsPerPage,newUser])


  return (
    <InjectIntl>
      {({ intl }) => (
        <>
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
      <Navbar setLanguage={props.setLanguage}  language={props.language}/>
      <div className="flex flex-row items-start">
        <Sidebar languageSelected={languageSelected}/>

        <div className="w-full p-4">
        <div className="w-full">
            <div className="font-semibold text-[24px] text-[#048A8F] text-center mb-2 py-2"> List of APP Keys</div>
          </div>
          <div className="p-2 mb-2 bg-zinc-50 shadow-sm flex flex-row justify-between items-center" style={{marginTop: '50px'}}>
            <div className="font-semibold text-[24px] text-[#048A8F] mb-2">{intl.formatMessage({ id: 'home.apps' })}</div>
            {showInput ? (
              <div>
                <input
                  value={newAppName}
                  onChange={(e) => setNewAppName(e.target.value)}
                  placeholder="App Name"
                  className="text-white  bg-[#048A8F] px-2 py-1 outline-none" id="inputID"
                />
                <button
                  onClick={handleCreateApp}
                  className="bg-[#048A8F] text-white px-2 ml-1 py-1 mt-1"
                >
                  {intl.formatMessage({ id: 'home.create' })}
                </button>
                <button
                  onClick={() => setShowInput(false)}
                  className="bg-zinc-500 text-white px-2 ml-1 py-1"
                >
                  {intl.formatMessage({ id: 'home.cancel' })}
                </button>
              </div>
            ) : (
              <button
                onClick={() => setShowInput(true)}
                className="bg-[#048A8F] text-white px-2 py-1 rounded-sm flex flex-row items-center"
              >
                <AiOutlineAppstoreAdd size={24} className="mr-4" />
                <span> {intl.formatMessage({ id: 'home.addNew' })}</span>
              </button>
            )}
          </div>

          {appKeys.length === 0 ? (
            <div className="py-2">{intl.formatMessage({ id: 'home.noApps' })}</div>
          ) : (
            <div className="py-2">
            <Table responsive>
            <thead className="bg-[#048A8F] text-[#fff]">
              <tr>
                {headerContent.map((item, index) => (
                  <TH
                    className={` ${index === 0 ? "rounded-l-md pl-2" : ""
                      } ${index === headerContent.length - 1
                        ? "rounded-r-md pr-2"
                        : ""
                      }`}
                    key={index}
                  >
                    {item}
                  </TH>
                ))}
              </tr>
            </thead>
            <tbody>
              {appKeys.map((app, index) => (
                <tr key={index}>
                  <TH className="pl-2">{index + 1}</TH>
                  <TD><span className={app.name.length>16 ?"appNameEllipse":""}>{app.name}</span></TD>
                  <TD><span className={app.name.length>16 ?"appNameEllipse":""}>{app.appId}</span></TD>
                  <TD><MdContentCopy
                      size={20}
                      onClick={() =>  navigator.clipboard.writeText(app.appId)}
                      className="cursor-pointer"
                    /></TD>
                   <TD>{ new Date(app?.createdAt).toLocaleString()}</TD>
                   <TD><MdDelete
                      onClick={() => handleSelectItemToDelete(app.appId)}
                      size={20}
                      className="ursor-pointer"
                    /></TD>
                   
                  {/* <TD>{ new Date(file.createdAt).toLocaleString()}</TD>
                 
                  <TD>{file.sourceFileLanguage}</TD>
                  <TD>

                    
                  </TD>
                  <TD>{file.targetFileLanguage}</TD> */}
                  {/* <TD>

                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => downloadFile(file)}
                      className="text-decoration-underline"
                    >
                     {intl.formatMessage({ id: 'home.targetFile' })}
                    </a>

                  </TD> */}
                
                  {/* <TD>{getFileSize(file.fileSize)}</TD>
                  <TD>{getFileStatus(file.status)}</TD> */}
                </tr>
              ))}
            </tbody>
            </Table>
            <Pagination
                  totalTranslations={totalAppKeys}
                  rowsPerPage={rowsPerPage}
                  currentpage={currentpage}
                  data={appKeys}
                  setRowsPerPage={setRowsPerPage}
                  loadNewPage={loadNewPage}
                  loadPreviousPage={loadPreviousPage}
                  loadNewPaginationSet={loadNewPaginationSet}
                  setCurrentPage={setCurrentPage}
                />
            <DeleteModal
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                handleDeleteApp={handleDeleteApp}
              />
            </div>
          )}
        </div>
      </div>


      <CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
    </div>
    <Footer />
    </>
    )}</InjectIntl>
  );
};

const DeleteModal = ({ show, onHide, handleDeleteApp }) => {
  return (
    <Modal
      show={show}
      body={null}
      title="Are you sure you want to delete this API Key?"
      footer={() => (
        <>
          <ModalButton text="Close" onClick={onHide} />
          <ModalButton text="Delete" type="red" onClick={handleDeleteApp} />
        </>
      )}
    />
  );
};
const TH = ({ children, className }) => {
  return (
    <th className={`font-semibold text-sm py-1 px-1 ${className}`}>{children}</th>
  );
};

const TD = ({ children, className }) => {
  return (
    <td className={`text-zinc-600 text-sm px-1 ${className}`}>{children}</td>
  );
};
export default Apps;
const CreateModal = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};