import React from "react";

const ModalButton = ({ type, text, onClick,disabled }) => {
  var bgStyle = "bg-[#048A8F] hover:bg-gray-700";
  if (type === "blue") {
    bgStyle = "bg-[#A92D62] hover:bg-blue-700";
  } else if (type === "red") {
    bgStyle = "bg-red-500 hover:bg-red-800";
  }
  return (
    <button
      className={`${bgStyle} px-3 py-2 text-white rounded-sm`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default ModalButton;
