import React, { useContext, useEffect, useState } from "react";
import Dotts from "../../svgIcons/Dotss";
import InjectIntl from 'react-intl-inject';
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navbar/Navbar";
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import VideoPlayer from "react-video-js-player";
import { Link, useNavigate } from "react-router-dom";
import Logindots from "../../svgIcons/Logindots";
const faqs = [
  {

   
    question: <FormattedMessage id="home.faq1"></FormattedMessage>,
    answer:<FormattedMessage id="home.ans1"></FormattedMessage>,
    
  },
  {
   
    question: <FormattedMessage id="home.faq2"></FormattedMessage>,
 
    answer:<FormattedMessage id="home.ans2"></FormattedMessage>,
  },
  {
    question: <FormattedMessage id="home.faq3"></FormattedMessage>,
 
    answer:<FormattedMessage id="home.ans3"></FormattedMessage>,
  },
  /* {
    question: <FormattedMessage id="home.faq4"></FormattedMessage>,
 
    answer:<FormattedMessage id="home.ans4"></FormattedMessage>,
  },
  {
    question: <FormattedMessage id="home.faq5"></FormattedMessage>,
 
    answer:<FormattedMessage id="home.ans5"></FormattedMessage>,
  }, */
];
  const Faq = (props) => {
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const [faqIndex, setFaqIndex] = useState(0);
    const [languageSelected, setAppLanguage] = useState('en');
    return (

        <InjectIntl>
  {({ intl }) => (
  <>
        <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (props.language == 'ar' || props.language == 'ur')  ? 'rtl' : 'ltr' }}>
        <Navigation setLanguage={props.setLanguage} language={props.language} />

        {  isTabletOrMobile &&
            <section className="flex  px-2 items-center flex-col sm:flex-row py-10">
            <div class="grid-container">
           
<div class="grid-child ">
<div className="">
                    <div className="absolute right-0 top-90 faqdots">
                      <Dotts size={120} />
                    </div>
                 {/*    <div className="items-center justify-center">
                      <iframe allowtransparency="true" src="https://www.youtube.com/embed/E7wJTI-1dvQ" height="540px"> </iframe> </div> */}
                  </div>
                  <div className="items-center justify-center ">
                 <VideoPlayer
        controls={true}
        src="/assets/imgs/video.mp4"
        className="VideoPlayer"

      />
            </div> 
</div>

<div class="grid-child ">

<div className="row  justify-content-md-center">
                      <div className="col-md-7 col-sm-7  col-xs-7  ">
                        <p className="faq">   {intl.formatMessage({ id: 'home.faqtab' })} </p>
                        <p className="faqHeadding">   {intl.formatMessage({ id: 'home.faq' })}</p>
                      </div>
                      {!isTabletOrMobile &&
                      <div className="col-md-5 col-sm-5 col-xs-5 text-center ">
                        <img src="/assets/imgs/er.png" className="er text-center"
                        />
                      </div>
                         }
                    </div>
<div className="flex flex flex-col mt-2">
                      {faqs.map((faq, i) => (
                        <div
                          onClick={() => setFaqIndex(i)}
                          className="w-full flex flex-row justify-between px-1 border-zinc-200 mt-[-1px] hover:bg-[#FAFBFF] cursor-pointer"
                          key={i}>
                                    
                         {/*  <div className="rounded-full bg-[#048A8FA6] justify-between text-[#FFFFFF] text-[20px]  random" >{i + 1}
                          
                          
                          </div> */}


                          
                
                <div className="row  ">
                
                     <span className="rounded-full bg-[#048A8FA6] text-[#FFFFFF] py-[6px] mt-6 random" >{i + 1}
                          
                          
                          </span>
            
                     <div className="">
                               <div className=" ml-10 mr-10  text-left faqquestions ">{faq.question}
                              <div className="text-left faqans mt-2">{faq.answer}</div>
                         
                            </div> 
                            </div>  
                         
                         </div> 
                             
                          </div>
                 
                      ))}
                    </div>

</div>

</div>
</section> 
}

{ !isTabletOrMobile &&
            <section className="flex flex-row bg-[#ffffff] py-20"  id="faq">
              <div className="container-fluid">
                <div className="row flex  items-center  ">
                  <div className="col-md-6 col-sm-6 col-xs-6">
                    <div className="absolute -right-0 top-120">
                      <Dotts size={120} />
                    </div>
                    
                    <div className="items-center justify-center py-10">
                          
                 
                    {/*   <iframe allowtransparency="true" src="https://www.youtube.com/embed/E7wJTI-1dvQ" height="540px"> </iframe> */} 
                    <VideoPlayer
        controls={true}
        src="/assets/imgs/video.mp4"
        className="VideoPlayer"
     

      />

               <div className="absolute left-10  videodots">
        <Logindots size={110} />
      </div>      
                      </div>
             
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-6">
                    <div className="row  justify-content-md-center">
                      <div className="col-md-7 col-sm-7  col-xs-7  ">
                        <p className="faq">   {intl.formatMessage({ id: 'home.faqtab' })} </p>
                        <p className="faqHeadding">   {intl.formatMessage({ id: 'home.faq' })}</p>
                      </div>
                      {!isTabletOrMobile &&
                      <div className="col-md-5 col-sm-5 col-xs-5 text-center ">
                        <img src="/assets/imgs/er.png" className="er text-center"
                        />
                      </div>
                         }
                    </div>
                    <div className="flex flex flex-col  mt-4">
                      {faqs.map((faq, i) => (
                        <div
                          onClick={() => setFaqIndex(i)}
                          className="w-full flex flex-row justify-between px-3 border-zinc-200 mt-[-1px] hover:bg-[#FAFBFF] cursor-pointer"
                          key={i}>
                          <div className="rounded-full bg-[#048A8FA6] justify-between py-[20px] text-[#FFFFFF] text-[20px] mt-6 random" >{i + 1}</div>
                          <div className="flex-1 flex-row">
                            <div className="ml-10 mr-10 mt-4  text-left faqquestions ">{faq.question}
                              <div className=" ml-2 text-left faqans">{faq.answer}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </section>
}
     
<div className="font-white text-[16px] text-center sm:text-left w-full mt-4">
              <button
              onClick={()=>navigate('/register')}
                className="getquote ">
        
                {intl.formatMessage({ id: 'home.getstart' })}
                
              </button>
            </div>
      </div>
      <Footer language={props.language} />
    </>

      )}</InjectIntl>
        );
    
}

export default Faq
