import React from "react";

const Modal = ({ show, body, title, footer }) => {
  return (
    <div
    tabIndex="-1"
      aria-hidden="true"
      className={`${
        show ? "flex" : "hidden"
      } transition-all overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
    >
      <div className="relative p-4 w-full max-w-4xl h-full  py-20">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Header */}
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <div className="font-semibold text-xl">{title}</div>
          </div>

          {/* Body */}
          {body ? <div className="p-6 space-y-6">{body()}</div> : null}

          {/* Footer */}
          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            {footer()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
