import React from "react";

const ImageToText = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="10" fill="#A92D62" />
      <path
        d="M25.2549 24.0625C25.2549 25.0073 24.8795 25.9135 24.2115 26.5816C23.5434 27.2497 22.6372 27.625 21.6924 27.625C20.7475 27.625 19.8414 27.2497 19.1733 26.5816C18.5052 25.9135 18.1299 25.0073 18.1299 24.0625C18.1299 23.1177 18.5052 22.2115 19.1733 21.5434C19.8414 20.8753 20.7475 20.5 21.6924 20.5C22.6372 20.5 23.5434 20.8753 24.2115 21.5434C24.8795 22.2115 25.2549 23.1177 25.2549 24.0625Z"
        fill="white"
      />
      <path
        d="M15.7549 13.375C14.4951 13.375 13.2869 13.8754 12.3961 14.7662C11.5053 15.657 11.0049 16.8652 11.0049 18.125V41.875C11.0049 43.1348 11.5053 44.343 12.3961 45.2338C13.2869 46.1246 14.4951 46.625 15.7549 46.625H44.2549C45.5147 46.625 46.7228 46.1246 47.6136 45.2338C48.5044 44.343 49.0049 43.1348 49.0049 41.875V18.125C49.0049 16.8652 48.5044 15.657 47.6136 14.7662C46.7228 13.8754 45.5147 13.375 44.2549 13.375H15.7549ZM44.2549 15.75C44.8848 15.75 45.4889 16.0002 45.9343 16.4456C46.3797 16.891 46.6299 17.4951 46.6299 18.125V33.5625L37.6595 28.9384C37.4368 28.8268 37.1846 28.7881 36.9387 28.8277C36.6928 28.8674 36.4655 28.9834 36.2891 29.1593L27.4779 37.9705L21.1604 33.762C20.9323 33.6101 20.6587 33.5418 20.386 33.5687C20.1133 33.5955 19.8582 33.7158 19.6641 33.9093L13.3799 39.5V18.125C13.3799 17.4951 13.6301 16.891 14.0755 16.4456C14.5209 16.0002 15.125 15.75 15.7549 15.75H44.2549Z"
        fill="white"
      />
    </svg>
  );
};

export default ImageToText;
