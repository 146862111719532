import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/globals";
import { userContext } from "../../store/context/user";
import axios from "axios";
import ModalAlert from "../../components/Modal/ModalAlert";
import ModalButton from "../../components/Modal/ModalButton";
import { FormattedMessage } from 'react-intl';

export default function AdminLogin() {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const { state, dispatch } = useContext(userContext);
  const [show, setShow] = useState(false);
  const [title,setTitle]=useState('')
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isLoggedIn && state.isAdmin) {
      navigate("/admin/users");
    } else if (state.isLoggedIn) {
      navigate("/");
    }
  }, [navigate, state.isAdmin, state.isLoggedIn]);

  const handleInputChange = (event) => {
    userData[event.target.name] = event.target.value;
    setUserData({ ...userData });
  };

  const handleLogin = (event) => {
    event.preventDefault();
    axios
      .post(`${API_BASE_URL}/auth/admin/login`, userData)
      .then((response) => {
        dispatch({
          type: "ADMIN_LOGIN",
          payload: {
            user: response.data.admin,
            token: response.data.admin.token,
            characterCountBalance: 1e6,
            pdfCountBalance: 1e6,
            docCountBalance: 1e6,
            ocrCountBalance: 1e6,
          },
        });
        navigate("/");
      })
    

      .catch((err) => {
        setTitle(<FormattedMessage id="home.alertFaillogin"></FormattedMessage>)
        setShow(true)
      });
  };

  return (
    <div className="container my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="text-[24px] font-semibold text-[#048A8F]">Admin Login</div>
      <form
        className="my-5 w-50 d-flex flex-column align-items-center justify-content-center"
        onSubmit={handleLogin}
        autoComplete="off"
      >
        <input
          type="email"
          className="outline-none px-3 py-2 w-full border-[1px] border-zinc-300 my-2"
          placeholder="Email"
          name="email"
          onChange={handleInputChange}
          required
        />
        <input
          type="password"
          className="outline-none px-3 py-2 w-full border-[1px] border-zinc-300 my-2"
          placeholder="Password"
          name="password"
          onChange={handleInputChange}
          autoComplete="off"
          minLength="8"
          required
        />
        <input
          type="submit"
          className="bg-[#A92D62] text-white px-3 py-2 rounded-md"
          value="Login"
        />
      </form>
      <CreateModal
              show={show}
              title={title}
              setShow={setShow}
            />
        
    </div>
  );
}

const CreateModal = ({
  show,
  title,
  setShow
}) => {
  return (
    <ModalAlert
      show={show}
      title={title}
      footer={() => <ModalButton text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)} />}
      body={() => (
        <div>
        
          <ModalButton  type="blue" text={<FormattedMessage id="home.ok"></FormattedMessage>} onClick={()=>setShow(false)}  />
        </div>
      )}
    />
  );
};
