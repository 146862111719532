import React from "react";
import FaceBook from "../../svgIcons/FaceBook";
import Instagram from "../../svgIcons/Instagram";
import LinkedIn from "../../svgIcons/LinkedIn";
import Posh from "../../svgIcons/Posh";
import Twitter from "../../svgIcons/Twitter";
import "./Footer.scss";

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
export default function Footer(props) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <>
    <br></br> <br></br>
    <hr className="footerhr" />
    <section className="flex flex-col mx-auto ">
    <div className='container  pt-5'>
        <div className='row'>
    <div className='col-sm-5 col-md-5 col-lg-4 mb-4'>
    { !isTabletOrMobile &&
          <div className="mb-3 flex flex-col items-center md:items-start">
          <Link to="/">
       <img
         src="/assets/imgs/homelogo.png"
         alt="logo"
         className="h-[100px] px-3  footerlogo"
       />
     </Link>
          </div>
}

{ isTabletOrMobile &&
          <div className="mb-3 flex flex-col  ">
          <Link to="/">
       <img
         src="/assets/imgs/homelogo.png"
         alt="logo"
         className=" h-[100px] px-3  footerlogo"
        
       />
     </Link>
          </div>
}
          <div className="mx-4  footerHeading">
          <FormattedMessage id="home.footerHeading"></FormattedMessage> <br></br>

          <FormattedMessage id="home.footerHeading1"></FormattedMessage>
           
          </div>
        </div>
        
        <div className='col  px-10 mb-5'>
          <div className="text-sm text-[#042552] ">
            <div className="contactheading">  <FormattedMessage id="home.company"></FormattedMessage></div>
            {/* <div className="contacttext ">
            <Link
              to="/about" className="contacttext ">  <FormattedMessage id="home.aboutUs"></FormattedMessage> </Link>
            </div> */}
          
            {/* <div className="text-bg-70">Reviews</div>
            <div className="text-bg-70">Help Center</div>
            <div className="text-bg-70">For Translators</div> */}

        <div className="contacttext">
            <Link
              to="/solutions" className="contacttext" >  <FormattedMessage id="home.solutions"></FormattedMessage>  </Link>
            </div>
            <div className="contacttext">
            <Link
              to="/faq" className="contacttext">  <FormattedMessage id="home.faqtab"></FormattedMessage>  </Link>
            </div>
                <div className="contacttext">
            <Link
              to="/pricing" className="contacttext">  <FormattedMessage id="home.pricing"></FormattedMessage> </Link>
            </div>
           
            <div className="contacttext">
            <Link
              to="/contact" className="contacttext"> <FormattedMessage id="home.contactUs"></FormattedMessage>  </Link>
            </div>
          </div>
          </div>
          <div className='col  mb-5'>
          <div className="text-sm text-[#042552] ">
            <div className="contactheading"> <FormattedMessage id="home.solutions"></FormattedMessage></div>
            <div className="contacttext ">
            <Link
              to={{
                pathname: "/solutions",
                hash: "pdf",
              }} className="contacttext " >  <FormattedMessage id="home.pdfToWord"></FormattedMessage> </Link>
            </div>
          
            <div className="contacttext">
            <Link
              to={{
                pathname: "/solutions",
                hash: "text",
              }} className="contacttext " >  <FormattedMessage id="home.imageToText"></FormattedMessage> </Link>
            </div>
            
           
            <div className="contacttext">
            <Link
               to={{
                pathname: "/solutions",
                hash: "word",
              }}className="contacttext ">  <FormattedMessage id="home.wordToPDF"></FormattedMessage></Link>
            </div>
          </div>
          </div>
          <div className='col  mb-5'>
          <div className="text-sm text-[#042552] ">
            <div className=" contactheading"> 
           Posh Translate
             </div>
             <div className="contacttext"> 
             <Link
              to="/termscondition" className="contacttext "><FormattedMessage id="home.terms"></FormattedMessage> </Link>
             </div>
            <div className="contacttext"><Link
              to="/privacypolicy" className="contacttext "><FormattedMessage id="home.privacy"></FormattedMessage> </Link></div>
           
          
                
          </div>
          
          </div>
          { !isTabletOrMobile &&
          <div className='col  mb-5'>
          <div className="text-sm text-[#042552] ">
            <div className="contactheading">
             <FormattedMessage id="home.quickTranslation"></FormattedMessage>
            </div>
            <div className="flex flex-row ">
              <div className="w-[80px] contacttext"> <FormattedMessage id="home.english"></FormattedMessage> </div>
              <div className="contacttext"> <FormattedMessage id="home.urdu"></FormattedMessage> </div>
           
            </div>
            
            <div className="flex flex-row">
              <div className="w-[80px] contacttext"> <FormattedMessage id="home.arabic"></FormattedMessage></div>
              <div className="w-[80px] contacttext"> <FormattedMessage id="home.japanese"></FormattedMessage></div>
            </div>
            <div className="flex flex-row">
              <div className=" w-[80px] contacttext"><FormattedMessage id="home.french"></FormattedMessage></div>
              <div className="contacttext"><FormattedMessage id="home.chinese"></FormattedMessage></div>
            </div>
            <div className="flex flex-row">
            <div className="contacttext  w-[80px]"><FormattedMessage id="home.spanish"></FormattedMessage></div>
        
            <div className="contacttext"><FormattedMessage id="home.turkish"></FormattedMessage></div>
            </div>
            <div className="flex flex-row">
           <div className="contacttext"><FormattedMessage id="home.hindi"></FormattedMessage></div> 
            </div>
          </div>
          </div>
}
        </div>
       
       </div>
    

      <hr />


      <div className="flex flex-col md:flex-row items-center md:items-start w-full">
      { isTabletOrMobile &&
        
        <div className="container-fluid">
          <div className="row">
      <div className="text-opacity-70 text-left   col-sm-2 col  reserv ">
        © 2022 PoshTranslate All Rights Reserved.
    </div>
    <div className="h-7 text-right col-sm-1 col   mr-4 ">
          <div className="  follow"> <FormattedMessage id="home.follow"></FormattedMessage></div>
            </div>
      
        </div>
        </div>
        
         
        
}
      { !isTabletOrMobile &&
        <div className="flex-1 p-2">
       
          <div className="row">
      <div className="text-opacity-70   col col-md-4 col  reserv ">
        © 2022 PoshTranslate All Rights Reserved.
    </div>
   {/*  <div className="h-7 flex justify-center items-center col-md-1 col  mr-4 ">
          <div className=" text-sm follow"> <FormattedMessage id="home.follow"></FormattedMessage></div>
            </div> */}
      
        </div>
      
        
         
        </div>
}
        <div className="flex p-4 flex flex-row">
       
          <div className="flex flex-row md:items-end">
          <div className="h-7 flex justify-center items-center col  mr-4 ">
          { !isTabletOrMobile &&
          <div className=" text-sm follow"> <FormattedMessage id="home.follow"></FormattedMessage></div>}
           
            </div>
            <div className="bg-[#048A8F] bg-opacity-40 w-7 h-7 flex justify-center items-center rounded-full mr-4 hover:bg-opacity-100 hover:bg-[#01BABF]">
            <a  href="https://instagram.com" >
              <Instagram size={14} />
              </a>
            </div>
            <div className="bg-[#048A8F] bg-opacity-40 w-7 h-7 flex justify-center items-center rounded-full mr-4 hover:bg-opacity-100 hover:bg-[#01BABF]">
            <a href="https://www.linkedin.com/">
              <LinkedIn size={14} />
              </a>
            </div>
            <div className="bg-[#048A8F] bg-opacity-40 w-7 h-7 flex justify-center items-center rounded-full mr-4 hover:bg-opacity-100 hover:bg-[#01BABF]">
            <a href="https://facebook.com">
              <FaceBook size={14} />
              </a>
            </div>
            <div className="bg-[#048A8F] bg-opacity-40 w-7 h-7 flex justify-center items-center rounded-full mr-4 hover:bg-opacity-100 hover:bg-[#01BABF]">
            <a href="https://twitter.com/i/flow/login">
              <Twitter size={14} />
              </a>
            </div>
          </div>
          </div>
        </div>
      
      
     
      
        
    </section>
    

    
    


    </>
  );
}
