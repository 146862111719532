import React from "react";
import { FormattedMessage } from 'react-intl';
const ModalAlert = ({ show, limit, title, footer }) => {
  return (
    <div
    tabIndex="-1"
      aria-hidden="true"
      className={`${
        show ? "flex" : "hidden"
      } 
      
      transition-all overflow-y-auto overflow-x-hidden fixed top-0  right-0 left-0 z-50  h-modal  justify-center items-center`}
    >
      <div className="relative p-4 w-70 popupfiles">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Header */}
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <div className="font-semibold text-xl text-[#A92D62] "><FormattedMessage id="home.alert"></FormattedMessage></div>
          </div>

          {/* Body */}
          { <div className="p-6 space-y-6 text-[#000]">{title}&nbsp;&nbsp;&nbsp;{limit &&limit}</div> }

          {/* Footer */}
          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            {footer()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAlert;
