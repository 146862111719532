import React,{useEffect,useState} from "react";
import TranslateIcon from "../../svgIcons/TranslateIcon";
import Dots from "../../svgIcons/Dots";
import Arrow from "../../svgIcons/Arrow";
import ImageToText from "../../svgIcons/ImageToText";
import WordToPdf from "../../svgIcons/WordToPdf";
import PdfToWord from "../../svgIcons/Pdf";
import Excel from "../../svgIcons/Excel";
import OdtOds from "../../svgIcons/OdtOds";
import SrtTxt from "../../svgIcons/SrtTxt";
import Check from "../../svgIcons/Check";
import Instagram from "../../svgIcons/Instagram";
import LinkedIn from "../../svgIcons/LinkedIn";
import FaceBook from "../../svgIcons/FaceBook";
import Twitter from "../../svgIcons/Twitter";
import Posh from "../../svgIcons/Posh";
import Footer from "../../components/Footer/Footer";
import Navbar from  "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import InjectIntl from 'react-intl-inject';
const About = (props) => {
  const [languageSelected, setAppLanguage] = useState('en');
  useEffect(() => {
    setAppLanguage(props.language)
  }, [props])
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (

    <InjectIntl>
    {({ intl }) => (
          <>
    <div className="max-w-[1280px] mx-auto py-20" style={{ direction: (languageSelected == 'ar' || languageSelected == 'ur')  ? 'rtl' : 'ltr' }}>
    <Navbar setLanguage={props.setLanguage}  language={props.language}/>

    

      <section className="flex px-10 items-center flex-col sm:flex-row">
        <div className="flex flex-1 p-4  flex-col">
          <div className="mb-2 aboutHeading">
          {intl.formatMessage({ id: 'home.aboutHeading' })}
          </div>
          <div className="text-[#048A8F] subHeading1">
          {intl.formatMessage({ id: 'home.subHeading1' })}
            <br />
            
            </div>
            <div className="subHeading2">
            {intl.formatMessage({ id: 'home.subHeading2' })}
            <br />
            <br />
           {/*  {intl.formatMessage({ id: 'home.subHeading3' })} */}
          </div>
         {/*  <button className="bg-[#A92D62] rounded-full text-white w-fit px-4 py-[6px] mt-4">
           
            <Link
              to="/pricing">{intl.formatMessage({ id: 'home.tryFree' })} </Link>
          </button> */}
        </div>
        <div className="flex flex-1 p-4">
          <div className="relative w-fit h-fit">
            {/* <div className="absolute -z-10 right-3 bottom-2">
              <Dots size={100} />
            </div> */}
            <img src="/assets/imgs/aboutus.png" alt="heroImage" />
          </div>
        </div>
      </section>

    {/*   <section className="flex flex-col items-center bg-[#042552] bg-opacity-[0.02] py-10">
        <div className="text-[#042552] font-semibold text-[30px] max-w-[600px] text-center">
        {intl.formatMessage({ id: 'home.discover' })}{" "}
          <span className="text-[#F9B035]">{intl.formatMessage({ id: 'home.posh' })}</span>
        </div>

        <div className="flex flex-row flex-wrap max-w-[700px] items-center justify-center mt-3">
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company1.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company2.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company3.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company4.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company5.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company6.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
          <div className="h-[40px] w-[140px] m-3 rounded-md shadow-md overflow-hidden">
            <img
              src="/assets/imgs/company7.png"
              alt="asd"
              className="h-full object-cover w-full"
            />
          </div>
        </div>
      </section> */}

      {/* <section className="py-10 flex flex-col justify-center items-center bg-[#048A8F]">
        <div className="text-white text-[24px] font-semibold my-1">
        {intl.formatMessage({ id: 'home.langHeading' })}
        </div>
        <div className="text-white text-sm font-normal my-1 max-w-[600px] text-center">
        {intl.formatMessage({ id: 'home.langSubHeading' })}
        </div>
   
      </section> */}


    </div>
    <Footer />
    </>
    
    )}

    </InjectIntl>

  
  );
};

export default About;
